import React from 'react';
import PropTypes from 'prop-types';
import './IsochronePolygonSymbol.scss';
import { ISOCHRONE_POLYGON_SYMBOL_COLOR_SPECTRE } from '../../../constants/isochroneConstants';

export default function IsochronePolygonSymbol(props) {
  const { polygonDistance } = props;
  const symbolColor = ISOCHRONE_POLYGON_SYMBOL_COLOR_SPECTRE[polygonDistance];

  return (
    <div className="isochrone-polygon-symbol" style={{ background: symbolColor }} />
  );
}

IsochronePolygonSymbol.propTypes = { polygonDistance: PropTypes.string.isRequired };
