import React from 'react';
import './StepInfoHeader.scss';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MixPanel from '../../../../setup/mixPanel';
import * as PlanningPageActions from '../../../../state/actions/planningPageActions';
import Button from '../../../../common/components/buttons/button/Button';
import { BUTTON_TYPES } from '../../../../common/components/buttons/button/constants/buttonTypes';

/**
 * Step info message and header
 *
 * @param {object} props - element props
 * @returns {JSX.Element} - step info message and header
 * @component
 * @alias StepInfoHeader
 * @category planning
 */
export default function StepInfoHeader(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  let coveredNum = null;

  const onCancel = () => {
    dispatch(PlanningPageActions.resetData());
    history.push({ pathname: props.options.cancelToPage || '/delivery-plan', state: location.state });
  };

  const backToPreviousPage = () => {
    MixPanel.track('Return clicked', { location: location.pathname });
    if (props?.options?.backCallback) {
      props.options.backCallback();
    }

    if (props.onReturnClick) props.onReturnClick();
    else history.goBack();
  };

  const nextStep = () => {
    if (props.options.nextButtonDisabled) {
      return;
    }

    if (props.onNextClick) props.onNextClick();
  };

  const checkCoveredRegions = () => {
    let num = 0;
    if (props.regionSelection) {
      Object.keys(props.regionSelection).forEach((hexId) => {
        if (props.regionSelection[hexId].covered) {
          num += props.regionSelection[hexId].stops;
        }
      });
    }
    coveredNum = num;
    return num;
  };

  return (
    <div className="step-info-header">
      <div className="message">{props.message}</div>
      {props.regionSelection && (
        <div className="shipments-covered-info">
          <div className={checkCoveredRegions() < props.regionSelectionLength ? 'not-covered' : 'covered'}>{coveredNum}</div>
          <div className="shipments-max">
            {' '}
            /
            {props.regionSelectionLength}
          </div>
        </div>
      )}
      {props.additionalElement && React.createElement(props.additionalElement, props.additionalElementProps)}
      {!props.options.cancelButtonHidden && (
        <Button type={BUTTON_TYPES.SECONDARY} text={props.options.cancelButtonText ? props.options.cancelButtonText : t('Cancel')} onClick={onCancel} />
      )}
      {!props.options.returnButtonHidden && (
        <Button type={BUTTON_TYPES.SECONDARY} text={t('Return')} onClick={backToPreviousPage} />
      )}
      {props.nextButtonText && (
        <Button type={BUTTON_TYPES.PRIMARY} text={props.nextButtonText} onClick={nextStep} disabled={props.options.nextButtonDisabled || props.options.nextButtonWithOpacity} />
      )}
    </div>
  );
}

StepInfoHeader.propTypes = {
  message: PropTypes.string.isRequired,
  nextButtonText: PropTypes.string,
  options: PropTypes.shape({
    nextButtonDisabled: PropTypes.bool,
    returnButtonHidden: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    cancelButtonHidden: PropTypes.bool,
    cancelToPage: PropTypes.string,
    backCallback: PropTypes.func,
    nextButtonWithOpacity: PropTypes.bool
  }),
  onNextClick: PropTypes.func,
  onReturnClick: PropTypes.func,
  additionalElement: PropTypes.func,
  additionalElementProps: PropTypes.object,
  regionSelection: PropTypes.shape(),
  regionSelectionLength: PropTypes.number
};

StepInfoHeader.defaultProps = {
  nextButtonText: '',
  options: {},
  onNextClick: null,
  onReturnClick: null,
  additionalElement: null,
  additionalElementProps: null,
  regionSelection: null,
  regionSelectionLength: 0
};
