import React from 'react';
import { useHistory } from 'react-router-dom';
import RouteUtil from '../utils/routeUtil';

/**
 * Redirects to /courier-analysis if requested url does not exists
 *
 * @returns {JSX.Element} - called when page is not found
 * @component
 * @alias PageNotFound
 * @category App
 */
export default function PageNotFound() {
  const history = useHistory();

  window.setTimeout(() => {
    history.push(RouteUtil.getDefaultPage());
  }, 1000);

  return <div />;
}
