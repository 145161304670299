import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './DistancePolygonConfigMenu.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../common/components/buttons/button/Button';
import { BUTTON_TYPES } from '../../../../../common/components/buttons/button/constants/buttonTypes';
import SingleRadioButton from '../../../../../common/components/buttons/radioButton/SingleRadioButton';
import DistanceTypeUtil, { DISTANCE_TYPE } from '../../../../../common/utils/distanceTypeUtil';
import MixPanel from '../../../../../setup/mixPanel';

export default function DistancePolygonConfigMenu(props) {
  const { t } = useTranslation();
  const [selectedDistanceValue, setSelectedDistanceValue] = useState(props.getDistanceType());

  const getContourDistancesAndUnits = (distanceType) => {
    const distances = DistanceTypeUtil.getDistanceValues(distanceType);
    return `${distances.join(` ${t('and')} `)} ${t(`unit-name-${DistanceTypeUtil.getUnit(distanceType)}`)}`;
  };

  return (
    <div className={`distance-polygon-config-menu-wrapper${props.isDistanceMenuOpen ? ' expanded' : ''}`}>
      <div className="animation-container">
        <div className="config-menu">
          <div className="config-menu-title">
            {t('Configure')}
          </div>
          <div className="config-menu-form">
            <div className="config-menu-choices">
              <div className="choices-title">
                {t('Chose distance')}
              </div>
              <div className="config-menu-choices-rows">
                {
                  Object.keys(DISTANCE_TYPE).map((distanceType) => (
                    <div className="choice-row" key={distanceType}>
                      <div className="single-radio-button-wrapper">
                        <SingleRadioButton
                          value={distanceType}
                          checked={selectedDistanceValue === distanceType}
                          onValueChange={setSelectedDistanceValue}
                          displayValue={false}
                          onClickAdditionalAction={() => {
                            MixPanel.track(`Delivery Area Analysis - Distance Config menu radio button clicked ${distanceType}`);
                          }}
                        />
                      </div>
                      <div className="choice-text">
                        <div className="choice-title">
                          {t(DistanceTypeUtil.getConfigMenuTitle(distanceType))}
                        </div>
                        <div className="choice-description">
                          {getContourDistancesAndUnits(distanceType)}
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="config-menu-buttons">
            <Button
              type={BUTTON_TYPES.SECONDARY}
              text={t('Cancel')}
              onClick={() => {
                MixPanel.track('Delivery Area Analysis - Distance Config menu close button clicked');
                props.onClose();
              }}
            />
            <Button
              type={BUTTON_TYPES.PRIMARY}
              text={t('Select')}
              disabled={props.getDistanceType() === selectedDistanceValue}
              onClick={() => {
                MixPanel.track('Delivery Area Analysis - Distance Config menu confirm button clicked');
                props.setSelectedDistanceValue(selectedDistanceValue);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DistancePolygonConfigMenu.propTypes = {
  setSelectedDistanceValue: PropTypes.func.isRequired,
  isDistanceMenuOpen: PropTypes.bool,
  getDistanceType: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

DistancePolygonConfigMenu.defaultProps = { isDistanceMenuOpen: false };
