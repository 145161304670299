import * as _ from 'lodash';
import { useEffect } from 'react';

/*
   * This hook allows you to use useEffect that will run only if all dependencies are not empty.
   * @param {Function} effect - the effect to run.
   * @param {Array} dependencies - the dependencies to check if they are empty.
 */
export default function useConditionalEffect(effect, dependencies) {
  useEffect(() => {
    const hasEmptyDependency = dependencies.some((dependency) => {
      return !dependency || _.isEmpty(dependency);
    });

    if (!hasEmptyDependency) {
      effect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
