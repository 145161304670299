import React from 'react';
import mapboxgl from 'mapbox-gl';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MAP_STYLE } from '../../../../common/constants/mapConstants';
import MapUtil from '../../../../common/utils/mapUtil';
import * as MapActions from '../../../../state/actions/mapActions';
import * as PageActions from '../../../../state/actions/pageActions';
import MapBoxWrapper from '../../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import './HubMap.scss';
import Footer from '../../../../common/components/footers/Footer';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

/**
 * A wrapper for map functionalities
 *
 * @component
 * @alias HubMap
 * @category HubMap
 */
class HubMapClass extends React.Component {
  constructor(props) {
    super();

    this.map = null;
    this.mapContainer = null;
    this.marker = null;

    this.state = {
      mapViewState: {
        longitude: props?.hubData?.lng,
        latitude: props?.hubData?.lat
      }
    };

    props.handleViewStateChange({ viewState: this.state.mapViewState });
  }

  componentDidMount() {
    this.props.dispatchLoadingPage(true);

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAP_STYLE,
      center: [this.props.hubData.lng, this.props.hubData.lat],
      zoom: this.props.zoom
    });
    this.props.dispatchSetMap(this.map);

    this.addPinToMap();
    this.setMapEvents();
    this.addMapControls();
    if (this.props.hubAdd) this.updatePinDrag();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hubData !== this.props.hubData) if (!this.props.hubAdd) this.changeMapViewPosition();
  }

  changeMapViewPosition = () => {
    if (this.props.hubData && this.props.hubData.lat && this.props.hubData.lng) {
      this.map.flyTo({
        center: [this.props.hubData.lng, this.props.hubData.lat],
        zoom: 15,
        speed: 1.0,
        curve: 1
      });
      this.addPinToMap();
    }
  };

  /**
   * Add a pin to the map,
   *
   * @function
   */
  addPinToMap = () => {
    if (this.marker == null) {
      this.marker = new mapboxgl.Marker({ color: '#009bfa', draggable: this.props.hubAdd });
    }
    this.marker.setLngLat([this.props.hubData.lng, this.props.hubData.lat]).addTo(this.map);
  };

  /**
   * Update the pin coordinates in HubManagement
   *
   * @function
   */
  updatePinDrag = () => {
    this.marker.on('drag', () => {
      this.props.savePin(this.marker);
    });
  };

  /**
   * Place where we set all map events
   *
   * @function
   */
  setMapEvents = () => {
    this.map.on('load', () => {
      this.props.dispatchLoadingPage(false);
    });
  };

  /**
   * Place where we add all map controls
   *
   * @function
   */
  addMapControls = () => {
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl());
  };

  setMapRef = (ref) => {
    this.mapContainer = ref;
  };

  render() {
    const footerData = [
      { label: this.props.t('Longitude'), value: this.props.coordinates.lng.toFixed(4) },
      { label: this.props.t('Latitude'), value: this.props.coordinates.lat.toFixed(4) }
    ];
    return (
      <div className={this.props.hubAdd ? 'hub-add-map-wrapper' : 'hub-management-map-wrapper'}>
        <MapBoxWrapper setMapRef={this.setMapRef} />
        <Footer data={footerData} />
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.regionAnalysisState, ...store.coordinatesState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @param {object} ownProps - commponent props
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchUpdateRegionStopsDistribution: ownProps.updateRegionStopsDistributionAction,
      dispatchSetMap: MapActions.setMap
    },
    dispatch
  );
}

HubMapClass.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  savePin: PropTypes.func,
  hubData: PropTypes.object,
  hubAdd: PropTypes.bool,
  zoom: PropTypes.number,
  handleViewStateChange: PropTypes.func,
  dispatchSetMap: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  coordinates: PropTypes.object.isRequired
};

HubMapClass.defaultProps = {
  hubData: { lng: MapUtil.getInitialViewStateForCompany().lng, lat: MapUtil.getInitialViewStateForCompany().lat },
  hubAdd: false,
  savePin: () => {},
  zoom: MapUtil.getInitialViewStateForCompany().zoom,
  handleViewStateChange: () => {}
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(HubMapClass));
