import geojson2h3 from 'geojson2h3';
import { H3_HEX_RESOLUTION } from '../../../../common/constants/mapConstants';
import DistanceTypeUtil, { DISTANCE_VALUE_TYPE_KEYS_ARRAY } from '../../../../common/utils/distanceTypeUtil';

export const IsochroneUtil = {
  createPointsIsochroneData: createPointsIsochroneData,
  buildIsochroneRequest: buildIsochroneRequest,
  getIsochronesAsHexagonsRequestVariables: getIsochronesAsHexagonsRequestVariables
};

function createPointsIsochroneData(mapPoints, data) {
  const newDistancePins = {};
  Object.values(mapPoints).forEach((mapPoint, i) => {
    const hexagonsCovered = {
      [DISTANCE_VALUE_TYPE_KEYS_ARRAY[0]]: data.innerIsochrone[i],
      [DISTANCE_VALUE_TYPE_KEYS_ARRAY[1]]: data.outerIsochrone[i]
    };
    const polygonFeatures = {
      type: 'FeatureCollection',
      features: [
        geojson2h3.h3SetToFeature(hexagonsCovered[DISTANCE_VALUE_TYPE_KEYS_ARRAY[0]]),
        geojson2h3.h3SetToFeature(hexagonsCovered[DISTANCE_VALUE_TYPE_KEYS_ARRAY[1]])
      ]
    };
    newDistancePins[mapPoint.id] = {
      polygonFeatures,
      hexagonsCovered,
      id: mapPoint.id
    };
  });

  return newDistancePins;
}

/*
 * Build the request for the routing api.
 * @param {Array} mapPoints - array of map mapPoints to fetch.
 * @param {String} distanceType - costing type. See ISOCHRONE.DISTANCE_TYPE in mapAdditionalFeaturesData.js
 * @param {Number} time - time in minutes.
 * @returns {Object} - request object for the routing api with the structure { action: String, request: Object }.
 */
function buildIsochroneRequest(mapPoints, distanceType, time) {
  const request = {
    locations: Object.values(mapPoints).map((point) => {
      return {
        lat: point.lat,
        lon: point.lng
      };
    }),
    costing: DistanceTypeUtil.getIsochroneApiDistanceType(distanceType),
    contours: [{ time: time }]
  };

  return { request: request };
}

function getIsochronesAsHexagonsRequestVariables(mapPoints, distanceType) {
  return {
    ...getIsochronesTypeRequestVariables(mapPoints, distanceType),
    resolution: H3_HEX_RESOLUTION
  };
}

function getIsochronesTypeRequestVariables(mapPoints, distanceType) {
  const distanceTypeValues = DistanceTypeUtil.getDistanceValues(distanceType);

  const innerDistanceRequest = IsochroneUtil.buildIsochroneRequest(mapPoints, distanceType, distanceTypeValues[0]);
  const outerDistanceRequest = IsochroneUtil.buildIsochroneRequest(mapPoints, distanceType, distanceTypeValues[1]);

  return {
    innerRequest: innerDistanceRequest.request,
    outerRequest: outerDistanceRequest.request
  };
}
