export const SELECTION_TOOL = {
  PICK: 'pick',
  POLYGON: 'polygon',
  RECTANGLE: 'rectangle',
  CIRCLE: 'circle'
};

export const SELECTION_TOOL_ICON = {
  pick: 'icon-select-object',
  polygon: 'icon-draw-polygon',
  rectangle: 'icon-rectangle',
  circle: 'icon-circle'
};

export const EDIT_MODE = {
  ADD: 'union',
  REMOVE: 'difference'
};
