import * as React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import '../../../analysis/courierAnalysis/components/MapWrapper.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import TeamSelect from '../../../../common/components/selections/teamSelect/TeamSelect';
import MixPanelUtil from '../../../../common/utils/mixPanelUtil';
import { raygunClient } from '../../../../setup/raygunClient';
import * as MapActions from '../../../../state/actions/mapActions';
import './PlanningPagePerCourier.scss';
import * as PageActions from '../../../../state/actions/pageActions';
import S3Util from '../../../../common/utils/s3Util';
import * as PlanningPageActions from '../../../../state/actions/planningPageActions';
import PlanPreview from '../components/PlanPreview';
import PlanningApi from '../../api/planningApi';
import CardMenuTable from '../../../../common/components/tables/cardMenuTable/CardMenuTable';
import PlanningCard from '../components/PlanningCard';
import MixPanel from '../../../../setup/mixPanel';
import TableActions from '../../../../common/components/tables/common/TableActions';
import PlanningDataUtil from '../../utils/planningDataUtil';
import QueryStringUtil from '../../../../common/utils/queryStringUtil';
import { ENTITY_TYPE } from '../../../../common/constants/entityTypes';
import { BUTTON_TYPES } from '../../../../common/components/buttons/button/constants/buttonTypes';
import Button from '../../../../common/components/buttons/button/Button';

/**
 * Component which displays first page in planning process
 *
 * @component
 * @alias PlanningPage
 * @category planning
 */
class PlanningPagePerCourierClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlan: false,
      plans: null,
      tablePageSize: 1,
      selectedPlanId: null
    };

    this.planElement = null;
    this.currentDate = moment().format('YYYY-MM-DD');
  }

  componentDidMount() {
    this.props.dispatchResetPlanningData();
    this.props.dispatchLoadingPage(false);
    if (this.props.history?.location?.state?.teamId) QueryStringUtil.setQueryStringValue('teamId', this.props.history.location.state.teamId);
    MixPanel.track('Page load - Delivery plans');
    MixPanelUtil.setUnloadListener('Page Unload - Delivery plans');
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
  }

  getCouriersPlans = () => {
    if (this.currentDate) {
      this.props.dispatchLoadingPage(true);
      PlanningApi.getCouriersPlans(this.currentDate, this.teamId)
        .then((plansData) => {
          this.setState({ plans: plansData?.data?.getCouriersPlans });
          this.props.dispatchLoadingPage(false);
        })
        .catch((error) => {
          raygunClient.send(error, 'Error loading plan data for courier', { date: this.currentDate });
          toast.error(this.props.t('Failed to load plan data'));
          this.props.dispatchLoadingPage(false);
        });
    }
  };

  createNewPlan = () => {
    MixPanel.track('Delivery plans - Create new plan', { teamId: this.teamId, planDate: this.planDate, teamName: this.teamName });
    this.props.history.push({
      pathname: '/delivery-plan/table-view',
      state: { planDate: this.currentDate, teamId: this.teamId, entityType: this.entityType, teamName: this.teamName }
    });
  };

  showPlan = (plan) => {
    const routePromise = PlanningDataUtil.getPlanRouteData(plan);
    const shipmentsPromise = PlanningDataUtil.getPlanShipmentsData(plan);

    Promise.all([routePromise, shipmentsPromise])
      .then((res) => {
        const [routeData, shipmentsData] = res;
        this.planElement = <PlanPreview routeData={[routeData]} shipmentsData={[shipmentsData]} />;
        this.setState({ showPlan: true, selectedPlanId: plan.courierId });
      })
      .catch((err) => {
        raygunClient.send(err, 'Error loading plan data', { plan: plan });
        toast.error(this.props.t('Oops something went wrong'));
      });

    MixPanel.track('Delivery plans - Show plan', {
      courierId: plan.courierId,
      courierName: plan.courierFullName,
      teamId: plan.teamId
    });
  };

  sendClientMessagesForPlan = (row, e) => {
    e.stopPropagation();
    const plan = row.original;
    const fileName = plan.clientMessagesCsv;
    this.props.dispatchLoadingPage(true);
    S3Util.downloadFileFromS3(
      fileName,
      {
        download: true,
        bucket: row.original.fileFolder,
        cacheControl: 'no-cache'
      },
      fileName
    )
      .then(() => {
        this.props.dispatchLoadingPage(false);
      })
      .catch((err) => {
        raygunClient.send(err, 'Error downloading messages for plan', { fileName: fileName });
        toast.error(this.props.t('Oops something went wrong'));
        this.props.dispatchLoadingPage(false);
      });

    MixPanel.track('Delivery plans - Download messages for plan', {
      courierId: row.original.courierId,
      courierName: row.original.courierFullName,
      teamId: row.original.teamId
    });
  };

  downloadPlan = (row, e) => {
    e.stopPropagation();
    const fileName = row.original.orderedShipmentsExcel;
    this.props.dispatchLoadingPage(true);
    S3Util.downloadFileFromS3(
      fileName,
      {
        download: true,
        bucket: row.original.fileFolder,
        cacheControl: 'no-cache'
      },
      fileName
    )
      .then(() => {
        this.props.dispatchLoadingPage(false);
      })
      .catch((err) => {
        raygunClient.send(err, 'Error downloading plan', { fileName: fileName });
        toast.error(this.props.t('Oops something went wrong'));
        this.props.dispatchLoadingPage(false);
      });

    MixPanel.track('Delivery plans - Download plan', {
      courierId: row.original.courierId,
      courierName: row.original.courierFullName,
      teamId: row.original.teamId
    });
  };

  deletePlan = async (row, e) => {
    const plan = row.original;
    e.stopPropagation();
    AppDialogActionsWrapper.openConfirmationDialog({
      title: `${this.props.t('Delete plan')}?`,
      body: `${this.props.t('All data relating to the plan will be removed')}.`,
      confirmButtonText: this.props.t('Delete'),
      continueCallback: () => {
        PlanningApi.deletePlan(plan.date, plan.teamId, plan.courierId)
          .then(() => {
            this.getCouriersPlans();
            this.setState({ showPlan: false, selectedPlanId: null });
            MixPanel.track('Delivery plans - Plan deleted', {
              courierId: row.original.courierId,
              courierName: row.original.courierFullName,
              teamId: row.original.teamId
            });
          })
          .catch((error) => {
            raygunClient.send(error, 'Error deleting plan');
            toast.error(this.props.t('Oops something went wrong'));
            this.props.dispatchLoadingPage(false);
          });
      }
    });
  };

  getTableColumns = () => [
    {
      Header: null,
      accessor: 'courierFullName'
    },
    {
      Header: null,
      id: 'actions',
      disableGlobalFilter: true,
      className: 'centered',
      Cell: ({ row }) => (
        <TableActions type="card" row={row} onNotify={this.sendClientMessagesForPlan} onDownload={this.downloadPlan} onDelete={this.deletePlan} />
      )
    }
  ];

  calculateTablePageSize = (height) => {
    if (this.state.tablePageSize !== height) {
      this.setState({ tablePageSize: height });
    }
  };

  onTeamChange = async (team) => {
    this.teamId = team.teamId;
    this.entityType = team.teamType;
    this.teamName = team.teamName;
    this.setState({ showPlan: false, selectedPlanId: null, teamId: team.teamId });
    this.getCouriersPlans();
  };

  render() {
    return (
      <div className="planning-page-per-courier">
        <div className="menu-wrapper">
          <div className="selection-wrapper">
            <div className="label">{this.props.t('Team')}</div>
            <TeamSelect onTeamChange={this.onTeamChange} returnFullObject eventTrackerNamePrefix="Delivery plans" teamTypeFilter={[ENTITY_TYPE.COURIERS]} />
          </div>
          <Button type={BUTTON_TYPES.PRIMARY} text={this.props.t('Create a new plan')} onClick={this.createNewPlan} />
          <div className="menu">
            {this.state.plans && this.state.plans.length > 0 && (
              <CardMenuTable
                key={this.state.tablePageSize}
                columns={this.getTableColumns()}
                data={this.state.plans}
                resetOn={this.state.teamId}
                sortBy={[{ id: 'name', desc: false }]}
                pageSize={this.state.tablePageSize}
                handleRowClick={this.showPlan}
                cardItemComponent={PlanningCard}
                hiddenColumns={['courierId']}
                defaultPageSize={this.state.tablePageSize}
                calculateTablePageSize={this.calculateTablePageSize}
                selectedIndex={this.state.selectedPlanId}
                showSearch
                autoResetSelectedRows
              />
            )}
          </div>
        </div>
        <div className="map-wrapper">
          {!this.state.showPlan ? (
            <div className="empty-page">
              <i className="icon icon-mily-logo" />
            </div>
          ) : (
            this.planElement
          )}
        </div>
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.planningPageState };
}

/**
 * @param {Function} dispatch - redux dispatch function
 * @returns {object} - map actions to component props
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchIsMapLoading: MapActions.mapIsLoading,
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchResetPlanningData: PlanningPageActions.resetData
    },
    dispatch
  );
}

export default withTranslation('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanningPagePerCourierClass)));

PlanningPagePerCourierClass.propTypes = {
  /**
   * React router history object
   */
  history: PropTypes.object.isRequired,
  /**
   * Dispatches event if page is currently loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  dispatchResetPlanningData: PropTypes.func.isRequired
};

PlanningPagePerCourierClass.defaultProps = {};
