import { Storage } from 'aws-amplify';

/**
 * Util for AWS S3 related functions
 *
 * @namespace
 * @category Common
 */
const S3Util = {
  uploadFileToS3: uploadFileToS3,
  getFileFromS3: getFileFromS3,
  downloadFileFromS3: downloadFileFromS3
};

/**
 * Uploads file to S3 bucket (default bucket is planning bucket)
 *
 * @param {string} fileName - file name
 * @param {object} fileContent - file content
 * @param {object} config - config object
 * @param {boolean} showcaseFeature - to hack local mode to use cloud data
 * @returns {Promise} - file upload promise
 * @memberOf S3Util
 * @function
 */
function uploadFileToS3(fileName, fileContent, config, showcaseFeature = false) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api' || showcaseFeature) {
    return Storage.put(fileName, fileContent, config);
  }

  return new Promise((resolve) => {
    resolve(true);
  });
}

/**
 * Uploads file to S3 bucket (default bucket is planning bucket)
 *
 * @param {string} fileName - file name
 * @param {object} config - config object
 * @param {boolean} showcaseFeature - to hack local mode to use cloud data
 * @returns {Promise} promise with file content
 * @memberOf S3Util
 * @function
 */
function getFileFromS3(fileName, config, showcaseFeature = false) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api' || showcaseFeature) {
    return Storage.get(fileName, config).then((f) => f.Body.text());
  }

  return fetch(`/testData/${fileName}`).then((data) => data.text());
}

function downloadFileFromS3(fileName, config, downloadedFileName = null) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return Storage.get(fileName, config).then((res) => {
      const blob = res.Body;
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', downloadedFileName || fileName);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  }

  const pathRemovedFileName = downloadedFileName.substring(downloadedFileName.lastIndexOf('/') + 1);

  const fileUrl = `/testData/${fileName}`;
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', pathRemovedFileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return new Promise((resolve) => {
    resolve('Success');
  });
}

export default S3Util;
