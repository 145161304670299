import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../MapModeButton.scss';
import { useTranslation } from 'react-i18next';
import MapModeButton from '../MapModeButton';
import { SELECTION_TOOL, SELECTION_TOOL_ICON } from '../constants/drawHexagonsConst';
import MilyTooltipWrapper from '../../../tooltips/MilyTooltipWrapper';

export default function HexSelectionToolsMenu(props) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [selectionTool, setSelectionTool] = useState(getDefaultSelectionToolValue());

  function getDefaultSelectionToolValue() {
    return SELECTION_TOOL.PICK;
  }

  function changeSelectionTool(e) {
    if (props.changeSelectionTool) {
      props.changeSelectionTool(e);
    }

    setSelectionTool(e.currentTarget.dataset.value);
  }

  function getMenuItemClassName(selectionToolValue) {
    return `menu-item${selectionTool === selectionToolValue ? ' active' : ''}`;
  }

  return (
    <MapModeButton
      customKey="selection-tool"
      toggleMenu={() => { setOpenMenu(!openMenu); }}
      dataFor="selection-tool-tooltip"
      dataTip={t('Choose selection tool')}
      icon={SELECTION_TOOL_ICON[selectionTool]}
      isActive
    >
      <div className="menu menu-vertical" style={{ display: openMenu ? 'block' : 'none' }}>
        <div className={getMenuItemClassName(SELECTION_TOOL.PICK)} onClick={changeSelectionTool} data-value={SELECTION_TOOL.PICK}>
          <i className="icon icon-select-object" />
          <span className="selection-label">Pick</span>
        </div>
        <div className={getMenuItemClassName(SELECTION_TOOL.RECTANGLE)} onClick={changeSelectionTool} data-value={SELECTION_TOOL.RECTANGLE}>
          <i className="icon icon-rectangle" />
          <span className="selection-label">Rectangle</span>
        </div>
        <div className={getMenuItemClassName(SELECTION_TOOL.POLYGON)} onClick={changeSelectionTool} data-value={SELECTION_TOOL.POLYGON}>
          <i className="icon icon-draw-polygon" />
          <span className="selection-label">Polygon</span>
        </div>
        <div className={getMenuItemClassName(SELECTION_TOOL.CIRCLE)} onClick={changeSelectionTool} data-value={SELECTION_TOOL.CIRCLE}>
          <i className="icon icon-circle" />
          <span className="selection-label">Circle</span>
        </div>
      </div>
      {!openMenu && <MilyTooltipWrapper id="selection-tool-tooltip" className="tooltip" place="right" effect="solid" />}
    </MapModeButton>
  );
}

HexSelectionToolsMenu.propTypes = { changeSelectionTool: PropTypes.func };

HexSelectionToolsMenu.defaultProps = { changeSelectionTool: null };
