import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

export default function Button(props) {
  function getClassNames() {
    let classNames = `mily-button ${props.type}`;

    if (props.disabled) {
      classNames += ' disabled';
    }
    return classNames;
  }

  return (
    <div
      className={getClassNames()}
      onClick={() => { if (!props.disabled) props.onClick(); }}
    >
      {props.icons?.left && <i className={`left-icon ${props.icons?.left}`} />}
      {props.text}
      {props.icons?.right && <i className={`right-icon ${props.icons?.right}`} />}
    </div>
  );
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  icons: PropTypes.shape({
    left: PropTypes.string,
    right: PropTypes.string
  })
};

Button.defaultProps = {
  disabled: false,
  onClick: () => {},
  text: '',
  icons: null
};
