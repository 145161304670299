import PropTypes from 'prop-types';
import React from 'react';
import './CoverageDataTable.scss';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import DistanceTypeUtil, { DISTANCE_VALUE_TYPE_KEYS_ARRAY } from '../../../../../common/utils/distanceTypeUtil';

export default function CoverageDataTable(props) {
  const { t } = useTranslation();

  // {innerDistance: x, outerDistance: x, percentage: x}

  function getDistanceRows() {
    const distanceTypeIcon = DistanceTypeUtil.getIcon(props.distanceType);
    const unit = DistanceTypeUtil.getUnit(props.distanceType);
    return DISTANCE_VALUE_TYPE_KEYS_ARRAY
      .map((distanceKey) => {
        const value = DistanceTypeUtil.getDistanceValue(props.distanceType, distanceKey);
        return (
          <div className="data-row" key={`${distanceKey}`}>
            <i className={`icon ${distanceTypeIcon}`} />
            <div className="data-name">
              {`${value}${unit}`}
            </div>
            <div className={getClassForDataValue(props.coverageMenuTableData[distanceKey])}>
              {props.coverageMenuTableData[distanceKey]}
            </div>
          </div>
        );
      });
  }

  function getClassForDataValue(value) {
    if (value.toString().length > 7) return 'data-value small-font-size';
    return 'data-value';
  }

  function checkIfCoverageDataExists() {
    let dataExists = false;
    if (!_.isEmpty(props.coverageMenuTableData)) {
      DISTANCE_VALUE_TYPE_KEYS_ARRAY.forEach((polygonType) => {
        if (props.coverageMenuTableData[polygonType] > 0) dataExists = true;
      });
    }

    return dataExists;
  }

  return (
    <div className={`coverage-data-table ${props.coverageType}`}>
      <div className="data-row-percentage">
        <div className="data-name">
          {t(props.coverageType)}
        </div>
        <div className="data-value">
          {checkIfCoverageDataExists() && props.coverageMenuTableData.percentage}
        </div>
      </div>

      {checkIfCoverageDataExists() ? getDistanceRows() : (<div className="no-coverage-data">/</div>)}

    </div>
  );
}

CoverageDataTable.propTypes = {
  coverageType: PropTypes.string,
  coverageMenuTableData: PropTypes.object,
  distanceType: PropTypes.string.isRequired
};

CoverageDataTable.defaultProps = {
  coverageType: null,
  coverageMenuTableData: null
};
