import { gql } from '@apollo/client';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';

const ShipmentsApi = {
  getNonCompletedShipmentsFor: getNonCompletedShipmentsFor,
  getAssignedShipments: getAssignedShipments
};

const baseShipmentFields = ['name', 'address', 'shipmentCode', 'phone'];

const GetNonCompletedShipmentsFor = gql`
  query GetNonCompletedShipmentsFor($pk: String, $sk: String, $indexName: String, $lastEvaluatedKey: String, $operation: String) {
    queryShipments(pk: $pk, sk: $sk, indexName: $indexName, lastEvaluatedKey: $lastEvaluatedKey, operation: $operation) {
      items {
        ${ApiHelpers.createQueryFieldList(baseShipmentFields)}
        jobType
        creationTime
        fuzzyAddress {
          houseNumber
          streetName
          townName
          country
          geoLat
          geoLng
        }
        region
      }
      lastEvaluatedKey
    }
  }
`;

const GetAssignedShipments = gql`
  query GetAssignedShipments($pk: String, $sk: String, $indexName: String) {
    queryShipments(pk: $pk, sk: $sk, indexName: $indexName) {
      items {
        ${ApiHelpers.createQueryFieldList(baseShipmentFields)}
        jobType
        creationTime
        fuzzyAddress {
          houseNumber
          streetName
          townName
          country
          geoLat
          geoLng
          hasFuzzyMatch
          receiver
        }
        region
        senderName
        cashOnDelivery
        townName
      }
      lastEvaluatedKey
    }
  }
`;

async function getNonCompletedShipmentsFor(areaId, date) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const variables = {
      pk: DynamoStringExtensionsUtil.addShipmentPrefix(areaId),
      indexName: 'shi',
      operation: 'gt'
    };

    if (date) {
      variables.sk = date;
    }

    return ApiHelpers.createQuery(GetNonCompletedShipmentsFor, variables);
  }

  return fetch(`/testData/shipments/nonCompletedFor_${areaId}.json`)
    .then((data) => data.json())
    .catch((e) => e);
}

async function getAssignedShipments(courierId, date) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const variables = {
      pk: DynamoStringExtensionsUtil.addShipmentPrefix(courierId),
      sk: DynamoStringExtensionsUtil.addShipmentPrefix(date),
      indexName: 'gsi2'
    };
    return ApiHelpers.createQuery(GetAssignedShipments, variables);
  }

  return fetch('/testData/planning-per-courier/planPreview/assignedShipments.json')
    .then((data) => data.json())
    .catch((e) => e);
}

export default ShipmentsApi;
