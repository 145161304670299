import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import store from '../../state/store';
import { USER_MAP_LAYERS } from '../../features/analysis/deliveryAreaAnalysis/constants/userMapLayers';
import SortUtil from './sortUtil';

/**
 * Util for authentication related functionalities
 *
 * @namespace
 * @category Common
 */
const AuthUtil = {
  getTeams: getTeams,
  getHubs: getHubs,
  getDeliveryArea: getDeliveryArea,
  isFeatureEnabled: isFeatureEnabled,
  useIsFeatureEnabled: useIsFeatureEnabled,
  hasAnyOfFeaturesEnabled: hasAnyOfFeaturesEnabled,
  getTenantId: getTenantId,
  useTenantId: useTenantId,
  getUsername: getUsername,
  isLocationDataHighQuality: isLocationDataHighQuality,
  getRegionAnalysisLayers: getRegionAnalysisLayers,
  useRegionAnalysisLayers: useRegionAnalysisLayers
};

/**
 * Fetches teams from the store for a current user
 *
 * @returns {Array.<object>} Array of teams
 * @memberOf AuthUtil
 * @function
 */
function getTeams() {
  const { authState } = store.getState();

  return authState?.userPermissions?.teams;
}

/**
 * Fetches hubs from the store for a current user
 *
 * @returns {Array.<object>} Array of teams
 * @memberOf AuthUtil
 * @function
 */
function getHubs() {
  const { authState } = store.getState();

  return Object.keys(authState?.userPermissions?.hubs).map((key) => authState?.userPermissions?.hubs[key]);
}

/**
 * Fetches subcenters from store for a current user
 *
 * @returns {Array.<object>} Array of subcenters
 * @memberOf AuthUtil
 * @function
 */
function getDeliveryArea() {
  const { authState } = store.getState();

  return SortUtil.sortObjectByUpperCaseField(authState?.userPermissions?.deliveryAreas, 'name');
}

/**
 * Checks if feature is enabled for a current user - (for use in class components)
 *
 * @param {string} featureName - name of the feature
 * @returns {boolean} true if feature is enabled, otherwise false
 * @memberOf AuthUtil
 * @function
 */
function isFeatureEnabled(featureName) {
  const { authState } = store.getState();

  return isFeatureEnabledFn(featureName, authState);
}

/**
    * Checks if feature is enabled for a current user - function that do the job
    * @param {string} featureName - name of the feature
    * @param {object} authState - authState from store
    * @returns {boolean} true if feature is enabled, otherwise false
 */
function isFeatureEnabledFn(featureName, authState) {
  if (isPrivilegedUser(authState)) return true;

  return authState?.userPermissions?.features && authState.userPermissions.features.includes(featureName);
}

/**
 * Checks if feature is enabled for a current user - (for use in functional components)
 * @param featureName
 * @returns {unknown}
 */
function useIsFeatureEnabled(featureName) {
  const features = useSelector((state) => state.authState?.userPermissions?.features);

  return useMemo(() => features && features.includes(featureName), [features, featureName]);
}

function hasAnyOfFeaturesEnabled(features) {
  const { authState } = store.getState();
  if (isPrivilegedUser(authState)) return true;

  return authState?.userPermissions?.features && authState.userPermissions.features.some((f) => features.includes(f));
}

function isPrivilegedUser(authState) {
  const privilegedUserName = `${process.env.REACT_APP_MILY_USER_NAME}.${authState?.userPermissions?.userTenant}${process.env.REACT_APP_MILY_USER_EMAIL}`;
  return privilegedUserName === authState?.userPermissions?.username && authState.userPermissions?.roleId === process.env.REACT_APP_MILY_USER_ROLE;
}

/**
 * Fetches tenant id from the store for a current user
 * @returns {*|string}
 */
function getTenantId() {
  const { authState } = store.getState();

  return getTenantIdFn(authState);
}

/**
 *  Fetches tenant id from the store for a current user - function that do the job
 * @param authState
 * @returns {*|string}
 */
function getTenantIdFn(authState) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return authState?.userPermissions?.userTenant;
  }

  return 'milytech';
}

/**
 * Fetches tenant id from the store for a current user - (for use in functional components)
 * @returns {*|string}
 */
function useTenantId() {
  return useSelector((state) => getTenantIdFn(state.authState));
}

function getUsername() {
  const { authState } = store.getState();

  return authState?.userPermissions?.username;
}

// TODO create a structure on back-end that maps these kind of parametes for tenants
function isLocationDataHighQuality() {
  return AuthUtil.getTenantId() !== 'dexpress';
}

/**
 * Fetches region analysis layers from the store for a current user
 * @returns {*|[]}
 */
function getRegionAnalysisLayers() {
  const { authState } = store.getState();

  return getRegionAnalysisLayersFn(authState);
}

/**
 * Fetches region analysis layers from the store for a current user - function that do the job
 * @param authState
 * @returns {*|*[]}
 */
function getRegionAnalysisLayersFn(authState) {
  if (isPrivilegedUser(authState)) {
    return USER_MAP_LAYERS;
  }

  const features = authState?.userPermissions?.features;

  if (!features) return [];

  return SortUtil.sortByPredefinedOrder(features.filter((v) => v.startsWith('hex-type'))
    .map((str) => removeHexTypePrefix(str))
    .map((str) => hyphenToCamelCase(str)), USER_MAP_LAYERS);
}

/**
 * Fetches region analysis layers from the store for a current user - (for use in functional components)
 * @returns {*|*[]}
 */
function useRegionAnalysisLayers() {
  const authState = useSelector((state) => state.authState);
  return useMemo(() => getRegionAnalysisLayersFn(authState), [authState]);
}

function removeHexTypePrefix(str) {
  return str.replace(/^hex-type-/, '');
}

function hyphenToCamelCase(str) {
  return str.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
}

export default AuthUtil;
