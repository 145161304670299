import React from 'react';
import DeckGL from '@deck.gl/react';
import PropTypes from 'prop-types';
import { EditableH3ClusterLayer } from '@nebula.gl/layers';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import add from '../../../resources/add.png';
import './StreetMappingMapView.scss';
import MapUtil from '../../../common/utils/mapUtil';
import { EDIT_MODE,
  SELECTION_TOOL } from '../../../common/components/buttons/mapModeButton/constants/drawHexagonsConst';
import { DEFAULT_HEX_SIZE } from '../../management/deliveryAreas/constants/deliveryAreaConstants';
import DrawHexagonsUtil from '../../../common/components/buttons/mapModeButton/utils/drawHexagonsUtil';
import * as CoordinatesActions from '../../../state/actions/coordinatesActions';

class StreetMappingMapViewClass extends React.Component {
  constructor(props) {
    super(props);
    const defaultView = MapUtil.getInitialViewStateForCompany();
    this.state = {
      mapViewState: {
        longitude: defaultView.lng,
        latitude: defaultView.lat,
        zoom: defaultView.zoom,
        pitch: 0,
        bearing: 0
      },
      selectedHexagon: []
    };

    props.handleViewStateChange({ viewState: this.state.mapViewState });
  }

  componentDidUpdate() {
    if (this.props.coordinates) {
      this.changeMapViewPosition();
    }
  }

  changeMapViewPosition = () => {
    const getNewMapViewState = (prevState) => ({
      ...prevState,
      zoom: parseFloat(14),
      latitude: this.props.coordinates[0],
      longitude: this.props.coordinates[1],
      transitionInterpolator: null, // new FlyToInterpolator(),
      transitionDuration: 1000
    });

    this.setState((prevState) => ({ mapViewState: getNewMapViewState(prevState.mapViewState) }));
    this.props.dispatchCoordinates(null);
  };

  getCursor = () => {
    return `url(${add}) 5 15, pointer`;
  };

  generateHexDiffAndValidated = (updatedHex) => {
    this.props.updateHexValidation(updatedHex);
  };

  getH3Layer = () => {
    const selectedIndex = 0;
    const defaultLayerConfig = {
      id: 'h3-cluster-layer',
      data: this.state.selectedHexagon,
      getHexagons: (d) => (d && d.hexIds ? d.hexIds : []),
      resolution: DEFAULT_HEX_SIZE,
      getFillColor: [0, 155, 250, 50]
    };

    const additionalEditableLayerConfig = {
      modeConfig: { booleanOperation: EDIT_MODE.ADD },
      mode: DrawHexagonsUtil.getSelectionMode(SELECTION_TOOL.PICK),
      selectedIndexes: [selectedIndex],
      getEditedCluster: (updatedHexIds) => {
        let updatedHexagonIDs = updatedHexIds;
        if (this.state.hexToRemove) {
          updatedHexagonIDs = updatedHexagonIDs.filter((hexId) => hexId !== this.state.hexToRemove);
        }
        this.setState({ hexToRemove: updatedHexagonIDs[0] });
        this.generateHexDiffAndValidated(updatedHexagonIDs[0]);
        return {
          hexIds: updatedHexagonIDs,
          colorId: selectedIndex
        };
      },
      onEdit: ({ updatedData }) => {
        const newHex = updatedData.filter((hexId) => hexId !== this.state.selectedHexagon);
        this.setState({ selectedHexagon: newHex });
      },

      _subLayerProps: {
        'tentative-hexagons': {
          getFillColor: () => {
            return [0, 155, 250, 50];
          },
          lineWidthScale: 0
        },
        hexagons: {
          getFillColor: () => [0, 155, 250, 50],
          lineWidthScale: 0
        }
      }
    };

    return new EditableH3ClusterLayer({
      ...defaultLayerConfig,
      ...additionalEditableLayerConfig
    });
  };

  render() {
    let layers = [];
    if (!this.props.isPageLoading) {
      layers = [this.getH3Layer()];
    }

    return (
      <div className="street-mapping-map">
        <DeckGL
          initialViewState={this.state.mapViewState}
          controller
          layers={layers}
          height="100%"
          width="100%"
          getCursor={this.getCursor}
          onViewStateChange={this.props.handleViewStateChange}
        >
          {/* <StaticMap mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} mapStyle={MAP_STYLE} /> */}
        </DeckGL>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return { ...store.pageState, ...store.coordinatesState };
}

/**
 * @param {object} dispatch - redux store
 * @returns {object} - actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchCoordinates: CoordinatesActions.coordinatesChange },
    dispatch
  );
}

StreetMappingMapViewClass.propTypes = {
  handleViewStateChange: PropTypes.func,
  isPageLoading: PropTypes.bool,
  updateHexValidation: PropTypes.func,
  dispatchCoordinates: PropTypes.func.isRequired,
  coordinates: PropTypes.arrayOf(PropTypes.number)
};

StreetMappingMapViewClass.defaultProps = {
  handleViewStateChange: () => {},
  isPageLoading: true,
  coordinates: null,
  updateHexValidation: () => {},
};

const DeliveryAreaMapView = withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(StreetMappingMapViewClass));

export default DeliveryAreaMapView;
