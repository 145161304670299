import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import './TableComponent.scss';
import { useTranslation } from 'react-i18next';
import TableSearch from '../common/TableSearch';
import Button from '../../buttons/button/Button';
import MixPanel from '../../../../setup/mixPanel';
import { BUTTON_TYPES } from '../../buttons/button/constants/buttonTypes';

/**
 *Table for displaying data
 *
 * @param {object} props - table props
 * @returns {JSX.Element} - Table for displaying data
 * @component
 * @alias TableComponent
 * @category Common
 */
export default function TableComponent(props) {
  const { t } = useTranslation();
  const {
    columns,
    data,
    resetOn,
    pageSize,
    paginationLabel,
    sortBy,
    getAdditionalRowClass,
    showSearch,
    hiddenColumns,
    calculateTablePageSize,
    autoResetPage,
    autoResetGlobalFilter
  } = props;
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pageSize, sortBy: sortBy, hiddenColumns: hiddenColumns },
      autoResetPage: autoResetPage,
      autoResetGlobalFilter: autoResetGlobalFilter
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const sortedByRef = useRef(props.sortBy[0]?.id);
  const elementHeight = useRef(1);
  const tableHeight = useRef(null);

  const prevResetOn = useRef(null);

  useEffect(() => {
    if (prevResetOn.current !== resetOn && !tableInstance.autoResetPage) {
      tableInstance.gotoPage(0);
      prevResetOn.current = resetOn;
    }
  }, [resetOn, tableInstance]);

  const additionalAction = () => {
    if (props.onActionButtonClick) props.onActionButtonClick();
  };

  const getPaginationNumbers = () => {
    let numbers = [];
    const { pageIndex } = tableInstance.state;

    if (tableInstance.pageCount <= 6) {
      numbers = [...Array(tableInstance.pageCount).keys()];

      return numbers;
    }

    switch (pageIndex) {
      case 0:
        numbers = [];
        break;
      case 1:
        numbers = [0];
        break;
      case 2:
        numbers = [0, 1];
        break;
      case 3:
        numbers = [0, 1, 2];
        break;
      default:
        numbers = [0, -1];
        numbers.push(pageIndex - 2);
        numbers.push(pageIndex - 1);
    }

    numbers.push(pageIndex);

    if (pageIndex < tableInstance.pageCount - 4) {
      numbers.push(pageIndex + 1);
      numbers.push(pageIndex + 2);
    }

    switch (pageIndex) {
      case tableInstance.pageCount - 1:
        break;
      case tableInstance.pageCount - 2:
        numbers.push(tableInstance.pageCount - 1);
        break;
      case tableInstance.pageCount - 3:
        numbers.push(tableInstance.pageCount - 2);
        numbers.push(tableInstance.pageCount - 1);
        break;
      case tableInstance.pageCount - 4:
        numbers.push(tableInstance.pageCount - 3);
        numbers.push(tableInstance.pageCount - 2);
        numbers.push(tableInstance.pageCount - 1);
        break;
      default:
        numbers.push(-2);
        numbers.push(tableInstance.pageCount - 1);
    }

    return numbers;
  };

  const getPageEndRange = () => {
    if (tableInstance.state.pageIndex === tableInstance.pageCount - 1) {
      return tableInstance.state.pageIndex * tableInstance.state.pageSize + tableInstance.page.length;
    }
    return (tableInstance.state.pageIndex + 1) * tableInstance.state.pageSize;
  };

  function onRowClick(row) {
    if (props.handleRowClick) {
      props.handleRowClick(row);
    }
  }

  const measureBodyRef = useCallback(
    (node, rowHeight) => {
      if (node !== null && calculateTablePageSize) {
        if (!tableHeight.current) {
          tableHeight.current = node.clientHeight;
        }

        calculateTablePageSize(Math.trunc(tableHeight.current / rowHeight.current));
      }
    },
    [calculateTablePageSize]
  );

  const measureElementRef = useCallback(
    (node) => {
      if (node !== null) {
        if (node.clientHeight > elementHeight.current) {
          elementHeight.current = node.clientHeight;
        }
      }
    },
    [elementHeight]
  );

  function measureBodyRefWrapper(node) {
    measureBodyRef(node, elementHeight);
  }

  const mixPanelTrackSortEvent = (columnName) => {
    sortedByRef.current = columnName;
    MixPanel.track(`${props.pageUsing} - Sort by '${columnName}' toggled`);
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <div className="table-component" {...tableInstance.getTableProps()}>
      <div className="pre-header">
        {showSearch && (
          <TableSearch
            preGlobalFilteredRows={tableInstance.preGlobalFilteredRows}
            globalFilter={tableInstance.state.globalFilter}
            setGlobalFilter={(value) => {
              if (value) tableInstance.gotoPage(0);
              tableInstance.setGlobalFilter(value);
            }}
          />
        )}
        {props.actionButtonText && <Button text={props.actionButtonText} type={BUTTON_TYPES.PRIMARY} onClick={additionalAction} />}
      </div>
      <div className="header">
        {tableInstance.headerGroups.map((headerGroup) => {
          return headerGroup.headers.map((column) => {
            if (column.isSorted && props.pageUsing && sortedByRef.current !== column.id) {
              mixPanelTrackSortEvent(column.id);
            }
            return (
              <div
                className={column.className ? `table-header table-cell ${column.className}` : 'table-header table-cell'}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {column.isSorted && (
                  <span className="sort-icons">
                    {column.isSortedDesc ? <i className="icon icon-keyboard-arrow-down" /> : <i className="icon icon-keyboard-arrow-up" />}
                  </span>
                )}
              </div>
            );
          });
        })}
      </div>

      <div className="table-body" {...tableInstance.getTableBodyProps()} ref={measureBodyRefWrapper}>
        {tableInstance.page.length > 0 ? (
          tableInstance.page.map((row) => {
            tableInstance.prepareRow(row);
            let rowClass = 'table-row';
            if (getAdditionalRowClass) {
              rowClass = `table-row ${getAdditionalRowClass(row)}`;
            }

            return (
              <div
                className={rowClass}
                {...row.getRowProps()}
                onClick={() => {
                  onRowClick(row.original);
                }}
                ref={measureElementRef}
              >
                {row.cells.map((cell) => (
                  <div className="table-data table-cell" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <div className="no-data">{t('No data for selected filters')}</div>
        )}
      </div>

      <div className="pagination">
        <div className="page-items-number">
          {tableInstance.state.pageIndex * tableInstance.state.pageSize + 1}
          {' '}
          -
          {getPageEndRange()}
        </div>
        <div className="change-page-wrapper">
          <div className={tableInstance.canPreviousPage ? 'button' : 'button disabled'} onClick={() => tableInstance.previousPage()}>
            <i className="icon icon-keyboard-arrow-left" />
          </div>
          <div className="pages">
            {getPaginationNumbers().map((number) => {
              if (number < 0) {
                return <div key={number}>...</div>;
              }

              return (
                <div
                  className={tableInstance.state.pageIndex === number ? 'page-number selected' : 'page-number'}
                  key={number}
                  onClick={() => {
                    tableInstance.gotoPage(number);
                  }}
                >
                  {number + 1}
                </div>
              );
            })}
          </div>
          <div className={tableInstance.canNextPage ? 'button' : 'button disabled'} onClick={() => tableInstance.nextPage()}>
            <i className="icon icon-keyboard-arrow-right" />
          </div>
        </div>
        <div className="results-count">
          {paginationLabel}
          :
          {tableInstance.data.length}
        </div>
      </div>
    </div>
  );
}

TableComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  resetOn: PropTypes.string,
  pageSize: PropTypes.number,
  paginationLabel: PropTypes.string,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool
    })
  ),
  getAdditionalRowClass: PropTypes.func,
  showSearch: PropTypes.bool,
  handleRowClick: PropTypes.func,
  actionButtonText: PropTypes.string,
  onActionButtonClick: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  calculateTablePageSize: PropTypes.func,
  pageUsing: PropTypes.string,
  autoResetPage: PropTypes.bool,
  autoResetGlobalFilter: PropTypes.bool
};

TableComponent.defaultProps = {
  resetOn: '',
  pageSize: 10,
  paginationLabel: '',
  sortBy: [],
  getAdditionalRowClass: null,
  showSearch: false,
  handleRowClick: null,
  actionButtonText: '',
  onActionButtonClick: null,
  hiddenColumns: [],
  calculateTablePageSize: null,
  pageUsing: null,
  autoResetPage: false,
  autoResetGlobalFilter: false
};
