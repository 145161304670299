import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QueryStringUtil from '../../../utils/queryStringUtil';
import CustomSelect from '../CustomSelect';
import MixPanel from '../../../../setup/mixPanel';
import AuthUtil from '../../../utils/authUtil';

/**
 * Select component for hubs
 *
 * @param {object} props - props object for the component
 * @returns {JSX.Element} Hub select
 * @component
 * @alias HubSelect
 * @category Common
 */
export default function HubSelect(props) {
  const { t } = useTranslation();
  const { returnFullObject, onHubChange } = props;

  const [hubOptions, setHubOptions] = useState([]);
  const [hubs, setHubs] = useState(null);

  const [selectedHub, setSelectedHub] = useState('');

  const getHubSelectDefaultValue = useCallback(() => {
    let hubSelectDefaultValue;
    if (hubs && hubs.length > 0) {
      let key = QueryStringUtil.getQueryStringValue('hubId');
      if (!key) {
        key = hubs && hubs[0].id;
      }

      if (hubs && key) {
        hubSelectDefaultValue = {
          label: hubs.find((s) => s.id === key).name,
          value: key
        };
      }
    }

    return hubSelectDefaultValue;
  }, [hubs]);

  const handlePropsOnChange = useCallback(
    (hubId) => {
      if (onHubChange) {
        if (returnFullObject) {
          const hub = hubs.find((s) => s.id === hubId);
          onHubChange(hub);

          return;
        }

        onHubChange(hubId);
      }
    },
    [onHubChange, returnFullObject, hubs]
  );

  useEffect(() => {
    const userHubs = AuthUtil.getHubs();
    if (userHubs) {
      setHubs(userHubs);
      setHubOptions(
        userHubs.map((hub) => {
          return {
            value: hub.id,
            label: hub.name
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    const hubOpt = getHubSelectDefaultValue();
    if (hubOpt) {
      handlePropsOnChange(hubOpt.value);
      setSelectedHub(hubOpt.value);
    }
  }, [getHubSelectDefaultValue, handlePropsOnChange]);

  const changeSelectedHub = useCallback((opt) => {
    MixPanel.track(`Delivery Plans - Hub changed to ${opt.value}`, { deliveryArea: opt.value });
    QueryStringUtil.setQueryStringValue('hubId', opt.value);

    handlePropsOnChange(opt.value);

    setSelectedHub(opt.value);
  }, [handlePropsOnChange, setSelectedHub]);

  return (
    <div className="center-selection-wrapper">
      <div className="label">{props.label ? props.label : t('Hub')}</div>
      {hubs && Object.keys(hubs).length > 0 && (
        <CustomSelect
          className="select"
          options={hubOptions}
          placeholder={t('Select a hub')}
          value={hubOptions.filter((option) => option.value === selectedHub)}
          defaultValue={getHubSelectDefaultValue()}
          onChange={changeSelectedHub}
          // isDisabled={!hubs || Object.keys(hubs).length === 1}
          menuPortalTarget={document.querySelector('.MuiDialog-root')}
        />
      )}
    </div>
  );
}

HubSelect.propTypes = {
  onHubChange: PropTypes.func.isRequired,
  returnFullObject: PropTypes.bool,
  label: PropTypes.string
};

HubSelect.defaultProps = {
  returnFullObject: false,
  label: null
};
