import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import { INPUT_SIZE } from '../../../constants/uiConstants';

/**
 * Contains default style adjustments on existing elements
 *
 * @namespace DefaultSelectStyleUtil
 * @category Common
 */
const selectStyle = (size) => ({
  /**
   * Adjusts container style
   *
   * @param {object} provided - provided container style
   * @param {object} state - current state
   * @returns {{minHeight: string, opacity: string}} adjusted style
   */
  container: (provided, state) => ({
    ...provided,
    minHeight: '1px',
    opacity: state.isDisabled ? '0.5' : '1'
  }),

  /**
   * Adjusts control element style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, boxShadow: string, backgroundColor: *, borderRadius: string, color: *, "&:hover": {borderColor: *}, height: string}} adjusted style
   */
  control: (provided, state) => (() => {
    return {
      ...provided,
      borderRadius: '4px',
      boxShadow: state.menuIsOpen ? '0px 0px 5px 0px #009BFA !important' : '0 !important',
      backgroundColor: colorsAndFonts.tile_background_color,
      color: colorsAndFonts.light_text,
      borderColor: state.menuIsOpen || state.hasValue ? colorsAndFonts.selection_color : colorsAndFonts.kashmir_blue,
      padding: size === INPUT_SIZE.MEDIUM ? '4px 10px' : '8px 10px',
      minHeight: '1px',
      '&:hover': { backgroundColor: colorsAndFonts.border_color_dark },
      '&:active': { borderColor: colorsAndFonts.selection_color },
      '&:focus': {
        borderColor: colorsAndFonts.selection_color,
        boxShadow: `0px 0px 5px 0px ${colorsAndFonts.selection_color}}`
      }
    };
  }),

  /**
   * Adjusts value container style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, paddingBottom: string}} adjusted style
   */
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: colorsAndFonts.font_weight_input,
    minHeight: '1px',
    padding: '0',
    fontSize: '14px',
    lineHeight: '22px'
  }),

  /**
   * Adjusts single value element style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, color: *}} adjusted style
   */
  singleValue: (provided) => ({
    ...provided,
    minHeight: '1px',
    color: colorsAndFonts.light_text,
    fontSize: '14px'
  }),

  /**
   * Adjusts input style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, color: *}} adjusted style
   */
  input: (provided) => ({
    ...provided,
    minHeight: '1px',
    color: colorsAndFonts.light_text,
    margin: '0',
    padding: '0'
  }),

  /**
   * Adjusts indicators container style
   *
   * @param {object} provided - provided container style
   * @param {object} state - current state
   * @returns {{minHeight: string, display: (string), height: string}} adjusted style
   */
  indicatorsContainer: (provided, state) => ({
    ...provided,
    minHeight: '1px',
    display: state.isDisabled ? 'none' : 'flex',
  }),

  /**
   * Adjusts indicator separator style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string, marginBottom: string, marginTop: string}} adjusted style
   */
  indicatorSeparator: () => ({ display: 'none' }),

  /**
   * Adjusts dropdown indicator style
   *
   * @param {object} provided - provided container style
   * @param {object} state - current state
   * @returns {{minHeight: string, padding: string}} adjusted style
   */
  dropdownIndicator: (provided, state) => ({
    ...provided,
    minHeight: '1px',
    padding: '0',
    color: state.hasValue ? colorsAndFonts.$light_text : colorsAndFonts.kashmir_blue
  }),

  /**
   * Resets indicator style
   *
   * @param {object} provided - provided container style
   * @returns {{minHeight: string}} adjusted style
   */
  clearIndicator: (provided) => ({
    ...provided,
    minHeight: '1px'
  }),

  /**
   * Adjusts menu style
   *
   * @param {object} provided - provided container style
   * @returns {{border: string, backgroundColor: *, borderRadius: number, zIndex: string}} adjusted style
   */
  menu: (provided) => ({
    ...provided,
    backgroundColor: colorsAndFonts.tile_background_color,
    border: `1px solid ${colorsAndFonts.selection_color}`,
    borderRadius: 4,
    zIndex: '10',
    marginTop: '2px',
  }),

  /**
   * Adjusts option element style
   *
   * @param {object} provided - provided container style
   * @param {object} state - current state
   * @returns {{backgroundColor: (*|null), borderRadius: string, color: *, ":active": {backgroundColor: *}, "&:hover": {backgroundColor: *}}} adjusted style
   */
  option: (provided, state) => ({
    ...provided,
    borderRadius: '0',
    backgroundColor: state.isSelected ? colorsAndFonts.selection_color : null,
    padding: '8px 16px',
    color: colorsAndFonts.light_text,
    fontSize: '14px',
    '&:hover': { backgroundColor: colorsAndFonts.border_color_dark },
    ':active': { backgroundColor: colorsAndFonts.selection_color },
  }),

  /**
   * Adjusts placeholder style
   *
   * @param {object} provided - provided container style
   * @returns {{fontStyle: string}} adjusted style
   */
  placeholder: (provided) => ({
    ...provided,
    color: colorsAndFonts.kashmir_blue,
    marginLeft: '0'
  })
});

const DefaultSelectStyleUtil = { selectStyle };

export default DefaultSelectStyleUtil;
