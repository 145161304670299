import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberCircle from './components/NumberCircle';
import './MapMarker.scss';
import MixPanel from '../../../setup/mixPanel';
import MapPointMarker from './MapPointMarker';

export default function MapMarker(props) {
  const {
    icon,
    text,
    id,
    cssClass,
    onMouseEnter,
    onMouseLeave,
    backgroundImageUrl,
    onClick,
    withPulsingAnimation,
    onHoverPopupData
  } = props;

  let count = 0;
  let activeOnHoverPopup;

  const [selected, setSelected] = useState(props.mapPointIconData?.isSelected);

  useEffect(() => {
    setSelected(props.mapPointIconData?.isSelected);
  }, [props.mapPointIconData?.isSelected]);

  const handleMapPointClick = () => {
    setSelected((prevState) => !prevState);
  };

  function mouseEnter(e) {
    if (onMouseEnter) {
      onMouseEnter(e);
    }
  }

  function mouseLeave(e) {
    if (onMouseLeave) {
      onMouseLeave(e);
    }
  }

  function handleClick(e) {
    if (onClick) {
      onClick(e);
    }

    if (props.mapPointIconData) {
      handleMapPointClick();
    }

    if (props && props.onClickLogMessage) MixPanel.track(props.onClickLogMessage);
  }

  async function addOnHoverPopup() {
    if (onHoverPopupData) {
      activeOnHoverPopup = await onHoverPopupData.getHoverPopup();
      if (count === 0) activeOnHoverPopup.setLngLat(onHoverPopupData.coordinates).addTo(onHoverPopupData.map);
      count++;
    }
  }

  function removeOnHoverPopup() {
    if (onHoverPopupData) {
      if (activeOnHoverPopup) activeOnHoverPopup.remove();
      count = count === 0 ? 0 : count - 1;
    }
  }

  function getInnerElement() {
    if (icon) {
      return <i className={`icon ${icon}`} />;
    }

    if (text) {
      return <div className="text">{text}</div>;
    }
    return null;
  }

  function getImageElement() {
    if (withPulsingAnimation) {
      return (
        <div className="animation-wrapper">
          <img alt={backgroundImageUrl} className={`background-image ${cssClass}`} src={backgroundImageUrl} />
          <div className="pulsating-circle" />
        </div>
      );
    }

    if (props.backgroundImageUrl) {
      return (
        <img
          alt={backgroundImageUrl}
          className={`background-image ${cssClass}`}
          src={backgroundImageUrl}
          onPointerOverCapture={addOnHoverPopup}
          onPointerOutCapture={removeOnHoverPopup}
        />
      );
    }

    if (props.mapPointIconData) {
      return (
        <MapPointMarker
          state={props.mapPointIconData.state}
          isSelected={selected}
          options={props.mapPointIconData.options}
          mapPointType={props.mapPointIconData.mapPointType}
          popupActions={
          {
            addOnHoverPopup,
            removeOnHoverPopup
          }
        }
        />
      );
    }

    return (<div className={`pin ${cssClass}`}>{getInnerElement()}</div>);
  }

  return (
    <div className="map-marker" id={id} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} onClick={handleClick}>
      {props.additionalNumber && props.additionalNumber !== '0' && <NumberCircle numberToShow={props.additionalNumber} />}
      {getImageElement()}
    </div>
  );
}

MapMarker.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  id: PropTypes.string.isRequired,
  mapPointIconData: PropTypes.object,
  cssClass: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  backgroundImageUrl: PropTypes.string,
  onClick: PropTypes.func,
  withPulsingAnimation: PropTypes.bool,
  additionalNumber: PropTypes.string,
  onClickLogMessage: PropTypes.string,
  onHoverPopupData: PropTypes.shape({
    getHoverPopup: PropTypes.func,
    coordinates: PropTypes.arrayOf(PropTypes.string),
    map: PropTypes.object
  })
};

MapMarker.defaultProps = {
  icon: null,
  text: null,
  mapPointIconData: null,
  cssClass: '',
  onMouseEnter: null,
  onMouseLeave: null,
  backgroundImageUrl: '',
  onClick: null,
  withPulsingAnimation: false,
  additionalNumber: null,
  onClickLogMessage: null,
  onHoverPopupData: null
};
