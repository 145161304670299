import AuthUtil from '../../common/utils/authUtil';

const RouteUtil = { getDefaultPage: getDefaultPage };
function getDefaultPage() {
  switch (AuthUtil.getTenantId()) {
    case 'dexpress':
      return '/delivery-area-analysis';
    case 'xexpress':
      return '/courier-analysis';
    case 'jokic':
      return '/delivery-areas';
    case 'eexpress':
      return '/courier-analysis';
    default:
      return '/delivery-area-analysis';
  }
}

export default RouteUtil;
