export const COVERAGE_DATA_TABLE_TYPES = {
  CURRENT: 'current',
  ESTIMATED: 'estimated',
};

export const IMPACT_TABLE_TYPES = {
  MOST: 'most',
  LEAST: 'least',
};

export const POINT_DETAILS_MENU_ITEMS = {
  name: 'Location',
  workingHours: 'Work hours',
  address: 'Address',
  city: 'City',
  capacity: 'Capacity'
};
