import React from 'react';
import './MapTypeButton.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import heatmapImg from '../../../../../resources/heatmap-300x300.png';
import clusterImg from '../../../../../resources/cluster-300x300.png';
import * as RegionAnalysisActions from '../../../../../state/actions/regionAnalysisActions';
import MixPanel from '../../../../../setup/mixPanel';
import { MAP_VIEW_TYPE } from '../../constants/mapConstants';

/**
 * Button which represents a map type.<br/>
 * Dispatches the event based on the provided map type in regionalAnalysisState.<br/>
 * Current options are: heatmap, cluster<br/>
 * if **heatmap** is provided, it dispatches event RegionAnalysisActions.changeMapType to **cluster** and vice versa.<br/>
 *
 * @returns {JSXElement} MapTypeButton component
 * @component
 * @alias MapTypeButton
 * @category RegionAnalysis
 */
export default function MapTypeButton(props) {
  const dispatch = useDispatch();
  const mapType = useSelector((state) => state.regionAnalysisState.mapViewType);
  const { t } = useTranslation();

  /**
   * Toggles map view type based on the provided map type
   */
  const changeMapType = () => {
    switch (mapType) {
      case MAP_VIEW_TYPE.CLUSTER:
        dispatch(RegionAnalysisActions.changeMapType(MAP_VIEW_TYPE.HEATMAP));
        MixPanel.track('Delivery Area Analysis - Map type changed to heatmap');
        break;
      case MAP_VIEW_TYPE.HEATMAP:
        dispatch(RegionAnalysisActions.changeMapType(MAP_VIEW_TYPE.CLUSTER));
        MixPanel.track('Delivery Area Analysis - Map type changed to cluster');
        break;
      default:
        dispatch(RegionAnalysisActions.changeMapType(MAP_VIEW_TYPE.CLUSTER));
        MixPanel.track('Delivery Area Analysis - Map type changed to cluster');
    }
  };

  /**
   * Based on the mapType it returns appropriate image
   *
   * @returns {string} .png image 300x300
   */
  const getButtonImg = () => {
    switch (mapType) {
      case MAP_VIEW_TYPE.CLUSTER:
        return heatmapImg;
      case MAP_VIEW_TYPE.HEATMAP:
        return clusterImg;
      default:
        return clusterImg;
    }
  };

  return (
    <div
      className="map-type-button"
      onClick={changeMapType}
      onMouseOver={() => props.buttonHoveredAction(t(`Legend ${mapType}`))}
      onFocus={() => null}
      onMouseOut={() => props.buttonHoveredAction(null)}
      onBlur={() => null}
    >
      <img src={getButtonImg()} alt="" />
    </div>
  );
}

MapTypeButton.propTypes = { buttonHoveredAction: PropTypes.func };

MapTypeButton.defaultProps = { buttonHoveredAction: null };
