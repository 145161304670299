import * as turf from '@turf/turf';
import * as _ from 'lodash';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { MapMarkerUtil } from '../components/marker/util/mapMarkerUtil';
import { MAP_ADDITIONAL_FEATURES_TOGGLES } from '../constants/mapAdditionalFeaturesData';
import MixPanel from '../../setup/mixPanel';
import MapMarkerPopup from '../components/popups/components/MapMarkerPopup';
import { MARKER_STATES } from '../components/marker/constants/markerConstants';
import colorsAndFonts from '../../resources/colors-and-fonts.scss';
import { PolygonCalculationsUtilClass } from './polygonCalculationsUtil';
import { DISTANCE_VALUE_TYPE_KEYS_ARRAY } from './distanceTypeUtil';

/**
 * Util for showing package lockers in a map based on the mapView type.
 * If heatmap view, show package lockers, if not, remove them.
 *
 * @category RegionAnalysis
 * @alias PackageLockerUtil
 */
// TODO this file should be removed in some future refactorings
export default class PackageLockerUtilClass {
  /**
   * @class
   * @param {mapboxgl.Map} map - an initialized map
   */
  constructor(map) {
    this.map = map;
    this.mapPoints = {};
    this.polygonCalculationsUtil = new PolygonCalculationsUtilClass();
  }

  // oohPoint, 'no-hex'
  plotPackageLockerIcon = (packageLocker, hexType, distanceType) => {
    const mapPointIconData = {
      state: MARKER_STATES.STANDARD,
      options: {},
      mapPointType: MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS
    };

    const feature = {
      geometry: {
        coordinates: [packageLocker.lng, packageLocker.lat],
        type: 'Point'
      },
      properties: { name: packageLocker.name, featureType: 'mapFeature' }
    };

    const marker = MapMarkerUtil.addMarkerToMap(
      this.map,
      feature.geometry.coordinates,
      {
        id: `${packageLocker.id}-packageLockerIcon_logo`,
        cssClass: 'package-locker-icon',
        mapPointIconData: mapPointIconData,
        onClick: () => {
          this.toggleIsochrone({ lat: packageLocker.lat, lng: packageLocker.lng }, distanceType);
          MixPanel.track('OOH Point Analysis - Isochrone toggled', {
            entityName: packageLocker.name,
            type: MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS
          });
        }
      },
      feature.properties,
      async () => this.getOnHoverPopup(feature, hexType, distanceType)
    );

    if (!this.mapPoints[MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS]) {
      this.mapPoints[MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS] = {};
    }

    this.mapPoints[MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS][`${packageLocker.id}`] = {
      marker: marker,
      data: {
        ...packageLocker,
        type: MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS
      }
    };
    return this.mapPoints[MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS][`${packageLocker.id}`];
  };

  getOnHoverPopup = async (feature, hexType, distanceType) => {
    const updatedFeature = await this.addIsochroneEntitiesCountFeature(feature, hexType, distanceType);
    const placeholder = document.createElement('div');
    ReactDOM.render(<MapMarkerPopup data={updatedFeature.properties} />, placeholder);
    return new mapboxgl.Popup({ maxWidth: '500px', offset: 20 }).setDOMContent(placeholder);
  };

  removeMapMarkers = (type) => {
    if (this.mapPoints[type] && !_.isEmpty(this.mapPoints[type])) {
      Object.keys(this.mapPoints[type]).forEach((pointId) => {
        this.removeMapMarker(type, pointId);
      });
    }
    delete this.mapPoints[type];
  };

  removeMapMarker = (type, pointId) => {
    if (this.mapPoints[type] && this.mapPoints[type][pointId]) {
      this.removeIsochrone(this.mapPoints[type][pointId].marker._lngLat);
      this.mapPoints[type][pointId].marker.remove();
      delete this.mapPoints[type][pointId];
      if (_.isEmpty(this.mapPoints[type])) {
        delete this.mapPoints[type];
      }
    }
  };

  toggleIsochrone = async (mapPoint, distanceType) => {
    const isochroneRemoved = this.removeIsochrone(mapPoint);
    if (!isochroneRemoved) this.addIsochronePolygonToMap(mapPoint, distanceType);
  };

  addIsochronePolygonToMap = (mapPoint, distanceType) => {
    this.polygonCalculationsUtil.getIsochronesPolygonData(mapPoint, distanceType, true).then((polygonData) => {
      // we have exactly one polygon since we queried only one point.
      const isochronePolygon = polygonData.polygonFeatures;
      if (isochronePolygon) {
        const sourceId = `isochrone-${mapPoint.lat}-${mapPoint.lng}`;
        this.map.addSource(sourceId, { type: 'geojson', data: isochronePolygon });

        this.map.addLayer({
          id: `polygon-layer-${sourceId}`,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': colorsAndFonts.selection_color,
            'fill-opacity': 0.3
          }
        });

        this.map.addLayer({
          id: `outline-layer-${sourceId}`,
          type: 'line',
          source: sourceId,
          layout: {},
          paint: {
            'line-color': colorsAndFonts.polygon_border_color,
            'line-width': 1.5
          }
        });
      }
    });
  };

  removeIsochrone = (mapPoint, id = null) => {
    const sourceId = id ? `isochrone-${id}` : `isochrone-${mapPoint.lat}-${mapPoint.lng}`;
    if (this.map.getSource(sourceId)) {
      this.map.removeLayer(`polygon-layer-${sourceId}`);
      this.map.removeLayer(`outline-layer-${sourceId}`);
      this.map.removeSource(sourceId);
      return sourceId;
    }
    return null;
  };

  addIsochroneEntitiesCountFeature = async (feature, hexType, distanceType) => {
    const [lng, lat] = feature.geometry.coordinates;
    return this.getIsochroneNoHexEntitiesCoveredCount({ lat, lng }, distanceType).then(
      (entitiesCoveredSum) => {
        const updatedFeature = feature;
        if (!_.isEmpty(entitiesCoveredSum)) {
          updatedFeature.properties.isochroneCoveredEntities = {
            count: entitiesCoveredSum,
            hexType: hexType,
          };
          updatedFeature.properties.distanceType = distanceType;
        }
        return updatedFeature;
      }
    );
  };

  getIsochroneNoHexEntitiesCoveredCount = async (mapPoint, distanceType) => {
    return this.polygonCalculationsUtil.getIsochronesPolygonData(mapPoint, distanceType).then((polygonData) => {
      // we have exactly one polygon since we queried only one point.
      const isochronePolygon = polygonData.polygonFeatures;
      const entitiesCoveredSum = {};
      if (isochronePolygon) {
        isochronePolygon.features.forEach((polygon, i) => {
          let entitiesPerHexSum = 0;
          this.stops.forEach((stop) => {
            const isInside = turf.booleanPointInPolygon(stop.geometry.coordinates, polygon);
            if (isInside) {
              entitiesPerHexSum += 1;
            }
          });
          entitiesCoveredSum[DISTANCE_VALUE_TYPE_KEYS_ARRAY[i]] = entitiesPerHexSum;
        });
      }
      return entitiesCoveredSum;
    });
  };

  updateStops = (stops) => {
    this.stops = stops;
  };
}
