import React from 'react';
import './InputErrorText.scss';
import PropTypes from 'prop-types';
import { LABEL_POSITION } from '../../../constants/uiConstants';

export default function InputErrorText(props) {
  return (
    <div className={`input-error ${!props.text ? 'hidden' : ''}`}>
      {props.labelPosition === LABEL_POSITION.LEFT && (<div className="align-with-input" />)}
      <i className="icon icon-warning-circle" />
      <div className="error-text">
        {props.text}
      </div>
    </div>
  );
}

InputErrorText.propTypes = {
  text: PropTypes.string,
  labelPosition: PropTypes.oneOf(Object.values(LABEL_POSITION))
};

InputErrorText.defaultProps = {
  text: '',
  labelPosition: LABEL_POSITION.TOP
};
