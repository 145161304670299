import React from 'react';
import { useTranslation } from 'react-i18next';
import './DistanceChangeConfirmationDialog.scss';
import PropTypes from 'prop-types';
import Button from '../../../../../common/components/buttons/button/Button';
import { BUTTON_TYPES } from '../../../../../common/components/buttons/button/constants/buttonTypes';
import MixPanel from '../../../../../setup/mixPanel';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';

export default function DistanceChangeConfirmationDialog(props) {
  const { t } = useTranslation();

  function cancelCallback(source) {
    MixPanel.track(`Delivery Area Analysis - Distance change confirmation dialog closed from ${source}`);

    AppDialogActionsWrapper.closeAppDialog();
  }

  return (
    <div className="distance-change-confirmation-wrapper">
      <div className="confirmation-title">
        {t('Configure distance for isochrones')}
        <div className="confirmation-title-exit" onClick={() => { cancelCallback('close button'); }}>
          <i className="icon icon-close" />
        </div>
      </div>
      <div className="confirmation-body">
        <div className="confirmation-body-question">
          {t('Are you sure you want change the distance?')}
        </div>
        <div className="confirmation-body-text">
          <div className="confirmation-body-text-paragraph">
            {t('Configure distance confirm 1')}
          </div>
          <div className="confirmation-body-text-paragraph">
            {t('Configure distance confirm 2')}
          </div>
        </div>
      </div>
      <div className="confirmation-buttons">
        <Button type={BUTTON_TYPES.SECONDARY} onClick={() => { cancelCallback('cancel button'); }} text={t('Cancel')} />
        <Button type={BUTTON_TYPES.PRIMARY} onClick={props.submitCallback} text={t('Confirm')} />
      </div>
    </div>
  );
}

DistanceChangeConfirmationDialog.propTypes = { submitCallback: PropTypes.func.isRequired };
