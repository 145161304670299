import ReactMapGL, { ScaleControl, useMap } from 'react-map-gl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import geojson2h3 from 'geojson2h3';
import bbox from '@turf/bbox';
import './DeliveryAreaAnalysisMap.scss';
import { useTranslation } from 'react-i18next';
import { DELIVERY_AREA_MAP_STYLE } from '../../../../common/constants/mapConstants';
import DistancePinMarkerWrapper from './mapMarkers/DistancePinMarkerWrapper';
import MapPointMarkerWrapper from './mapMarkers/MapPointMarkerWrapper';
import AuthUtil from '../../../../common/utils/authUtil';
import HexagonsSourceWrapper from './mapSourceAndLayers/HexagonsSourceWrapper';
import RegionsWrapper from './mapSourceAndLayers/RegionsWrapper';
import RecommenderNetworkMapSourceWrapper from './mapSourceAndLayers/RecommenderNetworkMapSourceWrapper';
import UnwantedLocationMarkerWrapper from './mapMarkers/UnwantedLocationMarkerWrapper';
import useConditionalEffect from '../../../../common/hooks/useConditionalEffect';

export default function DeliveryAreaAnalysisMap(props) {
  const { t } = useTranslation();
  const layersForUser = AuthUtil.useRegionAnalysisLayers();
  const { deliveryAreaAnalysisMap } = useMap();

  const pointImpactList = useSelector((state) => state.regionAnalysisState.pointImpactList);
  const highlightedRegion = useSelector((state) => state.regionAnalysisState.highlightedRegion);

  const [pointImpactObject, setPointImpactObject] = useState({});

  const [hexagonsSources, setHexagonsSources] = React.useState(null);
  const [regionSources, seRegionSources] = React.useState(null);
  const [visibleMapSource, setVisibleMapSource] = React.useState(null);

  const createHexagonsSourcesDataCallback = useCallback(createHexagonsSourcesData, [props.hexLayersCountMap, props.centerPositionData.id, layersForUser]);
  const createRegionsSourcesDataCallback = useCallback(createRegionsSourcesData, [props.regionsData, props.hexType]);
  const checkIfImpactListSizeIsRelevantCallback = useCallback(checkIfImpactListSizeIsRelevant, [pointImpactList]);

  const coordinates = useSelector((state) => state.coordinatesState.coordinates);

  useEffect(() => {
    // Create hexagons sources from hexagons data
    if (props.hexLayersCountMap) {
      const newHexagonsSources = createHexagonsSourcesDataCallback();
      setHexagonsSources(newHexagonsSources);
    }
  }, [props.hexLayersCountMap, createHexagonsSourcesDataCallback]);

  useEffect(() => {
    // Create regions sources from regions data
    if (props.regionsData && props.regionsTotals) {
      const newRegionSources = createRegionsSourcesDataCallback();
      seRegionSources(newRegionSources);
    } else {
      seRegionSources(null);
    }
  }, [props.regionsData, createRegionsSourcesDataCallback, props.regionsTotals]);

  useEffect(() => {
    // Change visible map source on hex type change
    if (hexagonsSources && hexagonsSources.length > 0 && props.hexType) {
      const newVisibleMapSource = hexagonsSources.find((source) => source.layerType === props.hexType);
      setVisibleMapSource(newVisibleMapSource);
    } else {
      setVisibleMapSource(null);
    }
  }, [hexagonsSources, props.hexType]);

  useEffect(() => {
    // Create point impact object
    const newPointImpactObject = {};

    if (pointImpactList && checkIfImpactListSizeIsRelevantCallback()) {
      Object.keys(pointImpactList).forEach((key) => {
        pointImpactList[key].forEach((impactPoint, index) => {
          Object.keys(impactPoint).forEach((mapPointId) => {
            if (impactPoint[mapPointId] >= 0) {
              newPointImpactObject[mapPointId] = {
                impactType: `${key}-impactful`,
                icon: `${getIconFromIndexOfImpactPoint(index)}`
              };
            }
          });
        });
      });
    }

    setPointImpactObject(newPointImpactObject);
  }, [pointImpactList, checkIfImpactListSizeIsRelevantCallback]);

  useEffect(() => {
    deliveryAreaAnalysisMap?.on('mousemove', (e) => {
      const features = deliveryAreaAnalysisMap.queryRenderedFeatures(e.point);
      let hoveredRegion = null;

      features.forEach((feature) => {
        const featureProperties = feature.properties;
        if (featureProperties.kind === 'POLYGON') {
          hoveredRegion = `${t('Region')}: ${featureProperties.title}`;
        }
      });

      document.getElementById('region-displayer').textContent = hoveredRegion;
    });
  }, [deliveryAreaAnalysisMap, t]);

  useEffect(() => {
    // Recenter map to region on region click in bar chart
    if (highlightedRegion && highlightedRegion.flyTo) {
      const bounds = bbox(deliveryAreaAnalysisMap.getSource(highlightedRegion.regionId)._data);
      deliveryAreaAnalysisMap.fitBounds(bounds, { padding: 140 });
    }
  }, [highlightedRegion, deliveryAreaAnalysisMap]);

  useEffect(() => {
    // Resize map on barchart show / hide so region centering can work correctly
    let resizeTimeout;
    if (deliveryAreaAnalysisMap) {
      // We have to wait for barChart animation to finish before resizing map
      resizeTimeout = setTimeout(() => {
        deliveryAreaAnalysisMap.resize();
      }, 1000);
    }

    return () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
    };
  }, [props.showRegionStopsDistributionChart, deliveryAreaAnalysisMap]);

  useConditionalEffect(() => {
    // Recenter map on impact list row click
    deliveryAreaAnalysisMap.flyTo({
      center: [coordinates.lng, coordinates.lat],
      zoom: 15,
      speed: 1.0,
      curve: 1
    });
  }, [coordinates, deliveryAreaAnalysisMap]);

  useEffect(() => {
    // Recenter map on da change
    let flyToTimeout;
    if (props.centerPositionData && deliveryAreaAnalysisMap) {
      flyToTimeout = setTimeout(() => {
        deliveryAreaAnalysisMap.flyTo({
          center: [props.centerPositionData.lng, props.centerPositionData.lat],
          zoom: props.centerPositionData.zoom,
          speed: 1.0,
        });
      }, 500);
    }

    return () => {
      if (flyToTimeout) {
        clearTimeout(flyToTimeout);
      }
    };
  }, [props.centerPositionData, deliveryAreaAnalysisMap]);

  const getVisibleMapPoints = useMemo(() => {
    let visibleMapPoints = [];
    if (props.mapPointsStaticData) {
      visibleMapPoints = Object.values(props.mapPointsStaticData)
        .filter((pl) => {
          return props.mapPointsVisibilityData[pl.id];
        });
    }

    return visibleMapPoints;
  }, [props.mapPointsStaticData, props.mapPointsVisibilityData]);

  function createRegionsSourcesData() {
    const newRegionsSources = [];

    props.regionsData.forEach((region) => {
      const regionId = region.properties.title;
      newRegionsSources.push({
        id: regionId,
        data: region,
        layerType: props.hexType
      });
    });

    return newRegionsSources;
  }

  function getIconFromIndexOfImpactPoint(index) {
    switch (index) {
      case 0: return 'icon-number-one';
      case 1: return 'icon-number-two';
      case 2: return 'icon-number-three';
      default: return null;
    }
  }

  function checkIfImpactListSizeIsRelevant() {
    const differentImpactPoints = new Set();
    Object.keys(pointImpactList).forEach((key) => {
      pointImpactList[key].forEach((impactPoint) => {
        Object.keys(impactPoint).forEach((impactPointKey) => {
          differentImpactPoints.add(impactPointKey);
        });
      });
    });
    return differentImpactPoints.size === 6;
  }

  function createHexagonsSourcesData() {
    const newHexagonsSources = [];
    const hexagonsPerLayer = {};

    layersForUser.forEach((layer) => {
      hexagonsPerLayer[layer] = {};
    });

    Object.entries(props.hexLayersCountMap)
      .forEach(([hexId, hexData]) => {
        layersForUser.forEach((layer) => {
          if (hexData[layer]) {
            hexagonsPerLayer[layer][hexId] = hexData[layer];
          }
        });
      });

    layersForUser.forEach((layer) => {
      const hexagonFeatureCollection = geojson2h3.h3SetToFeatureCollection(Object.keys(hexagonsPerLayer[layer]), (h3Index) => ({ data: hexagonsPerLayer[layer][h3Index] }));
      newHexagonsSources.push({
        id: `${props.centerPositionData.id}-hexagons-${layer}`,
        data: hexagonFeatureCollection,
        layerType: layer
      });
    });

    return newHexagonsSources;
  }

  function getRecommenderMapSource() {
    const featureCollection = geojson2h3.h3SetToFeatureCollection(
      Object.keys(props.recommenderResults.recommendedHexagons),
      (hexId) => props.recommenderResults.recommendedHexagons[hexId]
    );

    return (
      <RecommenderNetworkMapSourceWrapper
        data={featureCollection}
      />
    );
  }

  return (
    <div className="delivery-area-analysis-map">
      <pre className="region-displayer" id="region-displayer" />
      <pre className="meta-attribution">
        <a href="https://about.meta.com/uk/company-info/" target="_blank" rel="noreferrer">© Meta</a>
      </pre>
      <ReactMapGL
        initialViewState={{
          latitude: '21.53994600340641',
          longitude: '39.15738377861156',
          zoom: 10
        }}
        style={{
          width: '100%',
          height: '100%'
        }}
        id="deliveryAreaAnalysisMap"
        mapStyle={DELIVERY_AREA_MAP_STYLE}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        ref={props.deliveryAreaAnalysisMapRef}
      >
        <ScaleControl position="bottom-right" style={{ backgroundColor: 'transparent' }} />
        { visibleMapSource && hexagonsSources && hexagonsSources.length > 0 && hexagonsSources.map((source) => {
          return (
            <HexagonsSourceWrapper
              key={source.id}
              source={source}
              centerPositionData={props.centerPositionData}
              visibleMapSource={visibleMapSource}
              totalLayerCount={props.deliveryAreaTotalCount[source.layerType]}
            />
          );
        })}

        {hexagonsSources && hexagonsSources.length > 0 && visibleMapSource && regionSources && regionSources.length > 0 && regionSources.map((source) => {
          return (
            <RegionsWrapper
              key={`${source.id}-${source.layerType}`}
              source={source}
              centerPositionData={props.centerPositionData}
              visibleMapSource={visibleMapSource}
              regionTotals={props.regionsTotals && props.regionsTotals[source.id]}
            />
          );
        })}
        {props.recommenderResults && getRecommenderMapSource()}
        {
          getVisibleMapPoints.map((pl) => {
            return (
              <MapPointMarkerWrapper
                key={pl.id}
                pin={pl}
                mapPointIsochroneData={props.mapPointsIsochroneData[pl.id]}
                mapPointCoverageData={props.mapPointsCoverageData[pl.id]}
                mapPointCollectionData={props.mapPointsCollectionData && props.mapPointsCollectionData[pl.id]}
                hexType={props.hexType}
                distanceType={props.distanceType}
                draggable={false}
                impactPointData={pointImpactObject[pl.id]}
              />
            );
          })
        }
        {
          Object.values(props.distancePinsStaticData)
            .map((pl) => {
              return (
                <DistancePinMarkerWrapper
                  key={pl.id}
                  pin={pl}
                  draggable
                  updateDistancePinData={props.updateDistancePinData}
                  distancePinCoverageData={props.distancePinsCoverageData[pl.id]}
                  hexType={props.hexType}
                  distanceType={props.distanceType}
                  removeDistancePin={props.removeDistancePin}
                  isochroneData={props.distancePinsIsochroneData[pl.id]}
                  removeDistancePinPolygon={props.removeDistancePinPolygon}
                />
              );
            })
        }
        {
          Object.values(props.unwantedLocationPinsStaticData)
            .map((pin) => {
              return (
                <UnwantedLocationMarkerWrapper
                  key={pin.id}
                  pin={pin}
                  draggable
                  removeMarker={props.removeUnwantedLocationPin}
                  updateMarkerData={props.updateUnwantedLocationPinData}
                />
              );
            })
        }
        {
           Object.values(props.staticMapFeaturesData).map((mapPoint) => {
             return (
               <MapPointMarkerWrapper
                 key={mapPoint.id}
                 pin={mapPoint}
                 mapPointCoverageData={props.staticMapFeaturesCoverageData[mapPoint.id]}
                 mapPointIsochroneData={props.staticMapFeaturesIsochroneData && props.staticMapFeaturesIsochroneData[mapPoint.id]}
                 hexType={props.hexType}
                 distanceType={props.distanceType}
                 draggable={false}
                 isStaticFeaturePoint
               />
             );
           })
         }
      </ReactMapGL>
    </div>
  );
}

DeliveryAreaAnalysisMap.propTypes = {
  hexLayersCountMap: PropTypes.object,
  mapPointsStaticData: PropTypes.object,
  mapPointsIsochroneData: PropTypes.object,
  mapPointsVisibilityData: PropTypes.object,
  mapPointsCollectionData: PropTypes.object,
  mapPointsCoverageData: PropTypes.object,
  distancePinsStaticData: PropTypes.object,
  distancePinsIsochroneData: PropTypes.object,
  distancePinsCoverageData: PropTypes.object,
  unwantedLocationPinsStaticData: PropTypes.object,
  updateUnwantedLocationPinData: PropTypes.func,
  removeUnwantedLocationPin: PropTypes.func,
  updateDistancePinData: PropTypes.func,
  hexType: PropTypes.string,
  distanceType: PropTypes.string.isRequired,
  removeDistancePin: PropTypes.func,
  centerPositionData: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
    id: PropTypes.string
  }),
  deliveryAreaTotalCount: PropTypes.object,
  regionsData: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    properties: PropTypes.shape({
      title: PropTypes.string,
      color: PropTypes.string
    }),
    geometry: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)))
    }),
  })),
  regionsTotals: PropTypes.object,
  deliveryAreaAnalysisMapRef: PropTypes.object,
  recommenderResults: PropTypes.object,
  staticMapFeaturesData: PropTypes.object,
  staticMapFeaturesIsochroneData: PropTypes.object,
  staticMapFeaturesCoverageData: PropTypes.object,
  showRegionStopsDistributionChart: PropTypes.bool,
  removeDistancePinPolygon: PropTypes.func
};

DeliveryAreaAnalysisMap.defaultProps = {
  hexLayersCountMap: null,
  mapPointsStaticData: null,
  mapPointsIsochroneData: null,
  mapPointsVisibilityData: null,
  mapPointsCollectionData: null,
  mapPointsCoverageData: null,
  distancePinsStaticData: null,
  distancePinsIsochroneData: null,
  distancePinsCoverageData: null,
  unwantedLocationPinsStaticData: null,
  updateUnwantedLocationPinData: null,
  removeUnwantedLocationPin: null,
  updateDistancePinData: null,
  hexType: null,
  removeDistancePin: null,
  centerPositionData: null,
  deliveryAreaTotalCount: null,
  regionsData: null,
  regionsTotals: null,
  deliveryAreaAnalysisMapRef: null,
  recommenderResults: null,
  staticMapFeaturesData: null,
  staticMapFeaturesIsochroneData: null,
  staticMapFeaturesCoverageData: null,
  showRegionStopsDistributionChart: false,
  removeDistancePinPolygon: null
};
