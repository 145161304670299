import { Auth } from 'aws-amplify';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

class AppSyncClient {
  constructor() {
    const httpLink = new HttpLink({ uri: process.env.REACT_APP_APP_SYNC_URL });
    const link = ApolloLink.from([
      createAuthLink({
        url: process.env.REACT_APP_APP_SYNC_URL,
        region: process.env.REACT_APP_AWS_REGION,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
        }
      }),
      createSubscriptionHandshakeLink({
        url: process.env.REACT_APP_APP_SYNC_URL,
        region: process.env.REACT_APP_AWS_REGION,
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
        }
      }, httpLink)
    ]);
    this.appSyncClient = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      connectToDevTools: process.env.REACT_APP_BRANCH_NAME !== 'master'
    });
  }
}

export const appSyncClient = new AppSyncClient().appSyncClient;
