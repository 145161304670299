import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './HubManagementPage.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import MapUtil from '../../../../common/utils/mapUtil';
import { raygunClient } from '../../../../setup/raygunClient';
import * as MapActions from '../../../../state/actions/mapActions';
import * as PlanningPageActions from '../../../../state/actions/planningPageActions';
import CardMenuTable from '../../../../common/components/tables/cardMenuTable/CardMenuTable';
import Button from '../../../../common/components/buttons/button/Button';
import * as PageActions from '../../../../state/actions/pageActions';
import HubMap from '../components/HubMap';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import * as CoordinatesActions from '../../../../state/actions/coordinatesActions';
import HubApi from '../api/hubApi';
import IdUtils from '../../../../common/utils/idUtil';
import TableActions from '../../../../common/components/tables/common/TableActions';
import LogoDisplay from '../../../../common/components/logoDisplay/LogoDisplay';
import AuthUtil from '../../../../common/utils/authUtil';
import { BUTTON_TYPES } from '../../../../common/components/buttons/button/constants/buttonTypes';

class HubManagementPageClass extends React.Component {
  constructor() {
    super();
    this.state = {
      showHub: false,
      tablePageSize: 1
    };
  }

  componentDidMount() {
    this.loadHubs();
  }

  loadHubs = () => {
    this.setState({ hub: AuthUtil.getHubs() });
    this.props.dispatchLoadingPage(false);
  };

  getTableColumns = () => {
    const tableColumns = [
      {
        Header: this.props.t('Name'),
        accessor: 'name', // accessor is the "key" in the data
        className: 'flex-3'
      },
      { accessor: 'integrationId' }
    ];
    if (AuthUtil.isFeatureEnabled('manageHub')) {
      tableColumns.push({
        Header: this.props.t('Actions'),
        id: 'actions',
        disableGlobalFilter: true,
        className: 'centered',
        Cell: ({ row }) => <TableActions type="card" row={row} onEdit={this.editHub} onDelete={this.deleteHub} />
      });
    }

    return tableColumns;
  };

  createNewHub = () => {
    const newId = IdUtils.generateId();
    this.props.history.push({
      pathname: `/hub-management/hub-add/${newId}`,
      state: {
        create: true,
        lat: MapUtil.getInitialViewStateForCompany().lat,
        lng: MapUtil.getInitialViewStateForCompany().lng,
        id: newId
      }
    });
  };

  editHub = (row) => {
    this.props.history.push({
      pathname: `/hub-management/hub-add/${row.original.id}`,
      state: {
        name: row.original.name,
        lat: parseFloat(row.original.lat),
        lng: parseFloat(row.original.lng),
        id: row.original.id,
        integrationId: row.original.integrationId,
        create: false
      }
    });
  };

  deleteHub = (row, e) => {
    e.stopPropagation();
    AppDialogActionsWrapper.openConfirmationDialog({
      title: `${this.props.t('Delete hub')}?`,
      body: `${this.props.t('Delete hub info')}.`,
      confirmButtonText: this.props.t('Delete'),
      continueCallback: () => {
        if (process.env.REACT_APP_DATA_SOURCE === 'api') {
          HubApi.deleteHub(row.original.id)
            .then(() => {
              if (this.message !== 'NoApi') {
                this.loadHubs();
                this.setState({ showHub: false });
              }
            })
            .catch((error) => {
              raygunClient.send(error, 'Error deleting hub', { id: row.original.id });
              toast.error(this.props.t('Oops something went wrong'));
              this.props.dispatchLoadingPage(false);
            });
        }
      }
    });
  };

  flyToCoordinates = (showHubData) => {
    this.setState({ showHub: true, clickedHubData: showHubData });
    this.props.dispatchCoordinates({ lng: parseFloat(showHubData.lng), lat: parseFloat(showHubData.lat) });
  };

  calculateTablePageSize = (height) => {
    if (this.state.tablePageSize !== height) {
      this.setState({ tablePageSize: height });
    }
  };

  render() {
    return (
      <div className="hub-management">
        <div className="menu-wrapper">
          <div className="menu">
            {AuthUtil.isFeatureEnabled('manageHub') && <Button text={this.props.t('Add new hub')} onClick={this.createNewHub} type={BUTTON_TYPES.PRIMARY} />}
            <div className="table-wrapper">
              {this.state.hub && this.state.hub.length > 0 && (
                <CardMenuTable
                  key={this.state.tablePageSize}
                  columns={this.getTableColumns()}
                  data={this.state.hub}
                  sortBy={[{ id: 'name', desc: false }]}
                  showSearch
                  handleRowClick={this.flyToCoordinates}
                  pageSize={this.state.tablePageSize}
                  calculateTablePageSize={this.calculateTablePageSize}
                  hiddenColumns={['integrationId']}
                  autoResetSelectedRows
                />
              )}
            </div>
          </div>
        </div>
        <div className="hub-map-section">
          {this.state.showHub && <HubMap hubData={this.state.clickedHubData} zoom={15.0} />}
          {!this.state.showHub && <LogoDisplay />}
        </div>
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.planningPageState };
}

/**
 * @param {Function} dispatch - redux dispatch function
 * @returns {object} - map actions to component props
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchIsMapLoading: MapActions.mapIsLoading,
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchResetPlanningData: PlanningPageActions.resetData,
      dispatchCoordinates: CoordinatesActions.coordinatesChange
    },
    dispatch
  );
}

export default withTranslation('translations')(withRouter(connect(mapStateToProps, mapDispatchToProps)(HubManagementPageClass)));

HubManagementPageClass.propTypes = {
  /**
   * React router history object
   */
  history: PropTypes.object.isRequired,
  /**
   * Dispatches event if page is currently loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  dispatchCoordinates: PropTypes.func.isRequired
};
