import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import MixPanel from '../../../../setup/mixPanel';
import AuthUtil from '../../../utils/authUtil';
import QueryStringUtil from '../../../utils/queryStringUtil';
import CustomSelect from '../CustomSelect';
import { ENTITY_TYPE } from '../../../constants/entityTypes';

/**
 * Checks if teams teamId is a teamId of an existing team
 *
 * @param {object.<Array>} regionCenters - array of regions
 * @param {string} teamId team ID
 * @returns {boolean} true if teamId is valid, otherwise false
 * @function
 */
function isQueryTeamIdValid(regionCenters, teamId) {
  return regionCenters && teamId && Object.keys(regionCenters)
    .includes(teamId);
}

/**
 * Select component for teams for displaying entities (couriers and ooh points)
 *
 * @param {object} props - props object for the component
 * @returns {JSX.Element} Team select
 * @component
 * @alias CourierTeamSelect
 * @category Common
 */
export default function TeamSelect(props) {
  const {
    onTeamChange,
    returnFullObject,
    eventTrackerNamePrefix
  } = props;
  const userPermissions = useSelector((state) => state.authState.userPermissions);
  const [teamOptions, setTeamOptions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState('');
  /**
   * Sets team based on current user permissions
   */
  useEffect(() => {
    /**
     * Fetches Team data and entities of a specific team
     *
     * @function
     */
    function getTeams() {
      const userTeams = { ...AuthUtil.getTeams() };
      if (userTeams && Object.keys(userTeams)) {
        Object.keys(userTeams)
          .forEach((teamId) => {
            if (!props.teamTypeFilter.includes(userTeams[teamId].teamType)) {
              delete (userTeams[teamId]);
            }
          });

        let key = QueryStringUtil.getQueryStringValue('teamId');
        if (!key || !isQueryTeamIdValid(userTeams, key)) {
          key = Object.keys(userTeams)[0];
          QueryStringUtil.setQueryStringValue('teamId', key);
        }
        if (returnFullObject) {
          onTeamChange({
            teamId: key,
            teamName: userTeams[key].name,
            teamType: getEntityTypeFromTeam(userTeams[key])
          });
        } else {
          onTeamChange(key, getEntityTypeFromTeam(userTeams[key]));
        }
        setSelectedTeamId(key);

        const allTeamOptions = Object.keys(userTeams)
          .map((teamId) => ({
            value: userTeams[teamId].id,
            label: userTeams[teamId].name,
            teamType: userTeams[teamId].teamType
          }));

        setTeams(userTeams);
        setTeamOptions(allTeamOptions);
      }
    }

    if (userPermissions) {
      getTeams();
    }
  }, [userPermissions, onTeamChange, returnFullObject]);

  function getEntityTypeFromTeam(team) {
    return (team && team.teamType) || ENTITY_TYPE.COURIERS;
  }

  /**
   * Creates default value for react.Select used for teams
   *
   * @returns {object} Option value to be used as a default value
   * @function
   */
  const getTeamSelectDefaultValue = () => {
    let teamSelectDefaultValue;
    const queryTeamId = QueryStringUtil.getQueryStringValue('teamId');
    if (teams && Object.keys(teams).length > 0) {
      const teamId = isQueryTeamIdValid(teams, queryTeamId) ? queryTeamId : Object.keys(teams)[0];

      teamSelectDefaultValue = {
        label: teams[teamId].name,
        value: teamId
      };
    }

    return teamSelectDefaultValue;
  };

  const handleTeamChange = useCallback(
    (opt) => {
      setSelectedTeamId(opt.value);
      MixPanel.track(`${eventTrackerNamePrefix} - Team changed`);
      QueryStringUtil.setQueryStringValue('teamId', opt.value);
      if (onTeamChange) {
        if (returnFullObject) {
          onTeamChange({
            teamId: opt.value,
            teamName: opt.label,
            teamType: opt.teamType
          });
          return;
        }
        onTeamChange(opt.value, getEntityTypeFromTeam(opt));
      }
    },
    [eventTrackerNamePrefix, onTeamChange, returnFullObject]
  );

  return (
    <CustomSelect
      className="select"
      options={teamOptions}
      defaultValue={getTeamSelectDefaultValue()}
      value={teamOptions.filter((option) => option.value === selectedTeamId)}
      onChange={handleTeamChange}
      isDisabled={!teams || Object.keys(teams).length === 1 || props.isDisabled}
    />
  );
}

TeamSelect.propTypes = {
  onTeamChange: PropTypes.func.isRequired,
  returnFullObject: PropTypes.bool,
  isDisabled: PropTypes.bool,
  eventTrackerNamePrefix: PropTypes.string,
  teamTypeFilter: PropTypes.arrayOf(PropTypes.string)
};

TeamSelect.defaultProps = {
  isDisabled: false,
  returnFullObject: false,
  eventTrackerNamePrefix: '',
  teamTypeFilter: []
};
