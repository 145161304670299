import { raygunClient } from '../../setup/raygunClient';

export const DISTANCE_TYPE = {
  RADIAL: 'RADIAL',
  // DRIVE: 'DRIVE',
  WALK: 'WALK'
};

export const DISTANCE_VALUE_TYPE_KEYS_ARRAY = ['INNER_DISTANCE', 'OUTER_DISTANCE'];

// Isochrone related constants.
export const ISOCHRONE = {
  RADIAL: {
    name: 'radial-distance',
    icon: 'icon-open_in_full',
    unit: 'm',
    distanceValueType: {
      INNER_DISTANCE: 250,
      OUTER_DISTANCE: 500
    },
    recommenderRequestParams: { prefix: '' },
    isochroneApiDistanceType: '',
    configMenuTitle: 'Fixed distance'
  },
  DRIVE: {
    name: 'drive-distance',
    icon: 'icon-directions_car',
    unit: 'min',
    distanceValueType: {
      INNER_DISTANCE: 5,
      OUTER_DISTANCE: 10
    },
    recommenderRequestParams: { prefix: 'd' },
    isochroneApiDistanceType: 'auto',
    configMenuTitle: 'Driving distance'
  },
  WALK: {
    name: 'walk-distance',
    icon: 'icon-directions_walk',
    unit: 'min',
    distanceValueType: {
      INNER_DISTANCE: 5,
      OUTER_DISTANCE: 10
    },
    recommenderRequestParams: { prefix: 'w' },
    isochroneApiDistanceType: 'pedestrian',
    configMenuTitle: 'Walking distance'
  }
};

const DistanceTypeUtil = {
  getDefaultDistanceType: getDefaultDistanceType,
  getLargestDistanceKey: getLargestDistanceKey,
  isLargestDistance: isLargestDistance,
  getName: getName,
  getUnit: getUnit,
  getIcon: getIcon,
  getDistanceValue: getDistanceValue,
  getDistanceValues: getDistanceValues,
  buildDistanceTypeForRecommender: buildDistanceTypeForRecommender,
  shouldFetchIsochronesFromBack: shouldFetchIsochronesFromBack,
  getIsochroneApiDistanceType: getIsochroneApiDistanceType,
  getConfigMenuTitle: getConfigMenuTitle
};

/*
 * Returns the default distance type that is used by the pages that use isochrone polygons
 * @returns {String} - Distance type, one of the keys from DISTANCE_TYPE object.
 */
function getDefaultDistanceType() {
  return DISTANCE_TYPE.RADIAL;
}

/*
 * Get the largest distance value for different distance types.
 * The largest distance could be in term of meters for aerial distance or minutes for isochrones.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {String} - Largest distance value for the given distanceType.
 */
function getLargestDistanceValue(distanceType) {
  if (ISOCHRONE[distanceType]) return ISOCHRONE[distanceType].distanceValueType.OUTER_DISTANCE;
  raygunClient.send(`Unknown distance type: ${distanceType}`);
  return '';
}

/*
 * Returns the key of the largest distance value for different distance types.
 * When any polygon coverage is calculated by default it's calculated for the largest distance.
 * @returns {String} - Key of the largest distance isochrone polygon
 */
function getLargestDistanceKey() {
  return 'OUTER_DISTANCE';
}

/*
 * Returns the name of the provided distance type.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {String} - The name of the provided distance type.
 */
function getName(distanceType) {
  if (ISOCHRONE[distanceType]) return ISOCHRONE[distanceType].name;
  raygunClient.send(`Unknown distance type: ${distanceType}`);
  return '';
}

/*
 * Utility to transform the distance type to a human readable unit.
 * For now the unit is the same in all languages but we might need to hook it to translation in the future.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {String} - Human readable unit for provided distance type.
 */
function getUnit(distanceType) {
  if (ISOCHRONE[distanceType]) return ISOCHRONE[distanceType].unit;
  raygunClient.send(`Unknown distance type: ${distanceType}`);
  return '';
}

/*
 * Returns the icon used for the provided distance type.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {String} - Icon string of the provided distance type.
 */
function getIcon(distanceType) {
  if (ISOCHRONE[distanceType]) return ISOCHRONE[distanceType].icon;
  raygunClient.send(`Unknown distance type: ${distanceType}`);
  return '';
}

/*
 * Returns the value of the provided distance type and distance level.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @param {String} distanceLevel - Distance level, one of the elements of the DISTANCE_VALUE_TYPE_KEYS_ARRAY array.
 * @returns {Number} - true if the distance type requires a routing request.
 */
function getDistanceValue(distanceType, distanceLevel) {
  return ISOCHRONE[distanceType].distanceValueType[distanceLevel];
}

/*
 * Returns the values for the provided distance type for all levels.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {Array<Number>} - Array of the values for the provided distance type for all levels.
 */
function getDistanceValues(distanceType) {
  if (ISOCHRONE[distanceType]) return Object.values(ISOCHRONE[distanceType].distanceValueType);
  raygunClient.send(`Unknown distance type: ${distanceType}`);
  return {};
}

/*
 * Returns true if the distance type requires a backend isochrone request.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {Boolean} - true if the distance type requires an isochrone request.
 */
function shouldFetchIsochronesFromBack(distanceType) {
  if (ISOCHRONE[distanceType]) return distanceType !== DISTANCE_TYPE.RADIAL;
  raygunClient.send(`Unknown distance type: ${distanceType}`);
  return null;
}

/*
 * Returns the cost type for the isochrone api.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {String} - Cost type for provided distance type.
 */
function getIsochroneApiDistanceType(distanceType) {
  return ISOCHRONE[distanceType].isochroneApiDistanceType;
}

/*
 * Builds the backend distance type for the recommender.
 * The backend distance type could be in term of meters for aerial distance or minutes for isochrones.
 * The logic is <prefix><value><postfix> where;
 * -- prefix is empty string '' for aerial distance, 'w' for walking distance and and 'd' for driving distance.
 * -- value is the largest distance in the object returned by `getDistanceOrTimes(distanceType)`
 * -- Postfix is 'm' for aerial distance implying meters, and 'min' for walking distance and driving distances
 *    implying minutes
 *
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {String} - backend distance type.
 */
function buildDistanceTypeForRecommender(distanceType) {
  const largestDistanceValue = getLargestDistanceValue(distanceType);
  return `${ISOCHRONE[distanceType].recommenderRequestParams.prefix}${largestDistanceValue}${ISOCHRONE[distanceType].unit}`;
}

/*
 * Extension that checks if the given distance value is the largest distance for the provided distance type or not.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @param {String} distanceValue - Distance value, one of the keys of the distanceValueType prop in ISOCHONE object's keys.
 * @returns {Boolean} - true if the distance is the largest distance for the given distance type.
 * @see isLargestDistance
 */
function isLargestDistance(distanceType, distanceValue) {
  if (ISOCHRONE[distanceType]) return getLargestDistanceValue(distanceType) === distanceValue;
  raygunClient.send(`Unknown distance type: ${distanceType}`);
  return null;
}

/*
 * Returns the string used as the title of the distance config menu for the provided distance type.
 * @param {String} distanceType - Distance type, one of the keys of the DISTANCE_TYPE object.
 * @returns {Boolean} - Title for the provided distance type in the distance config menu.
 * @see getConfigMenuTitle
 */
function getConfigMenuTitle(distanceType) {
  return ISOCHRONE[distanceType].configMenuTitle;
}

export default DistanceTypeUtil;
