import { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import * as _ from 'lodash';
import DistanceTypeUtil from '../../../../common/utils/distanceTypeUtil';
import { IsochroneUtil } from '../util/isochroneUtil';
import PolygonCalculationsUtil from '../../../../common/utils/polygonCalculationsUtil';
import { GET_ISOCHRONES_AS_HEXAGONS } from '../api/deliveryAreaAnalysisApi';
import idUtil from '../../../../common/utils/idUtil';

export default function usePinsIsochronesApi(distanceType) {
  // Pins data
  const [getDistancePinIsochrones, { loading: loadingDistancePinsData }] = useLazyQuery(GET_ISOCHRONES_AS_HEXAGONS);

  const [distancePinsStaticData, setDistancePinsStaticData] = useState({});
  const [distancePinsIsochroneData, setDistancePinsIsochroneData] = useState({});

  const getDistancePinIsochroneData = useCallback(getDistancePinIsochroneDataFn, [distanceType, getDistancePinIsochrones]);
  const updateDistancePinDataFromMap = useCallback(updateDistancePinDataFromMapFn, [getDistancePinIsochroneData]);
  const removeDistancePinPolygon = useCallback(removeDistancePinPolygonFn, []);
  const removeDistancePin = useCallback(removeDistancePinFn, []);
  const addNewDistancePins = useCallback(addNewDistancePinsFn, [getDistancePinIsochroneData]);

  const removeAllDistancePins = useCallback(() => {
    setDistancePinsStaticData({});
    setDistancePinsIsochroneData({});
  }, []);

  function updateDistancePinDataFromMapFn(mapMarkerEvent, distancePin) {
    const { lat, lng } = mapMarkerEvent.lngLat;
    setDistancePinsStaticData((prevState) => {
      return {
        ...prevState,
        [distancePin.id]: {
          ...distancePin,
          lat,
          lng
        }
      };
    });

    getDistancePinIsochroneData({ [distancePin.id]: { ...distancePin, lat, lng } });
  }

  function removeDistancePinPolygonFn(distancePinId) {
    removePinIsochronesData(distancePinId);
  }

  function getDistancePinIsochroneDataFn(newDistancePins, shouldResetState, distance = distanceType) {
    if (DistanceTypeUtil.shouldFetchIsochronesFromBack(distance) && !_.isEmpty(newDistancePins)) {
      const requestVariables = IsochroneUtil.getIsochronesAsHexagonsRequestVariables(newDistancePins, distance);
      getDistancePinIsochrones({
        variables: requestVariables,
        onCompleted: (data) => {
          const distancePinIsochronesData = IsochroneUtil.createPointsIsochroneData(newDistancePins, data);
          if (shouldResetState) {
            setDistancePinsIsochroneData(distancePinIsochronesData);
          } else {
            setDistancePinsIsochroneData((prevState) => {
              return {
                ...prevState,
                ...distancePinIsochronesData
              };
            });
          }
        }
      });
    } else {
      const newMapPointsIsochroneData = {};
      Object.values(newDistancePins).forEach((pin) => {
        const hexagonsCovered = PolygonCalculationsUtil.getIsochroneFromRadialDistance(pin.lat, pin.lng, true);
        newMapPointsIsochroneData[pin.id] = {
          ...hexagonsCovered,
          id: pin.id
        };
      });
      setDistancePinsIsochroneData((prevState) => {
        return {
          ...prevState,
          ...newMapPointsIsochroneData
        };
      });
    }
  }

  function removeDistancePinFn(distancePinId) {
    setDistancePinsStaticData((prevState) => {
      const newState = { ...prevState };
      delete newState[distancePinId];

      return newState;
    });

    removePinIsochronesData(distancePinId);
  }

  function removePinIsochronesData(distancePinId) {
    setDistancePinsIsochroneData((prevState) => {
      const newState = { ...prevState };
      delete newState[distancePinId];

      return newState;
    });
  }

  function addNewDistancePinsFn(newDistancePinsData) {
    const newDistancePins = updateDistancePinsStaticData(newDistancePinsData);
    getDistancePinIsochroneData(newDistancePins, false);
  }

  function updateDistancePinsStaticData(distancePinsData) {
    const newDistancePins = {};
    distancePinsData.forEach((pinData) => {
      const newPinId = idUtil.generateId();
      newDistancePins[newPinId] = {
        id: newPinId,
        type: 'distancePin',
        ...pinData
      };
    });

    setDistancePinsStaticData((prevState) => {
      return {
        ...prevState,
        ...newDistancePins
      };
    });

    return newDistancePins;
  }

  return {
    updateDistancePinDataFromMap,
    removeDistancePinPolygon,
    removeDistancePin,
    removeAllDistancePins,
    addNewDistancePins,
    distancePinsStaticData,
    distancePinsIsochroneData,
    loadingDistancePinsData,
    getDistancePinIsochroneData
  };
}
