export const USER_MAP_LAYERS = [
  'stops',
  'packages',
  'shipments',
  'shipmentsWithOnePackage',
  'shipmentsForPackageLockers',
  'population'
];

export const USER_MAP_LAYERS_FOR_DEMO = USER_MAP_LAYERS;

export const COUNT_DAYS_FOR_LAYERS = {
  default: true,
  population: false
};
export const DEFAULT_ADJUSTMENT_FACTORS = {
  default: 0.025,
  stops: 0.01,
  population: 0.004
};

export const PER_DAY_ADJUSTMENT_FACTORS = {
  default: 0.0075, // (0.25 - 0.025) / 30
  stops: 0.003, // (0.1 - 0.01) / 30,
  population: 0
};

export const HEATMAP_COLOR = {
  default: '#F4713D',
  population: '#FBB021'
};

export const HEATMAP_BORDER_COLOR = {
  default: '#E8721D',
  population: '#F5A56A'
};

export const PERMISSIONS_FOR_MAP_LAYERS = [
  'hex-type-stops',
  'hex-type-packages',
  'hex-type-shipments',
  'hex-type-shipments-with-one-package',
  'hex-type-shipments-for-package-lockers',
  'hex-type-population'
];

export const COVERAGE_RECOMMENDATION_LAYER = 'coverage-recommendation-layer';
export const COVERAGE_RECOMMENDATION_SOURCE = 'coverage-recommendation';
