import React from 'react';
import * as d3 from 'd3';
import './BarChart.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './HeatMapChart.scss';
import { withTranslation } from 'react-i18next';
import * as CharActions from '../../../../state/actions/chartActions';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import MixPanel from '../../../../setup/mixPanel';

const COLORS100 = [
  '#FFFFE5',
  '#FFFEE2',
  '#FFFEDF',
  '#FFFDDC',
  '#FFFCD8',
  '#FFFCD6',
  '#FFFBD2',
  '#FFFACE',
  '#FFFACB',
  '#FFF9C7',
  '#FFF9C5',
  '#FFF8C1',
  '#FFF7BD',
  '#FFF6BA',
  '#FFF4B6',
  '#FFF3B4',
  '#FFF1B0',
  '#FFF0AD',
  '#FFEEA9',
  '#FEECA5',
  '#FEEBA2',
  '#FEE99E',
  '#FEE89B',
  '#FEE697',
  '#FEE493',
  '#FEE390',
  '#FEE08A',
  '#FEDE86',
  '#FEDB80',
  '#FED97C',
  '#FED676',
  '#FED36F',
  '#FED16B',
  '#FECE65',
  '#FECC61',
  '#FEC95B',
  '#FEC754',
  '#FEC550',
  '#FEC14C',
  '#FEBE4A',
  '#FEBA46',
  '#FEB643',
  '#FEB340',
  '#FEAF3D',
  '#FEAD3A',
  '#FEA937',
  '#FEA634',
  '#FEA231',
  '#FE9E2D',
  '#FE9B2B',
  '#FD9728',
  '#FC9427',
  '#FA9125',
  '#F98D23',
  '#F88A21',
  '#F6861F',
  '#F5841E',
  '#F3801C',
  '#F27D1B',
  '#F07919',
  '#EE7617',
  '#ED7316',
  '#EB6F14',
  '#E96D13',
  '#E66A11',
  '#E3660F',
  '#E1640E',
  '#DE610C',
  '#DC5E0B',
  '#D95B09',
  '#D65808',
  '#D45507',
  '#D15205',
  '#CF5004',
  '#CC4C02',
  '#C94B02',
  '#C44802',
  '#C04602',
  '#BC4503',
  '#B84203',
  '#B44103',
  '#B03F03',
  '#AB3C03',
  '#A83B03',
  '#A33904',
  '#A03704',
  '#9B3504',
  '#983404',
  '#933204',
  '#8E3104',
  '#8B3005',
  '#862E05',
  '#832D05',
  '#7E2C05',
  '#792B05',
  '#762A05',
  '#712806',
  '#6E2706',
  '#692606',
  '#662506'
];

class HeatMapChartClass extends React.Component {
  componentDidMount() {
    this.drawChart(this.props.keys);
    this.drawLegend();
  }

  componentDidUpdate(prevProps) {
    let shouldRedrawChart = false;
    if (prevProps.t !== this.props.t) {
      shouldRedrawChart = true;
    }

    if (prevProps.resetHeatMap !== this.props.resetHeatMap && this.props.chartId !== this.props.containerId) {
      shouldRedrawChart = true;
    }

    if (prevProps.data !== this.props.data && this.props.chartId !== this.props.containerId) {
      shouldRedrawChart = true;
    }

    if (shouldRedrawChart) {
      this.drawChart(this.props.keys);
      this.drawLegend();
    }
  }

  getColors(includeNull = false) {
    const colorObject = this.createColorObjectFromSpectre();
    return includeNull ? {
      '-1': colorsAndFonts.tile_background_color,
      ...colorObject
    } : colorObject;
  }

  createColorObjectFromSpectre() {
    const myObject = {};
    const spectreLength = COLORS100.length - 1;
    const dif = this.props.scaleByMaxValue ? spectreLength / this.maxValue : spectreLength / this.props.capacity;
    let spectreIncrement = 0;
    let i = 0;
    while (Math.floor(spectreIncrement) <= spectreLength) {
      const colorKeyObject = {};
      colorKeyObject[`${i}`] = COLORS100[Math.floor(spectreIncrement)];
      Object.assign(myObject, colorKeyObject);
      i++;
      spectreIncrement += dif;
    }

    return myObject;
  }

  drawChart = (keys) => {
    d3.select(`#${this.props.containerId} svg`)
      .remove('svg');
    d3.select(`#${this.props.containerId} .tooltip`)
      .remove('.tooltip');
    // set the dimensions and margins of the graph
    const margin = {
      top: 10,
      right: 10,
      bottom: 20,
      left: 80
    };
    const width = this.props.width - margin.left - margin.right;
    const height = this.props.height - margin.top - margin.bottom;
    const instance = this;
    // append the svg object to the body of the page
    const svg = d3
      .select(`#${this.props.containerId}`)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.bottom})`);

    const { t } = this.props;
    // Labels of row and columns
    const data = this.props.data.chartData;
    const hourGroups = d3.set(data.map((d) => d.hour))
      .values();
    const dayVars = d3
      .set(data.map((d) => t(d.day)))
      .values()
      .reverse();

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    hourGroups.sort(collator.compare);

    // Build X scales and axis:
    const x = d3.scaleBand()
      .range([0, width])
      .domain(hourGroups)
      .padding(0.01);
    svg.append('g')
      .attr('class', 'axis')
      .call(d3.axisTop(x));

    // Build Y scales and axis:
    const y = d3.scaleBand()
      .range([height, 0])
      .domain(dayVars)
      .padding(0.01);
    svg.append('g')
      .attr('class', 'axis')
      .call(d3.axisLeft(y));

    // Find max value
    if (this.props.scaleByMaxValue) {
      let maxValue = 0;
      Object.values(data)
        .forEach((d) => {
          if (d.value > maxValue) {
            maxValue = d.value;
          }
        });

      this.maxValue = maxValue;
    }

    // Add brush
    if (this.props.containerId === 'hourly-collections-heat-map') {
      this.brush = d3.brush();
      svg
        .append('g')
        .attr('class', 'brush')
        .call(
          this.brush // Add the brush feature using the d3.brush function
            .extent([
              [0, 0],
              [width, height]
            ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
            .on('start brush', this.prepareForBrushing)
            .on('end', this.filterData) // Each time the brush selection changes, trigger the 'updateChart' function
        );
    }

    // Create tooltip
    const tooltip = d3.select(`#${this.props.containerId}`)
      .append('div')
      .attr('class', 'tooltip')
      .style('visibility', 'hidden');

    const colorSchema = this.getColors(true);
    // Read the data
    svg
      .selectAll()
      .data(data, (d) => `${d.hour}:${t(d.day)}`)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.hour))
      .attr('y', (d) => y(t(d.day)))
      .attr('class', 'cell')
      .attr('width', x.bandwidth())
      .attr('height', y.bandwidth())
      .attr('day', (d) => t(d.day))
      .attr('dayKey', (d) => d.day)
      .attr('hour', (d) => d.hour)
      .attr('cursor', this.brush ? 'crosshair' : '')
      .style('fill', (d) => colorSchema[Math.min(instance.getDayValue(d, keys), this.props.capacity)])
      .on('mouseover', function (d) {
        if (instance.getDayValue(d, keys) !== -1) {
          instance.showToolTip(this, tooltip, d, keys);
        }
      })
      .on('mousemove', function (d) {
        if (instance.getDayValue(d, keys) !== -1) {
          instance.showToolTip(this, tooltip, d, keys);
        }
      })
      .on('mouseleave', () => {
        tooltip.style('visibility', 'hidden');
      })
      .on('mousedown', () => {
        if (this.brush) {
          tooltip.style('visibility', 'hidden');
          const brushElem = svg.select('.brush > .overlay')
            .node();
          const brushSelection = svg.select('.brush > .selection')
            .node();
          const bbox = brushSelection.getBoundingClientRect();
          if (
            brushSelection.style.display !== 'none'
            && d3.event.pageX > bbox.left
            && d3.event.pageX < bbox.left + bbox.width
            && d3.event.pageY > bbox.top
            && d3.event.pageY < bbox.top + bbox.height
          ) {
            // Click happened on a cell, inside the current brush selection, so, don't do anything
            return;
          }
          // Click happened on a cell, with no rectangle selection or outside the rectangle selection
          // so let's start a new selection :
          const newClickEvent = new MouseEvent('mousedown', {
            pageX: d3.event.pageX,
            pageY: d3.event.pageY,
            clientX: d3.event.clientX,
            clientY: d3.event.clientY,
            layerX: d3.event.layerX,
            layerY: d3.event.layerY,
            bubbles: true,
            cancelable: true,
            view: window
          });
          brushElem.dispatchEvent(newClickEvent);
        }
      });
  };

  getDayValue = (d, keys) => {
    let value = 0;

    keys.forEach((key) => {
      value += d[key];
    });
    return value;
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  showToolTip = (heatMap, tooltip, data, keys) => {
    const ctm = heatMap.getCTM();
    const coords = {};
    coords.x = heatMap.getAttribute('x');
    coords.y = heatMap.getAttribute('y');

    const x = ctm.e + coords.x * ctm.a + coords.y * ctm.c;
    tooltip
      .style('left', `${x}px`)
      .style('top', `${d3.mouse(heatMap)[1] - 30}px`)
      .style('visibility', 'visible')
      .html(`${this.getDayValue(data, keys)}`);
  };

  drawLegend = () => {
    // set the dimensions and margins of the graph
    const margin = {
      top: 10,
      right: 5,
      bottom: 5,
      left: 0
    };

    const width = 45;
    const height = this.props.height;
    const colors = Object.values(this.getColors())
      .reverse();

    colors[colors.length - 1] = colorsAndFonts.tile_background_color;
    const containerId = this.props.containerId;
    const svgContainer = d3.select(`#${containerId}-legend`);

    // Check if the SVG element already exists
    const existingSvg = svgContainer.select('svg.legend');
    if (existingSvg) {
      existingSvg.remove(); // Remove the existing SVG element
    }

    // Create a new SVG element

    // append the svg object to the body of the page
    const svg = d3
      .select(`#${this.props.containerId}-legend`)
      .append('svg')
      .attr('class', 'legend')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Build Y scales for cells:
    const y = d3
      .scaleBand()
      .range([0, height - margin.top - 1])
      .domain(colors)
      .padding(0.01);

    const colorBarHeight = (height - margin.top) / colors.length;
    // Build Y scale and axis for label
    const scale = d3
      .scaleLinear()
      .domain([0, colors.length - 1])
      .range([height - margin.top - margin.bottom - colorBarHeight / 2, colorBarHeight / 2]);

    const yAxis = d3
      .axisRight()
      .ticks(10)
      .tickFormat((x) => {
        return `${x}`;
      })
      .scale(scale);

    svg.append('g')
      .attr('class', 'legend-axis')
      .attr('transform', 'translate(20, 0)')
      .call(yAxis);

    // Create cells
    svg
      .selectAll()
      .append('g')
      .data(colors, (d) => d)
      .enter()
      .append('rect')
      .attr('y', (d) => y(d))
      .attr('height', y.bandwidth())
      .attr('width', 20)
      .attr('fill', (d) => d);
  };

  prepareForBrushing = () => {
    d3.selectAll(`#${this.props.containerId} .cell`)
      .style('opacity', 0.5);
    d3.select(`#${this.props.containerId} .tooltip`)
      .style('visibility', 'hidden');
  };

  filterData = () => {
    const cellSelection = d3.selectAll(`#${this.props.containerId} .cell`);
    const cells = cellSelection._groups[0];

    if (!d3.event.selection) {
      this.props.dispatchResetChartData();
      d3.selectAll(`#${this.props.containerId} .cell`)
        .style('opacity', 1);
      return;
    }

    const [selectionStart, selectionEnd] = d3.event.selection;

    const days = new Set();
    const hours = new Set();
    cells.forEach((cell) => {
      const coords = {};
      coords.x = parseInt(cell.getAttribute('x'), 10);
      coords.y = parseInt(cell.getAttribute('y'), 10);
      const width = cell.getAttribute('width');
      const height = cell.getAttribute('height');

      const xEnd = coords.x + parseInt(width, 10);
      const yEnd = coords.y + parseInt(height, 10);

      if (coords.x <= selectionEnd[0] && xEnd >= selectionStart[0] && coords.y <= selectionEnd[1] && yEnd >= selectionStart[1]) {
        const selectedDay = cell.getAttribute('dayKey');
        days.add(selectedDay);
        const selectedHour = cell.getAttribute('hour');
        hours.add(selectedHour);

        // eslint-disable-next-line no-param-reassign
        cell.style.opacity = 1;
      } else {
        // eslint-disable-next-line no-param-reassign
        cell.style.opacity = 0.5;
      }
    });

    MixPanel.track('OOH Point Analysis - Collection by hour of day chart filter applied');
    this.props.dispatchChangeSelectedDates(days, hours, this.props.containerId);
    this.brush(d3.select(`#${this.props.containerId}`));
  };

  render() {
    return (
      <div className="heat-map">
        <div id={this.props.containerId} />
        <div id={`${this.props.containerId}-legend`} />
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} redux store
 */
function mapStateToProps(store) {
  return { ...store.chartState };
}

/**
 * @param {Function} dispatch - redux dispatch
 * @returns {object} redux dispatch actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchChangeSelectedDates: CharActions.changeSelectedData,
      dispatchResetChartData: CharActions.resetData
    },
    dispatch
  );
}

HeatMapChartClass.propTypes = {
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Change selected dates
   */
  dispatchChangeSelectedDates: PropTypes.func.isRequired,
  dispatchResetChartData: PropTypes.func.isRequired,
  /**
   * Reset chart
   */
  resetHeatMap: PropTypes.instanceOf(Date),
  /**
   * Chart width
   */
  width: PropTypes.number.isRequired,
  /**
   * Chart height
   */
  height: PropTypes.number.isRequired,
  containerId: PropTypes.string.isRequired,
  chartId: PropTypes.string,
  capacity: PropTypes.number,
  /**
   * Chart data
   */
  data: PropTypes.shape({
    chartData: PropTypes.arrayOf(PropTypes.shape({
      day: PropTypes.string,
      hour: PropTypes.number,
      value: PropTypes.number
    })),
    dataExists: PropTypes.bool
  }).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string),
  scaleByMaxValue: PropTypes.bool
};

HeatMapChartClass.defaultProps = {
  resetHeatMap: null,
  capacity: null,
  chartId: null,
  keys: null,
  scaleByMaxValue: false
};

const HeatMapChart = connect(mapStateToProps, mapDispatchToProps)(HeatMapChartClass);

export default withTranslation('translations')(HeatMapChart);
