import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HubManagementPage from './pages/HubManagementPage';
import AddHubPage from './pages/AddHubPage';

/**
 * Hub page wrapper for page navigation
 *
 * @returns {JSX.Element} - Hub page wrapper for page navigation
 * @component
 * @alias HubRoutes
 * @category hub-management
 */
export default function HubRoutes() {
  return (
    <Switch>
      <Route exact path="/hub-management" key="/hub-management" component={HubManagementPage} />
      <Route path="/hub-management/hub-add" key="/hub-management/hub-add" component={AddHubPage} />
    </Switch>
  );
}
