import { gql } from '@apollo/client';
import moment from 'moment';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import { raygunClient } from '../../../../setup/raygunClient';
import { IsochroneUtil } from '../../deliveryAreaAnalysis/util/isochroneUtil';
import DistanceTypeUtil from '../../../../common/utils/distanceTypeUtil';

const OohAnalysisApi = {
  getOohPointShipmentsData: getOohPointShipmentsData,
  getIsochronesAsPolygons: getIsochronesAsPolygons
};

const GetOohPointShipmentsData = gql`
  query GetOohPointShipmentsData($oohPointId: String, $fromDate: String, $toDate: String) {
    getOohPointShipmentsData(oohPointId: $oohPointId, fromDate: $fromDate, toDate: $toDate) {
      snapshots {
        oohId
        date
        capacity
        vacancy
        utilizationRate
        collections
        unsuccessful
        dayOfTheWeek,
        workingHours
      }
      terminalStates {
        lat
        lng
        geoLat
        geoLng
        geoDistance
        dwellTime
        address
        event
        timestamp
        shipmentCode
        dayOfTheWeek
        hour
        numPackages
      }
    }
  }
`;

/**
 * Fetches stops of a courier
 *
 * @param {string} oohPointId - ooh point id
 * @param {Date} dateFrom - filter start date
 * @param {Date} dateTo - filter end date
 * @returns {Promise<Array.<object>> | null} Array of stops data
 * @async
 * @function
 * @memberOf OohAnalysisApi
 * @alias getStops
 */
async function getOohPointShipmentsData(oohPointId, dateFrom, dateTo) {
  const startDate = moment(dateFrom).startOf('day').format('YYYY-MM-DDTHH');
  const endDate = moment(dateTo).endOf('day').format('YYYY-MM-DDTHH');

  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetOohPointShipmentsData, { oohPointId: oohPointId, fromDate: startDate, toDate: endDate }).then(
      (res) => res?.data?.getOohPointShipmentsData
    );
  }
  return fetch(`/testData/analysis/ooh/ooh-point-${oohPointId}.json`).then((data) => data.json());
}

const REQUEST_ROUTE_GQL = gql`
  query GetIsochronesAsPolygons($innerIsochroneRequest: IsochronesRequestInput, $outerIsochroneRequest: IsochronesRequestInput) {
    innerIsochrone: getIsochronesAsPolygons(request: $innerIsochroneRequest)
    outerIsochrone: getIsochronesAsPolygons(request: $outerIsochroneRequest)
  }
`;

function getIsochronesAsPolygons(mapPoint, distanceType) {
  const distanceTypeValues = DistanceTypeUtil.getDistanceValues(distanceType);

  const innerIsochroneRequest = IsochroneUtil.buildIsochroneRequest([mapPoint], distanceType, distanceTypeValues[0]);
  const outerIsochroneRequest = IsochroneUtil.buildIsochroneRequest([mapPoint], distanceType, distanceTypeValues[1]);
  return ApiHelpers.createQuery(REQUEST_ROUTE_GQL, {
    innerIsochroneRequest: innerIsochroneRequest.request,
    outerIsochroneRequest: outerIsochroneRequest.request
  }).then((response) => {
    if (response.errors) {
      raygunClient.send('Failed to get isochrones', null, response.errors);
      return [];
    }
    return {
      innerIsochrone: response.data.innerIsochrone,
      outerIsochrone: response.data.outerIsochrone
    };
  }).catch((error) => {
    raygunClient.send(
      error,
      'Failed to create graphql query for isochrones',
      { innerIsochroneRequest: innerIsochroneRequest, outerIsochroneRequest: outerIsochroneRequest }
    );
    return [];
  });
}

export default OohAnalysisApi;
