export const POLYGON_COLORS = [
  '#b03060',
  '#556B2F',
  '#A0522D',
  '#7F0000',
  '#e3590a',
  '#b32ab7',
  '#191970',
  '#ff0000',
  '#007700',
  '#05a7bc',
  '#88b329',
  '#00008B',
  '#daa520',
  '#03ae5e',
  '#215fe2',
  '#880088',
  '#9d315a',
  '#ba6c09',
  '#26a726',
  '#ff0000',
  '#0178b7',
  '#ffaa00',
  '#0000CD',
  '#5648ab',
  '#bc0e30',
  '#00bfff',
  '#fd8213',
  '#9370db',
  '#ea4ddd',
  '#997344',
  '#1276d6',
  '#ff6f5d',
  '#ff5100',
  '#2348d9',
  '#e368e3',
  '#808000',
  '#45b592',
  '#A020F0',
  '#a76201',
  '#e95fa4'
];

export const MAP_VIEW_TYPE = {
  CLUSTER: 'cluster',
  HEATMAP: 'heatmap'
};
