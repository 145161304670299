import geojson2h3 from 'geojson2h3';
import { h3GetResolution, h3ToChildren, h3ToParent } from 'h3-js';
import React from 'react';
import './AddDeliveryAreaPage.scss';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as moment from 'moment';
import { GEOCODING_HEX_LEVEL } from '../../../../common/constants/geocodingConstants';
import TenantUtil from '../../../../common/utils/tenantUtil';
import { raygunClient } from '../../../../setup/raygunClient';
import * as PageActions from '../../../../state/actions/pageActions';
import * as DialogActions from '../../../../state/actions/dialogActions';
import S3Util from '../../../../common/utils/s3Util';
import StepInfoHeader from '../../../planning/planManagement/components/StepInfoHeader';
import DeliveryAreaMapView from '../components/DeliveryAreaMapView';
import MapModeButton from '../../../../common/components/buttons/mapModeButton/MapModeButton';
import HexSelectionToolsMenu from '../../../../common/components/buttons/mapModeButton/components/HexSelectionToolsMenu';
import DrawHexagonsUtil from '../../../../common/components/buttons/mapModeButton/utils/drawHexagonsUtil';
import BackendResourceConfigUtil from '../../../../common/utils/api/backendResourceConfigUtil';
import DeliveryAreasApi from '../api/deliveryAreasApi';
import { EDIT_MODE, SELECTION_TOOL } from '../../../../common/components/buttons/mapModeButton/constants/drawHexagonsConst';
import DeliveryAreaMenu from '../components/DeliveryAreaMenuTable';
import DeliveryAreaTableToggleIcon from '../components/DeliveryAreaTableToggleIcon';
import { DEFAULT_HEX_SIZE,
  DEMOGRAPHIC_HEX_LEVEL,
  MAX_HEX_LEVEL,
  MIN_HEX_LEVEL } from '../constants/deliveryAreaConstants';
import MixPanel from '../../../../setup/mixPanel';
import DeliveryAreasUtil from '../util/DeliveryAreasUtil';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import DeliveryAreaForm from '../components/DeliveryAreaForm';
import MilyTooltipWrapper from '../../../../common/components/tooltips/MilyTooltipWrapper';

const MAX_HEXAGONS_PER_DA = 10000;

/**
 * Wrapper component for adding/editing delivery area data (name and hexagons)
 *
 * @component
 * @alias AddDeliveryAreaPage
 * @category deliveryAreas
 * @returns {React.Component} AddDeliveryAreaPage page
 */
class AddDeliveryAreaPageClass extends React.Component {
  constructor(props) {
    super(props);
    this.dataType = props?.location?.pathname.split('/')[1] || 'delivery-areas';
    this.state = {
      selectionTool: SELECTION_TOOL.PICK,
      editMode: EDIT_MODE.ADD,
      hexLevel: DEFAULT_HEX_SIZE,
      deliveryAreaData: this.props?.history?.location?.state,
      activeDeliveryAreas: null
    };

    this.hexValidation = {};
  }

  componentDidMount() {
    this.props.dispatchLoadingPage(false);
  }

  getDefaultName = () => {
    if (this.props?.history?.location?.state?.name) {
      return this.props.history.location.state.name;
    }

    if (this.dataType === 'zip-code') {
      return this.props?.history?.location?.state?.id;
    }

    return `${this.props.t('Delivery Area')} ${moment().format('YYYY-MM-DD')}`;
  };

  openDeliveryAreaForm = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: DeliveryAreaForm,
      dialogComponentProps: {
        data: { name: this.getDefaultName(), integrationId: this.props.history.location.state.integrationId },
        title: this.props.t('Add new delivery area'),
        submitCallback: (data) => {
          this.props.dispatchLoadingPage(true);
          this.saveDeliveryAreaData(data.integrationId, data.name);
        }
      }
    });
  };

  saveDeliveryAreaData = (integrationId, deliveryAreaName) => {
    const areaId = this.props?.history?.location?.state?.id;
    const areaData = {
      name: deliveryAreaName,
      hexagonsFile: `${areaId}_hexagons.json`,
      polygonFile: `${areaId}_polygon.json`,
      integrationId: integrationId
    };
    const filePromises = this.generateDeliveryAreaFiles(areaId);
    Promise.all(filePromises)
      .then(() => {
        DeliveryAreasApi.upsertDeliveryArea(areaId, areaData, this.dataType)
          .then(() => {
            this.props.history.push({
              pathname: `/${this.dataType}/map-view/${areaId}/view-state`,
              state: { deliveryAreaData: { ...this.props?.history?.location?.state } }
            });
          })
          .catch((err) => {
            raygunClient.send(err, 'Error editing da', { areaId: areaId });
            toast.error(this.props.t('Failed to save data'));
          });
      })
      .catch((e) => {
        raygunClient.send(e, 'Error generating da files', { areaId: areaId });
        toast.error(this.props.t('Failed to save data'));
      });
  };

  generateHexagonsForLevel = (hexLevel) => {
    const geocodingHexs = new Set();
    this.props.regionsData[0].hexIds.forEach((hexId) => {
      const hexResolution = h3GetResolution(hexId);

      if (hexResolution === hexLevel) {
        geocodingHexs.add(hexId);
      }

      if (hexResolution > hexLevel) {
        geocodingHexs.add(h3ToParent(hexId, hexLevel));
      }

      h3ToChildren(hexId, hexLevel).forEach((item) => geocodingHexs.add(item));
    });
    return Array.from(geocodingHexs);
  };

  generateDeliveryAreaFiles = (areaId) => {
    let hexagonsFileName = `${DeliveryAreasUtil.getDefaultFilePath(this.dataType)}/${areaId}/${areaId}_hexagons.json`; // FIXME sync file paths
    hexagonsFileName = TenantUtil.addTenantToFileName(hexagonsFileName);
    const hexagons = this.props.regionsData[0].hexIds;
    const hexagonsFile = S3Util.uploadFileToS3(hexagonsFileName, hexagons, {
      contentType: 'application/json',
      bucket: BackendResourceConfigUtil.getRegionDataBucketName()
    });

    let polygonFileName = `${DeliveryAreasUtil.getLatestFilePath(this.dataType)}/${areaId}/${areaId}_polygon.json`;
    polygonFileName = TenantUtil.addTenantToFileName(polygonFileName);
    const polygon = geojson2h3.h3SetToFeature(this.props.regionsData[0].hexIds);
    const polygonFile = S3Util.uploadFileToS3(polygonFileName, polygon, {
      contentType: 'application/json',
      bucket: BackendResourceConfigUtil.getRegionDataBucketName()
    });

    let demographicHexsFileName = `${DeliveryAreasUtil.getLatestFilePath(this.dataType)}/${areaId}/${areaId}_level_6_hexagons.json`;
    demographicHexsFileName = TenantUtil.addTenantToFileName(demographicHexsFileName);
    const demographicHexs = this.generateHexagonsForLevel(DEMOGRAPHIC_HEX_LEVEL);
    const demographicHexagonsFile = S3Util.uploadFileToS3(demographicHexsFileName, demographicHexs, {
      contentType: 'application/json',
      bucket: BackendResourceConfigUtil.getRegionDataBucketName()
    });

    let geocodingHexsFileName = `${DeliveryAreasUtil.getLatestFilePath(this.dataType)}/${areaId}/${areaId}_level_7_hexagons.json`;
    geocodingHexsFileName = TenantUtil.addTenantToFileName(geocodingHexsFileName);
    const geocodingHexs = this.generateHexagonsForLevel(GEOCODING_HEX_LEVEL);
    const geocodingHexagonsFile = S3Util.uploadFileToS3(geocodingHexsFileName, geocodingHexs, {
      contentType: 'application/json',
      bucket: BackendResourceConfigUtil.getRegionDataBucketName()
    });

    let geocodingRegionHexagonsFile;
    if (this.dataType === 'zip-code') {
      let geocodingRegionFileName = `latest/region-hex-mapping/${areaId}/${areaId}_level_7_hex_for_geocoding_mapping.json`;
      geocodingRegionFileName = TenantUtil.addTenantToFileName(geocodingRegionFileName);
      geocodingRegionHexagonsFile = S3Util.uploadFileToS3(geocodingRegionFileName, geocodingHexs, {
        contentType: 'application/json',
        bucket: BackendResourceConfigUtil.getRegionDataBucketName()
      });
    }

    return [polygonFile, hexagonsFile, geocodingHexagonsFile, demographicHexagonsFile, geocodingRegionHexagonsFile];
  };

  /**
   * Change selection tool type
   *
   * @param {object} opt - selected option object (value, label)
   * @function
   */
  changeSelectionTool = (opt) => {
    this.setState({ selectionTool: opt.currentTarget.dataset.value });
  };

  /**
   * Change edit mode
   *
   * @param {object } e - JsEvent
   * @function
   */
  changeEditMode = (e) => {
    this.setState({ editMode: e.currentTarget.dataset.value });
  };

  changeHexLevel = (e) => {
    const number = parseInt(e.currentTarget.dataset.value, 10);
    this.setState((prevState) => {
      if (prevState.hexLevel === MAX_HEX_LEVEL && number > 0) {
        return prevState;
      }

      if (prevState.hexLevel === MIN_HEX_LEVEL && number < 0) {
        return prevState;
      }

      return { hexLevel: prevState.hexLevel + number };
    });
  };

  setHexLevel = (hexLevel) => {
    if (this.props?.regionsData[0]?.hexIds) {
      this.hexValidation[hexLevel] = new Set(this.props?.regionsData[0]?.hexIds);
    }
    this.setState({ hexLevel: hexLevel });
  };

  updateHexValidation = (newHex, level, adding) => {
    if (!this.hexValidation[level]) {
      this.hexValidation[level] = new Set([]);
    }

    newHex.forEach((hexId) => {
      if (adding) {
        this.hexValidation[level].add(hexId);
      } else {
        this.hexValidation[level].delete(hexId);
      }
    });

    if (this.hexValidation[level].size === 0) {
      delete this.hexValidation[level];
    }
  };

  haveDifferentHexLevels = () => {
    return Object.keys(this.hexValidation).length > 1;
  };

  isSaveDisabled = () => {
    return (
      this.haveDifferentHexLevels()
      || !this.props.regionsData
      || !this.props.regionsData[0]
      || !this.props.regionsData[0].hexIds.length > 0
      || this.haveToManyHexagons()
    );
  };

  haveToManyHexagons = () => {
    return this.props.regionsData && this.props.regionsData[0] && this.props.regionsData[0].hexIds.length > MAX_HEXAGONS_PER_DA;
  };

  toggleDeliveryArea = (row) => {
    const id = row.original.id;
    MixPanel.track('Delivery Areas Management - Show/Hide delivery area button clicked');
    // eslint-disable-next-line react/no-access-state-in-setstate
    const activeDA = this.state.activeDeliveryAreas || {};
    if (activeDA[id]) {
      delete activeDA[id];
    } else {
      activeDA[id] = {
        polygonFile: row.original.polygonFile,
        id: id
      };
    }
    this.setState({ activeDeliveryAreas: { ...activeDA } });
  };

  getTableColumns = () => {
    return [
      {
        Header: this.props.t('Name'),
        accessor: 'name', // accessor is the "key" in the data
        className: 'flex-3'
      },
      {
        Header: this.props.t('Actions'),
        id: 'show-actions',
        disableGlobalFilter: true,
        className: 'centered',
        Cell: ({ row }) => {
          const isChecked = !!(this.state.activeDeliveryAreas && this.state.activeDeliveryAreas[row.original.id]);
          return (
            <div
              className="delivery-area-toggle"
              onClick={this.toggleDeliveryArea.bind(this, row)}
              data-tip={`${this.props.t('Show/hide area')}`}
              data-for="da-preview"
            >
              <DeliveryAreaTableToggleIcon checked={isChecked} />
              <MilyTooltipWrapper id="da-preview" className="tooltip" place="right" effect="solid" />
            </div>
          );
        }
      }
    ];
  };

  render() {
    return (
      <div className="create-delivery-area-component">
        <StepInfoHeader
          message={this.props.t('Mark delivery area on map')}
          nextButtonText={this.props.t('Save')}
          options={{
            cancelButtonHidden: false,
            cancelToPage: `/${this.dataType}`,
            returnButtonHidden: true,
            nextButtonDisabled: this.isSaveDisabled()
          }}
          onNextClick={this.openDeliveryAreaForm}
        />

        <div className="content-wrapper">
          <div className="side-wrapper">
            <div className="menu-wrapper">
              <div className="menu">
                <DeliveryAreaMenu getTableColumns={this.getTableColumns} dataType={this.dataType} deliveryAreas={this.props.location?.state?.deliveryAreas} />
              </div>
            </div>

            <div className="controls-wrapper">
              <MapModeButton
                customKey="add-hexagons"
                isActive={this.state.editMode === EDIT_MODE.ADD}
                dataTip={this.props.t('Add hexagons')}
                dataValue={EDIT_MODE.ADD}
                onClick={this.changeEditMode}
                icon="icon-edit"
              />
              <MapModeButton
                customKey="remove-hexagons"
                isActive={this.state.editMode === EDIT_MODE.REMOVE}
                dataTip={this.props.t('Remove hexagons')}
                dataValue={EDIT_MODE.REMOVE}
                onClick={this.changeEditMode}
                icon="icon-eraser"
              />
              <HexSelectionToolsMenu changeSelectionTool={this.changeSelectionTool} />

              {this.dataType === 'delivery-areas' && (
                <div className="hex-level-select">
                  <div className="hex-value-wrapper">
                    <div className={this.state.hexLevel === MAX_HEX_LEVEL ? 'hex-arrow disabled' : 'hex-arrow'} onClick={this.changeHexLevel} data-value={1}>
                      <i className="icon icon-keyboard-arrow-up" />
                    </div>
                    <div className="hex-value">{this.state.hexLevel}</div>
                    <div className={this.state.hexLevel === MIN_HEX_LEVEL ? 'hex-arrow disabled' : 'hex-arrow'} onClick={this.changeHexLevel} data-value={-1}>
                      <i className="icon icon-keyboard-arrow-down" />
                    </div>
                  </div>
                  {this.haveDifferentHexLevels() && (
                    <div className="hex-warning">
                      <i className="icon icon-warning" data-for="hex-warning-tooltip" data-tip={this.props.t('All hexagons must be of the same size')} />
                    </div>
                  )}
                  {this.haveToManyHexagons() && (
                    <div className="hex-warning">
                      <i
                        className="icon icon-warning"
                        data-for="hex-len-warning-tooltip"
                        data-tip={this.props.t('Too many hexagons', { hexLimit: MAX_HEXAGONS_PER_DA })}
                      />
                    </div>
                  )}
                </div>
              )}

              {this.haveDifferentHexLevels() && <MilyTooltipWrapper id="hex-warning-tooltip" className="tooltip" place="right" effect="solid" />}
              {this.haveToManyHexagons() && <MilyTooltipWrapper id="hex-len-warning-tooltip" className="tooltip" place="right" effect="solid" />}
            </div>
          </div>
          <DeliveryAreaMapView
            deliveryAreaData={this.state.deliveryAreaData}
            selectionTool={DrawHexagonsUtil.getSelectionMode(this.state.selectionTool)}
            hexLevel={this.state.hexLevel}
            editMode={this.state.editMode}
            setHexLevel={this.setHexLevel}
            updateHexValidation={this.updateHexValidation}
            activeDeliveryAreas={this.state.activeDeliveryAreas}
            dataType={this.dataType}
          />
        </div>
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} store state
 */
function mapStateToProps(store) {
  return { ...store.pageState, ...store.saveChangesDialogState, ...store.planningPageState };
}

/**
 * @param {object} dispatch - redux store
 * @returns {object} - actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchDataChanged: DialogActions.showNextAlertDialog
    },
    dispatch
  );
}

AddDeliveryAreaPageClass.propTypes = {
  /**
   * Dispatch if page is loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Regions data - created through planning process
   */
  regionsData: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  location: PropTypes.object.isRequired
};

AddDeliveryAreaPageClass.defaultProps = {
  regionsData: [],
  history: null
};

const AddDeliveryAreaPage = withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(AddDeliveryAreaPageClass));

export default AddDeliveryAreaPage;
