import { gql } from '@apollo/client';
import { appSyncClient } from '../../../../setup/appSyncClient';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';
import { DB_TYPES } from '../../../../common/constants/dbTypes';

const HubApi = {
  getHubs: getHubs,
  upsertHub: upsertHub,
  deleteHub: deleteHub
};

const GetHubs = gql`
  query GetHubs($pk: String) {
    getHubs(pk: $pk) {
      id
      name
      lat
      lng
      integrationId
    }
  }
`;

const UpsertHub = gql`
  mutation UpsertHub(
    $pk: String
    $sk: String
    $id: String
    $name: String
    $lat: String
    $lng: String
    $type: String
    $integrationId: String
    $mappingPk: String
    $mappingSk: String
    $mappingType: String
  ) {
    upsertDataWithMapping(
      pk: $pk
      sk: $sk
      input: {
        hubInputData: { name: $name, id: $id, lat: $lat, lng: $lng, type: $type, integrationId: $integrationId }
        mappingInputData: { pk: $mappingPk, sk: $mappingSk, id: $integrationId, milyId: $id, type: $mappingType }
      }
    )
  }
`;

const DeleteEntityWithMapping = gql`
  mutation DeleteEntityWithMapping($pk: String, $sk: String, $mappingPk: String, $mappingSk: String) {
    deleteDataWithMapping(pk: $pk, sk: $sk, input: { mappingInputData: { pk: $mappingPk, sk: $mappingSk } })
  }
`;

async function getHubs() {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetHubs, { pk: DB_TYPES.HUB });
  }

  return fetch('/testData/management/hubs.json').then((data) => data.json());
}

async function upsertHub(id, data) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpsertHub,
      variables: {
        pk: DB_TYPES.HUB,
        sk: `${DynamoStringExtensionsUtil.getHubPrefix()}${id}`,
        name: data.name,
        id: id,
        lat: data.lat,
        lng: data.lng,
        integrationId: data.integrationId,
        type: DB_TYPES.HUB,
        mappingPk: DB_TYPES.INTEGRATION_MAPPING,
        mappingSk: `${DynamoStringExtensionsUtil.getMappingPrefix()}${DynamoStringExtensionsUtil.getHubPrefix()}${id}`,
        mappingType: DB_TYPES.INTEGRATION_MAPPING
      }
    });
  }

  return null;
}

function deleteHub(id) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: DeleteEntityWithMapping,
      variables: {
        pk: DB_TYPES.HUB,
        sk: `${DynamoStringExtensionsUtil.getHubPrefix()}${id}`,
        mappingPk: DB_TYPES.INTEGRATION_MAPPING,
        mappingSk: `${DynamoStringExtensionsUtil.getMappingPrefix()}${DynamoStringExtensionsUtil.getHubPrefix()}${id}`
      }
    });
  }

  return null;
}

export default HubApi;
