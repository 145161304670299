import React from 'react';
import PropTypes from 'prop-types';

import './MapModeButton.scss';
import MilyTooltipWrapper from '../../tooltips/MilyTooltipWrapper';

export default function MapModeButton(props) {
  const { isActive, dataTip, dataValue, dataFor, onClick } = props;

  function getWrapperClassName() {
    const withNumberClass = props.buttonNumber !== null ? ' with-number' : '';
    const isActiveClass = isActive ? ' active' : '';
    const additionalClasses = ` ${props.additionalClasses.join(' ')}`;

    return `mode-button-wrapper${withNumberClass}${isActiveClass}${props.additionalClasses.length > 0 ? additionalClasses : ''}`;
  }

  function getButtonClassName() {
    const isActiveClass = isActive ? ' active' : '';
    const isWithTextClass = props.text ? ' with-text' : '';

    return `mode-button${isActiveClass}${isWithTextClass}`;
  }

  return (
    <div
      className={getWrapperClassName()}
      data-tip={dataTip}
      data-for={dataFor || `${props.customKey}-map-mode-button-tooltip}`}
      onClick={props.toggleMenu}
      onMouseEnter={props.onMouseEnterEvent}
    >
      <div
        className={getButtonClassName()}
        data-value={dataValue}
        onClick={onClick}
      >
        <i className={`icon ${props.icon}`} />
        {props.text}
      </div>
      {(!dataFor) && <MilyTooltipWrapper id={`${props.customKey}-map-mode-button-tooltip}`} className="tooltip" place={props.dataTipPlace} effect="solid" />}
      {props.children}
      {(props.buttonNumber !== null) && <div className="button-number">{props.buttonNumber}</div>}
    </div>
  );
}

MapModeButton.propTypes = {
  customKey: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  dataTip: PropTypes.string,
  dataFor: PropTypes.string,
  dataTipPlace: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  dataValue: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnterEvent: PropTypes.func,
  toggleMenu: PropTypes.func,
  icon: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  text: PropTypes.string,
  buttonNumber: PropTypes.number,
  additionalClasses: PropTypes.arrayOf(PropTypes.string)
};

MapModeButton.defaultProps = {
  isActive: false,
  dataTip: '',
  dataFor: null,
  dataTipPlace: 'right',
  dataValue: null,
  onClick: () => {},
  onMouseEnterEvent: () => {},
  toggleMenu: () => {},
  children: null,
  text: null,
  buttonNumber: null,
  additionalClasses: []
};
