import React, { useEffect } from 'react';
import { Marker, Popup } from 'react-map-gl';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MixPanel from '../../../../../setup/mixPanel';
import IsochronePolygonWrapper from '../mapSourceAndLayers/IsochronePolygonWrapper';
import MapMarkerPopup from '../../../../../common/components/popups/components/MapMarkerPopup';
import MapPointMarker from './MapPointMarker';
import MapMarkerPopupMenu from '../../../../../common/components/popups/components/MapMarkerPopupMenu';
import { MAP_ADDITIONAL_FEATURES_COVERAGE_MENU } from '../../../../../common/constants/mapAdditionalFeaturesData';
import store from '../../../../../state/store';
import * as RegionAnalysisActions from '../../../../../state/actions/regionAnalysisActions';

export default function MapPointMarkerWrapper(props) {
  const { t } = useTranslation();
  const dispatch = store.dispatch;

  const [showOnHoverPopup, setShowOnHoverPopup] = React.useState(false);
  const [rightClickMenuOpened, setRightClickMenuOpened] = React.useState(false);
  const { disabledPoints } = useSelector((state) => state.regionAnalysisState);
  const { selectedMapPoints } = useSelector((state) => state.regionAnalysisState);
  const viewDetailsMapPointId = useSelector((state) => state.regionAnalysisState.mapPointDetailsMenuData);

  useEffect(() => {
    if (showOnHoverPopup) {
      setRightClickMenuOpened(false);
      MixPanel.track('Delivery Area Analysis - Location popup shown', {
        id: props.pin.id,
        name: props.pin.name,
        type: props.pin.type
      });
    }
  }, [showOnHoverPopup, props.pin]);

  useEffect(() => {
    if (rightClickMenuOpened) {
      setShowOnHoverPopup(false);
    }
  }, [rightClickMenuOpened]);

  function getPopupMenuButtonsData() {
    const popupButtons = [];
    const pointId = props.pin.id;

    if (Object.values(MAP_ADDITIONAL_FEATURES_COVERAGE_MENU).includes(props.pin.type)) {
      if (disabledPoints.has(pointId)) {
        popupButtons.push({
          title: t('Enable location'),
          onClickAction: () => {
            dispatch(RegionAnalysisActions.enableMapPoint(pointId));
            MixPanel.track('Delivery Area Analysis - Enable location clicked', {
              id: pointId,
              name: props.pin.name,
              type: props.pin.type
            });
            setRightClickMenuOpened(false);
          }
        });
      } else {
        popupButtons.push({
          title: t('Disable location'),
          onClickAction: () => {
            dispatch(RegionAnalysisActions.disableMapPoint(pointId));
            dispatch(RegionAnalysisActions.unselectMapPoint(pointId));
            MixPanel.track('Delivery Area Analysis - Disable location clicked', {
              id: pointId,
              name: props.pin.name,
              type: props.pin.type
            });
            setRightClickMenuOpened(false);
          }
        });
      }
    }
    popupButtons.push({
      title: t('View details'),
      onClickAction: () => {
        dispatch(RegionAnalysisActions.openPointDetailsMenu(pointId));
        MixPanel.track('Delivery Area Analysis - View point details location clicked', { pointId });
        setRightClickMenuOpened(false);
      }
    });
    if (props.removeMarker) {
      popupButtons.push({
        title: t('Remove pin'),
        onClickAction: () => {
          props.removeMarker(pointId);
          MixPanel.track('Delivery Area Analysis - Remove unwanted location clicked', { pointId });
          setRightClickMenuOpened(false);
        }
      });
    }
    return popupButtons;
  }

  function isMarkerSelected() {
    return selectedMapPoints.has(props.pin.id)
      || rightClickMenuOpened
      || viewDetailsMapPointId === props.pin.id;
  }

  return (
    <>
      <Marker
        longitude={props.pin.lng}
        latitude={props.pin.lat}
        onClick={() => {
          if (disabledPoints.has(props.pin.id)) {
            return;
          }

          if (selectedMapPoints.has(props.pin.id)) {
            MixPanel.track('Delivery Area Analysis - Unselect location clicked', { entityName: props.pin.name, type: props.pin.type });
          } else {
            MixPanel.track('Delivery Area Analysis - Select location clicked', { entityName: props.pin.name, type: props.pin.type });
          }
          dispatch(RegionAnalysisActions.toggleSelectMapPoint(props.pin.id));
        }}
        draggable={props.draggable}
      >
        <MapPointMarker
          pinType={props.pin.type}
          iconType={props.pin.iconType}
          isStaticFeaturePoint={props.isStaticFeaturePoint}
          isSelected={isMarkerSelected()}
          isDisabled={disabledPoints.has(props.pin.id)}
          impactPointData={props.impactPointData}
          mapPointCollectionData={props.mapPointCollectionData}
          openRightClickMenu={setRightClickMenuOpened}
          setShowOnHoverPopup={setShowOnHoverPopup}
          hexType={props.hexType}
        />
      </Marker>
      {selectedMapPoints.has(props.pin.id) && (<IsochronePolygonWrapper polygonId={props.pin.id} sourceData={props.mapPointIsochroneData?.polygonFeatures} />)}
      {showOnHoverPopup && (
        <Popup
          latitude={props.pin.lat}
          longitude={props.pin.lng}
          onClose={() => setShowOnHoverPopup(false)}
          offset={15}
          closeButton={false}
        >
          <MapMarkerPopup data={{
            pointCoverageData: props.mapPointCoverageData,
            hexType: props.hexType,
            distanceType: props.distanceType,
            name: props.pin.name,
            featureType: 'mapFeature'
          }}
          />
        </Popup>
      )}

      {rightClickMenuOpened && (
        <Popup
          className="context-menu-popup"
          latitude={props.pin.lat}
          longitude={props.pin.lng}
          onClose={() => setRightClickMenuOpened(false)}
          offset={[105, -20]}
          closeButton={false}
        >
          <MapMarkerPopupMenu rowButtonsData={getPopupMenuButtonsData()} />
        </Popup>
      )}

    </>
  );
}

MapPointMarkerWrapper.propTypes = {
  pin: PropTypes.object,
  mapPointCoverageData: PropTypes.object,
  mapPointCollectionData: PropTypes.number,
  impactPointData: PropTypes.object,
  draggable: PropTypes.bool,
  hexType: PropTypes.string,
  distanceType: PropTypes.string.isRequired,
  isStaticFeaturePoint: PropTypes.bool,
  mapPointIsochroneData: PropTypes.object,
  removeMarker: PropTypes.func
};

MapPointMarkerWrapper.defaultProps = {
  pin: null,
  mapPointCoverageData: null,
  mapPointCollectionData: null,
  impactPointData: null,
  draggable: false,
  hexType: null,
  isStaticFeaturePoint: false,
  mapPointIsochroneData: null,
  removeMarker: null
};
