import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthUtil from '../../../utils/authUtil';
import QueryStringUtil from '../../../utils/queryStringUtil';
import CustomSelect from '../CustomSelect';
import MixPanel from '../../../../setup/mixPanel';

/**
 * Select component for teams for displaying couriers
 *
 * @param {object} props - props object for the component
 * @returns {JSX.Element} Team select
 * @component
 * @alias CourierTeamSelect
 * @category Common
 */
export default function DeliveryAreaSelect(props) {
  const { t } = useTranslation();
  const { selectedDeliveryAreaId, returnFullObject, onDeliveryAreaChange } = props;
  const userPermissions = useSelector((state) => state.authState.userPermissions);

  const [deliveryAreaOptions, setDeliveryAreaOptions] = useState([]);
  const [deliveryAreas, setDeliveryAreas] = useState(null);

  const [selectedRegion, setSelectedRegion] = useState('');

  const getDeliveryAreaSelectDefaultValue = useCallback(() => {
    let areaSelectDefaultValue;
    if (deliveryAreas && Object.keys(deliveryAreas).length > 0) {
      let key = QueryStringUtil.getQueryStringValue('deliveryAreaId');
      if (!key) {
        if (selectedDeliveryAreaId) {
          key = selectedDeliveryAreaId;
        } else {
          key = deliveryAreas && Object.keys(deliveryAreas) && Object.keys(deliveryAreas)[0];
        }
      }

      if (deliveryAreas && key) {
        areaSelectDefaultValue = {
          label: deliveryAreas[key].name,
          value: key
        };
      }
    }

    return areaSelectDefaultValue;
  }, [deliveryAreas, selectedDeliveryAreaId]);

  const handlePropsOnChange = useCallback(
    (areaId) => {
      if (onDeliveryAreaChange) {
        if (returnFullObject) {
          const deliveryAreaId = Object.keys(deliveryAreas).find((d) => d === areaId);
          onDeliveryAreaChange(deliveryAreas[deliveryAreaId]);

          return;
        }

        onDeliveryAreaChange(areaId);
      }
    },
    [onDeliveryAreaChange, returnFullObject, deliveryAreas]
  );

  useEffect(() => {
    if (userPermissions) {
      const userDeliveryAreas = AuthUtil.getDeliveryArea();
      if (userDeliveryAreas) {
        setDeliveryAreas(userDeliveryAreas);
        setDeliveryAreaOptions(
          Object.keys(userDeliveryAreas).map((key) => ({
            value: key,
            label: userDeliveryAreas[key].name
          }))
        );
      }
    }
  }, [userPermissions]);

  useEffect(() => {
    const deliveryAreaOpt = getDeliveryAreaSelectDefaultValue();
    if (deliveryAreaOpt) {
      handlePropsOnChange(deliveryAreaOpt.value);
      setSelectedRegion(deliveryAreaOpt.value);
    }
  }, [deliveryAreas, getDeliveryAreaSelectDefaultValue, handlePropsOnChange]);

  const changeSelectedDeliveryArea = useCallback((opt) => {
    const prevSelection = deliveryAreaOptions.find((option) => option.value === QueryStringUtil.getQueryStringValue('deliveryAreaId'));
    const newSelection = deliveryAreaOptions.find((option) => option.value === opt.value);

    MixPanel.track('Delivery Area Analysis - Delivery area changed', {
      previousSelection: prevSelection?.label,
      newSelection: newSelection.label
    });

    QueryStringUtil.setQueryStringValue('deliveryAreaId', opt.value);
    handlePropsOnChange(opt.value);

    setSelectedRegion(opt.value);
  }, [handlePropsOnChange, setSelectedRegion, deliveryAreaOptions]);

  return (
    <div className="center-selection-wrapper">
      <div className="label">{t('Delivery Area')}</div>
      {deliveryAreas && Object.keys(deliveryAreas).length > 0 && (
        <CustomSelect
          className="select"
          options={deliveryAreaOptions}
          placeholder={t('Select a center')}
          value={deliveryAreaOptions.filter((option) => option.value === selectedRegion)}
          defaultValue={getDeliveryAreaSelectDefaultValue()}
          onChange={changeSelectedDeliveryArea}
          isDisabled={!deliveryAreas || Object.keys(deliveryAreas).length === 1}
        />
      )}
    </div>
  );
}

DeliveryAreaSelect.propTypes = {
  onDeliveryAreaChange: PropTypes.func.isRequired,
  returnFullObject: PropTypes.bool,
  selectedDeliveryAreaId: PropTypes.string
};

DeliveryAreaSelect.defaultProps = {
  returnFullObject: false,
  selectedDeliveryAreaId: null
};
