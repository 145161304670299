import './DeliveryAreaAnalysis.scss';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import * as _ from 'lodash';
import { MapProvider } from 'react-map-gl';
import { toast } from 'react-toastify';
import * as h3 from 'h3-js';
import { getHexagonsQuery,
  getOohPointCollections,
  generateNetwork,
  GetPointsOfInterest,
  getParamsForReadPointOfInterestByDA,
  POINT_OF_INTEREST_TYPE } from './api/deliveryAreaAnalysisApi';
import AuthUtil from '../../../common/utils/authUtil';
import { MAP_ADDITIONAL_FEATURES_DATA,
  MAP_ADDITIONAL_FEATURES_ORDERED_KEYS,
  MAP_ADDITIONAL_FEATURES_SIGNALS,
  MAP_ADDITIONAL_FEATURES_TOGGLES,
  MAP_STATIC_FEATURE_TYPES,
  MAP_ADDITIONAL_FEATURES_COVERAGE_MENU } from '../../../common/constants/mapAdditionalFeaturesData';
import * as PageActions from '../../../state/actions/pageActions';
import DeliveryAreaSelect from '../../../common/components/selections/deliveryAreaSelect/DeliveryAreaSelect';
import RangeDayPicker from './components/RangeDayPicker';
import { PERMISSIONS_FOR_MAP_LAYERS, USER_MAP_LAYERS_FOR_DEMO } from './constants/userMapLayers';
import MapModeButton from '../../../common/components/buttons/mapModeButton/MapModeButton';
import MixPanel from '../../../setup/mixPanel';
import MapUtil from '../../../common/utils/mapUtil';
import * as RegionAnalysisActions from '../../../state/actions/regionAnalysisActions';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';
import DeliveryAreaAnalysisMap from './components/DeliveryAreaAnalysisMap';
import CoverageMenu from './components/rightMenuComponents/CoverageMenu';
import ViewPointDetailsMenu from './components/rightMenuComponents/ViewPointDetailsMenu';
import MapTypeButton from './components/mapButtons/MapTypeButton';
import ButtonLegend from './components/mapButtons/ButtonLegend';
import RegionStopsDistributionChart from './components/regionsComponents/RegionStopsDistributionChart';
import { IMPACT_TABLE_TYPES } from './constants/menuConstants';
import AppDialogActionsWrapper from '../../../common/components/dialogs/utils/appDialogActionsWrapper';
import CoverageInputForm from './components/recommenderComponents/CoverageInputForm';
import { COVERAGE, LOCATIONS } from './constants/formTypes';
import { H3_HEX_RESOLUTION } from '../../../common/constants/mapConstants';
import ResultMessage from './components/recommenderComponents/ResultMessage';
import TenantUtil from '../../../common/utils/tenantUtil';
import S3Util from '../../../common/utils/s3Util';
import BackendResourceConfigUtil from '../../../common/utils/api/backendResourceConfigUtil';
import { raygunClient } from '../../../setup/raygunClient';
import DistancePolygonConfigMenu from './components/rightMenuComponents/DistancePolygonConfigMenu';
import useIsochroneApi from './hooks/useIsochroneApi';
import usePinsIsochronesApi from './hooks/usePinsIsochronesApi';
import DistanceChangeConfirmationDialog from './components/rightMenuComponents/DistanceChangeConfirmationDialog';
import MilyTooltipWrapper from '../../../common/components/tooltips/MilyTooltipWrapper';
import idUtil from '../../../common/utils/idUtil';
import DistanceTypeUtil from '../../../common/utils/distanceTypeUtil';
import { PIN_ORIGINS } from './constants/distancePinsConstants';

const LOCAL_DATA_START_DATE = '2021-11-26';
const LOCAL_DATA_END_DATE = '2021-11-26';
export default function DeliveryAreaAnalysis() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tenantId = AuthUtil.useTenantId();
  const hasRegionsEnabled = AuthUtil.useIsFeatureEnabled('regionAnalytics');

  const deliveryAreaAnalysisMapRef = useRef(null);

  const viewDetailsMapPointId = useSelector((state) => state.regionAnalysisState.mapPointDetailsMenuData);
  const disabledPoints = useSelector((state) => state.regionAnalysisState.disabledPoints);

  const [getHexagons, { loading: loadingGetHexagons, data: getHexagonsQueryResults }] = useLazyQuery(getHexagonsQuery());
  const [getParcelLockers, { loading: loadingParcelLockers, data: getParcelLockersQueryResults }] = useLazyQuery(GetPointsOfInterest);
  const [getParcelShops, { loading: loadingParcelShops, data: getParcelShopsQueryResults }] = useLazyQuery(GetPointsOfInterest);
  const [getGasStations, { loading: loadingGasStations, data: getGasStationsQueryResults }] = useLazyQuery(GetPointsOfInterest);

  // For local data
  const apolloClient = useApolloClient();
  const [waitingToSetLocalData, setWaitingToSetLocalData] = useState(process.env.REACT_APP_DATA_SOURCE !== 'api');

  // Hexagons data
  const [hexLayersCountMap, setHexLayersCountMap] = useState({});
  const [deliveryAreaTotalCount, setDeliveryAreaTotalCount] = useState({});
  const hexagonSize = useMemo(getHexagonAreaSize, [getHexagonsQueryResults]);

  const [distanceType, setDistanceType] = useState(DistanceTypeUtil.getDefaultDistanceType());

  // Regions data
  const [regionsData, setRegionsData] = useState(null);
  const [regionsTotals, setRegionsTotals] = useState(null);

  // Additional map features
  const additionalMapFeatures = useSelector((state) => state.authState?.userPermissions?.additionalMapFeatures);
  const [showAdditionalMapFeatures, setShowAdditionalMapFeatures] = useState(getAdditionalMapFeaturesDefaultValues);

  // Hexagon types
  const userHexTypes = AuthUtil.useRegionAnalysisLayers();
  const hexType = useSelector((state) => state.regionAnalysisState.hexType);

  // Map Points data
  const [mapPointsStaticData, setMapPointsStaticData] = useState({});
  const [mapPointsCollectionData, setMapPointsCollectionData] = useState({});
  const { mapPointsIsochroneData, loading: loaingMapPointsIsochrones } = useIsochroneApi(mapPointsStaticData, distanceType);
  const mapPointsCoverageData = useMemo(getMapPointsCoverageData, [hexLayersCountMap, mapPointsIsochroneData, userHexTypes]);
  const mapPointsVisibilityData = useMemo(createMapPointsVisibilityData, [mapPointsStaticData, showAdditionalMapFeatures]);

  // Gas stations & shops data
  const [staticMapFeaturesData, setStaticMapFeaturesData] = useState({});
  const [staticMapFeaturesCoverageData, setStaticMapFeaturesCoverageData] = useState({});
  const { mapPointsIsochroneData: staticMapFeaturesIsochroneData, loading: loadingStaticMapFeaturesIsochroneData } = useIsochroneApi(staticMapFeaturesData, distanceType);

  // Unwanted locations data
  const [unwantedLocationPinsStaticData, setUnwantedLocationPinsStaticData] = useState({});

  // Date filters
  const regionsDateFrom = useSelector((state) => state.regionAnalysisState.regionsDateFrom);
  const regionsDateTo = useSelector((state) => state.regionAnalysisState.regionsDateTo);

  // Pins data
  const [distancePinsCoverageData, setDistancePinsCoverageData] = useState({});

  const {
    updateDistancePinDataFromMap,
    removeDistancePinPolygon,
    removeDistancePin,
    removeAllDistancePins,
    addNewDistancePins,
    distancePinsStaticData,
    distancePinsIsochroneData,
    loadingDistancePinsData,
    getDistancePinIsochroneData
  } = usePinsIsochronesApi(distanceType);
  const resetDistancePinsData = useCallback(resetDistancePinsDataFn, [removeAllDistancePins]);

  // Copied state
  const [showExtendedWidth, setExtendedWidth] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(null);
  const [buttonLegend, setButtonLegend] = useState(null);
  const [showRegionStopsDistributionChart, setRegionStopsDistributionChart] = useState(false);

  const [lastSelectedCenterPosition, setLastSelectedCenterPosition] = useState(MapUtil.getInitialViewStateForCompany());

  const [openedRightMenu, setOpenedRightMenu] = useState(null);

  // Recommender
  const [shouldTriggerRecommender, setShouldTriggerRecommender] = useState(false);
  const getErrorMessage = useCallback(() => {
    return t('Oops something went wrong');
  }, [t]);
  const [recommendationResults, setRecommendationResults] = useState(null);
  const recommendedLocationPinPlacement = useRef(false);
  const [recommendationFormParamsData, setRecommendationFormParamsData] = useState(null);
  const [recommendationFormType, setRecommendationFormType] = useState(null);
  const [showRecommenderMessage, setShowRecommenderMessage] = useState(false);
  const recommenderHexType = useRef(hexType);
  const cachedFileName = useRef(null);

  const getInitialHexType = useCallback(() => {
    const queryHexType = QueryStringUtil.getQueryStringValue('hexType');
    if (queryHexType && userHexTypes.includes(queryHexType)) {
      return queryHexType;
    }
    QueryStringUtil.setQueryStringValue('hexType', userHexTypes[0]);
    return userHexTypes[0];
  }, [userHexTypes]);

  // Set default hex type
  useEffect(() => {
    if (userHexTypes.length > 0) {
      const initialHexType = getInitialHexType();
      dispatch(RegionAnalysisActions.changeHexType(initialHexType));
    }
  }, [dispatch, getInitialHexType, userHexTypes]);

  // Track page load, and unload events
  useEffect(() => {
    MixPanel.track('Page Load - Delivery Area Analysis');
    MixPanelUtil.setUnloadListener('Page Unload - Delivery Area Analysis');
    return () => {
      MixPanelUtil.removeUnloadListener();
    };
  }, [dispatch]);

  // Handle spinner show / hide
  useEffect(() => {
    const isLoading = loadingGetHexagons
    || loadingParcelLockers
    || loadingParcelShops
    || loadingGasStations
    || loadingDistancePinsData
    || loaingMapPointsIsochrones
    || loadingStaticMapFeaturesIsochroneData;

    dispatch(PageActions.loadingPage(isLoading));

    if (!isLoading) {
      MixPanel.track('Delivery Area Analysis - Delivery area (re)loaded', {
        id: lastSelectedCenterPosition?.id,
        name: lastSelectedCenterPosition?.name
      });
    }
  }, [
    dispatch,
    loadingGetHexagons,
    loadingParcelLockers,
    loadingParcelShops,
    loadingGasStations,
    loadingDistancePinsData,
    loaingMapPointsIsochrones,
    loadingStaticMapFeaturesIsochroneData
  ]);

  // Prepare local data to be used instead of fetching from api
  useEffect(() => {
    if (process.env.REACT_APP_DATA_SOURCE !== 'api') {
      // Load data
      const deliveryAreaId = 'BG-NB';
      const packageLockersPromise = fetch(`/testData/management/package_lockers_${deliveryAreaId}.json`)
        .then((data) => data.json());
      const hexagonsPromise = fetch(`/testData/analysis/${deliveryAreaId}_hex.json`)
        .then((data) => data.json());
      const parcelShopsPromise = fetch(`/testData/management/parcel_shops_${deliveryAreaId}.json`)
        .then((data) => data.json());

      // Update cache so app can use it instead of fetching from api
      Promise.all([packageLockersPromise, hexagonsPromise, parcelShopsPromise])
        .then((data) => {
          const [packageLockers, hexagons, parcelShops] = data;

          apolloClient.writeQuery({
            query: GetPointsOfInterest,
            ...getParamsForReadPointOfInterestByDA(deliveryAreaId, POINT_OF_INTEREST_TYPE.PARCEL_LOCKERS),
            data: packageLockers.data
          });

          apolloClient.writeQuery({
            query: GetPointsOfInterest,
            ...getParamsForReadPointOfInterestByDA(deliveryAreaId, POINT_OF_INTEREST_TYPE.PARCEL_SHOPS),
            data: parcelShops.data
          });

          apolloClient.writeQuery({
            query: getHexagonsQuery(),
            variables: {
              areaId: deliveryAreaId,
              fromDate: LOCAL_DATA_START_DATE,
              toDate: LOCAL_DATA_END_DATE,
              userMapLayers: USER_MAP_LAYERS_FOR_DEMO,
              shouldUseCache: true
            },
            data: {
              getHexagonsForDeliveryArea: {
                hexagons: hexagons.hexagons,
                cacheLocationS3: {
                  bucket: 'not-important',
                  fileName: `${tenantId}/demo/hex-layers/${deliveryAreaId}/hexagons_with_neighbours_v0.1.json`
                }
              }
            }
          });

          setWaitingToSetLocalData(false);
        });
    }
  }, [apolloClient, tenantId, userHexTypes]);

  // Hexagon fetching and processing
  useEffect(() => {
    if (!waitingToSetLocalData && lastSelectedCenterPosition?.id) {
      const fromDate = process.env.REACT_APP_DATA_SOURCE === 'api' ? moment(regionsDateFrom).format('YYYY-MM-DD') : LOCAL_DATA_START_DATE;
      const endDate = process.env.REACT_APP_DATA_SOURCE === 'api' ? moment(regionsDateTo).format('YYYY-MM-DD') : LOCAL_DATA_END_DATE;
      getHexagons({
        variables: {
          areaId: lastSelectedCenterPosition.id,
          fromDate: fromDate,
          toDate: endDate,
          userMapLayers: userHexTypes,
          shouldUseCache: true
        },
        fetchPolicy: process.env.REACT_APP_DATA_SOURCE === 'api' ? 'no-cache' : 'cache-first'
      });
    }
  }, [getHexagons, lastSelectedCenterPosition, regionsDateFrom, regionsDateTo, waitingToSetLocalData, userHexTypes]);

  // Fetch regions data
  useEffect(() => {
    if (hasRegionsEnabled && lastSelectedCenterPosition?.id) {
      const areaId = lastSelectedCenterPosition.id;
      let regionFileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${areaId}/${areaId}_regions.json`;
      regionFileName = TenantUtil.addTenantToFileName(regionFileName);
      S3Util.getFileFromS3(regionFileName, {
        download: true,
        bucket: BackendResourceConfigUtil.getRegionDataBucketName()
      })
        .then((content) => JSON.parse(content))
        .then((res) => {
          setRegionsData(res);
        })
        .catch((err) => {
          setRegionsData(null);
          // TODO this is temporary fix until we have regions for all xexpress areas or permsission is removed
          if (err.message !== 'NoSuchKey' || tenantId !== 'xexpress') {
            raygunClient.send(err, 'Error loading regions data');
          }
        });
    }
  }, [lastSelectedCenterPosition, regionsDateFrom, regionsDateTo, tenantId, hasRegionsEnabled]);

  // load hex-regions mapping and create stops count per region
  useEffect(() => {
    if (hasRegionsEnabled && !_.isEmpty(hexLayersCountMap) && lastSelectedCenterPosition?.id) {
      const areaId = lastSelectedCenterPosition.id;
      let hexMappingFileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${areaId}/${areaId}_hex_region_mapping.json`;
      hexMappingFileName = TenantUtil.addTenantToFileName(hexMappingFileName);
      S3Util.getFileFromS3(hexMappingFileName, {
        download: true,
        bucket: BackendResourceConfigUtil.getRegionDataBucketName()
      })
        .then((content) => {
          const hexRegionMap = JSON.parse(content);
          const newRegionsTotals = {};

          Object.entries(hexLayersCountMap).forEach(([hexId, hexData]) => {
            const rId = hexRegionMap[hexId];
            if (!rId) {
              return;
            }
            if (!newRegionsTotals[rId]) {
              newRegionsTotals[rId] = {};
              userHexTypes.forEach((layer) => {
                newRegionsTotals[rId][layer] = 0;
              });
              newRegionsTotals[rId].maxStopsHex = {
                count: -1,
                hex: null
              };
            }

            userHexTypes.forEach((layer) => {
              newRegionsTotals[rId][layer] += hexData[layer];
            });
            if (newRegionsTotals[rId].maxStopsHex.count < hexData.stops) {
              newRegionsTotals[rId].maxStopsHex = {
                count: hexData.stops,
                hex: hexId
              };
            }
          });

          return newRegionsTotals;
        })
        .then((newRegionsTotals) => {
          setRegionsTotals(newRegionsTotals);
        })
        .catch((err) => {
          setRegionsTotals(null);
          // TODO this is temporary fix until we have regions for all xexpress areas or permsission is removed
          if (err.message !== 'NoSuchKey' || tenantId !== 'xexpress') {
            raygunClient.send(err, 'Error loading regions mapping data');
          }
        });
    }
  }, [lastSelectedCenterPosition, hexLayersCountMap, tenantId, hasRegionsEnabled, userHexTypes]);

  // remove all pins
  useEffect(() => {
    resetDistancePinsData();
    removeAllUnwantedLocationPins();
  }, [lastSelectedCenterPosition, resetDistancePinsData]);

  const numberOfHexagonsInDA = useRef(null);

  // Create map of all counts per hexagons and layers
  useEffect(() => {
    const hexagons = getHexagonsQueryResults?.getHexagonsForDeliveryArea?.hexagons;
    let entitiesPerHexagon = null;

    if (hexagons?.length > 0) {
      entitiesPerHexagon = {};
      hexagons.forEach((hexagon) => {
        entitiesPerHexagon[hexagon.id] = {};
        userHexTypes.forEach((layer) => {
          entitiesPerHexagon[hexagon.id][layer] = hexagon[layer];
        });
      });

      numberOfHexagonsInDA.current = hexagons.length;
    }

    setHexLayersCountMap(entitiesPerHexagon);
    cachedFileName.current = getHexagonsQueryResults?.getHexagonsForDeliveryArea?.cacheLocationS3;
  }, [getHexagonsQueryResults, userHexTypes]);

  // Calculate total count per layer for delivery area
  useEffect(() => {
    if (!_.isEmpty(hexLayersCountMap)) {
      const newDeliveryAreaTotal = {};
      userHexTypes.forEach((layer) => {
        newDeliveryAreaTotal[layer] = 0;
      });

      Object.values(hexLayersCountMap)
        .forEach((hexData) => {
          userHexTypes.forEach((layer) => {
            newDeliveryAreaTotal[layer] += hexData[layer];
          });
        });

      setDeliveryAreaTotalCount(newDeliveryAreaTotal);
    }
  }, [hexLayersCountMap, userHexTypes]);

  // Parcel locker fetching
  useEffect(() => {
    if (!waitingToSetLocalData && lastSelectedCenterPosition?.id) {
      getParcelLockers(getParamsForReadPointOfInterestByDA(
        lastSelectedCenterPosition.id,
        POINT_OF_INTEREST_TYPE.PARCEL_LOCKERS
      ));
    }
  }, [getParcelLockers, lastSelectedCenterPosition, waitingToSetLocalData]);

  // Parcel shops fetching
  useEffect(() => {
    if (!waitingToSetLocalData && lastSelectedCenterPosition?.id) {
      getParcelShops(getParamsForReadPointOfInterestByDA(
        lastSelectedCenterPosition.id,
        POINT_OF_INTEREST_TYPE.PARCEL_SHOPS
      ));
    }
  }, [getParcelShops, lastSelectedCenterPosition, waitingToSetLocalData]);

  // Gas stations fetching
  useEffect(() => {
    if (!waitingToSetLocalData && lastSelectedCenterPosition?.id) {
      getGasStations(getParamsForReadPointOfInterestByDA(
        lastSelectedCenterPosition.id,
        POINT_OF_INTEREST_TYPE.GAS_STATIONS
      ));
    }
  }, [getGasStations, lastSelectedCenterPosition, waitingToSetLocalData]);

  // Create all map points static data
  useEffect(() => {
    if (!_.isEmpty(getParcelLockersQueryResults) || !_.isEmpty(getParcelLockersQueryResults)) {
      const newC = {};
      if (!_.isEmpty(getParcelLockersQueryResults)) {
        // For all package lockers
        getParcelLockersQueryResults?.getPointsOfInterest?.pointsOfInterest.forEach((mapPoint) => {
          newC[mapPoint.id] = {
            ...mapPoint,
            type: MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS,
          };
        });
      }

      if (!_.isEmpty(getParcelShopsQueryResults)) {
        // For all parcel shops
        getParcelShopsQueryResults?.getPointsOfInterest?.pointsOfInterest.forEach((mapPoint) => {
          newC[mapPoint.id] = {
            ...mapPoint,
            type: MAP_ADDITIONAL_FEATURES_TOGGLES.PARCEL_SHOPS,
          };
        });
      }

      setMapPointsStaticData(newC);
    }
  }, [getParcelLockersQueryResults, getParcelShopsQueryResults]);

  // Fetch ooh circle
  useEffect(() => {
    if (!_.isEmpty(mapPointsStaticData)) {
      setMapPointsCollectionData(null);
      const enrichedParcelLockersPromises = Object.values(mapPointsStaticData).map((parcelLocker) => {
        return getOohPointCollections(parcelLocker.id, regionsDateFrom, regionsDateTo)
          .then((res) => {
            return {
              id: parcelLocker.id,
              collections: res.data.getOohPointCollections
            };
          });
      });

      Promise.all(enrichedParcelLockersPromises)
        .then((res) => {
          const result = {};
          res.forEach((parcelLocker) => {
            result[parcelLocker.id] = parcelLocker.collections;
          });
          setMapPointsCollectionData(result);
        });
    }
  }, [mapPointsStaticData, regionsDateFrom, regionsDateTo]);

  // Reset disabled points
  useEffect(() => {
    dispatch(RegionAnalysisActions.resetDisabledMapPoints());
  }, [dispatch, lastSelectedCenterPosition]);

  // Reset selected points
  useEffect(() => {
    dispatch(RegionAnalysisActions.resetSelectedMapPoints());
  }, [dispatch, lastSelectedCenterPosition]);

  // Create impact list object
  useEffect(() => {
    const impactList = {
      [IMPACT_TABLE_TYPES.MOST]: [],
      [IMPACT_TABLE_TYPES.LEAST]: [],
    };

    const allImpactfulMapPointsMap = {};

    Object.keys(mapPointsCoverageData).filter((mapPointId) => {
      return mapPointsVisibilityData[mapPointId]
          && !disabledPoints.has(mapPointId);
    }).forEach((mapPointId) => {
      const largestDistance = DistanceTypeUtil.getLargestDistanceKey();
      if (largestDistance === undefined) {
        raygunClient.send(`No largest distance for map point ${mapPointId}`, null, { mapPointsCoverageData });
        return;
      }
      allImpactfulMapPointsMap[mapPointId] = mapPointsCoverageData[mapPointId][largestDistance];
    });
    const sortedListForLayer = sortImpactListForLayer(allImpactfulMapPointsMap, hexType);
    const bounds = sortedListForLayer.length >= 3 ? 3 : sortedListForLayer.length;

    impactList[IMPACT_TABLE_TYPES.MOST] = sortedListForLayer.slice(0, bounds);
    impactList[IMPACT_TABLE_TYPES.LEAST] = sortedListForLayer.slice(sortedListForLayer.length - bounds, sortedListForLayer.length).reverse();

    dispatch(RegionAnalysisActions.updatePointImpactList(impactList));
  }, [disabledPoints, dispatch, mapPointsCoverageData, mapPointsVisibilityData, hexType]);

  // Close point details menu
  useEffect(() => {
    dispatch(RegionAnalysisActions.closePointDetailsMenu());
  }, [dispatch, mapPointsStaticData]);

  // Reset recommendation results to null on delivery area
  useEffect(() => {
    setRecommendationResults(null);
  }, [lastSelectedCenterPosition]);

  // Enable map points
  useEffect(() => {
    const notVisiblePoints = Object.keys(mapPointsVisibilityData).filter((key) => !mapPointsVisibilityData[key]);
    dispatch(RegionAnalysisActions.enableMapPointsFromList(notVisiblePoints));
  }, [dispatch, mapPointsVisibilityData]);

  // Unselect map points
  useEffect(() => {
    const notVisiblePoints = Object.keys(mapPointsVisibilityData).filter((key) => !mapPointsVisibilityData[key]);
    dispatch(RegionAnalysisActions.unselectMapPointFromList(notVisiblePoints));
  }, [dispatch, mapPointsVisibilityData]);

  // Close pint details menu
  useEffect(() => {
    if ((mapPointsVisibilityData && !mapPointsVisibilityData[viewDetailsMapPointId]) && (staticMapFeaturesData && !staticMapFeaturesData[viewDetailsMapPointId])) {
      dispatch(RegionAnalysisActions.closePointDetailsMenu());
    }
  }, [dispatch, mapPointsVisibilityData, staticMapFeaturesData, viewDetailsMapPointId]);

  // Create Coverage Data for distance pins
  useEffect(() => {
    if (!_.isEmpty(distancePinsIsochroneData) && !_.isEmpty(hexLayersCountMap)) {
      const newDistancePinsCoverageData = {};
      Object.entries(distancePinsIsochroneData).forEach(([distancePinId, distancePinIsochroneData]) => {
        newDistancePinsCoverageData[distancePinId] = getHexLayerCountCoverage(distancePinIsochroneData.hexagonsCovered, hexLayersCountMap, userHexTypes);
      });

      setDistancePinsCoverageData(newDistancePinsCoverageData);
    }
  }, [distancePinsIsochroneData, hexLayersCountMap, userHexTypes]);

  // Load static map features data
  useEffect(() => {
    const staticMapFeatures = Object.keys(showAdditionalMapFeatures).filter((key) => MAP_STATIC_FEATURE_TYPES[key]);
    const staticFeaturesPromises = staticMapFeatures.map((key) => {
      if (showAdditionalMapFeatures[key]) {
        const promises = MAP_STATIC_FEATURE_TYPES[key].map((featureKey) => {
          return fetch(`/mapData/${featureKey}.geojson`)
            .then((data) => data.json())
            .then((mapGeojson) => {
              return mapGeojson.map((mapPoint) => {
                return {
                  ...mapPoint,
                  type: featureKey
                };
              });
            })
            .catch((err) => {
              raygunClient.send(err, 'Error loading data for single static map features', { key: key });
            });
        });
        return Promise.all(promises)
          .then((data) => {
            return { [key]: data };
          })
          .catch((err) => {
            raygunClient.send(err, 'Error creating static map features', { key: key });
          });
      }
      return { [key]: null };
    });

    const newStaticMapFeaturesData = {};

    // For all gas stations. For demo, we want to show only omv gas stations.
    if (showAdditionalMapFeatures[POINT_OF_INTEREST_TYPE.GAS_STATIONS]
      && !_.isEmpty(getGasStationsQueryResults)
      && process.env.REACT_APP_DATA_SOURCE === 'api') {
      getGasStationsQueryResults?.getPointsOfInterest?.pointsOfInterest.forEach((mapPoint) => {
        newStaticMapFeaturesData[mapPoint.id] = {
          ...mapPoint,
          type: MAP_ADDITIONAL_FEATURES_TOGGLES.POINT_OF_INTEREST,
        };
      });
    }

    Promise.all(staticFeaturesPromises)
      .then((staticFeatureData) => {
        staticFeatureData.forEach((featureType) => {
          Object.values(featureType).forEach((feature) => {
            if (feature) {
              Object.values(feature).forEach((featurePoints) => {
                featurePoints.forEach((featurePoint) => {
                  newStaticMapFeaturesData[featurePoint.id] = featurePoint;
                });
              });
            }
          });
        });

        setStaticMapFeaturesData(newStaticMapFeaturesData);
      })
      .catch((err) => {
        raygunClient.send(err, 'Error creating static map data ');
      });
  }, [showAdditionalMapFeatures, getGasStationsQueryResults]);

  // Create Coverage Data for Static Map Features
  useEffect(() => {
    if (!_.isEmpty(staticMapFeaturesIsochroneData) && !_.isEmpty(hexLayersCountMap)) {
      const newMapPointCoverageData = {};
      Object.values(staticMapFeaturesIsochroneData).forEach((mapPoint) => {
        if (mapPoint.hexagonsCovered[DistanceTypeUtil.getLargestDistanceKey()]) {
          newMapPointCoverageData[mapPoint.id] = getHexLayerCountCoverage(mapPoint.hexagonsCovered, hexLayersCountMap, userHexTypes);
        }
      });

      setStaticMapFeaturesCoverageData(newMapPointCoverageData);
    }
  }, [staticMapFeaturesIsochroneData, hexLayersCountMap, userHexTypes]);

  const addRecommenderPins = useCallback(addRecommenderPinsFn, [addNewDistancePins]);
  const onRecommenderResultsReceived = useCallback(async (e) => {
    setShowRecommenderMessage(true);
    if (e.data) {
      const response = e.data;
      const recommendedHexagons = await S3Util.getFileFromS3(response.resultFileKey, {
        download: true,
        bucket: BackendResourceConfigUtil.getCoverageRecommendationBucketName(),
        cacheControl: 'no-cache'
      }, process.env.REACT_APP_SHOWCASE_RECOMMENDER).then((c) => JSON.parse(c));
      setRecommendationResults({
        ...response,
        recommendedHexagons
      });

      // Add distance pins that the recommender generated
      if (recommendedHexagons && recommendedLocationPinPlacement.current) {
        addRecommenderPins(recommendedHexagons);
      }
    }
    dispatch(PageActions.loadingPage(false));
  }, [dispatch, setRecommendationResults, addRecommenderPins]);

  const numberOfDistancePins = useRef();

  useEffect(() => {
    numberOfDistancePins.current = Object.keys(distancePinsStaticData)?.length;
  }, [distancePinsStaticData]);

  // Trigger recommender
  useEffect(() => {
    function triggerRecommendation() {
      recommenderHexType.current = hexType;

      dispatch(PageActions.loadingPage(true));
      setRecommendationResults(null);
      const dateFrom = moment(regionsDateFrom)
        .format('YYYY-MM-DD');
      const dateTo = moment(regionsDateTo)
        .format('YYYY-MM-DD');
      const resultFileKey = `${lastSelectedCenterPosition.id}_${recommenderHexType.current}_${dateFrom}_${dateTo}_${recommendationFormParamsData.coverage}_${recommendationFormParamsData.locations}`;

      const currentNetworkFileKey = `${tenantId}/input/${lastSelectedCenterPosition.id}_current_network.json`;
      const additionalNetworkFileKey = `${tenantId}/input/${lastSelectedCenterPosition.id}_${moment().unix()}_current_pins.json`;

      // gets the hexagon ids where the unwanted location pins are marked
      const unwantedLocationsHexSet = new Set();
      Object.values(unwantedLocationPinsStaticData).forEach((pin) => {
        unwantedLocationsHexSet.add(h3.geoToH3(pin.lat, pin.lng, H3_HEX_RESOLUTION));
      });
      let unwantedLocationsHexArray = Array.from(unwantedLocationsHexSet);

      let allowedLocations = [];
      if (showAdditionalMapFeatures[POINT_OF_INTEREST_TYPE.GAS_STATIONS]) {
        getGasStationsQueryResults?.getPointsOfInterest?.pointsOfInterest.forEach((mapPoint) => {
          allowedLocations.push(h3.geoToH3(mapPoint.lat, mapPoint.lng, H3_HEX_RESOLUTION));
        }, []);
        const filteredList = allowedLocations.filter((item) => !unwantedLocationsHexArray.includes(item));
        allowedLocations = filteredList;
        unwantedLocationsHexArray = [];
      }

      const runCoverageRecommendation = () => {
        generateNetwork(
          recommendationFormParamsData.coverage,
          recommendationFormParamsData.locations,
          recommendationFormParamsData.layers,
          recommendationFormParamsData.coverageType,
          lastSelectedCenterPosition.id,
          DistanceTypeUtil.buildDistanceTypeForRecommender(distanceType),
          dateFrom,
          dateTo,
          resultFileKey,
          currentNetworkFileKey,
          cachedFileName.current.fileName,
          additionalNetworkFileKey,
          unwantedLocationsHexArray,
          allowedLocations
        ).then((response) => {
          if (response.errors) {
            toast.error(getErrorMessage());
            dispatch(PageActions.loadingPage(false));
            raygunClient.send('Error running network generator', null, { response });
            return;
          }
          onRecommenderResultsReceived(response);

          if (recommendationFormType === COVERAGE) {
            MixPanel.track('Delivery Area Analysis - Recommendation completed', {
              goal: recommendationFormType,
              outcome: recommendationFormParamsData.coverage <= response.data.baseLayerCoveragePercent ? 'success' : 'warning',
              target: recommendationFormParamsData.coverage,
              result: response.data.baseLayerCoveragePercent,
              numberOfSuggestions: response.data.numberOfSelectedLocations,
              achievedCoverage: response.data.baseLayerCoveragePercent
            });
          }

          if (recommendationFormType === LOCATIONS) {
            MixPanel.track('Delivery Area Analysis - Recommendation completed', {
              goal: recommendationFormType,
              outcome: recommendationFormParamsData.locations <= response.data.numberOfSelectedLocations ? 'success' : 'warning',
              target: recommendationFormParamsData.locations,
              result: response.data.numberOfSelectedLocations,
              numberOfSuggestions: response.data.numberOfSelectedLocations,
              achievedCoverage: response.data.baseLayerCoveragePercent
            });
          }
        }).catch((e) => {
          if (e.message === 'Execution timed out.') {
            toast.error('You have chosen too many locations. Please reduce the number and try again.', { autoClose: 5000 });
          } else {
            toast.error(getErrorMessage(), { autoClose: 5000 });
          }
          dispatch(PageActions.loadingPage(false));
          raygunClient.send(e, 'Error running network generator', { recommendationFormParamsData, dateFrom, dateTo, lastSelectedCenterPosition });
        });
      };

      const activePointsList = [];

      Object.values(mapPointsStaticData).forEach((mapPoint) => {
        if (!disabledPoints.has(mapPoint.id) && mapPointsVisibilityData[mapPoint.id]) {
          activePointsList.push({
            lat: mapPoint.lat,
            lng: mapPoint.lng,
            hexId: h3.geoToH3(mapPoint.lat, mapPoint.lng, H3_HEX_RESOLUTION),
            neighbour: mapPointsIsochroneData[mapPoint.id].hexagonsCovered.OUTER_DISTANCE
          });
        }
      });

      const additionalPointsList = Object.values(distancePinsStaticData).map((pin) => {
        return {
          lat: pin.lat,
          lng: pin.lng,
          hexId: h3.geoToH3(pin.lat, pin.lng, H3_HEX_RESOLUTION),
          neighbour: distancePinsIsochroneData[pin.id].hexagonsCovered.OUTER_DISTANCE
        };
      });

      const currentNetworkFilePromise = S3Util.uploadFileToS3(currentNetworkFileKey, activePointsList, {
        contentType: 'application/json',
        bucket: BackendResourceConfigUtil.getCoverageRecommendationBucketName()
      }, process.env.REACT_APP_SHOWCASE_RECOMMENDER);

      const additionalNetworkFilePromise = S3Util.uploadFileToS3(additionalNetworkFileKey, additionalPointsList, {
        contentType: 'application/json',
        bucket: BackendResourceConfigUtil.getCoverageRecommendationBucketName()
      }, process.env.REACT_APP_SHOWCASE_RECOMMENDER);

      const enrichmentLayers = recommendationFormParamsData.layers
        .map((l) => l.layerType)
        .filter((fl) => fl !== recommenderHexType.current);

      if (recommendationFormType === COVERAGE) {
        MixPanel.track('Delivery Area Analysis - Generating recommendation', {
          goal: recommendationFormType,
          deliveryArea: lastSelectedCenterPosition.name,
          dateFrom: dateFrom,
          dateTo: dateTo,
          inputBaseLayer: recommenderHexType.current,
          inputEnrichmentLayers: enrichmentLayers,
          inputUnwantedLocations: unwantedLocationsHexArray.length,
          inputDesiredCoverage: recommendationFormParamsData.coverage,
          inputMaximumLocations: recommendationFormParamsData.locations,
          inputPlacePins: recommendationFormParamsData.placePins,
          inputNumberOfPreviouslyPlacedPins: numberOfDistancePins.current,
          inputNumberOfHexagons: numberOfHexagonsInDA.current
        });
      }

      if (recommendationFormType === LOCATIONS) {
        MixPanel.track('Delivery Area Analysis - Generating recommendation', {
          goal: recommendationFormType,
          deliveryArea: lastSelectedCenterPosition.name,
          dateFrom: dateFrom,
          dateTo: dateTo,
          inputBaseLayer: recommenderHexType.current,
          inputEnrichmentLayers: enrichmentLayers,
          inputUnwantedLocations: unwantedLocationsHexArray.length,
          inputMaximumLocations: recommendationFormParamsData.locations,
          inputPlacePins: recommendationFormParamsData.placePins,
          inputNumberOfPreviouslyPlacedPins: numberOfDistancePins.current,
          inputNumberOfHexagons: numberOfHexagonsInDA.current
        });
      }

      Promise.all([currentNetworkFilePromise, additionalNetworkFilePromise])
        .then(() => {
          runCoverageRecommendation();
        })
        .catch((err) => {
          raygunClient.send(err, 'Error creating recommender files');
          toast.error(getErrorMessage());
          dispatch(PageActions.loadingPage(false));
        });
    }

    if (shouldTriggerRecommender) {
      setShouldTriggerRecommender(false);
      triggerRecommendation();
    }

    return () => {
    };
  }, [
    disabledPoints,
    mapPointsStaticData,
    mapPointsVisibilityData,
    recommendationFormParamsData,
    lastSelectedCenterPosition,
    regionsDateFrom,
    regionsDateTo,
    shouldTriggerRecommender,
    dispatch,
    getErrorMessage,
    onRecommenderResultsReceived,
    hexType,
    distancePinsIsochroneData,
    distancePinsStaticData,
    distanceType,
    mapPointsIsochroneData,
    tenantId,
    unwantedLocationPinsStaticData
  ]);

  function getMapPointsCoverageData() {
    const newMapPointCoverageData = {};
    if (!_.isEmpty(mapPointsIsochroneData) && !_.isEmpty(hexLayersCountMap)) {
      Object.values(mapPointsIsochroneData).forEach((parcelLocker) => {
        newMapPointCoverageData[parcelLocker.id] = getHexLayerCountCoverage(parcelLocker.hexagonsCovered, hexLayersCountMap, userHexTypes);
      });
    }

    return newMapPointCoverageData;
  }

  // Create Visibility Data for Map Points
  function createMapPointsVisibilityData() {
    const visibilityObject = {};

    if (!_.isEmpty(mapPointsStaticData)) {
      Object.values(mapPointsStaticData).forEach((mapPoint) => {
        visibilityObject[mapPoint.id] = showAdditionalMapFeatures[mapPoint.type];
      });
    }

    return visibilityObject;
  }

  // Open point details menu (if it was closed)
  useEffect(() => {
    if (viewDetailsMapPointId) setOpenedRightMenu('point-menu');
  }, [viewDetailsMapPointId]);

  function sortImpactListForLayer(impactMap, layer) {
    return Object.keys(impactMap).sort((firstId, secondId) => {
      return (impactMap[firstId][layer] || 0) > (impactMap[secondId][layer] || 0) ? -1 : 1;
    }).map((mapPointId) => {
      return { [mapPointId]: impactMap[mapPointId][layer] || 0 };
    });
  }

  function updateUnwantedPinDataFromMap(mapMarkerEvent, pin) {
    const { lat, lng } = mapMarkerEvent.lngLat;
    setUnwantedLocationPinsStaticData((prevState) => {
      return {
        ...prevState,
        [pin.id]: {
          ...pin,
          lat,
          lng
        }
      };
    });
  }

  // Unwanted locations pins actions

  function addUnwantedLocationPinButtonClick() {
    const { lat, lng } = deliveryAreaAnalysisMapRef.current.getCenter();
    const id = idUtil.generateId();
    MixPanel.track('Delivery Area Analysis - Unwanted location pin added');
    setUnwantedLocationPinsStaticData((prevState) => {
      const newState = { ...prevState };
      newState[id] = {
        id,
        lat,
        lng,
        type: 'unwantedLocationPin'
      };
      return newState;
    });
  }

  function removeAllUnwantedLocationPinButtonClick() {
    MixPanel.track('Delivery Area Analysis - All unwanted location pins cleared');
    removeAllUnwantedLocationPins();
  }

  function removeAllUnwantedLocationPins() {
    setUnwantedLocationPinsStaticData({});
  }

  function removeUnwantedLocationPin(unwantedLocationPinId) {
    setUnwantedLocationPinsStaticData((prevState) => {
      const newState = { ...prevState };
      delete newState[unwantedLocationPinId];

      return newState;
    });
  }

  // Distance pins actions

  function addDistancePinButtonClick() {
    const { lat, lng } = deliveryAreaAnalysisMapRef.current.getCenter();
    addNewDistancePins([{ lat: `${lat}`, lng: `${lng}`, pinOrigin: PIN_ORIGINS.MANUAL }]);
  }

  function resetDistancePinsDataFn() {
    removeAllDistancePins();
    setDistancePinsCoverageData({});
  }

  // Old functions
  const togglePicker = (show) => {
    setExtendedWidth(show);
  };

  const changeSelectedRegions = useCallback((deliveryArea) => {
    const defaultView = MapUtil.getInitialViewStateForCompany();
    setLastSelectedCenterPosition({
      lat: parseFloat(deliveryArea.lat) || defaultView.lat,
      lng: parseFloat(deliveryArea.lng) || defaultView.lng,
      zoom: parseFloat(deliveryArea.zoom) || defaultView.zoom,
      id: deliveryArea.id,
      name: deliveryArea.name
    });
  }, []);

  function getHexLayerCountCoverage(hexagonsCovered, layersCountMap, hexTypesForUser) {
    const hexLayerCountCoverage = {};
    if (hexagonsCovered) {
      Object.keys(hexagonsCovered)
        .forEach((distance) => {
          hexLayerCountCoverage[distance] = {};
          hexagonsCovered[distance].forEach((hexId) => {
            hexTypesForUser.forEach((layer) => {
              if (layersCountMap && layersCountMap[hexId] && layersCountMap[hexId][layer]) {
                if (hexLayerCountCoverage[distance][layer]) hexLayerCountCoverage[distance][layer] += layersCountMap[hexId][layer];
                else hexLayerCountCoverage[distance][layer] = layersCountMap[hexId][layer];
              }
            });
          });
        });
    }
    return hexLayerCountCoverage;
  }

  const setDateFilters = (e) => {
    let startDate;
    let endDate;

    switch (e.target.dataset.target) {
      case 'last7Days':
        startDate = moment().subtract(8, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Delivery Area Analysis - Date changed - Last 7 days', { numberOfDays: 8 });
        break;
      case 'last30Days':
        startDate = moment().subtract(31, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Delivery Area Analysis - Date changed - Last 30 days', { numberOfDays: 31 });
        break;
      default:
        startDate = moment().subtract(1, 'day').startOf('day').toDate();
        endDate = moment().subtract(1, 'day').startOf('day').toDate();
        MixPanel.track('Delivery Area Analysis - Date changed - Yesterday', { numberOfDays: 1 });
    }

    dispatch(RegionAnalysisActions.changeDateRange(startDate, endDate));
    QueryStringUtil.setQueryStringValue('fromDate', moment(startDate).startOf('day').format('YYYY-MM-DDTHH'));
    QueryStringUtil.setQueryStringValue('toDate', moment(endDate).endOf('day').format('YYYY-MM-DDTHH'));
  };

  function handleMenuToggle(type) {
    if (openedMenu === type) setOpenedMenu(null);
    else setOpenedMenu(type);
  }

  function handleRightMenuToggle(menuTitle) {
    if (openedRightMenu === menuTitle) {
      setOpenedRightMenu(null);
      MixPanel.track(`Delivery Area Analysis - ${menuTitle} menu closed`);
    } else {
      setOpenedRightMenu(menuTitle);
      MixPanel.track(`Delivery Area Analysis - ${menuTitle} menu opened`);
    }
  }

  const getActiveClassIfCurrentHexType = (hexTypeName) => (hexType === hexTypeName ? 'active' : '');

  const changeHexType = (selectedHexType) => {
    dispatch(RegionAnalysisActions.changeHexType(selectedHexType));
    QueryStringUtil.setQueryStringValue('hexType', selectedHexType);
    MixPanel.track(`Delivery Area Analysis - hexagon type changed to ${selectedHexType}`, { hexType: selectedHexType });
  };

  const mapAdditionalFeatureAction = (event) => {
    const featureType = event.currentTarget.dataset.value;
    const newState = { ...showAdditionalMapFeatures };
    const newVisibilityFlag = !showAdditionalMapFeatures[featureType];
    if (Object.values(MAP_ADDITIONAL_FEATURES_TOGGLES).includes(featureType)) {
      MixPanel.track(`Delivery Area Analysis - ${featureType} turned ${newVisibilityFlag ? 'ON' : 'OFF'}`);
      QueryStringUtil.setQueryStringValue(featureType, newVisibilityFlag);
      newState[featureType] = newVisibilityFlag;
    }
    if (Object.values(MAP_ADDITIONAL_FEATURES_COVERAGE_MENU).includes(featureType) && newVisibilityFlag) {
      setOpenedRightMenu('point-menu');
    }
    setShowAdditionalMapFeatures(newState);
  };

  function getAdditionalMapFeaturesDefaultValues() {
    const visibilityObject = {};
    if (!additionalMapFeatures) {
      return {};
    }
    additionalMapFeatures.forEach((feature) => {
      if (Object.values(MAP_ADDITIONAL_FEATURES_TOGGLES)
        .includes(feature)) {
        visibilityObject[feature] = QueryStringUtil.getQueryStringValue(feature) === 'true';
      }
      if (Object.values(MAP_ADDITIONAL_FEATURES_SIGNALS)
        .includes(feature)) {
        visibilityObject[feature] = false;
      }
    });

    return visibilityObject;
  }

  const toggleRegionStopsDistributionChartView = () => {
    MixPanel.track(`Delivery Area Analysis - Bar chart distribution turned ${!showRegionStopsDistributionChart ? 'ON' : 'OFF'}`, {});
    setRegionStopsDistributionChart(!showRegionStopsDistributionChart);
  };

  const openRecommendationParamsForm = (e) => {
    const dialogMaxWidth = '390px';
    const formType = e.currentTarget.dataset.value;
    MixPanel.track('Delivery Area Analysis - Recommendation form opened', { goal: formType });
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: CoverageInputForm,
      dialogComponentProps: {
        maxWidth: dialogMaxWidth,
        formType: formType,
        isModern: true,
        submitCallback: (callBackData) => {
          setRecommendationFormType(formType);
          setShowRecommenderMessage(false);
          setRecommendationParamsFromForm(callBackData);
          dispatch(RegionAnalysisActions.closePointDetailsMenu());
          setOpenedRightMenu('point-menu');
        }
      },
      isModern: true
    });
  };

  function setRecommendationParamsFromForm(data) {
    setRecommendationFormParamsData({
      coverage: Number(data.coverage),
      locations: Number(data.locations),
      coverageType: data.coverageType,
      placePins: data.placePins,
      layers: data.layers
    });
    setShouldTriggerRecommender(true);

    recommendedLocationPinPlacement.current = data.placePins;
  }

  function addRecommenderPinsFn(recommendedHexagons) {
    const newPinsToAdd = Object.keys(recommendedHexagons).map((hexId) => {
      const hexCenter = h3.h3ToGeo(hexId);
      return { lat: hexCenter[0], lng: hexCenter[1], pinOrigin: PIN_ORIGINS.NETWORK_GENERATOR };
    });

    addNewDistancePins(newPinsToAdd);
  }

  function openDistanceChangeConfirmationDialog(selectedDistanceType) {
    const dialogMaxWidth = '464px';
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: DistanceChangeConfirmationDialog,
      dialogComponentProps: {
        maxWidth: dialogMaxWidth,
        isModern: true,
        submitCallback: () => {
          setDistanceType(selectedDistanceType);
          setOpenedRightMenu('point-menu');
          getDistancePinIsochroneData(distancePinsStaticData, true, selectedDistanceType);
          MixPanel.track(`Delivery Area Analysis - Distance confirmation dialog confirm button clicked - ${selectedDistanceType}`);
          AppDialogActionsWrapper.closeAppDialog();
        }
      },
      isModern: true
    });
  }

  // Sets the area size based on one of the fetched hexagons
  // Works only when the hexagons are all the same size, if they differ all the features using this state should be reworked
  function getHexagonAreaSize() {
    const hexagons = getHexagonsQueryResults?.getHexagonsForDeliveryArea?.hexagons;
    if (!_.isEmpty(hexagons)) {
      const hexAreaSize = h3.cellArea(hexagons[0].id, h3.UNITS.m2);
      return Math.round(hexAreaSize);
    }
    return null;
  }

  return (
    <MapProvider>
      <div className="delivery-area-analysis">
        {showRecommenderMessage && recommendationResults && (
          <ResultMessage
            targetLocations={recommendationFormParamsData.locations}
            targetCoverage={recommendationFormParamsData.coverage}
            recommendationResults={recommendationResults}
            messageType={recommendationFormType}
            layer={recommenderHexType.current}
          />
        )}
        <div className="controls-wrapper">
          <div className={showExtendedWidth ? 'filter-wrapper extend-width' : 'filter-wrapper'}>
            <DeliveryAreaSelect onDeliveryAreaChange={changeSelectedRegions} returnFullObject />
            <div className="date-range">
              <div className="title">{t('Date range')}</div>
              <RangeDayPicker togglePicker={togglePicker} eventTrackerNamePrefix="Delivery Area Analysis" />
            </div>
            <div className="quick-links">
              <div onClick={setDateFilters} data-target="yesterday">
                {t('yesterday')}
              </div>
              <div onClick={setDateFilters} data-target="last7Days">
                {t('last7Days')}
              </div>
              <div onClick={setDateFilters} data-target="last30Days">
                {t('last30Days')}
              </div>
            </div>
          </div>
          <div className="mode-buttons">
            {AuthUtil.hasAnyOfFeaturesEnabled(PERMISSIONS_FOR_MAP_LAYERS)
            && (
              <MapModeButton
                customKey="hex-type"
                toggleMenu={() => handleMenuToggle('hex-type')}
                dataTip={t('hex-type')}
                dataFor="hex-type-menu-tooltip"
                dataValue={hexType}
                icon="icon-stack"
                text={t(`hex-${hexType}`)}
              >
                <div className="menu menu-vertical" style={{ display: openedMenu === 'hex-type' ? 'block' : 'none' }}>
                  {userHexTypes.map((layer) => {
                    return (
                      <div
                        className={`menu-item ${getActiveClassIfCurrentHexType(layer)}`}
                        onClick={(opt) => { changeHexType(opt.currentTarget.dataset.value); }}
                        data-value={layer}
                        key={layer}
                      >
                        <span className="selection-label">{t(`hex-${layer}`)}</span>
                      </div>
                    );
                  })}
                </div>
              </MapModeButton>
            )}
            {additionalMapFeatures
            && MAP_ADDITIONAL_FEATURES_ORDERED_KEYS.filter((featureType) => additionalMapFeatures.includes(featureType)).map((featureType) => {
              switch (featureType) {
                case MAP_ADDITIONAL_FEATURES_SIGNALS.ISOCHRONE_PIN:
                  return (
                    <MapModeButton
                      key={featureType}
                      customKey={featureType}
                      toggleMenu={() => handleMenuToggle('isochrone-pin')}
                      dataTip={t('Manage map pins')}
                      isActive={openedMenu === 'isochrone-pin'}
                      dataFor="isochrone-pin-menu-tooltip"
                      icon={`${MAP_ADDITIONAL_FEATURES_DATA[MAP_ADDITIONAL_FEATURES_SIGNALS.ISOCHRONE_PIN].icon}`}
                    >
                      <div
                        className="menu menu-vertical"
                        style={{ display: openedMenu === 'isochrone-pin' ? 'block' : 'none' }}
                      >
                        <div
                          key="add-pin"
                          className="menu-item"
                          onClick={() => {
                            addDistancePinButtonClick();
                            MixPanel.track('Delivery Area Analysis - Distance pin added');
                          }}
                        >
                          <span className="selection-label">{t('Add a distance pin')}</span>
                        </div>
                        <div
                          key="clear-all-pins"
                          className="menu-item"
                          onClick={() => {
                            resetDistancePinsData();
                            MixPanel.track('Delivery Area Analysis - All distance pins cleared');
                          }}
                        >
                          <span className="selection-label">{t('Clear all pins')}</span>
                        </div>
                      </div>
                    </MapModeButton>
                  );
                default:
                  return (
                    <MapModeButton
                      key={featureType}
                      customKey={featureType}
                      isActive={Object.values(MAP_ADDITIONAL_FEATURES_TOGGLES)
                        .includes(featureType) ? showAdditionalMapFeatures[featureType] : null}
                      dataTip={t(`${MAP_ADDITIONAL_FEATURES_DATA[featureType].label}`)}
                      onClick={mapAdditionalFeatureAction}
                      dataValue={featureType}
                      icon={`${MAP_ADDITIONAL_FEATURES_DATA[featureType].icon}`}
                    />
                  );
              }
            })}
            {AuthUtil.isFeatureEnabled('unwantedLocation')
              && (
              <MapModeButton
                customKey="unwanted-location"
                key="unwanted-location"
                toggleMenu={() => handleMenuToggle('unwanted-location')}
                dataTip={t('Manage unwanted locations')}
                isActive={openedMenu === 'unwanted-location'}
                dataFor="unwanted-locations-menu-tooltip"
                icon="icon-unwanted"
              >
                <div
                  className="menu menu-vertical"
                  style={{ display: openedMenu === 'unwanted-location' ? 'block' : 'none' }}
                >
                  <div
                    key="add-unwanted-location"
                    className="menu-item"
                    onClick={addUnwantedLocationPinButtonClick}
                  >
                    <span className="selection-label">{t('Mark an unwanted location')}</span>
                  </div>
                  <div
                    key="clear-all-unwanted-locations"
                    className="menu-item"
                    onClick={removeAllUnwantedLocationPinButtonClick}
                  >
                    <span className="selection-label">{t('Clear all unwanted locations')}</span>
                  </div>
                </div>
              </MapModeButton>
              )}
            {AuthUtil.isFeatureEnabled('recommendation-system') && (
            <MapModeButton
              key="recommendation"
              customKey="recommendation"
              toggleMenu={() => handleMenuToggle('recommendation')}
              dataTip={t('Generate')}
              isActive={openedMenu === 'recommendation'}
              dataFor="recommendation-menu-tooltip"
              icon="icon-covered-selection"
            >
              <div className="menu menu-vertical" style={{ display: openedMenu === 'recommendation' ? 'block' : 'none' }}>
                <div
                  className="menu-item"
                  onClick={openRecommendationParamsForm}
                  key="generate-coverage"
                  data-value={COVERAGE}
                >
                  <span className="selection-label">{t('Generate coverage')}</span>
                </div>
                <div
                  className="menu-item"
                  onClick={openRecommendationParamsForm}
                  key="generate-locations"
                  data-value={LOCATIONS}
                >
                  <span className="selection-label">{t('Generate locations')}</span>
                </div>
                <div
                  className="menu-item"
                  onClick={() => {
                    setRecommendationResults(null);
                    recommendedLocationPinPlacement.current = false;
                    MixPanel.track('Delivery Area Analysis - Clear results clicked');
                  }}
                  key="recommendation-system-reset"
                >
                  <span className="selection-label">{t('Clear results')}</span>
                </div>
              </div>
            </MapModeButton>
            )}
            {openedMenu !== 'hex-type' && <MilyTooltipWrapper id="hex-type-menu-tooltip" className="tooltip" place="right" effect="solid" />}
            {openedMenu !== 'recommendation' && <MilyTooltipWrapper id="recommendation-menu-tooltip" className="tooltip" place="right" effect="solid" />}
            {openedMenu !== 'isochrone-pin' && <MilyTooltipWrapper id="isochrone-pin-menu-tooltip" className="tooltip" place="right" effect="solid" />}
            {openedMenu !== 'unwanted-location' && <MilyTooltipWrapper id="unwanted-locations-menu-tooltip" className="tooltip" place="right" effect="solid" />}
          </div>
        </div>
        <div className="right-menu">
          <div className="right-menu-buttons">
            <MapModeButton
              key="point-menu"
              customKey="point-menu"
              icon="icon-list-alt"
              isActive={openedRightMenu === 'point-menu'}
              dataTip={t('Coverage')}
              dataTipPlace="left"
              onClick={() => {
                MixPanel.track('Delivery Area Analysis - Coverage data menu tab clicked');
                handleRightMenuToggle('point-menu');
              }}
              additionalClasses={['left-tab']}
            />
            {AuthUtil.isFeatureEnabled('isochroneConfig') && (
            <MapModeButton
              key="distance-config"
              customKey="distance-config"
              icon="icon-settings"
              isActive={openedRightMenu === 'distance-config'}
              dataTip={t('Configure')}
              dataTipPlace="left"
              onClick={() => {
                MixPanel.track('Delivery Area Analysis - Distance config menu tab clicked');
                handleRightMenuToggle('distance-config');
              }}
              additionalClasses={['left-tab']}
            />
            )}
          </div>
          <div className="right-menu-menus">
            <div className={`point-menu${openedRightMenu === 'point-menu' ? ' expanded' : ''}`}>
              <div className="animation-container">
                { !(mapPointsStaticData[viewDetailsMapPointId] || staticMapFeaturesData[viewDetailsMapPointId]) && hexLayersCountMap ? (
                  <CoverageMenu
                    hexType={hexType}
                    distanceType={distanceType}
                    mapFeaturesActive={showAdditionalMapFeatures}
                    mapPointsStaticData={mapPointsStaticData}
                    mapPointsIsochroneData={mapPointsIsochroneData}
                    mapPointsVisibilityData={mapPointsVisibilityData}
                    distancePinsIsochroneData={distancePinsIsochroneData}
                    hexLayersCountMap={hexLayersCountMap}
                  />
                )
                  : (
                    <ViewPointDetailsMenu
                      hexType={hexType}
                      distanceType={distanceType}
                      mapPointStaticData={mapPointsStaticData[viewDetailsMapPointId] || staticMapFeaturesData[viewDetailsMapPointId]}
                      mapPointCoverageData={mapPointsCoverageData[viewDetailsMapPointId] || staticMapFeaturesCoverageData[viewDetailsMapPointId]}
                    />
                  )}
              </div>
            </div>
            {AuthUtil.isFeatureEnabled('isochroneConfig') && (
            <DistancePolygonConfigMenu
              isDistanceMenuOpen={openedRightMenu === 'distance-config'}
              setSelectedDistanceValue={(selectedDistanceType) => {
                if (selectedDistanceType !== distanceType) {
                  openDistanceChangeConfirmationDialog(selectedDistanceType);
                }
              }}
              getDistanceType={() => distanceType}
              onClose={() => handleRightMenuToggle('distance-config')}
            />
            )}
          </div>
        </div>

        <div className="map">
          <DeliveryAreaAnalysisMap
            hexLayersCountMap={hexLayersCountMap}
            mapPointsStaticData={mapPointsStaticData}
            mapPointsIsochroneData={mapPointsIsochroneData}
            mapPointsVisibilityData={mapPointsVisibilityData}
            mapPointsCollectionData={mapPointsCollectionData}
            mapPointsCoverageData={mapPointsCoverageData}
            distancePinsStaticData={distancePinsStaticData}
            distancePinsIsochroneData={distancePinsIsochroneData}
            distancePinsCoverageData={distancePinsCoverageData}
            updateDistancePinData={(mapMarkerEvent, distancePin) => { updateDistancePinDataFromMap(mapMarkerEvent, distancePin); setOpenedMenu(null); }}
            removeDistancePinPolygon={(distancePinId) => { removeDistancePinPolygon(distancePinId); }}
            removeDistancePin={(distancePinId) => { removeDistancePin(distancePinId); setOpenedMenu(null); }}
            unwantedLocationPinsStaticData={unwantedLocationPinsStaticData}
            updateUnwantedLocationPinData={(mapMarkerEvent, unwantedLocationPin) => {
              updateUnwantedPinDataFromMap(mapMarkerEvent, unwantedLocationPin);
            }}
            removeUnwantedLocationPin={(pinId) => { removeUnwantedLocationPin(pinId); setOpenedMenu(null); }}
            hexType={hexType}
            distanceType={distanceType}
            centerPositionData={lastSelectedCenterPosition}
            deliveryAreaTotalCount={deliveryAreaTotalCount}
            regionsData={regionsData}
            regionsTotals={regionsTotals}
            showRegionStopsDistributionChart={showRegionStopsDistributionChart}
            deliveryAreaAnalysisMapRef={deliveryAreaAnalysisMapRef}
            staticMapFeaturesData={staticMapFeaturesData}
            staticMapFeaturesIsochroneData={staticMapFeaturesIsochroneData}
            staticMapFeaturesCoverageData={staticMapFeaturesCoverageData}
            recommenderResults={recommendationResults}
          />

          <div className="map-buttons">
            {hexagonSize && (
            <div className="info-button">
              <MapModeButton
                customKey="hexagon-info"
                key="hexagon-info"
                icon="icon-info-outline"
                dataTip={t('Hexagon info', { hexagonSize: hexagonSize })}
                dataValue="hexagon-info"
                onMouseEnterEvent={() => { MixPanel.track('Delivery Area Analysis - Hexagon info button hovered'); }}
              />
            </div>
            )}
            <div className="region-map-buttons">
              {hasRegionsEnabled && <MapTypeButton buttonHoveredAction={setButtonLegend} />}
              {hasRegionsEnabled && (
              <div
                className={showRegionStopsDistributionChart ? 'map-type-button button active' : 'map-type-button button'}
                onClick={toggleRegionStopsDistributionChartView}
                onMouseOver={() => setButtonLegend(t('regionStopsDistributionHint'))}
                onMouseOut={() => setButtonLegend(null)}
                onBlur={() => null}
                onFocus={() => null}
              >
                <i className="icon icon-bar-chart" />
              </div>
              )}
              {buttonLegend && <ButtonLegend text={buttonLegend} />}
            </div>
          </div>
        </div>
        <div className={showRegionStopsDistributionChart ? 'chart' : 'chart hidden'}>
          <RegionStopsDistributionChart centerTotalsInfo={deliveryAreaTotalCount} regionsTotal={regionsTotals} hexType={hexType} isChartVisible />
        </div>
      </div>
    </MapProvider>
  );
}
