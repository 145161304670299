import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import colorsAndFonts from '../../../resources/colors-and-fonts.scss';

export default function MilyTooltipWrapper(props) {
  const { id, className, place, effect } = props;

  return (
    <ReactTooltip textColor={colorsAndFonts.light_text} id={id} className={className} place={place} effect={effect} />
  );
}

MilyTooltipWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  place: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  effect: PropTypes.oneOf(['float', 'solid'])
};

MilyTooltipWrapper.defaultProps = {
  place: 'top',
  effect: 'float'
};
