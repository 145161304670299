import PropTypes from 'prop-types';
import React from 'react';
import './CoverageMenu.scss';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import CoverageDataTable from './CoverageDataTable';
import { COVERAGE_DATA_TABLE_TYPES, IMPACT_TABLE_TYPES } from '../../constants/menuConstants';
import { MAP_ADDITIONAL_FEATURES_COVERAGE_MENU,
  MAP_ADDITIONAL_FEATURES_TOGGLES } from '../../../../../common/constants/mapAdditionalFeaturesData';
import DistanceTypeUtil, { DISTANCE_VALUE_TYPE_KEYS_ARRAY, } from '../../../../../common/utils/distanceTypeUtil';
import * as PageActions from '../../../../../state/actions/pageActions';
import * as MapActions from '../../../../../state/actions/mapActions';
import * as RegionAnalysisActions from '../../../../../state/actions/regionAnalysisActions';
import * as CoordinatesActions from '../../../../../state/actions/coordinatesActions';
import AuthUtil from '../../../../../common/utils/authUtil';
import ImpactfulLocationsList from './ImpactfulLocationsList';
import MapPointCountIcon from './MapPointCountIcon';
import { raygunClient } from '../../../../../setup/raygunClient';

class CoverageMenuClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coverageMenuTableData: {},
      currentCoverageMenuTableData: {}
    };
    this.selectedHexagonsMap = {};
    this.userLayers = AuthUtil.getRegionAnalysisLayers();
  }

  componentDidMount() {
    const newSelectedHexagonsMap = this.createSelectedHexagonsMap();
    const currentCoverageMenuTableData = this.calculateCurrentCoverageMenuTableData();
    const coverageMenuTableData = this.calculateEstimatedCoverageMenuTableData(newSelectedHexagonsMap);
    this.selectedHexagonsMap = newSelectedHexagonsMap;
    this.setState({ coverageMenuTableData: coverageMenuTableData, currentCoverageMenuTableData: currentCoverageMenuTableData });
  }

  componentDidUpdate(prevProps) {
    let newSelectedHexagonsMap;
    let currentCoverageMenuTableData;

    // Create A on DA change
    if (this.props.mapPointsIsochroneData !== prevProps.mapPointsIsochroneData) {
      newSelectedHexagonsMap = this.createSelectedHexagonsMap();
      this.selectedHexagonsMap = newSelectedHexagonsMap;
      currentCoverageMenuTableData = this.calculateCurrentCoverageMenuTableData();
    }

    if (prevProps.hexLayersCountMap !== this.props.hexLayersCountMap) {
      newSelectedHexagonsMap = { ...this.selectedHexagonsMap };
      currentCoverageMenuTableData = this.calculateCurrentCoverageMenuTableData();
    }

    // Update A - on map point button click (show/hide pl, shops, etc)
    if (this.props.mapPointsVisibilityData !== prevProps.mapPointsVisibilityData) {
      currentCoverageMenuTableData = this.calculateCurrentCoverageMenuTableData();
      newSelectedHexagonsMap = { ...this.selectedHexagonsMap };
      Object.values(this.props.mapPointsIsochroneData).forEach((mapPoint) => {
        if (this.props.mapPointsVisibilityData[mapPoint.id] && !prevProps.mapPointsVisibilityData[mapPoint.id]) {
          newSelectedHexagonsMap = this.selectPointHexagons(mapPoint, newSelectedHexagonsMap);
        } else if (!this.props.mapPointsVisibilityData[mapPoint.id] && prevProps.mapPointsVisibilityData[mapPoint.id] && !this.props.disabledPoints.has(mapPoint.id)) {
          newSelectedHexagonsMap = this.unselectPointHexagons(mapPoint, newSelectedHexagonsMap);
        }
      });
    } else if (this.props.disabledPoints !== prevProps.disabledPoints) {
      // Update A - point disabled
      newSelectedHexagonsMap = { ...this.selectedHexagonsMap };
      if (!_.isEmpty(this.props.disabledPoints)) {
        this.props.disabledPoints.forEach((mapPointId) => {
          if (!prevProps.disabledPoints.has(mapPointId) && this.props.mapPointsVisibilityData[mapPointId]) {
            newSelectedHexagonsMap = this.unselectPointHexagons(this.props.mapPointsIsochroneData[mapPointId], newSelectedHexagonsMap);
          }
        });
      }

      if (!_.isEmpty(prevProps.disabledPoints)) {
        prevProps.disabledPoints.forEach((mapPointId) => {
          if (!this.props.disabledPoints.has(mapPointId) && this.props.mapPointsVisibilityData[mapPointId]) {
            newSelectedHexagonsMap = this.selectPointHexagons(this.props.mapPointsIsochroneData[mapPointId], newSelectedHexagonsMap);
          }
        });
      }
    } else if (this.props.distancePinsIsochroneData !== prevProps.distancePinsIsochroneData) {
      // Update A - on pin change
      newSelectedHexagonsMap = { ...this.selectedHexagonsMap };
      let pinRemoved = false;

      // Pin removed
      Object.values(prevProps.distancePinsIsochroneData)
        .forEach((distancePin) => {
          if (!this.props.distancePinsIsochroneData[distancePin.id]) {
            newSelectedHexagonsMap = this.unselectPointHexagons(distancePin, newSelectedHexagonsMap);
            pinRemoved = true;
          }
        });

      if (!pinRemoved) {
        Object.values(this.props.distancePinsIsochroneData)
          .forEach((distancePin) => {
            // Pin added
            if (!prevProps.distancePinsIsochroneData[distancePin.id]) {
              newSelectedHexagonsMap = this.selectPointHexagons(distancePin, newSelectedHexagonsMap);
            } else {
              // Pin moved
              newSelectedHexagonsMap = this.unselectPointHexagons(prevProps.distancePinsIsochroneData[distancePin.id], newSelectedHexagonsMap);
              newSelectedHexagonsMap = this.selectPointHexagons(distancePin, newSelectedHexagonsMap);
            }
          });
      }
    }

    if (newSelectedHexagonsMap) {
      const coverageMenuTableData = this.calculateEstimatedCoverageMenuTableData(newSelectedHexagonsMap);
      this.selectedHexagonsMap = newSelectedHexagonsMap;
      if (currentCoverageMenuTableData) {
        this.setState({ coverageMenuTableData: coverageMenuTableData, currentCoverageMenuTableData: currentCoverageMenuTableData });
      } else {
        this.setState({ coverageMenuTableData: coverageMenuTableData });
      }
    }
  }

  createSelectedHexagonsMap = () => {
    let newSelectedHexagonsMap = {};
    Object.entries(this.props.mapPointsIsochroneData).forEach(([mapPointId, mapPoint]) => {
      if (this.props.mapPointsVisibilityData[mapPointId] && (!this.props.disabledPoints || !this.props.disabledPoints.has(mapPointId))) {
        newSelectedHexagonsMap = this.selectPointHexagons(mapPoint, newSelectedHexagonsMap);
      }
    });

    Object.values(this.props.distancePinsIsochroneData)
      .forEach((distancePin) => {
        newSelectedHexagonsMap = this.selectPointHexagons(distancePin, newSelectedHexagonsMap);
      });

    return newSelectedHexagonsMap;
  };

  selectPointHexagons = (mapPoint, selectedHexagonsMap) => {
    const newSelectedHexagonsMap = { ...selectedHexagonsMap };
    Object.entries(mapPoint.hexagonsCovered)
      .forEach(([distance, hexagonsCovered]) => {
        hexagonsCovered.forEach((hexId) => {
          if (!newSelectedHexagonsMap[hexId]) {
            newSelectedHexagonsMap[hexId] = {};
          }

          if (!newSelectedHexagonsMap[hexId][distance]) {
            newSelectedHexagonsMap[hexId][distance] = 0;
          }

          newSelectedHexagonsMap[hexId][distance] += 1;
        });
      });

    return newSelectedHexagonsMap;
  };

  unselectPointHexagons = (mapPoint, selectedHexagonsMap) => {
    const newSelectedHexagonsMap = { ...selectedHexagonsMap };
    Object.entries(mapPoint.hexagonsCovered)
      .forEach(([distance, hexagonsCovered]) => {
        hexagonsCovered.forEach((hexId) => {
          try {
            newSelectedHexagonsMap[hexId][distance] -= 1;
            if (newSelectedHexagonsMap[hexId][distance] === 0) {
              delete newSelectedHexagonsMap[hexId][distance];
            }

            if (_.isEmpty(newSelectedHexagonsMap[hexId])) {
              delete newSelectedHexagonsMap[hexId];
            }
          } catch (e) {
            raygunClient.send(e, 'Error removing hexagon from selected hexagons map', { hexId, newSelectedHexagonsMap, hexagonsCovered, mapPoint });
          }
        });
      });

    return newSelectedHexagonsMap;
  };

  calculateCurrentCoverageMenuTableData = () => {
    if (!_.isEmpty(this.props.mapPointsVisibilityData)) {
      const currentCoverageMenuTableData = {};

      const hexagonsSelected = {};
      const totalHexagonsCount = {};

      // For current network final percentage calculation
      this.userLayers.forEach((layer) => {
        currentCoverageMenuTableData[layer] = {};
        DISTANCE_VALUE_TYPE_KEYS_ARRAY.forEach((polygonType) => {
          currentCoverageMenuTableData[layer][polygonType] = 0;
        });
        totalHexagonsCount[layer] = 0;
        Object.values(this.props.hexLayersCountMap).forEach((hexId) => {
          totalHexagonsCount[layer] += hexId[layer];
        });
      });

      DISTANCE_VALUE_TYPE_KEYS_ARRAY.forEach((polygonType) => {
        hexagonsSelected[polygonType] = new Set();
      });

      Object.entries(this.props.mapPointsIsochroneData).forEach(([mapPointId, mapPoint]) => {
        if (this.props.mapPointsVisibilityData[mapPointId]) {
          Object.entries(mapPoint.hexagonsCovered).forEach(([polygonType, pointHexagonsSelected]) => {
            pointHexagonsSelected.forEach((hexId) => {
              hexagonsSelected[polygonType].add(hexId);
            });
          });
        }
      });

      this.userLayers.forEach((layer) => {
        Object.keys(hexagonsSelected).forEach((polygonType) => {
          hexagonsSelected[polygonType].forEach((hexId) => {
            currentCoverageMenuTableData[layer][polygonType] += this.props.hexLayersCountMap[hexId] ? this.props.hexLayersCountMap[hexId][layer] : 0;
          });
        });

        const largestDistance = DistanceTypeUtil.getLargestDistanceKey();
        currentCoverageMenuTableData[layer].percentage = `${Math.trunc(
          (currentCoverageMenuTableData[layer][largestDistance] / totalHexagonsCount[layer]) * 100
        )}%`;
      });

      return currentCoverageMenuTableData;
    }

    return null;
  };

  getActiveMapPointsIconArray = () => {
    const toggledFeaturesAffectingCoverage = Object.keys(MAP_ADDITIONAL_FEATURES_COVERAGE_MENU).filter((key) => {
      return !!(Object.keys(MAP_ADDITIONAL_FEATURES_TOGGLES).includes(key) && this.props.mapFeaturesActive[MAP_ADDITIONAL_FEATURES_TOGGLES[key]]);
    });

    const mapPointCounts = {};

    Object.values(this.props.mapPointsStaticData).forEach((mapPoint) => {
      if (mapPointCounts[mapPoint.type]) {
        mapPointCounts[mapPoint.type] += 1;
      } else {
        mapPointCounts[mapPoint.type] = 1;
      }
    });

    return toggledFeaturesAffectingCoverage.map((mapPoint) => {
      const mapPointKey = MAP_ADDITIONAL_FEATURES_TOGGLES[mapPoint];
      if (mapPointCounts[mapPointKey]) return (<MapPointCountIcon key={mapPointKey} count={mapPointCounts[mapPointKey]} imageName={mapPointKey} />);
      return null;
    }).filter((mapPointCount) => mapPointCount !== null);
  };

  calculateEstimatedCoverageMenuTableData = (newSelectedHexagonsMap) => {
    const coverageMenuTableData = {};

    if (!_.isEmpty(this.props.disabledPoints) || !_.isEmpty(this.props.distancePinsIsochroneData)) {
      const totalHexagonsCount = {};
      this.userLayers.forEach((layer) => {
        coverageMenuTableData[layer] = {};
        DISTANCE_VALUE_TYPE_KEYS_ARRAY.forEach((polygonType) => {
          coverageMenuTableData[layer][polygonType] = 0;
        });

        totalHexagonsCount[layer] = 0;

        Object.values(this.props.hexLayersCountMap).forEach((hexId) => {
          totalHexagonsCount[layer] += hexId[layer];
        });
      });

      const largestDistance = DistanceTypeUtil.getLargestDistanceKey();
      Object.entries(newSelectedHexagonsMap).forEach(([hexId, hexagonsCovered]) => {
        Object.keys(hexagonsCovered).forEach((polygonType) => {
          this.userLayers.forEach((layer) => {
            if (this.props.hexLayersCountMap[hexId] && hexagonsCovered[polygonType] > 0) {
              coverageMenuTableData[layer][polygonType] += this.props.hexLayersCountMap[hexId][layer];
            }
          });
        });
      });

      this.userLayers.forEach((layer) => {
        coverageMenuTableData[layer].percentage = `${Math.trunc((coverageMenuTableData[layer][largestDistance] / totalHexagonsCount[layer]) * 100)}%`;
      });
    }

    return coverageMenuTableData;
  };

  render() {
    return (
      <div className="coverage-menu-container">
        <div className="coverage-menu">
          <div className="coverage-menu-title">
            {this.props.t(`${this.props.hexType} data`)}
          </div>
          <div className="coverage-map-points-list">
            {this.props.t('Coverage')}
            <div className="visible-coverage-point-array">
              {this.getActiveMapPointsIconArray()}
            </div>
          </div>
          <div className="coverage-menu-table-titles">
            <div className="coverage-menu-table-title">{this.props.t('current')}</div>
            <div className="coverage-menu-table-title">{this.props.t('estimated')}</div>
          </div>
          <div className="coverage-data-tables">
            <CoverageDataTable
              coverageType={COVERAGE_DATA_TABLE_TYPES.CURRENT}
              coverageMenuTableData={this.state.currentCoverageMenuTableData && this.state.currentCoverageMenuTableData[this.props.hexType]}
              distanceType={this.props.distanceType}
            />
            <CoverageDataTable
              coverageType={COVERAGE_DATA_TABLE_TYPES.ESTIMATED}
              coverageMenuTableData={this.state.coverageMenuTableData && this.state.coverageMenuTableData[this.props.hexType]}
              distanceType={this.props.distanceType}
            />
          </div>
          {this.props.pointImpactList && !_.isEmpty(this.props.pointImpactList) && (
            <div className="impactful-points expanded">
              <div className="animation-container">
                <ImpactfulLocationsList
                  impactType={IMPACT_TABLE_TYPES.MOST}
                  pointImpactList={this.props.pointImpactList}
                  mapPointsStaticData={this.props.mapPointsStaticData}
                />
                <ImpactfulLocationsList
                  impactType={IMPACT_TABLE_TYPES.LEAST}
                  pointImpactList={this.props.pointImpactList}
                  mapPointsStaticData={this.props.mapPointsStaticData}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return { ...store.regionAnalysisState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @param {object} ownProps - commponent props
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchUpdateRegionStopsDistribution: ownProps.updateRegionStopsDistributionAction,
      dispatchSetMap: MapActions.setMap,
      dispatchUpdateCoverageMenuTable: RegionAnalysisActions.updateCoverageMenuTable,
      dispatchUpdatePointImpactList: RegionAnalysisActions.updatePointImpactList,
      dispatchCoordinates: CoordinatesActions.coordinatesChange
    },
    dispatch
  );
}

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(CoverageMenuClass));

CoverageMenuClass.propTypes = {
  hexType: PropTypes.string,
  distanceType: PropTypes.string.isRequired,
  mapFeaturesActive: PropTypes.object,
  hexLayersCountMap: PropTypes.object,
  mapPointsStaticData: PropTypes.object,
  mapPointsIsochroneData: PropTypes.object,
  mapPointsVisibilityData: PropTypes.object,
  disabledPoints: PropTypes.object,
  distancePinsIsochroneData: PropTypes.object,
  pointImpactList: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

CoverageMenuClass.defaultProps = {
  hexType: null,
  mapFeaturesActive: null,
  hexLayersCountMap: null,
  mapPointsStaticData: null,
  mapPointsIsochroneData: null,
  mapPointsVisibilityData: null,
  disabledPoints: null,
  distancePinsIsochroneData: null
};
