import React from 'react';
import DeckGL from '@deck.gl/react';
import { EditableH3ClusterLayer } from '@nebula.gl/layers';
import './RegionSelectionPage.scss';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import geojson2h3 from 'geojson2h3';
import { toast } from 'react-toastify';
import { GeoJsonLayer } from '@deck.gl/layers';
import { renderToString } from 'react-dom/server';
import csv from 'jquery-csv';
import * as h3 from 'h3-js';
import hull from 'hull.js/src/hull';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import ShipmentsUtil from '../../../../common/utils/shipmentsUtil';
import TenantUtil from '../../../../common/utils/tenantUtil';
import { raygunClient } from '../../../../setup/raygunClient';
import * as PageActions from '../../../../state/actions/pageActions';
import * as DialogActions from '../../../../state/actions/dialogActions';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import S3Util from '../../../../common/utils/s3Util';
import RegionDistributionChart from '../components/RegionDistributionChart';
import MapMarkerPopup from '../../../../common/components/popups/components/MapMarkerPopup';
import StepInfoHeader from '../components/StepInfoHeader';
import * as PlanningPageActions from '../../../../state/actions/planningPageActions';
import PlanningPageUtil from '../../utils/planningPageUtil';
import add from '../../../../resources/add.png';
import eraser from '../../../../resources/eraser.png';
import '../../../../resources/colors-and-fonts.scss';
import DrawHexagonsUtil from '../../../../common/components/buttons/mapModeButton/utils/drawHexagonsUtil';
import MapModeButton from '../../../../common/components/buttons/mapModeButton/MapModeButton';
import HexSelectionToolsMenu from '../../../../common/components/buttons/mapModeButton/components/HexSelectionToolsMenu';
import ClusteringIcons from '../components/ClusteringIcons';
import BackendResourceConfigUtil from '../../../../common/utils/api/backendResourceConfigUtil';
import PlanningApi from '../../api/planningApi';
import { H3_HEX_RESOLUTION, INITIAL_VIEW_STATE } from '../../../../common/constants/mapConstants';
import { EDIT_MODE, SELECTION_TOOL } from '../../../../common/components/buttons/mapModeButton/constants/drawHexagonsConst';
import Button from '../../../../common/components/buttons/button/Button';
import CardMenuTable from '../../../../common/components/tables/cardMenuTable/CardMenuTable';
import TableActions from '../../../../common/components/tables/common/TableActions';
import RegionCard from '../components/RegionCard';
import RegionForm from '../components/RegionForm';
import DepartureForm from '../components/DepartureForm';
import MapUtil from '../../../../common/utils/mapUtil';
import { BUTTON_TYPES } from '../../../../common/components/buttons/button/constants/buttonTypes';

const HEX_RES = 10;

const CSV_SEPARATOR = ';';

const CLUSTERING_ALGORITHM_FILE_SUFFIX = 'k_means';

/**
 * Region Selection Wrapper provides all functionalities needed region selection page
 *
 * @component
 * @alias RegionSelectionPage
 * @category planning
 * @returns {React.Component} RegionSelection page
 */
class RegionSelectionPageClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectionTool: SELECTION_TOOL.PICK,
      regionIds: [],
      selectedRegionId: 0,
      selectedDeliveryAreaName: props?.history?.location?.state?.deliveryAreaId,
      regionClusters: [],
      editMode: 'union',
      hullLevel: 0.5, // 0.02
      tablePageSize: 1,
      allShipmentsCovered: false,
      hoveredRegionId: null,
      showChart: false,
      chartData: []
    };

    this.defaultView = MapUtil.getInitialViewStateForCompany();
    this.polyData = [];
    this.currentViewState = null;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.clusteredStops = null;
    this.shipmentsHexIds = {};
  }

  componentDidMount() {
    // TODO this feature is disabled for now
    // let fileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${this.state.selectedDeliveryAreaName}/${this.state.selectedDeliveryAreaName}_region_hexagons.json`;
    // fileName = TenantUtil.addTenantToFileName(fileName);
    // this.loadRegionsData(fileName).then(data => {
    //   this.shipmentsHexIds = {};
    //   if (this.props.shipmentsData && this.props.shipmentsData.length > 0) {
    //     this.props.shipmentsData.forEach(shipment => {
    //       if (shipment.lat && shipment.lng) {
    //         const hexId = h3.geoToH3(shipment.lat, shipment.lng, 10);
    //         if (!this.shipmentsHexIds[hexId]) {
    //           const region = this.findHexIdInRegionsData(data, hexId);
    //           this.shipmentsHexIds[hexId] = {
    //             covered: !!region,
    //             stops: 0,
    //             labels: region ? region.regionId : -1
    //           };
    //         }
    //
    //         this.shipmentsHexIds[hexId].stops += 1;
    //       }
    //     });
    //     this.createStopsDistributionChartData();
    //   }
    this.clearPolygons();
    this.props.dispatchLoadingPage(false);
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.regionClusters
      && this.state.regionClusters.length > 0
      && this.state.regionClusters !== prevState.regionClusters
      && !this.props.showNextAlertDialog
    ) {
      this.props.dispatchDataChanged(true);
    }
  }

  findHexIdInRegionsData = (data, hexId) => data.find((regionData) => regionData.hexIds.includes(hexId));

  /**
   * Get regions data
   *
   * @function
   */
  loadRegionsData = async (fileName) => {
    let data;
    return S3Util.getFileFromS3(fileName, {
      download: true,
      bucket: BackendResourceConfigUtil.getRegionDataBucketName(),
      cacheControl: 'no-cache'
    }).then((string) => {
      data = JSON.parse(string);

      const regionIds = [];
      data.forEach((region) => {
        if (!region.name) {
          // eslint-disable-next-line no-param-reassign
          region.name = region.regionId;
        }

        // eslint-disable-next-line no-param-reassign
        region.regionId = region.regionId.match(/\d+/)[0];

        regionIds.push(region.regionId);
      });

      this.setState({ regionIds: regionIds, selectedRegionId: regionIds[0] });

      this.props.dispatchSaveRegionsData(data);

      return data;
    });
  };

  createRegionHexFile = (fileName1) => {
    const featureFile = [];
    this.props.regionsData.forEach((regionData) => {
      const hexagonFeatureCollection = geojson2h3.h3SetToFeature(regionData.hexIds, { title: regionData.regionId });
      featureFile.push(hexagonFeatureCollection);
    });

    let fileName = fileName1
      || `${process.env.REACT_APP_LATEST_DELIVERY_AREA_FILE_PATH}/${this.state.selectedDeliveryAreaName}/${this.state.selectedDeliveryAreaName}_regions.json`;
    fileName = TenantUtil.addTenantToFileName(fileName);

    return S3Util.uploadFileToS3(fileName, featureFile, { contentType: 'application/json', bucket: BackendResourceConfigUtil.getRegionDataBucketName() });
  };

  finishRegionSelection = (direction) => {
    const planDate = this.props?.history?.location?.state?.planDate;

    if (process.env.REACT_APP_DATA_SOURCE === 'api') {
      this.props.dispatchLoadingPage(true);
      let latestFileName = `${process.env.REACT_APP_LATEST_DELIVERY_AREA_FILE_PATH}/${this.state.selectedDeliveryAreaName}/${this.state.selectedDeliveryAreaName}_region_hexagons.json`;
      latestFileName = TenantUtil.addTenantToFileName(latestFileName);
      let fileName = `delivery-area/${this.state.selectedDeliveryAreaName}/${planDate}_${this.state.selectedDeliveryAreaName}_region_hexagons.json`;
      fileName = TenantUtil.addTenantToFileName(fileName);

      const file = this.createRegionsJsonFile();

      const filePromise = S3Util.uploadFileToS3(fileName, file, {
        contentType: 'application/json',
        bucket: BackendResourceConfigUtil.getRegionDataBucketName()
      });
      const latestFilePromise = S3Util.uploadFileToS3(latestFileName, file, {
        contentType: 'application/json',
        bucket: BackendResourceConfigUtil.getRegionDataBucketName()
      });
      const regionsPromise = this.createRegionHexFile();

      Promise.all([filePromise, latestFilePromise, regionsPromise])
        .then((res) => {
          const [fileRes, latestFileRes, regionRes] = res;
          if (fileRes?.key && latestFileRes?.key && regionRes?.key) {
            PlanningApi.generateHexMapping(latestFileName)
              .then(() => {
                this.nextPage(direction, latestFileName);
              })
              .catch((error) => {
                this.props.dispatchLoadingPage(false);
                toast.error(this.props.t('Failed to save regions data. Please try again.'));
                raygunClient.send(error, 'Failed to save regions data');
              });
          } else {
            toast.error(this.props.t('Failed to save regions data. Please try again.'));
            this.props.dispatchLoadingPage(false);
            raygunClient.send(`Failed to save regions data. Reason: We are missing a key in response. Response: ${res}`);
          }
        })
        .catch((err) => {
          this.props.dispatchLoadingPage(false);
          toast.error(this.props.t('Failed to save regions data. Please try again.'));
          raygunClient.send(err, 'Failed to save regions data');
        });
    } else {
      this.nextPage(direction);
    }
  };

  nextPage = (direction, regionHexagonsFileKey) => {
    const oldState = this.props?.history?.location?.state;
    oldState.regionsFromOtherPage = true;
    if (direction) {
      this.props.dispatchLoadingPage(false);
      this.props.history.push({
        pathname: '/delivery-plan/route-view',
        state: {
          deliveryAreaId: this.state.selectedDeliveryAreaName,
          previousViewState: this.currentViewState,
          planDate: oldState.planDate,
          startHub: this.startHub,
          endHub: this.endHub,
          regionHexagonsFileKey: regionHexagonsFileKey
        }
      });
    } else this.props.history.push({ pathname: '/delivery-plan/table-view', state: oldState });
  };

  // TODO this feature is disabled for now
  // eslint-disable-next-line react/no-unused-class-component-methods
  saveAsDefault = () => {
    if (process.env.REACT_APP_DATA_SOURCE === 'api') {
      this.props.dispatchLoadingPage(true);
      let defaultFileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${this.state.selectedDeliveryAreaName}/${this.state.selectedDeliveryAreaName}_region_hexagons_optimized_24.json`;
      defaultFileName = TenantUtil.addTenantToFileName(defaultFileName);

      const file = this.createRegionsJsonFile();

      const defaultFilePromise = S3Util.uploadFileToS3(defaultFileName, file, {
        contentType: 'application/json',
        bucket: BackendResourceConfigUtil.getRegionDataBucketName()
      });

      const hexFileName = `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${this.state.selectedDeliveryAreaName}/${this.state.selectedDeliveryAreaName}_regions_optimized_24.json`;
      const regionsPromise = this.createRegionHexFile(hexFileName);

      Promise.all([defaultFilePromise, regionsPromise])
        .then((res) => {
          const [defaultFileRes, regionRes] = res;
          if (defaultFileRes?.key && regionRes?.key) {
            PlanningApi.generateHexMapping(defaultFileName)
              .then(() => {
                this.props.dispatchLoadingPage(false);
              })
              .catch((error) => {
                this.props.dispatchLoadingPage(false);
                toast.error(this.props.t('Failed to save regions data. Please try again.'));
                raygunClient.send(error, 'Failed to generate hex mapping');
              });
          } else {
            raygunClient.send(`Failed to save regions data. Reason: We are missing a key in response. Response: ${res}`);
            toast.error(this.props.t('Failed to save regions data. Please try again.'));
            this.props.dispatchLoadingPage(false);
          }
        })
        .catch((err) => {
          this.props.dispatchLoadingPage(false);
          toast.error(this.props.t('Failed to save regions data. Please try again.'));
          raygunClient.send(err, 'Failed to save regions data');
        });
    }
  };

  /**
   * Create athena compatible json file from selected regions
   *
   * @returns {string} file content
   * @function
   */
  createRegionsJsonFile = () => {
    const fileJson = [];
    this.props.regionsData.forEach((region) => {
      const regionData = {
        regionId: region.regionId,
        name: region.name,
        hexIds: region.hexIds
      };
      fileJson.push(regionData);
    });

    return JSON.stringify(fileJson);
  };

  /**
   * Change selection tool type
   *
   * @param {object} opt - selected option object (value, label)
   * @function
   */
  changeSelectionTool = (opt) => {
    this.setState({ selectionTool: opt.currentTarget.dataset.value });
  };

  /**
   * Change selected region
   *
   * @param {object} opt - selected option object (value, label)
   * @function
   */
  changeRegion = (opt) => {
    if (!opt) {
      return;
    }
    this.setState({ selectedRegionId: opt.regionId, hoveredRegionId: opt.regionId });
  };

  highlightRegionOn = (opt) => {
    if (!opt) {
      return;
    }
    this.setState({ hoveredRegionId: opt.regionId });
  };

  highlightRegionOff = () => {
    this.setState({ hoveredRegionId: null });
  };

  /**
   * Change edit mode
   *
   * @param {object } e - JsEvent
   * @function
   */
  changeEditMode = (e) => {
    this.setState({ editMode: e.currentTarget.dataset.value });
  };

  /**
   * Get h3 editable layer
   *
   * @returns {object} h3 layer
   *
   * @function
   */
  getH3Layer = () => {
    const selectedIndex = this.state.regionIds.indexOf(this.state.selectedRegionId) || 0;

    return new EditableH3ClusterLayer({
      id: 'h3-cluster-layer',
      data: this.props.regionsData,
      getHexagons: (d) => (d && d.hexIds ? d.hexIds : []),
      modeConfig: { booleanOperation: this.state.editMode },
      mode: DrawHexagonsUtil.getSelectionMode(this.state.selectionTool),
      resolution: H3_HEX_RESOLUTION,
      selectedIndexes: [selectedIndex],
      getEditedCluster: (updatedHexagonIDs, existingCluster) => {
        let difference;
        if (this.state.editMode === EDIT_MODE.ADD) {
          difference = updatedHexagonIDs.filter((x) => !existingCluster.hexIds.includes(x));
        } else {
          difference = existingCluster.hexIds.filter((x) => !updatedHexagonIDs.includes(x));
        }
        this.updateClusteredStops(difference);
        if (existingCluster) {
          return {
            ...existingCluster,
            hexIds: updatedHexagonIDs,
            regionId: this.state.selectedRegionId
          };
        }
        return {
          hexIds: updatedHexagonIDs,
          regionId: this.state.selectedRegionId
        };
      },
      onEdit: ({ updatedData }) => {
        if (updatedData !== this.props.regionsData) {
          this.props.dispatchSaveRegionsData(updatedData);
          this.createStopsDistributionChartData();
        }
      },

      _subLayerProps: {
        'tentative-hexagons': {
          getFillColor: () => {
            if (this.state.editMode === EDIT_MODE.ADD) {
              const color = PlanningPageUtil.getColorFromRegionId(this.state.selectedRegionId);
              return PlanningPageUtil.hexToRGB(color, this.state.selectedRegionId != null);
            }

            return [0, 0, 0, 200];
          },
          lineWidthScale: 0
        },
        hexagons: {
          getFillColor: (cluster) => {
            const color = PlanningPageUtil.getColorFromRegionId(cluster.regionId);
            const isNotTransparent = cluster.regionId === this.state.hoveredRegionId ? false : this.state.selectedRegionId != null;

            return PlanningPageUtil.hexToRGB(color, isNotTransparent);
          },
          updateTriggers: { getFillColor: [this.state.hoveredRegionId] },
          lineWidthScale: 0
        }
      }
    });
  };

  updateClusteredStops(hexIds) {
    const newShipmentsHexIds = this.shipmentsHexIds;
    hexIds.forEach((hexId) => {
      if (this.shipmentsHexIds && this.shipmentsHexIds[hexId]) {
        newShipmentsHexIds[hexId] = {
          stops: newShipmentsHexIds[hexId].stops,
          covered: this.state.editMode === EDIT_MODE.ADD,
          labels: this.state.editMode === EDIT_MODE.ADD ? this.state.selectedRegionId : -1
        };
      }
    });

    this.shipmentsHexIds = newShipmentsHexIds;
  }

  getShipmentsLayer = () => {
    const shipmentsFeatureCollection = {
      type: 'FeatureCollection',
      features: []
    };

    this.props.shipmentsData.forEach((shipment) => {
      const feature = {
        type: 'Feature',
        properties: {
          ...ShipmentsUtil.addClassNameBasedOnDeliveryType(shipment),
          keyOrder: ['shipmentCode', 'name', 'address', 'phone']
        },
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(shipment.lng), parseFloat(shipment.lat)]
        }
      };

      shipmentsFeatureCollection.features.push(feature);
    });

    return new GeoJsonLayer({
      id: 'geojson-layer',
      data: shipmentsFeatureCollection,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: true,
      getFillColor: [169, 49, 199, 256],
      getPointRadius: 5,
      pointRadiusUnits: 'pixels'
    });
  };

  findOptimalRoute = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: DepartureForm,
      dialogComponentProps: {
        title: this.props.t('Select hub'),
        submitCallback: (data) => {
          this.startHub = data.startHub;
          this.endHub = data.endHub;
          this.finishRegionSelection(true);
        }
      }
    });
  };

  getCursor = () => {
    if (this.state.editMode === EDIT_MODE.ADD) {
      return `url(${add}) 5 15, pointer`;
    }

    return `url(${eraser}) 5 20, pointer`;
  };

  handleViewStateChange = ({ viewState: currentViewState }) => {
    this.currentViewState = currentViewState;
  };

  onMessage = (e) => {
    if (e.data) {
      const response = JSON.parse(e.data);

      if (response && response.clientData) {
        this.clusteringFlow(response.clientData);
      } else if (response && response.error) {
        raygunClient.send(response.error, 'Error reading websocket message');
        toast.error(this.props.t('Oops, something went wrong'));
      }
    }

    if (this.ws) {
      this.ws.close();
    }
  };

  triggerClustering = async (numberOfClusters) => {
    if (numberOfClusters > 0) {
      this.props.dispatchLoadingPage(true);
      const fileName = this.props.shipmentsFileName;
      const planDate = this.props?.history?.location?.state?.planDate;
      let outFileName = `clustering/${planDate}/${this.state.selectedDeliveryAreaName}/${planDate}_${this.state.selectedDeliveryAreaName}_${numberOfClusters}_${CLUSTERING_ALGORITHM_FILE_SUFFIX}.csv`;
      outFileName = TenantUtil.addTenantToFileName(outFileName);
      if (process.env.REACT_APP_DATA_SOURCE === 'api') {
        // Thesis back-testing flow
        // this.loadClustersData(outFileName)
        //   .then(() => {
        //     this.props.dispatchLoadingPage(false);
        //   })
        //   .catch(() => {
        //     PlanningPageUtil.connectToWebSocket(fileName, this.onMessage)
        //       .then(wsRes => {
        //         this.ws = wsRes;
        //         PlanningApi.triggerClustering(fileName, outFileName, numberOfClusters, ';');
        //       })
        //       .catch(e => {
        //         console.log(e);
        //       });
        //   });

        // Old flow
        ApiHelpers.connectToWebSocket(fileName, this.onMessage)
          .then((wsRes) => {
            this.ws = wsRes;
            PlanningApi.triggerClustering(fileName, outFileName, numberOfClusters, ';');
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.loadRegionsData(`clustering/${numberOfClusters}_clusters_region_hexagons.json`).then(() => {
          this.props.dispatchLoadingPage(false);
        });
      }
    } else {
      toast.error(this.props.t('Number of regions must be 1 or higher'));
    }
  };

  clusteringFlow = async (fileName) => {
    await this.loadClustersData(fileName);
    this.createHull();
    this.fillPolyWithHex();
    this.props.dispatchLoadingPage(false);
  };

  loadClustersData = async (fileName) => {
    // fileName = 'dexpress/multiple-k-means-bc/output/bc-kmeans-test.csv';
    return S3Util.getFileFromS3(fileName, {
      download: true,
      bucket: BackendResourceConfigUtil.getPlanningBucketName(),
      contentType: 'application/json',
      cacheControl: 'no-cache'
    }).then((content) => {
      const clusteredStops = csv.toObjects(content, { separator: CSV_SEPARATOR });

      return this.createClusteredRegions(clusteredStops);
    });
  };

  createStopsDistributionChartData = () => {
    const chartData = {};
    if (!this.shipmentsHexIds) {
      return chartData;
    }

    Object.keys(this.shipmentsHexIds).forEach((hexId) => {
      const stop = this.shipmentsHexIds[hexId];
      if (stop.labels !== -1) {
        if (!chartData[stop.labels]) {
          chartData[stop.labels] = {
            value: 0,
            regionId: stop.labels,
            color: PlanningPageUtil.getColorFromRegionId(stop.labels.toString())
          };
        }

        chartData[stop.labels].value += parseInt(stop.stops, 10);
      }
    });

    return this.setState({ chartData: Object.values(chartData) });
  };

  createClusteredRegions = (clusteredStops) => {
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.clusteredStops = clusteredStops;
    let coveredShipments = 0;
    this.clearAllCoveredShipments();

    const newRegionsData = [];
    clusteredStops.forEach((stop) => {
      const hex = stop.hexId || h3.geoToH3(stop.lat, stop.lng, HEX_RES);

      if (!newRegionsData[stop.labels]) {
        newRegionsData[stop.labels] = {
          regionId: stop.labels,
          hexIds: new Set()
        };
      }

      newRegionsData[stop.labels].hexIds.add(hex);

      // for k-means clustering flow
      if (!this.shipmentsHexIds[hex]) {
        this.shipmentsHexIds[hex] = {
          covered: true,
          stops: 1,
          labels: stop.labels
        };
      } else {
        this.shipmentsHexIds[hex].stops += 1;
      }

      // Thesis flow
      // if (this.shipmentsHexIds[hex]) {
      //   this.shipmentsHexIds[hex].labels = stop.labels;
      //   this.shipmentsHexIds[hex].covered = true;
      // }
      coveredShipments += this.shipmentsHexIds[hex].stops || 1;
    });

    newRegionsData.forEach((region) => {
      // eslint-disable-next-line no-param-reassign
      region.hexIds = Array.from(region.hexIds);
    });

    this.setState({
      regionIds: Object.keys(newRegionsData),
      selectedRegionId: newRegionsData[0].regionId,
      showChart: true,
      allShipmentsCovered: coveredShipments === this.props.shipmentsData.length
    });

    newRegionsData.forEach((el, index) => {
      newRegionsData[index].name = this.getRegionName(index);
    });

    this.props.dispatchSaveRegionsData(newRegionsData);

    this.createStopsDistributionChartData();

    return true;
  };

  createHull = () => {
    const polyData = [];
    this.props.regionsData.forEach((data, index) => {
      const points = [];
      this.props.regionsData[index].hexIds.forEach((hexId) => {
        const pts = h3.h3ToGeoBoundary(hexId, true);
        points.push(...pts);
      });
      const clusterPolygon = { polygonPoints: [] };
      clusterPolygon.polygonPoints.push(hull(points, this.state.hullLevel));
      polyData.push(clusterPolygon);
    });

    this.polyData = polyData;
  };

  fillPolyWithHex = () => {
    const newRegionsData = [...this.props.regionsData];
    this.polyData.forEach((poly, index) => {
      const newHexIds = h3.polyfill(poly.polygonPoints, HEX_RES, true);
      newRegionsData[index].hexIds = newHexIds;
    });

    this.props.dispatchSaveRegionsData(newRegionsData);
  };

  clearAllCoveredShipments = () => {
    if (this.shipmentsHexIds) {
      Object.keys(this.shipmentsHexIds).forEach((hexId) => {
        this.shipmentsHexIds[hexId].covered = false;
        this.shipmentsHexIds[hexId].labels = -1;
      });

      this.setState({ chartData: [] });
    }
  };

  clearPolygons = () => {
    this.props.dispatchSaveRegionsData([]);

    this.clearAllCoveredShipments();
    this.setState({ regionIds: [], selectedRegionId: null, allShipmentsCovered: false });

    this.polyData = [];
  };

  loadDefaults = () => {
    let fileName = process.env.REACT_APP_DATA_SOURCE === 'api'
      ? `${process.env.REACT_APP_DEFAULT_DELIVERY_AREA_FILE_PATH}/${this.state.selectedDeliveryAreaName}/${this.state.selectedDeliveryAreaName}_region_hexagons.json`
      : `latest/delivery-area/${this.state.selectedDeliveryAreaName}/${this.state.selectedDeliveryAreaName}_region_hexagons.json`;
    fileName = TenantUtil.addTenantToFileName(fileName);
    this.loadRegionsData(fileName).then((data) => {
      Object.keys(this.shipmentsHexIds).forEach((hexId) => {
        const region = this.findHexIdInRegionsData(data, hexId);
        this.shipmentsHexIds[hexId].covered = !!region;
        this.shipmentsHexIds[hexId].labels = region ? region.regionId : -1;
      });

      this.createStopsDistributionChartData();
      this.props.dispatchLoadingPage(false);
    });
  };

  getTableColumns = () => [
    {
      Header: null,
      accessor: 'name' // accessor is the "key" in the data
    },
    {
      Header: null,
      id: 'actions',
      disableGlobalFilter: true,
      className: 'centered',
      Cell: ({ row }) => <TableActions type="card" row={row} onEdit={this.editRegion} onDelete={this.deleteRegion} />
    }
  ];

  calculateTablePageSize = (height) => {
    if (this.state.tablePageSize !== height) {
      this.setState({ tablePageSize: height });
    }
  };

  getRegionName = (index) => {
    const ret = this.props.t('New region');
    return index === 0 ? ret : `${ret} (${index.toString()})`;
  };

  lowestIndex = () => {
    const regions = [...this.props.regionsData];
    let min = 0;
    let usedDefaultNamesIndexes = [];

    const i = this.props.t('New region');
    const regex = new RegExp('^'.concat(i.concat(' \\(\\d+\\)$')));

    regions.forEach((region) => {
      if (regex.test(region.name)) {
        usedDefaultNamesIndexes.push(parseInt(region.name.match(/\d+/), 10));
      } else if (region.name === this.props.t('New region')) min++;
    });

    usedDefaultNamesIndexes = usedDefaultNamesIndexes.sort((a, b) => a - b);

    usedDefaultNamesIndexes.every((num) => {
      if (num === min) {
        min++;
        return true;
      }
      return num <= min;
    });

    return min;
  };

  getAvailableColor = () => {
    let regions = [...this.props.regionsData];
    let min = 0;

    regions = regions.sort((a, b) => parseInt(a.regionId, 10) - parseInt(b.regionId, 10));

    regions.every((region) => {
      if (parseInt(region.regionId, 10) === min) {
        min++;
        return true;
      }
      return parseInt(region.regionId, 10) <= min;
    });

    return min.toString();
  };

  numberToStringWithParanthesis = (num) => ` (${num.toString()})`;

  addRegion = () => {
    this.props.dispatchLoadingPage(true);
    const placeToPut = this.lowestIndex();
    const newId = this.getAvailableColor();
    const name = `${this.props.t('New region')}${placeToPut === 0 ? '' : this.numberToStringWithParanthesis(placeToPut)}`;
    this.setState((prevState) => ({
      regionIds: [...prevState.regionIds, newId],
      selectedRegionId: newId
    }));
    this.props.dispatchSaveRegionsData([...this.props.regionsData, { regionId: newId, hexIds: [], name: name }]);
    this.props.dispatchLoadingPage(false);
  };

  editRegion = (row) => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: RegionForm,
      dialogComponentProps: {
        title: this.props.t('Edit region'),
        regionName: row.values.name,
        submitCallback: (data) => {
          const regionDataAr = [...this.props.regionsData];

          const ind = regionDataAr.findIndex((region) => region.regionId === row.index.toString());
          regionDataAr[ind].name = data.regionName;

          this.props.dispatchSaveRegionsData([...regionDataAr]);
        }
      }
    });
  };

  deleteRegion = (row) => {
    AppDialogActionsWrapper.openConfirmationDialog({
      title: `${this.props.t('Delete region')}?`,
      body: `${this.props.t('Region data will be removed')}.`,
      confirmButtonText: this.props.t('Delete'),
      continueCallback: () => {
        const updateRegionsData = (prevState) => {
          const arIds2 = [...prevState.regionIds];

          const index2 = arIds2.indexOf(row.original.regionId);

          if (index2 > -1) arIds2.splice(index2, 1);

          if (row.original.regionId === this.state.selectedRegionId && this.props.regionsData.length > 0) {
            return {
              regionIds: arIds2,
              selectedRegionId: arIds2.length > 0 ? arIds2[index2 === arIds2.length ? index2 - 1 : index2] : null
            };
          }
          return { regionIds: arIds2, selectedRegionId: null };
        };
        this.setState(updateRegionsData);

        if (this.props.regionsData) {
          const index = this.props.regionsData.findIndex((region) => region && region.regionId === row.original.regionId);
          if (index > -1) this.props.regionsData.splice(index, 1);
          this.props.dispatchSaveRegionsData([...this.props.regionsData]);
        }
      }
    });
  };

  returnClick = () => {
    this.nextPage(false);
  };

  toggleChart = () => {
    this.setState((oldState) => ({ showChart: !oldState.showChart }));
  };

  render() {
    let layers = [];
    if (!this.props.isPageLoading) {
      layers = [this.getH3Layer()];

      if (this.props.shipmentsData && this.props.shipmentsData.length > 0) {
        layers.push(this.getShipmentsLayer());
      }
    }

    return (
      <div className="region-selection-wrapper">
        {this.state.showChart && (
          <div className="chart-wrapper">
            <RegionDistributionChart regionDistribution={this.state.chartData} isChartVisible />
          </div>
        )}
        <StepInfoHeader
          message={this.props.t('regionSelectionStepMessage')}
          onNextClick={this.findOptimalRoute}
          nextButtonText={this.props.t('Find optimal route')}
          regionSelection={this.shipmentsHexIds}
          regionSelectionLength={this.props.shipmentsData ? this.props.shipmentsData.length : 0}
          regionsData={this.props.regionsData}
          options={{ nextButtonWithOpacity: !this.state.allShipmentsCovered }}
          onReturnClick={this.returnClick}
        />

        <div className="main-content">
          <div className="menu-wrapper">
            <div className="menu">
              <div className="clustering-menu">
                <ClusteringIcons triggerClustering={this.triggerClustering} clearPolygons={this.clearPolygons} loadDefaults={this.loadDefaults} />
              </div>

              <Button text={this.props.t('Add new region')} type={BUTTON_TYPES.PRIMARY} onClick={this.addRegion} />
              <div className="table-wrapper">
                {this.props.regionsData.length > 0 && (
                  <CardMenuTable
                    key={this.state.tablePageSize}
                    columns={this.getTableColumns()}
                    data={this.props.regionsData}
                    sortBy={[{ id: 'name', desc: false }]}
                    showSearch
                    handleRowClick={this.changeRegion}
                    handleRowOnMouseEnter={this.highlightRegionOn}
                    handleRowOnMouseLeave={this.highlightRegionOff}
                    cardItemComponent={RegionCard}
                    pageSize={this.state.tablePageSize}
                    calculateTablePageSize={this.calculateTablePageSize}
                    defaultPageSize={this.state.tablePageSize}
                    selectedIndex={this.state.selectedRegionId}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="map-wrapper">
            <div className="controls-wrapper">
              <MapModeButton
                customKey="edit"
                isActive={this.state.editMode === EDIT_MODE.ADD}
                dataTip={this.props.t('Add hexagons')}
                dataValue={EDIT_MODE.ADD}
                onClick={this.changeEditMode}
                icon="icon-edit"
              />
              <MapModeButton
                customKey="erase"
                isActive={this.state.editMode === EDIT_MODE.REMOVE}
                dataTip={this.props.t('Remove hexagons')}
                dataValue={EDIT_MODE.REMOVE}
                onClick={this.changeEditMode}
                icon="icon-eraser"
              />
              <HexSelectionToolsMenu changeSelectionTool={this.changeSelectionTool} />
              <MapModeButton
                customKey="chart"
                isActive={false}
                dataTip={this.props.t('Show chart')}
                onClick={this.toggleChart}
                icon="icon-bar-chart"
              />
            </div>
            {/* FIXME this feature is disabled for now */}
            {/* <div className="save-default" onClick={this.saveAsDefault}> */}
            {/*  {this.props.t('Save as optimized')} */}
            {/* </div> */}
            <div className="regions-map-wrapper">
              <DeckGL
                initialViewState={{ ...INITIAL_VIEW_STATE, latitude: this.defaultView.lat, longitude: this.defaultView.lng }}
                controller
                layers={layers}
                height="calc(100vh - 55px)"
                width="100%"
                getTooltip={({ object }) => {
                  if (object?.properties?.address) {
                    return {
                      html: renderToString(<MapMarkerPopup data={object.properties} />),
                      style: {
                        backgroundColor: '#0B1A2D',
                        padding: 0
                      }
                    };
                  }
                  return null;
                }}
                getCursor={this.getCursor}
                onViewStateChange={this.handleViewStateChange}
              >
                {/* <StaticMap mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} mapStyle={MAP_STYLE} /> */}
              </DeckGL>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} store state
 */
function mapStateToProps(store) {
  return { ...store.pageState, ...store.saveChangesDialogState, ...store.planningPageState };
}

/**
 * @param {object} dispatch - redux store
 * @returns {object} - actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchDataChanged: DialogActions.showNextAlertDialog,
      dispatchSaveRegionsData: PlanningPageActions.saveRegionsData
    },
    dispatch
  );
}

RegionSelectionPageClass.propTypes = {
  /**
   * Dispatch if page is loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Is page loading
   */
  isPageLoading: PropTypes.bool,
  /**
   * Set unsaved changes for alert dialog
   */
  dispatchDataChanged: PropTypes.func.isRequired,

  /**
   * True if there was a change in regions data
   */
  showNextAlertDialog: PropTypes.bool,
  /**
   * Shipments data
   */
  shipmentsData: PropTypes.arrayOf(PropTypes.object),
  /**
   * React router history object
   */
  history: PropTypes.object.isRequired,
  /**
   * Save regions data to redux store
   */
  dispatchSaveRegionsData: PropTypes.func.isRequired,
  /**
   * Regions data - created through planning process
   */
  regionsData: PropTypes.arrayOf(PropTypes.object),
  shipmentsFileName: PropTypes.string
};

RegionSelectionPageClass.defaultProps = {
  isPageLoading: false,
  showNextAlertDialog: false,
  shipmentsData: null,
  regionsData: [],
  shipmentsFileName: null
};

const RegionSelectionPage = withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RegionSelectionPageClass));

export default RegionSelectionPage;
