import PropTypes from 'prop-types';
import React, { useState } from 'react';
import './ImpactfulLocationsList.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as CoordinatesActions from '../../../../../state/actions/coordinatesActions';
import MixPanel from '../../../../../setup/mixPanel';
import StringUtils from '../../../../../common/utils/stringUtil';

export default function ImpactfulLocationsList(props) {
  const { t } = useTranslation();
  const [isAdditionalDataToggled, setSelectedIndex] = useState(true);
  const dispatch = useDispatch();

  function toggleAdditionalData() {
    MixPanel.track(`Delivery Area Analysis - ${StringUtils.firstLetterToUppercase(props.impactType)} impactful locations list ${isAdditionalDataToggled ? 'collapse' : 'expand'} clicked`);
    setSelectedIndex(!isAdditionalDataToggled);
  }

  function onItemClicked(mapPoint) {
    MixPanel.track(`Delivery Area Analysis - ${StringUtils.firstLetterToUppercase(props.impactType)} impactful locations list item clicked`);
    dispatch(CoordinatesActions.coordinatesChange({ lat: mapPoint.lat, lng: mapPoint.lng }));
  }

  function getPointImpactRows() {
    const pointImpactRows = [];

    props.pointImpactList[props.impactType].forEach((point, index) => {
      Object.entries(point).forEach(([mapPointId, mapPointCoverage]) => {
        if (mapPointId && props.mapPointsStaticData[mapPointId]) {
          pointImpactRows.push(
            (
              <div
                className="location-row"
                key={`${props.mapPointsStaticData[mapPointId].name}`}
                onClick={() => {
                  onItemClicked(props.mapPointsStaticData[mapPointId]);
                }}
              >
                <div className="ordinal-number">
                  {`${index + 1}.`}
                </div>
                <div className="location-name">
                  {props.mapPointsStaticData[mapPointId].name}
                </div>
                <div className="location-coverage-count">
                  {mapPointCoverage}
                </div>
              </div>
            )
          );
        }
      });
    });

    return pointImpactRows;
  }

  return (
    <div className="impactful-locations-table">
      <div className="impactful-locations-title">
        <div className={`circle ${props.impactType}`} />
        {t(`${props.impactType} impactful locations`)}
        <div className="arrow-wrapper" onClick={toggleAdditionalData}>
          {isAdditionalDataToggled ? <i className="icon icon-keyboard-arrow-up" /> : <i className="icon icon-keyboard-arrow-down" />}
        </div>
      </div>
      <div className={`locations-list ${isAdditionalDataToggled ? 'expanded' : ''}`}>
        <div className="animation-container">
          {getPointImpactRows()}
        </div>
      </div>
    </div>
  );
}

ImpactfulLocationsList.propTypes = {
  impactType: PropTypes.string.isRequired,
  mapPointsStaticData: PropTypes.object.isRequired,
  pointImpactList: PropTypes.object.isRequired
};
