import PropTypes from 'prop-types';
import './NumberCircle.scss';
import React from 'react';

export default function NumberCircle(props) {
  const contentLength = props.numberToShow.length;
  // Adjust the size of the circle based on the length of the content
  const circleStyle = {
    width: `${contentLength}ch`,
    height: `${contentLength}ch`,
  };

  return (
    <div className="circle-wrapper">
      <div className="dot" style={circleStyle}>{props.numberToShow}</div>
    </div>
  );
}

NumberCircle.propTypes = { numberToShow: PropTypes.string.isRequired };
