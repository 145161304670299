import React from 'react';
import * as d3 from 'd3';
import { Button } from '@material-ui/core';
import './BarChart.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './HeatMapChart.scss';
import { withTranslation } from 'react-i18next';
import * as CharActions from '../../../../state/actions/chartActions';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import MixPanel from '../../../../setup/mixPanel';

const COLORS = {
  0: colorsAndFonts.tile_background_color,
  1: '#fffbd5',
  2: '#fff8c6',
  3: '#fef4b6',
  4: '#feeca6',
  5: '#fee596',
  6: '#feda7d',
  7: '#fece65',
  8: '#fec24d',
  9: '#feb13f',
  10: '#fea130',
  11: '#fa9024',
  12: '#f3811c',
  13: '#ec7114',
  14: '#e1640e',
  15: '#d55607',
  16: '#c64902',
  17: '#b24002',
  18: '#9f3703',
  19: '#8c3004',
  20: '#792a05'
};

class HeatMapChartClass extends React.Component {
  componentDidMount() {
    const keys = this.getActiveStopTypes();
    this.drawChart(keys);
    this.drawLegend();
  }

  componentDidUpdate(prevProps) {
    let keys = [];
    if (prevProps.showDelivery !== this.props.showDelivery || prevProps.showPickup !== this.props.showPickup || prevProps.t !== this.props.t) {
      keys = this.getActiveStopTypes();
      this.drawChart(keys);
    }

    if (prevProps.resetHeatMap !== this.props.resetHeatMap && this.props.chartId !== this.props.containerId) {
      keys = this.getActiveStopTypes();
      this.drawChart(keys);
    }
  }

  getActiveStopTypes() {
    const keys = [];
    if (this.props.showDelivery) {
      keys.push('deliveries');
    }

    if (this.props.showPickup) {
      keys.push('pickups');
    }

    return keys;
  }

  drawChart = (keys) => {
    d3.select(`#${this.props.containerId} svg`).remove('svg');
    d3.select(`#${this.props.containerId} .tooltip`).remove('.tooltip');
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 10, bottom: 20, left: 80 };
    const width = this.props.width - margin.left - margin.right;
    const height = this.props.height - margin.top - margin.bottom;
    const instance = this;
    // append the svg object to the body of the page
    const svg = d3
      .select(`#${this.props.containerId}`)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.bottom})`);

    const { t } = this.props;
    // Labels of row and columns
    const { data } = this.props;
    const hourGroups = d3.set(data.map((d) => d.hour)).values();
    const dayVars = d3
      .set(data.map((d) => t(d.day)))
      .values()
      .reverse();

    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    hourGroups.sort(collator.compare);

    // Build X scales and axis:
    const x = d3.scaleBand().range([0, width]).domain(hourGroups).padding(0.01);
    svg.append('g').attr('class', 'axis').call(d3.axisTop(x));

    // Build Y scales and axis:
    const y = d3.scaleBand().range([height, 0]).domain(dayVars).padding(0.01);
    svg.append('g').attr('class', 'axis').call(d3.axisLeft(y));

    // Find max value
    let maxValue = 0;
    Object.values(data).forEach((d) => {
      if (d.value > maxValue) {
        maxValue = d.value;
      }
    });

    // Add brush
    this.brush = d3.brush();
    svg
      .append('g')
      .attr('class', 'brush')
      .call(
        this.brush // Add the brush feature using the d3.brush function
          .extent([
            [0, 0],
            [width, height]
          ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
          .on('start brush', this.prepareForBrushing)
          .on('end', this.filterData) // Each time the brush selection changes, trigger the 'updateChart' function
      );

    // Create tooltip
    const tooltip = d3.select(`#${this.props.containerId}`).append('div').attr('class', 'tooltip').style('visibility', 'hidden');

    // Read the data
    svg
      .selectAll()
      .data(data, (d) => `${d.hour}:${t(d.day)}`)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.hour))
      .attr('y', (d) => y(t(d.day)))
      .attr('class', 'cell')
      .attr('width', x.bandwidth())
      .attr('height', y.bandwidth())
      .attr('day', (d) => t(d.day))
      .attr('dayKey', (d) => d.day)
      .attr('hour', (d) => d.hour)
      .style('fill', (d) => COLORS[Math.min(instance.getDayValue(d, keys), 20)])
      .attr('cursor', 'pointer')
      .on('mouseover', function (d) {
        instance.showToolTip(this, tooltip, d, keys);
      })
      .on('mousemove', function (d) {
        // we need function here because we use this
        instance.showToolTip(this, tooltip, d, keys);
      })
      .on('mouseleave', () => {
        tooltip.style('visibility', 'hidden');
      })
      .on('mousedown', () => {
        tooltip.style('visibility', 'hidden');
        const brushElem = svg.select('.brush > .overlay').node();
        const brushSelection = svg.select('.brush > .selection').node();
        const bbox = brushSelection.getBoundingClientRect();
        if (
          brushSelection.style.display !== 'none'
          && d3.event.pageX > bbox.left
          && d3.event.pageX < bbox.left + bbox.width
          && d3.event.pageY > bbox.top
          && d3.event.pageY < bbox.top + bbox.height
        ) {
          // Click happened on a cell, inside the current brush selection, so, don't do anything
          return;
        }
        // Click happened on a cell, with no rectangle selection or outside the rectangle selection
        // so let's start a new selection :
        const newClickEvent = new MouseEvent('mousedown', {
          pageX: d3.event.pageX,
          pageY: d3.event.pageY,
          clientX: d3.event.clientX,
          clientY: d3.event.clientY,
          layerX: d3.event.layerX,
          layerY: d3.event.layerY,
          bubbles: true,
          cancelable: true,
          view: window
        });
        brushElem.dispatchEvent(newClickEvent);
      });
  };

  getDayValue = (d, keys) => {
    let value = 0;
    keys.forEach((key) => {
      value += d[key];
    });

    return value;
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  showToolTip = (heatMap, tooltip, data, keys) => {
    const ctm = heatMap.getCTM();
    const coords = {};
    coords.x = heatMap.getAttribute('x');
    coords.y = heatMap.getAttribute('y');

    const x = ctm.e + coords.x * ctm.a + coords.y * ctm.c;
    tooltip
      .style('left', `${x}px`)
      .style('top', `${d3.mouse(heatMap)[1] - 30}px`)
      .style('visibility', 'visible')
      .html(`${this.getDayValue(data, keys)}`);
  };

  drawLegend = () => {
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 0, bottom: 5, left: 0 };

    const width = 40;
    const height = 230;
    const colors = Object.values(COLORS).reverse();

    // append the svg object to the body of the page
    const svg = d3
      .select('#legend')
      .append('svg')
      .attr('class', 'legend')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Build Y scales for cells:
    const y = d3
      .scaleBand()
      .range([0, height - margin.top])
      .domain(colors)
      .padding(0.01);

    // Build Y scale and axis for label
    const scale = d3
      .scaleLinear()
      .domain([0, 20])
      .range([height - margin.top - margin.bottom, 0]);

    const yAxis = d3
      .axisRight()
      .ticks(10)
      .tickFormat((x, i) => `${i * 2}`)
      .scale(scale);

    svg.append('g').attr('class', 'legend-axis').attr('transform', 'translate(20, 0)').call(yAxis);

    // Create cells
    svg
      .selectAll()
      .append('g')
      .data(colors, (d) => d)
      .enter()
      .append('rect')
      .attr('y', (d) => y(d))
      .attr('height', y.bandwidth())
      .attr('width', 20)
      .attr('fill', (d) => d);
  };

  prepareForBrushing = () => {
    d3.selectAll('.cell').style('opacity', 0.5);
    d3.select(`#${this.props.containerId} .tooltip`).style('visibility', 'hidden');
  };

  filterData = () => {
    const cellSelection = d3.selectAll('.cell');
    const cells = cellSelection._groups[0];

    if (!d3.event.selection) {
      this.props.dispatchResetChartData();
      d3.selectAll('.cell').style('opacity', 1);
      return;
    }

    const [selectionStart, selectionEnd] = d3.event.selection;

    const days = new Set();
    const hours = new Set();
    cells.forEach((cell) => {
      const coords = {};
      coords.x = parseInt(cell.getAttribute('x'), 10);
      coords.y = parseInt(cell.getAttribute('y'), 10);
      const width = cell.getAttribute('width');
      const height = cell.getAttribute('height');

      const xEnd = coords.x + parseInt(width, 10);
      const yEnd = coords.y + parseInt(height, 10);

      if (coords.x <= selectionEnd[0] && xEnd >= selectionStart[0] && coords.y <= selectionEnd[1] && yEnd >= selectionStart[1]) {
        days.add(cell.getAttribute('dayKey'));
        hours.add(cell.getAttribute('hour'));
        // eslint-disable-next-line no-param-reassign
        cell.style.opacity = 1;
      } else {
        // eslint-disable-next-line no-param-reassign
        cell.style.opacity = 0.5;
      }
    });

    MixPanel.track('Courier Analysis - Heatmap chart filter applied');
    this.props.dispatchChangeSelectedDates(days, hours, this.props.containerId);
    this.brush(d3.select(`#${this.props.containerId}`));
  };

  reset = () => {
    const activeStopTypes = this.getActiveStopTypes();
    if (activeStopTypes.length) {
      this.drawChart(activeStopTypes);
      this.props.dispatchResetChartData();
    }
  };

  render() {
    return (
      <div className="heat-map">
        <div id="courier-analysis-heat-map" />
        <div id="legend" />
        <Button onClick={this.reset}>{this.props.t('Reset')}</Button>
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} redux store
 */
function mapStateToProps(store) {
  return { ...store.chartState };
}

/**
 * @param {Function} dispatch - redux dispatch
 * @returns {object} redux dispatch actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchChangeSelectedDates: CharActions.changeSelectedData,
      dispatchResetChartData: CharActions.resetData
    },
    dispatch
  );
}

HeatMapChartClass.propTypes = {
  /**
   * Show delivery bars
   */
  showDelivery: PropTypes.bool,
  /**
   * Show pickup bars
   */
  showPickup: PropTypes.bool,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Change selected dates
   */
  dispatchChangeSelectedDates: PropTypes.func.isRequired,
  dispatchResetChartData: PropTypes.func.isRequired,
  /**
   * Reset chart
   */
  resetHeatMap: PropTypes.instanceOf(Date),
  /**
   * Chart width
   */
  width: PropTypes.number.isRequired,
  /**
   * Chart height
   */
  height: PropTypes.number.isRequired,
  chartId: PropTypes.string,
  containerId: PropTypes.string,
  /**
   * Chart data
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      chartData: PropTypes.arrayOf(PropTypes.shape({})),
      averageDeliveries: PropTypes.number,
      averagePickups: PropTypes.number
    })
  ).isRequired
};

HeatMapChartClass.defaultProps = {
  showDelivery: true,
  showPickup: true,
  resetHeatMap: null,
  chartId: null,
  containerId: null
};

const HeatMapChart = connect(mapStateToProps, mapDispatchToProps)(HeatMapChartClass);

export default withTranslation('translations')(HeatMapChart);
