import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './StreetMappingPage.scss';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as h3 from 'h3-js';
import CardMenuTable from '../../../common/components/tables/cardMenuTable/CardMenuTable';
import * as CoordinatesActions from '../../../state/actions/coordinatesActions';
import StreetMappingMapView from './StreetMappingMapView';
import StreetMappingApi from './api/streetMappingApi';
import Button from '../../../common/components/buttons/button/Button';
import { FormInput } from '../../../common/components/form/components/FormInput';
import { Form } from '../../../common/components/form/Form';
import * as PageActions from '../../../state/actions/pageActions';
import { BUTTON_TYPES } from '../../../common/components/buttons/button/constants/buttonTypes';

class StreetMappingPageClass extends React.Component {
  constructor() {
    super();
    this.state = {
      tablePageSize: 1,
      selectedHexId: null,
      selectedHexagonStreets: [],
      formData: { hexId: '' },
      serverErrors: null
    };
    this.streetsPerHex = [];
    this.formRef = null;
  }

  componentDidMount() {
    this.props.dispatchCoordinates(null);
    this.props.dispatchLoadingPage(false);
  }

  updateHexValidation = async (newHex) => {
    let selectedHexagonStreets;
    if (this.streetsPerHex[newHex]) {
      selectedHexagonStreets = this.streetsPerHex[newHex];
    } else {
      selectedHexagonStreets = await StreetMappingApi.getStreetsFromHexagon(newHex);
      this.streetsPerHex[newHex] = selectedHexagonStreets;
    }
    this.setState({ selectedHexId: newHex, selectedHexagonStreets: selectedHexagonStreets });
  };

  getTableColumns = () => {
    return [
      {
        accessor: 'streetName',
        className: 'flex-3'
      },
      {
        accessor: 'source',
        className: 'flex-3'
      },
      {
        accessor: 'createdAtUtc',
        className: 'flex-3'
      }
    ];
  };

  calculateTablePageSize = (height) => {
    if (this.state.tablePageSize !== height) {
      this.setState({ tablePageSize: height });
    }
  };

  findHexagon = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    const hexCoordinates = h3.h3ToGeo(this.state.formData.hexId);
    this.props.dispatchCoordinates(hexCoordinates);
  };

  render() {
    return (
      <div className="street-mapping-management">
        <div className="menu-wrapper">
          <div className="menu">
            <div className="hexagon-id">
              {this.state.selectedHexId}
            </div>
            <div className="table-wrapper">
              {this.state.selectedHexagonStreets && this.state.selectedHexagonStreets.length > 0 && (
                <CardMenuTable
                  key={this.state.tablePageSize}
                  columns={this.getTableColumns()}
                  data={this.state.selectedHexagonStreets}
                  sortBy={[{ id: 'streetName', desc: false }]}
                  resetOn={this.state.selectedHexId}
                  showSearch
                  pageSize={this.state.tablePageSize}
                  calculateTablePageSize={this.calculateTablePageSize}
                  autoResetSelectedRows
                  autoResetPage
                  autoResetGlobalFilter
                />
              )}
            </div>
            <Form
              ref={(formRef) => {
                this.formRef = formRef;
              }}
              formData={this.state.formData}
              onFormChange={(newFormData) => {
                this.setState({ formData: newFormData });
              }}
              serverErrors={this.state.serverErrors}
            >
              <FormInput inputType="input" name="hexId" placeholder={this.props.t('Enter hex Id')} />
              <Button onClick={this.findHexagon} text={this.props.t('Find hexagon')} type={BUTTON_TYPES.PRIMARY} />
            </Form>
          </div>
        </div>
        <div className="map-section">
          <StreetMappingMapView
            updateHexValidation={this.updateHexValidation}
            zoom={15.0}
          />
        </div>
      </div>
    );
  }
}

/**
 * @param {Function} dispatch - redux dispatch function
 * @returns {object} - map actions to component props
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchCoordinates: CoordinatesActions.coordinatesChange
    },
    dispatch
  );
}

StreetMappingPageClass.propTypes = {
  dispatchCoordinates: PropTypes.func.isRequired,
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation('translations')(withRouter(connect(null, mapDispatchToProps)(StreetMappingPageClass)));
