import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace ChartActions
 * @category Actions
 */

/**
 * Creates an action with information on which data is selected in the chart.
 *
 * @param {number} days - selected days
 * @param {number} hours - selected hours
 * @param {number} id - chart id
 * @param {object} dwellTimeInterval - selected dwell times
 * @param {object} pointDistanceInterval - selected distance intervals
 * @returns {{payload: {hours: *, chartType: *, days: *}, type: string}} Action with a payload
 * @memberOf ChartActions
 * @alias changeSelectedData
 * @function
 */
export const changeSelectedData = (days, hours, id, dwellTimeInterval, pointDistanceInterval) => ({
  type: ActionTypes.CHART_DATA_SELECTED,
  payload: {
    id,
    days,
    hours,
    dwellTimeInterval,
    pointDistanceInterval
  }
});

export const resetData = () => ({ type: ActionTypes.CHART_RESET_DATA });
