import i18n from '../../../../resources/i18n';

export class FormValidation {
  static isRequired(formInputValue, required) {
    if (required !== undefined && !formInputValue) {
      return { isValid: false, message: required && required.length ? required : i18n.t('This field is required') };
    }
    return { isValid: true };
  }

  static checkMinLength(formInputValue, minLength) {
    if (minLength !== undefined && formInputValue.length < minLength) {
      return { isValid: false, message: i18n.t('min-length-validation-error', { minLength }) };
    }
    return { isValid: true };
  }

  static checkMaxLength(formInputValue, maxLength) {
    if (maxLength !== undefined && formInputValue.length > maxLength) {
      return { isValid: false, message: i18n.t('max-length-validation-error', { maxLength }) };
    }
    return { isValid: true };
  }

  static isNumber(formInputValue, isNumber) {
    if (isNumber !== undefined && Number.isNaN(Number(formInputValue)) && isNumber) {
      return { isValid: false, message: isNumber && isNumber.length ? isNumber : i18n.t('Enter a valid number') };
    }
    return { isValid: true };
  }

  static isPhoneNumber(formInputValue, isPhoneNumber) {
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp(
      /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\s*\d){0,13}\d$/
    );
    if (isPhoneNumber !== undefined && !regex.test(formInputValue) && isPhoneNumber) {
      return { isValid: false, message: isPhoneNumber && isPhoneNumber.length ? isPhoneNumber : i18n.t('phoneNumberErrorMessage') };
    }
    return { isValid: true };
  }

  static isValidEmailAddress(formInputValue, isValidEmailAddress) {
    const regex = new RegExp('^[\\w-.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
    if (isValidEmailAddress !== undefined && !regex.test(formInputValue) && isValidEmailAddress) {
      return { isValid: false, message: isValidEmailAddress && isValidEmailAddress.length ? isValidEmailAddress : i18n.t('Invalid email') };
    }
    return { isValid: true };
  }

  static isValidGeocoordinates(formInputValue, isValidGeocoordinates) {
    const regex = new RegExp('^\\s*[0-9]+(\\.[0-9]+)?\\s*,\\s*[0-9]+(\\.[0-9]+)?\\s*$');
    if (isValidGeocoordinates !== undefined && !regex.test(formInputValue) && isValidGeocoordinates) {
      return { isValid: false, message: isValidGeocoordinates && isValidGeocoordinates.length ? isValidGeocoordinates : i18n.t('Invalid coordinates input') };
    }
    return { isValid: true };
  }

  static checkRegex(formInputValue, regex) {
    if (regex) {
      if (regex instanceof RegExp) {
        if (!regex.test(formInputValue)) {
          return { isValid: false, message: i18n.t('Regex not matching') };
        }
      } else {
        const regexValue = regex.value;
        if (!regexValue.test(formInputValue)) {
          return { isValid: false, message: regex.message || i18n.t('Regex not matching') };
        }
      }
    }
    return { isValid: true };
  }

  static checkCustomValidation(formInputValue, formData, customFn) {
    if (customFn) {
      return customFn(formInputValue, formData);
    }
    return { isValid: true };
  }
}
