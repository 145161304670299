import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../resources/i18n';
import './BarChartWrapper.scss';
import BarChart from './BarChart';
import stopsUtil from '../../utils/stopsUtil';
import InfoDialogWrapper from '../../../../common/components/dialogs/infoDialog/InfoDialogWrapper';

const FILTER_AND_DATA_HEIGHT = 10 + 133 + 10 + 156 + 10 + 292 + 10 + 10 + 25 + 20 + 2 * 5 + 10;
const BAR_CHART_MIN_HEIGHT = 250;

/**
 * @param {object} props - component props
 * @returns {JSX.Element} BarChartWrapper component
 */
export default function BarChartWrapper(props) {
  const { t } = useTranslation();
  const calculateWorkWeekAvg = (weekData) => {
    let result = 0;
    let numberOfWorkingDays = 0;
    Object.keys(weekData).forEach((day) => {
      result += weekData[day];
      if (weekData[day] > 0) {
        numberOfWorkingDays += 1;
      }
    });

    return result === 0 ? 0 : result / numberOfWorkingDays;
  };

  const makeBarChartData = (data) => {
    const deliveriesPerDay = {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0
    };

    const pickupsPerDay = {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0
    };

    const daysNamesShort = {
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat',
      Sunday: 'Sun'
    };

    if (data && data.stops) {
      data.stops.forEach((stop) => {
        if (!deliveriesPerDay[stop.dayOfTheWeek]) {
          deliveriesPerDay[stop.dayOfTheWeek] = 0;
        }

        if (!pickupsPerDay[stop.dayOfTheWeek]) {
          pickupsPerDay[stop.dayOfTheWeek] = 0;
        }

        if (stopsUtil.getStopType(stop.event) === 'delivery') {
          deliveriesPerDay[stop.dayOfTheWeek] += 1;
        } else {
          pickupsPerDay[stop.dayOfTheWeek] += 1;
        }
      });
    }

    const finalData = [];
    Object.keys(deliveriesPerDay).forEach((key) => {
      finalData.push({
        dayName: i18n.t(key),
        dayNameShort: i18n.t(daysNamesShort[key]),
        deliveries: deliveriesPerDay[key],
        pickups: pickupsPerDay[key],
        day: i18n.t(daysNamesShort[key])
      });
    });

    return {
      chartData: finalData,
      averageDeliveries: calculateWorkWeekAvg(deliveriesPerDay),
      averagePickups: calculateWorkWeekAvg(pickupsPerDay)
    };
  };

  const chartHeightWithoutScroll = window.innerHeight - FILTER_AND_DATA_HEIGHT;
  const chartHeight = chartHeightWithoutScroll > BAR_CHART_MIN_HEIGHT ? chartHeightWithoutScroll : BAR_CHART_MIN_HEIGHT;

  return (
    <div className="bar-chart-wrapper">
      <InfoDialogWrapper title={t('Stops by day of week')} popupText={t('BarChartInfoText')} eventName="Courier Analysis - Info - Stops by day of week" />
      {props.data && props.data.stops && props.data.stops.length > 0 ? (
        <BarChart
          width={450}
          height={chartHeight}
          data={makeBarChartData(props.data)}
          showPickup={props.showPickup}
          showDelivery={props.showDelivery}
          containerId={props.containerId}
        />
      ) : (
        <div className="no-data">
          <div className="no-data-text">{i18n.t('No data for selected dates')}</div>
        </div>
      )}
    </div>
  );
}

BarChartWrapper.propTypes = {
  /**
   * Chart data
   */
  data: PropTypes.shape({ stops: PropTypes.arrayOf(PropTypes.shape({})) }),
  /**
   * Flag for showing pickups on chart
   */
  showPickup: PropTypes.bool,
  /**
   * Flag for showing deliveries on chart
   */
  showDelivery: PropTypes.bool,
  containerId: PropTypes.string
};

BarChartWrapper.defaultProps = {
  data: null,
  showPickup: true,
  showDelivery: true,
  containerId: null
};
