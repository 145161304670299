import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom';
import React from 'react';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import MapMarkerPopup from '../../../../common/components/popups/components/MapMarkerPopup';
import MapHelperUtil from './mapHelpersUtil';
import mapPopupDefaultStyle from '../../../../common/components/popups/util/mapPopupDefaultStyle';

/**
 * Util for rendering geocoding map views
 *
 * @class MissedPointsClass
 * @param map - map ref
 * @param t - translate function
 * @param isGeoFilterOn
 * @category CourierAnalysis
 */
const delivery = ['==', ['get', 'deliveryType'], 'delivery'];

export default class MissedPointsClass {
  constructor(map, t, isGeoFilterOn) {
    this.map = map;
    this.t = t;
    this.mapLayers = [];

    this.isGeoFilterOn = isGeoFilterOn;

    this.routeSource = {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      }
    };
  }

  /**
   * Toggles visibility for geocoding layers
   *
   * @memberOf MissedPointsClass
   * @param {boolean} isGeoFilterOn - new value to be set
   * @function
   */
  toggleVisibility = (isGeoFilterOn) => {
    this.isGeoFilterOn = isGeoFilterOn;
    this.map.setLayoutProperty('geocoded-layer', 'visibility', MapHelperUtil.getVisibilityString(isGeoFilterOn));
    this.map.setLayoutProperty('stop-point', 'visibility', MapHelperUtil.getVisibilityString(isGeoFilterOn));
  };

  /**
   * Add all layers needed for geocoding
   *
   * @memberOf MissedPointsClass
   * @function
   */
  plotMissedPoints = () => {
    if (this.map.getLayer('route') && this.map.getLayer('geocoded-layer')) {
      return;
    }

    this.map.addLayer({
      id: 'route',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#000',
        'line-width': 2
      }
    });
    this.mapLayers.push('route');

    this.map.addLayer({
      id: 'geocoded-layer',
      type: 'circle',
      source: 'geocodedExpectedStops',
      layout: { visibility: MapHelperUtil.getVisibilityString(this.isGeoFilterOn) },
      paint: {
        'circle-color': ['case', delivery, colorsAndFonts.deliveries_color, colorsAndFonts.pickups_color],
        'circle-radius': 5,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      }
    });
    this.mapLayers.push('geocoded-layer');
    this.addGeocodedMapEvents();
  };

  /**
   * Place where we set all geocoding map events
   *
   * @memberOf MissedPointsClass
   * @function
   */
  addGeocodedMapEvents = () => {
    this.map.on('click', 'geocoded-layer', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const popupData = MapHelperUtil.createPopupArgument(e.features[0].properties, true);
      const placeholder = document.createElement('div');
      ReactDOM.render(<MapMarkerPopup data={popupData} />, placeholder);
      new mapboxgl.Popup(mapPopupDefaultStyle).setDOMContent(placeholder).setLngLat(coordinates).addTo(this.map);
    });

    const popupExpected = new mapboxgl.Popup(mapPopupDefaultStyle, {
      closeButton: false,
      closeOnClick: false
    });

    const that = this;
    this.map.on('mouseenter', 'geocoded-layer', (e) => {
      this.map.getCanvas().style.cursor = 'pointer';

      that.routeSource.data.geometry.coordinates.push([parseFloat(e.features[0].properties.actualLong), parseFloat(e.features[0].properties.actualLat)]);
      that.routeSource.data.geometry.coordinates.push(e.features[0].geometry.coordinates);
      this.map.getSource('route').setData(that.routeSource.data);

      const popupData = MapHelperUtil.createPopupArgument(e.features[0].properties, true);
      const placeholder = document.createElement('div');
      ReactDOM.render(<MapMarkerPopup data={popupData} />, placeholder);
      popupExpected.setDOMContent(placeholder).setLngLat(e.features[0].geometry.coordinates.slice()).addTo(this.map);

      // create a HTML element for each feature
      const el = document.createElement('div');
      el.className = 'marker';

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el).setLngLat([parseFloat(e.features[0].properties.actualLong), parseFloat(e.features[0].properties.actualLat)]).addTo(this.map);
    });

    this.map.on('mouseleave', 'geocoded-layer', () => {
      popupExpected.remove();

      that.routeSource.data.geometry.coordinates = [];
      this.map.getSource('route').setData(that.routeSource.data);

      MapHelperUtil.removeElementsByClass('marker');

      this.map.getCanvas().style.cursor = '';
    });

    this.map.on('hover', 'geocoded-layer', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
  };
}
