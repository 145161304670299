import * as h3 from 'h3-js';
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import './LabelingPopup.scss';
import Button from '../../../common/components/buttons/button/Button';
import ConfirmationDialog from '../../../common/components/dialogs/confirmationDialog/ConfirmationDialog';
import AppDialogActionsWrapper from '../../../common/components/dialogs/utils/appDialogActionsWrapper';
import { GEOCODING_HEX_LEVEL } from '../../../common/constants/geocodingConstants';
import AuthUtil from '../../../common/utils/authUtil';
import { raygunClient } from '../../../setup/raygunClient';
import * as PageActions from '../../../state/actions/pageActions';
import { AddressLabelingApi } from '../../management/addressLabeling/api/addressLabelingApi';
import AddressLabelingMap from '../../management/addressLabeling/components/AddressLabelingMap';
import { BUTTON_TYPES } from '../../../common/components/buttons/button/constants/buttonTypes';

/**
 * Empty page component
 *
 * @returns {JSX.Element} - empty page for testing with common code examples
 * @component
 * @alias EmptyPage
 * @category Common
 */

export default function LabelingPopup(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { shipment, hexIds } = props;
  const pinLat = useRef();
  const pinLng = useRef();

  function saveAddress(close) {
    saveFixedAddress(pinLat.current, pinLng.current);
    close();
  }

  const savePinCoordinates = useCallback((location) => {
    pinLat.current = location.lat;
    pinLng.current = location.lng;
  }, []);

  function deleteUnsuccessfullyGeocodedAddress(hexagons, streetName, houseNumber, receiver) {
    return hexagons.map((hexId) => AddressLabelingApi.deleteUnsuccessfullyGeocodedAddress(hexId, streetName, houseNumber, receiver));
  }

  function saveFixedAddress(lat, lng) {
    const selectedHexId = h3.geoToH3(lat, lng, GEOCODING_HEX_LEVEL);
    if (hexIds.includes(selectedHexId)) {
      AppDialogActionsWrapper.openAppDialog({
        dialogComponent: ConfirmationDialog,
        dialogComponentProps: {
          title: t('Save location?'),
          body: t('Address corrected'),
          confirmButtonText: t('Save'),
          continueCallback: () => {
            dispatch(PageActions.loadingPage(true));
            AddressLabelingApi.insertLabeledGeocodedAddress(
              shipment.fuzzyAddress.streetName,
              shipment.fuzzyAddress.houseNumber,
              shipment.fuzzyAddress.receiver,
              selectedHexId,
              {
                lat: lat,
                lng: lng
              },
              AuthUtil.getTenantId()
            )
              .then(() => {
                const shipmentPromise = AddressLabelingApi.addLabelingDataToShipment(shipment.shipmentCode, shipment.deliveryType, lat, lng);
                const geoErrorPromise = deleteUnsuccessfullyGeocodedAddress(
                  hexIds,
                  shipment.fuzzyAddress.streetName,
                  shipment.fuzzyAddress.houseNumber,
                  shipment.fuzzyAddress.receiver
                );

                Promise.all([shipmentPromise, ...geoErrorPromise])
                  .then(() => {
                    dispatch(PageActions.loadingPage(false));
                    dispatch(PageActions.reloadData());
                    toast.success(t('Successfully labeled'));
                  })
                  .catch((error) => {
                    raygunClient.send(error, 'Failed to delete labeled address');
                    dispatch(PageActions.loadingPage(false));
                    toast.error(t('Error labeling'));
                  });
              })
              .catch((error) => {
                raygunClient.send(error, 'Failed to label address');
                dispatch(PageActions.loadingPage(false));
                toast.error(t('Error labeling'));
              });
          },
          cancelCallback: () => {}
        }
      });
    } else toast.error(t('Location not in hexagon'));
  }

  function getInitialAddressPosition() {
    if (hexIds && hexIds.length > 0) {
      const hexagonCoordinates = h3.h3ToGeo(hexIds[0]);
      return { lat: parseFloat(hexagonCoordinates[0]), lng: parseFloat(hexagonCoordinates[1]) };
    }

    return null;
  }

  return (
    <Popup ref={props.labelingPopupRef} modal nested>
      {(close) => (
        <div className="modal">
          <div className="address-data">
            <div>
              {t('Address')}
              :
              {props.shipment.address}
            </div>
            {props.shipment.fuzzyAddress.houseNumber === 'bb' && (
              <div>
                {t('Receiver')}
                :
                {props.shipment.name}
              </div>
            )}
            <Button
              text={t('Save')}
              onClick={() => {
                saveAddress(close);
              }}
              type={BUTTON_TYPES.PRIMARY}
            />
          </div>
          <div className="close" onClick={close}>
            &times;
          </div>
          <div className="labeling-map-wrapper">
            <AddressLabelingMap addressData={getInitialAddressPosition()} savePin={savePinCoordinates} zoom={15.0} hexIds={hexIds} />
          </div>
        </div>
      )}
    </Popup>
  );
}

LabelingPopup.propTypes = {
  shipment: PropTypes.shape({
    address: PropTypes.string,
    region: PropTypes.string,
    shipmentCode: PropTypes.string,
    deliveryType: PropTypes.string,
    name: PropTypes.string,
    fuzzyAddress: PropTypes.shape({
      streetName: PropTypes.string,
      houseNumber: PropTypes.string,
      receiver: PropTypes.string
    })
  }),
  hexIds: PropTypes.arrayOf(PropTypes.string),
  labelingPopupRef: PropTypes.object
};

LabelingPopup.defaultProps = {
  shipment: null,
  hexIds: null,
  labelingPopupRef: null
};
