import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import './ChartWrapper.scss';
import InfoDialogWrapper from '../../../../common/components/dialogs/infoDialog/InfoDialogWrapper';
import * as ChartActions from '../../../../state/actions/chartActions';

/**
 * @param {object} props - component props
 * @returns {JSX.Element} BarChartWrapper component
 */
export default function ChartWrapper(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reset = () => {
    dispatch(ChartActions.resetData());
  };

  function elementToRender() {
    if (props.loadingData) {
      return (
        <div className="chart-loader bar-chart-loader">
          <CircularProgress />
        </div>
      );
    }

    if (props.dataExists) {
      return props.children;
    }

    return (
      <div className="no-data">
        <div className="no-data-text">{t('No data for selected dates')}</div>
      </div>
    );
  }

  return (
    <div className={`bar-chart-wrapper ${props.className}`}>
      <div className="info-header">
        <InfoDialogWrapper title={t(props.title)} popupText={t(props.popupTranslationKey)} eventName={props.eventName} />
        {!props.hideResetButton && <Button onClick={reset}>{t('Reset')}</Button>}
      </div>
      {elementToRender()}
    </div>
  );
}

ChartWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  dataExists: PropTypes.bool,
  loadingData: PropTypes.bool,
  popupTranslationKey: PropTypes.string.isRequired,
  children: PropTypes.object,
  hideResetButton: PropTypes.bool,
  className: PropTypes.string
};

ChartWrapper.defaultProps = {
  dataExists: false,
  loadingData: true,
  children: null,
  hideResetButton: false,
  className: ''
};
