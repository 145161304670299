import React from 'react';
import './OohAnalysis.scss';
import moment from 'moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SimpleBar from 'simplebar-react';
import memoize from 'memoize-one';
import { raygunClient } from '../../../setup/raygunClient';
import ChartWrapper from './components/ChartWrapper';
import DailyBreakdownChart from './components/DailyBreakdownChart';
import HeatMapChart from './components/HeatMapChart';
import IntervalBarChart from './components/IntervalBarChart';
import UtilizationWeeklyChart from './components/UtilizationWeeklyChart';
import DatePickerWrapper from './components/WeekPickerWrapper';
import MapWrapper from './components/MapWrapper';
import DataCard from '../../../common/components/cards/DataCard';
import * as InfoDialogActions from '../../../state/actions/infoDialogActions';
import * as PageActions from '../../../state/actions/pageActions';
import MixPanel from '../../../setup/mixPanel';
import colorsAndFonts from '../../../resources/colors-and-fonts.scss';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import * as CharActions from '../../../state/actions/chartActions';
import TeamSelect from '../../../common/components/selections/teamSelect/TeamSelect';
import EntitySelect from '../../../common/components/selections/entitySelect/EntitySelect';
import EntityUtil from '../utils/entityUtil';
import { DISTANCE_TYPE } from '../../../common/utils/distanceTypeUtil';
import OohAnalysisApi from './api/oohAnalysisApi';
import { HOUR_BAR_GAP,
  POINT_DISTANCE_CHART_BAR_GAPS } from './constants/chartTypes';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';
import { ENTITY_TYPE } from '../../../common/constants/entityTypes';
import { COLLECTED_EVENT, UNSUCCESSFUL_EVENT } from '../../../common/constants/shipmentEventsConstants';
import i18n from '../../../resources/i18n';
import { WEEKDAYS_LONG } from '../../../common/constants/dayMonthsNames';
import { DAYS_NAMES_SHORT, DAYS_OF_WEEK, HOURS_OF_DAY } from './constants/dateConstants';
import FiltersUtil from './utils/filtersUtil';

const FILTER_AND_DATA_HEIGHT = 10 + 133 + 10 + 156 + 10 + 292 + 10 + 10 + 25 + 20 + 2 * 5 + 10;
const BAR_CHART_MIN_HEIGHT = 250;
const BAR_CHART_MAX_HEIGHT = 450;

class OOHAnalysisClass extends React.Component {
  constructor() {
    super();
    this.state = {
      showUnsuccessful: true,
      showCollected: true,
      oohPoint: null,
      isLoadingStops: false,
      isLoadingPackageLockers: false,
      teamId: '',
      chartData: {
        utilizationChartData: null,
        vacantSpacesChartData: null,
        hourlyCollectedChartData: null,
        dwellTimeChartData: null,
        dailyBreakdownChartData: null,
        pointDistanceChartData: null
      }
    };

    this.distanceType = DISTANCE_TYPE.RADIAL;

    this.oohPointsData = null;
    this.collections = 0;
    this.unsuccessful = 0;
    this.oohPointCapacity = null;
    this.workingHours = null;

    this.startDate = QueryStringUtil.getQueryStringValue('fromDate')
      ? moment(QueryStringUtil.getQueryStringValue('fromDate'), 'YYYY-MM-DDTHH')
        .toDate()
      : moment()
        .startOf('isoWeek')
        .toDate();
    this.endDate = QueryStringUtil.getQueryStringValue('toDate')
      ? moment(QueryStringUtil.getQueryStringValue('toDate'), 'YYYY-MM-DDTHH')
        .toDate()
      : moment()
        .endOf('isoWeek')
        .toDate();
  }

  componentDidMount() {
    MixPanel.track('Page Load - OOH Point Analysis');
    MixPanelUtil.setUnloadListener('Page Unload - OOH Point Analysis');

    const chartHeightWithoutScroll = window.innerHeight - FILTER_AND_DATA_HEIGHT;
    const chartHeight = chartHeightWithoutScroll > BAR_CHART_MIN_HEIGHT ? chartHeightWithoutScroll : BAR_CHART_MIN_HEIGHT;
    this.chartHeight = chartHeight > BAR_CHART_MAX_HEIGHT ? BAR_CHART_MAX_HEIGHT : chartHeight;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isMapLoading !== this.props.isMapLoading
      || prevState.isLoadingStops !== this.state.isLoadingStops
      || prevState.isLoadingPackageLockers !== this.state.isLoadingPackageLockers
    ) {
      this.props.dispatchLoadingPage(this.props.isMapLoading || this.state.isLoadingStops || this.state.isLoadingPackageLockers);
    }

    if (this.props.selectedDay !== prevProps.selectedDay
      || this.props.selectedHours !== prevProps.selectedHours
      || this.props.selectedDwellTimeInterval !== prevProps.selectedDwellTimeInterval
      || this.props.selectedPointDistanceInterval !== prevProps.selectedPointDistanceInterval
      || this.state.showCollected !== prevState.showCollected
      || this.state.showUnsuccessful !== prevState.showUnsuccessful) {
      if (this.shipmentsData?.terminalStates) {
        this.getFilteredChartValues();
      }
    }

    if (this.props.chartId !== prevProps.chartId) {
      if (this.props.chartId === 'hourly-collections-heat-map') {
        this.setState({ showUnsuccessful: false });
      } else {
        this.setState({ showUnsuccessful: true });
      }
    }
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
  }

  stopCounters = memoize((shipmentsData, selectedHours, selectedDay, selectedDwellTimeInterval, selectedPointDistanceInterval, showCollected, showUnsuccessful) => {
    return this.getStopsCounters(shipmentsData, selectedHours, selectedDay, selectedDwellTimeInterval, selectedPointDistanceInterval, showCollected, showUnsuccessful);
  });

  getStopsCounters = (shipmentsData, selectedHours, selectedDay, selectedDwellTimeInterval, selectedPointDistanceInterval, showCollected, showUnsuccessful) => {
    let unsuccessfulCounter = 0;
    let collectedCounter = 0;

    if (shipmentsData && shipmentsData.terminalStates) {
      shipmentsData.terminalStates.forEach((terminalState) => {
        let shouldPushStop = true;
        if (selectedDay?.size > 0) {
          shouldPushStop = FiltersUtil.checkDayIntervalSelection(terminalState.dayOfTheWeek, selectedDay);
        }

        if (selectedHours?.size > 0) {
          shouldPushStop = shouldPushStop && FiltersUtil.checkHourIntervalSelection(terminalState.hour, selectedHours);
        }

        if (selectedDwellTimeInterval) {
          shouldPushStop = shouldPushStop && FiltersUtil.checkDwellTimeIntervalSelection(terminalState.dwellTime, selectedDwellTimeInterval);
        }

        if (selectedPointDistanceInterval) {
          shouldPushStop = shouldPushStop && FiltersUtil.checkDistanceIntervalSelection(terminalState.geoDistance, this.props.selectedPointDistanceInterval);
        }

        if (terminalState.event === COLLECTED_EVENT) {
          shouldPushStop = shouldPushStop && showCollected;
        }
        if (terminalState.event === UNSUCCESSFUL_EVENT) {
          shouldPushStop = shouldPushStop && showUnsuccessful;
        }
        if (!shouldPushStop) {
          return;
        }

        if (terminalState.event === COLLECTED_EVENT) {
          collectedCounter++;
        }
        if (terminalState.event === UNSUCCESSFUL_EVENT) {
          unsuccessfulCounter++;
        }
      });
    }

    return {
      unsuccessfulCounter,
      collectedCounter
    };
  };

  fetchPackageLockers = async (teamId, entityType) => {
    this.setState({
      teamId: teamId,
      isLoadingPackageLockers: true
    });

    EntityUtil.getEntityData(teamId, entityType, true)
      .then((oohPointsData) => {
        this.oohPointsData = oohPointsData;
        this.setState({ isLoadingPackageLockers: false });
      })
      .catch((error) => {
        raygunClient.send(error, 'Failed to load ooh points for OOH Point Analysis');
        toast.error(this.props.t('Failed to load ooh points'));
        this.setState({ isLoadingPackageLockers: false });
      });

    if (QueryStringUtil.getQueryStringValue('showCollected')) {
      this.onShowCollectedChanged(QueryStringUtil.getQueryStringValue('showCollected') === 'true');
    }
    if (QueryStringUtil.getQueryStringValue('showUnsuccessful')) {
      this.onShowUnsuccessfulChanged(QueryStringUtil.getQueryStringValue('showUnsuccessful') === 'true');
    }
  };

  onShowCollectedChanged = (value) => {
    this.setState({ showCollected: value });
    MixPanel.track(`OOH Point Analysis - Collected turned ${value ? 'ON' : 'OFF'}`);
    QueryStringUtil.setQueryStringValue('showCollected', value);
  };

  onShowUnsuccessfulChanged = (value) => {
    this.setState({ showUnsuccessful: value });
    MixPanel.track(`OOH Point Analysis - Unsuccsessful turned ${value ? 'ON' : 'OFF'}`);
    QueryStringUtil.setQueryStringValue('showUnsuccessful', value);
  };

  onOohPointChange = (opt) => {
    const oohPointId = opt && opt.value;
    const selectedOohPoint = this.oohPointsData?.find((oohPoint) => oohPoint.id === oohPointId);
    this.setState({ oohPoint: selectedOohPoint });
    if (selectedOohPoint?.id) {
      MixPanel.track('OOH Point Analysis - Ooh point changed');

      this.fetchOohPointShipmentsDataAsync(selectedOohPoint.id);
    }

    this.props.dispatchResetChartData();
  };

  onDateChanged = (startDate, endDate) => {
    this.startDate = startDate;
    this.endDate = endDate;
    MixPanel.track('OOH Point Analysis - Date range changed');
    QueryStringUtil.setQueryStringValue('fromDate', moment(startDate)
      .startOf('day')
      .format('YYYY-MM-DDTHH'));
    QueryStringUtil.setQueryStringValue('toDate', moment(endDate)
      .endOf('day')
      .format('YYYY-MM-DDTHH'));
    QueryStringUtil.setQueryStringValue('date', moment(startDate)
      .startOf('day')
      .format('YYYY-MM-DDTHH'));
    if (this.state.oohPoint?.id) {
      this.fetchOohPointShipmentsDataAsync(this.state.oohPoint?.id)
        .then(() => {
          this.props.dispatchResetChartData();
        });
    }
  };

  getPerDayCounterObject = () => {
    const daysObject = {};
    DAYS_OF_WEEK.forEach((day) => {
      daysObject[day] = 0;
    });
    return daysObject;
  };

  // Filter parameters check
  checkDayFilter = (stop) => {
    return !this.props.selectedDay || FiltersUtil.checkDayIntervalSelection(stop.dayOfTheWeek, this.props.selectedDay);
  };

  checkHourFilter = (stop) => {
    return !this.props.selectedHours || FiltersUtil.checkHourIntervalSelection(stop.hour, this.props.selectedHours);
  };

  checkDwellTimeFilter = (stop) => {
    return !this.props.selectedDwellTimeInterval
      || FiltersUtil.checkDwellTimeIntervalSelection(stop.dwellTime, this.props.selectedDwellTimeInterval);
  };

  checkPointDistanceFilter = (stop) => {
    return !this.props.selectedPointDistanceInterval
      || FiltersUtil.checkDistanceIntervalSelection(stop.geoDistance, this.props.selectedPointDistanceInterval);
  };

  calculateHighlightedMetrics = () => {
    let collectionsSum = 0;
    let unsuccessfulSum = 0;
    let utilizationRate = 0;
    let utilizationRateDaysCount = 0;
    this.shipmentsData.snapshots.forEach((snapshot) => {
      if (snapshot) {
        collectionsSum += snapshot.collections;
        unsuccessfulSum += snapshot.unsuccessful;
        if (snapshot.utilizationRate) {
          utilizationRate += snapshot.utilizationRate;
          utilizationRateDaysCount++;
        }
      }
    });
    this.collections = collectionsSum;
    this.unsuccessful = unsuccessfulSum;
    this.utilizationRate = utilizationRateDaysCount > 0 ? Math.ceil((utilizationRate / utilizationRateDaysCount) * 100) : null;
  };

  getOohPointInfo = () => {
    this.shipmentsData.snapshots.forEach((dailySnapshot) => {
      if (dailySnapshot?.capacity) this.oohPointCapacity = dailySnapshot.capacity;
      if (dailySnapshot?.workingHours) this.workingHours = dailySnapshot.workingHours;
    });
  };

  fetchOohPointShipmentsDataAsync = async (oohPointId) => {
    this.setState({ isLoadingStops: true });

    try {
      this.shipmentsData = await OohAnalysisApi.getOohPointShipmentsData(oohPointId, this.startDate, this.endDate);
      if (this.shipmentsData) {
        this.calculateHighlightedMetrics();
        this.getOohPointInfo();
        this.getFilteredChartValues();
      }
    } catch (error) {
      raygunClient.send(error, 'Error loading ooh point data for OOH Point Analysis');
      toast.error(this.props.t('Error loading ooh point data'));
    } finally {
      this.setState({ isLoadingStops: false });
    }
  };

  getUtilizationHighlightedMetricValue = () => {
    if (this.state.showCollected
      && this.utilizationRate
      && !(this.props.selectedDay || this.props.selectedHours || this.props.selectedDwellTimeInterval || this.props.selectedPointDistanceInterval)) {
      return `${this.utilizationRate}%`;
    }
    return '-';
  };

  getFilteredChartValues = () => {
    this.setState({
      chartData: {
        ...this.processSnapshotData(this.shipmentsData?.snapshots),
        ...this.processTerminalStateData(this.shipmentsData?.terminalStates)
      }
    });
  };

  // Process fetched snapshot data to display data on "Utilization data chart" and on "Vacant spaces data heatmap"
  processSnapshotData = (snapshots) => {
    // Utilization data initialisation
    const utilizationPerDay = this.getPerDayCounterObject();
    let utilizationChartDataExists = false;

    // Vacant spaces data initialisation
    const vacantSpacesFilteredChartData = [];
    let vacantSpacesCharDataExists = false;

    if (snapshots) {
      snapshots.forEach((dailySnapshot, j) => {
        // Utilization data
        if (dailySnapshot?.utilizationRate) {
          utilizationPerDay[dailySnapshot.dayOfTheWeek] = dailySnapshot.utilizationRate;
          utilizationChartDataExists = true;
        }
        // Vacant spaces data
        const vacancyForDay = dailySnapshot ? JSON.parse(dailySnapshot?.vacancy) : null;
        for (let i = 0; i < 24; i++) {
          const hour = `${i < 10 ? '0' : ''}${i}`;
          const value = (vacancyForDay && vacancyForDay[hour]) ? vacancyForDay[hour] : -1;
          if (value !== -1) vacantSpacesCharDataExists = true;
          vacantSpacesFilteredChartData.push({
            hour: i,
            day: WEEKDAYS_LONG[(j + 1) % 7],
            value: value
          });
        }
      });
    }

    const utilizationFilteredData = [];
    Object.keys(utilizationPerDay)
      .forEach((key) => {
        utilizationFilteredData.push({
          dayName: i18n.t(key),
          dayNameShort: i18n.t(DAYS_NAMES_SHORT[key]),
          utilization: utilizationPerDay[key],
          day: i18n.t(DAYS_NAMES_SHORT[key])
        });
      });

    const utilizationChartData = {
      chartData: utilizationFilteredData,
      averageUtilization: this.utilizationRate,
      dataExists: utilizationChartDataExists
    };

    const vacantSpacesChartData = {
      chartData: vacantSpacesFilteredChartData,
      dataExists: vacantSpacesCharDataExists
    };

    return {
      utilizationChartData: utilizationChartData,
      vacantSpacesChartData: vacantSpacesChartData
    };
  };

  initialisePointDistanceChartData = () => {
    const stopsGroupedByDistance = {};
    POINT_DISTANCE_CHART_BAR_GAPS.forEach((key) => {
      stopsGroupedByDistance[key] = {
        collections: 0,
        unsuccessful: 0
      };
    });

    stopsGroupedByDistance[`>${POINT_DISTANCE_CHART_BAR_GAPS[POINT_DISTANCE_CHART_BAR_GAPS.length - 1]}`] = {
      collections: 0,
      unsuccessful: 0
    };
    return stopsGroupedByDistance;
  };

  getPointDistanceChartGroupKey = (distance) => {
    let key = null;
    POINT_DISTANCE_CHART_BAR_GAPS.every((distanceGroup) => {
      if (distanceGroup > distance) {
        key = distanceGroup;
        return false;
      }
      return true;
    });
    return key ? `${key}` : `>${POINT_DISTANCE_CHART_BAR_GAPS[POINT_DISTANCE_CHART_BAR_GAPS.length - 1]}`;
  };

  // Process fetched snapshot data to display data on "Collections per hour heatmap", "Dwell time chart"
  // and "Daily Breakdown chart"
  processTerminalStateData = (data) => {
    // Hourly collection data initialisation
    const collectionsDataXY = {};

    DAYS_OF_WEEK.forEach((day) => {
      HOURS_OF_DAY.forEach((hour) => {
        if (!collectionsDataXY[day]) {
          collectionsDataXY[day] = [];
        }

        collectionsDataXY[day][hour] = -1;
      });
    });

    // Dwell time data initialisation
    const terminalStates = {};
    let totalDwellTime = 0;
    let collectedCountFiltered = 0;
    let unsuccessfulCountFiltered = 0;
    let highestHour = 0;

    // Daily breakdown data initialisation
    const collectedPerDay = this.getPerDayCounterObject();
    const unsuccessfulPerDay = this.getPerDayCounterObject();
    let dataExists = false;

    // Point distance data initialisation
    let totalDistance = 0;
    const stopsGroupedByDistance = this.initialisePointDistanceChartData();

    let longestDistance = 0;
    let collectedCountDistanceRelevant = 0;
    let unsuccessfulCountDistanceRelevant = 0;

    if (data && data.length > 0) {
      data.forEach((stop) => {
        // Hourly collection data
        if (stop.event === COLLECTED_EVENT) {
          collectionsDataXY[stop.dayOfTheWeek][stop.hour] = collectionsDataXY[stop.dayOfTheWeek][stop.hour] < 0 ? 1 : collectionsDataXY[stop.dayOfTheWeek][stop.hour] + 1;
        }

        if (this.checkDayFilter(stop)
          && this.checkHourFilter(stop)
          && this.checkDwellTimeFilter(stop)
          && this.checkPointDistanceFilter(stop)) {
          dataExists = true;

          // Dwell time data
          const deliveryHour = Math.ceil(stop.dwellTime / HOUR_BAR_GAP);
          if (!terminalStates[deliveryHour]) {
            terminalStates[deliveryHour] = {
              collections: 0,
              unsuccessful: 0
            };
          }

          // Point distance data
          const distanceKey = this.getPointDistanceChartGroupKey(stop.geoDistance);

          if (this.state.showCollected && (stop.event === COLLECTED_EVENT)) {
            // Dwell time data
            collectedCountFiltered++;
            terminalStates[deliveryHour].collections += 1;
            totalDwellTime += stop.dwellTime;
            highestHour = deliveryHour > highestHour ? deliveryHour : highestHour;

            // Point distance data
            if (stop.geoDistance) {
              stopsGroupedByDistance[distanceKey].collections++;
              longestDistance = longestDistance < distanceKey ? distanceKey : longestDistance;
              totalDistance += parseFloat(stop.geoDistance);
              collectedCountDistanceRelevant++;
            }
          }

          if (this.state.showUnsuccessful && (stop.event === UNSUCCESSFUL_EVENT)) {
            // Dwell time data
            unsuccessfulCountFiltered++;
            terminalStates[deliveryHour].unsuccessful += 1;
            totalDwellTime += stop.dwellTime;
            highestHour = deliveryHour > highestHour ? deliveryHour : highestHour;

            // Point distance data
            if (stop.geoDistance) {
              stopsGroupedByDistance[distanceKey].unsuccessful++;
              longestDistance = longestDistance < distanceKey ? distanceKey : longestDistance;
              totalDistance += parseFloat(stop.geoDistance);
              unsuccessfulCountDistanceRelevant++;
            }
          }

          // Daily breakdown data
          if (stop.event === COLLECTED_EVENT) {
            collectedPerDay[stop.dayOfTheWeek] += 1;
          }
          if (stop.event === UNSUCCESSFUL_EVENT) {
            unsuccessfulPerDay[stop.dayOfTheWeek] += 1;
          }
        }
      });
    }

    // Hourly collection data
    const hourlyCollectedFilteredData = [];
    DAYS_OF_WEEK.forEach((day) => {
      HOURS_OF_DAY
        .forEach((hour) => {
          hourlyCollectedFilteredData.push({
            day,
            hour,
            value: collectionsDataXY[day][hour]
          });
        });
    });

    const hourlyCollectedChartData = {
      chartData: hourlyCollectedFilteredData,
      dataExists: dataExists
    };

    // Dwell time data
    let hourIterator = 1;
    while (hourIterator < highestHour) {
      if (!terminalStates[hourIterator]) {
        terminalStates[hourIterator] = {
          collections: 0,
          unsuccessful: 0
        };
      }
      hourIterator++;
    }

    const dwellTimeFilteredData = [];
    Object.keys(terminalStates)
      .forEach((key) => {
        dwellTimeFilteredData.push({
          dwellTimeGroup: i18n.t(key * HOUR_BAR_GAP),
          ...terminalStates[key]
        });
      });

    const averageDwellTime = totalDwellTime / (collectedCountFiltered + unsuccessfulCountFiltered);
    const dwellTimeChartData = {
      chartData: dwellTimeFilteredData,
      averageValue: averageDwellTime,
      dataExists: !!averageDwellTime
    };

    // Daily breakdown data
    const dailyBreakdownFilteredData = [];
    Object.keys(collectedPerDay)
      .forEach((key) => {
        dailyBreakdownFilteredData.push({
          dayNameShort: i18n.t(DAYS_NAMES_SHORT[key]),
          collected: collectedPerDay[key],
          unsuccessful: unsuccessfulPerDay[key],
          day: i18n.t(DAYS_NAMES_SHORT[key])
        });
      });

    const dailyBreakdownChartData = {
      chartData: dailyBreakdownFilteredData,
      averageCollected: this.calculateWorkWeekAvg(collectedPerDay),
      averageUnsuccessful: this.calculateWorkWeekAvg(unsuccessfulPerDay),
      dataExists: !!collectedPerDay || !!unsuccessfulPerDay
    };

    // Point distance data
    let distanceIterator = 0;
    while (stopsGroupedByDistance[distanceIterator] < longestDistance) {
      if (!stopsGroupedByDistance[distanceIterator]) {
        stopsGroupedByDistance[distanceIterator] = {
          collections: 0,
          unsuccessful: 0
        };
      }
      distanceIterator++;
    }

    const pointDistanceFilteredData = [];
    Object.keys(stopsGroupedByDistance)
      .forEach((key) => {
        pointDistanceFilteredData.push({
          distanceGroup: key,
          ...stopsGroupedByDistance[key]
        });
      });

    const averageDistance = totalDistance / (collectedCountDistanceRelevant + unsuccessfulCountDistanceRelevant);
    const pointDistanceChartData = {
      chartData: pointDistanceFilteredData,
      averageValue: averageDistance,
      dataExists: !!averageDistance
    };

    return {
      hourlyCollectedChartData: hourlyCollectedChartData,
      dwellTimeChartData: dwellTimeChartData,
      dailyBreakdownChartData: dailyBreakdownChartData,
      pointDistanceChartData: pointDistanceChartData
    };
  };

  calculateWorkWeekAvg = (weekData) => {
    let result = 0;
    let numberOfWorkingDays = 0;
    Object.keys(weekData)
      .forEach((day) => {
        result += weekData[day];
        if (weekData[day] > 0) {
          numberOfWorkingDays += 1;
        }
      });

    return result === 0 ? 0 : result / numberOfWorkingDays;
  };

  render() {
    const stopCounters = this.stopCounters(
      this.shipmentsData,
      this.props.selectedHours,
      this.props.selectedDay,
      this.props.selectedDwellTimeInterval,
      this.props.selectedPointDistanceInterval,
      this.state.showCollected,
      this.state.showUnsuccessful
    );

    const hideCollectionChartCondition = !this.state.showCollected
    || (!!this.props.selectedDay && !this.props.selectedHours)
    || this.props.selectedPointDistanceInterval
    || this.props.selectedDwellTimeInterval;

    const hideCapacityChartsCondition = !this.state.showCollected
    || !this.state.showUnsuccessful
    || this.props.selectedDay
    || this.props.selectedDwellTimeInterval
    || this.props.selectedPointDistanceInterval;

    return (
      <div className="home">
        <SimpleBar style={{ maxHeight: '100%' }} className="home-data-section">
          <div className="filters-section">
            <div className="team-selection-wrapper">
              <div className="label">{this.props.t('Team')}</div>
              <TeamSelect onTeamChange={this.fetchPackageLockers} eventTrackerNamePrefix="OOH Point Analysis" teamTypeFilter={[ENTITY_TYPE.PACKAGE_LOCKERS]} />
            </div>
            <div className="courier-selection-wrapper">
              <div className="label">{this.props.t('OOH Point')}</div>
              <EntitySelect
                entitiesData={this.oohPointsData}
                eventTrackerNamePrefix="OOH Point Analysis"
                onChange={this.onOohPointChange}
                entityType={ENTITY_TYPE.PACKAGE_LOCKERS}
              />
            </div>
            <div className="date-filters">
              <div className="label">{this.props.t('Date range')}</div>
              <DatePickerWrapper onDateChange={this.onDateChanged} selectedDays={[this.startDate, this.endDate]} />
            </div>
          </div>
          <div className="filters-section">
            <div className="ooh-info-wrapper">
              <div className="label">
                {this.props.t('Capacity')}
                :
                &nbsp;
                {this.oohPointCapacity}
              </div>
              <div className="label">
                {this.props.t('Working hours')}
                :
                &nbsp;
                {this.workingHours}
              </div>
            </div>
          </div>

          {this.state.oohPoint ? (
            <div className="data-section">
              {this.shipmentsData ? (
                <div className="data-cards">
                  <DataCard
                    title={this.props.t('Collected')}
                    value={this.collections}
                    filteredValue={stopCounters.collectedCounter}
                    toggleData={this.onShowCollectedChanged}
                    initialChecked={this.state.showCollected}
                    borderColor={colorsAndFonts.deliveries_color}
                    toggleClass="collected"
                  />
                  <DataCard
                    title={this.props.t('Unsuccessful')}
                    value={this.unsuccessful}
                    filteredValue={stopCounters.unsuccessfulCounter}
                    toggleData={this.onShowUnsuccessfulChanged}
                    initialChecked={this.state.showUnsuccessful}
                    borderColor={colorsAndFonts.warning_color}
                    toggleDisabled={this.props.chartId === 'hourly-collections-heat-map'}
                    toggleClass="unsuccessful"
                  />
                  <DataCard
                    title={this.props.t('Utilization')}
                    value={this.getUtilizationHighlightedMetricValue()}
                    borderColor={colorsAndFonts.tile_background_color}
                  />
                </div>
              ) : (
                <div className="data-cards-loader">
                  <CircularProgress />
                </div>
              )}
              {/* Utilization by day of week */}
              <ChartWrapper
                dataExists={this.state.chartData?.utilizationChartData?.dataExists}
                loadingData={!this.shipmentsData?.snapshots}
                popupTranslationKey="UtilizationBarChartInfoText"
                title="Utilization by day of week"
                eventName="OOH Point Analysis - Info - Utilization by day of week"
                className={hideCapacityChartsCondition ? 'chart-fade-out' : ''}
                hideResetButton
              >
                <UtilizationWeeklyChart
                  dataKeys={['utilization']}
                  width={450}
                  height={this.chartHeight}
                  data={this.state.chartData.utilizationChartData}
                  containerId="ooh-analysis-utilization-chart"
                  keys={['utilization']}
                />
              </ChartWrapper>
              {/* Vacant spaces by hour of day */}
              <ChartWrapper
                dataExists={this.state.chartData?.vacantSpacesChartData?.dataExists}
                loadingData={!this.shipmentsData?.snapshots}
                popupTranslationKey="VacantSpacesHeatMapInfoText"
                title="Vacant spaces by hour of day"
                eventName="OOH Point Analysis - Info - Collected by hour of day"
                className={hideCapacityChartsCondition ? 'chart-fade-out' : ''}
                hideResetButton
              >
                <HeatMapChart
                  width={430}
                  height={250}
                  data={this.state.chartData.vacantSpacesChartData}
                  containerId="vacant-spaces-heat-map"
                  capacity={this.oohPointCapacity}
                  keys={['value']}
                />
              </ChartWrapper>
              {/* Collection by hour of day */}
              <ChartWrapper
                dataExists={this.state.chartData?.hourlyCollectedChartData?.dataExists}
                loadingData={!this.shipmentsData?.terminalStates}
                popupTranslationKey="HourlyCollectionsHeatMapInfoText"
                title="Collection by hour of day"
                eventName="OOH Point Analysis - Info - Collected by hour of day"
                className={hideCollectionChartCondition ? 'chart-fade-out' : ''}
              >
                <HeatMapChart
                  width={430}
                  height={250}
                  data={this.state.chartData.hourlyCollectedChartData}
                  showCollected={this.state.showCollected}
                  containerId="hourly-collections-heat-map"
                  capacity={this.oohPointCapacity}
                  scaleByMaxValue
                  keys={['value']}
                />
              </ChartWrapper>
              {/* Dwell time */}
              {(this.state.showCollected || this.state.showUnsuccessful) && (
              <ChartWrapper
                dataExists={this.state.chartData?.dwellTimeChartData?.dataExists}
                loadingData={!this.shipmentsData?.terminalStates}
                popupTranslationKey="DwellTimeBarChartInfoText"
                title="Dwell time"
                eventName="OOH Point Analysis - Info - Stops by day of week"
              >
                <IntervalBarChart
                  width={450}
                  height={this.chartHeight}
                  data={this.state.chartData.dwellTimeChartData}
                  showUnsuccessful={this.state.showUnsuccessful}
                  showCollected={this.state.showCollected}
                  containerId="ooh-analysis-dwell-time-chart"
                  chartName="dwellTimeChart"
                />
              </ChartWrapper>
              )}
              {/* Breakdown by day of week */}
              {(this.state.showCollected || this.state.showUnsuccessful) && (
                <ChartWrapper
                  dataExists={this.state.chartData?.dailyBreakdownChartData?.dataExists}
                  loadingData={!this.shipmentsData?.terminalStates}
                  popupTranslationKey="DailyBreakdownBarChartInfoText"
                  title="Breakdown by day of week"
                  eventName="OOH Point Analysis - Info - Stops by day of week"
                >
                  <DailyBreakdownChart
                    width={450}
                    height={this.chartHeight}
                    data={this.state.chartData.dailyBreakdownChartData}
                    showUnsuccessful={this.state.showUnsuccessful}
                    showCollected={this.state.showCollected}
                    containerId="ooh-analysis-bar-chart"
                  />
                </ChartWrapper>
              ) }
              {/* Point distance */}
              {(this.state.showCollected || this.state.showUnsuccessful) && (
                <ChartWrapper
                  dataExists={this.state.chartData?.pointDistanceChartData?.dataExists}
                  loadingData={!this.shipmentsData?.terminalStates}
                  popupTranslationKey="PointDistanceBarChartInfoText"
                  title="Point distance"
                  eventName="OOH Point Analysis - Info - Distances from OOH point"
                >
                  <IntervalBarChart
                    width={450}
                    height={this.chartHeight}
                    data={this.state.chartData.pointDistanceChartData}
                    showUnsuccessful={this.state.showUnsuccessful}
                    showCollected={this.state.showCollected}
                    containerId="ooh-analysis-point-distance-chart"
                    chartName="pointDistanceChart"
                  />
                </ChartWrapper>
              )}
            </div>
          ) : (
            <div className="no-data">
              <div className="no-data-text">{this.props.t('No results found')}</div>
              <div className="no-data-call-to-action">{this.props.t('Please select a package locker')}</div>
            </div>
          )}
        </SimpleBar>
        <div className="map-section">
          {this.state.oohPoint ? (
            <MapWrapper
              hasStops={!this.state.isLoadingStops}
              stopsData={this.shipmentsData?.terminalStates}
              showCollected={this.state.showCollected}
              showUnsuccessful={this.state.showUnsuccessful}
              teamId={this.state.teamId}
              oohPoint={this.state.oohPoint}
              entityType="COURIER"
              distanceType={this.distanceType}
            />
          ) : (
            <i className="logo icon icon-mily-logo" />
          )}
        </div>

        <Popover
          id="simple-popover"
          open={this.props.isInfoDialogOpen}
          anchorEl={this.props.infoDialogAnchorEl}
          onClose={this.props.dispatchCloseInfoDialog}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          PaperProps={{ square: true }}
        >
          {React.createElement(this.props.component)}
        </Popover>
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} redux store
 */
function mapStateToProps(store) {
  return {
    ...store.chartState,
    ...store.infoDialogState,
    ...store.mapState,
    ...store.authState
  };
}

/**
 * @param {Function} dispatch - redux dispatch
 * @returns {object} redux actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchCloseInfoDialog: InfoDialogActions.closeInfoDialog,
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchResetChartData: CharActions.resetData,
    },
    dispatch
  );
}

const OOHAnalysis = connect(mapStateToProps, mapDispatchToProps)(OOHAnalysisClass);

export default withTranslation('translations')(OOHAnalysis);

OOHAnalysisClass.propTypes = {
  /**
   * Is map loading
   */
  isMapLoading: PropTypes.bool,
  /**
   * Set is map loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Change selected dates
   */
  dispatchResetChartData: PropTypes.func.isRequired,
  /**
   * Selected hours
   */
  selectedHours: PropTypes.object,
  /**
   * Selected days
   */
  selectedDay: PropTypes.object,
  /**
   * Selected dwell time interval
   */
  selectedDwellTimeInterval: PropTypes.object,
  /**
   * Selected point distance interval
   */
  selectedPointDistanceInterval: PropTypes.object,
  /**
   * Chart id where filter is applied
   */
  chartId: PropTypes.string,
  /**
   * Show info dialog
   */
  isInfoDialogOpen: PropTypes.bool,
  /**
   * Parent element for info dialog
   */
  infoDialogAnchorEl: PropTypes.element,
  /**
   * Close info dialog
   */
  dispatchCloseInfoDialog: PropTypes.func.isRequired,
  /**
   * Info dialog component
   */
  component: PropTypes.func.isRequired
};

OOHAnalysisClass.defaultProps = {
  isMapLoading: true,
  selectedHours: null,
  selectedDay: null,
  selectedDwellTimeInterval: null,
  selectedPointDistanceInterval: null,
  chartId: null,
  isInfoDialogOpen: false,
  infoDialogAnchorEl: null
};
