import React from 'react';
import * as d3 from 'd3';
import './BarChart.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as CharActions from '../../../../state/actions/chartActions';

class UtilizationWeeklyChartClass extends React.Component {
  componentDidMount() {
    this.drawChart(this.props.keys);
  }

  componentDidUpdate(prevProps) {
    let shouldRedrawChart = false;
    if (prevProps.t !== this.props.t) {
      this.props.dispatchResetChartData();
      shouldRedrawChart = true;
    }

    if (prevProps.resetBarChart !== this.props.resetBarChart && this.props.chartId !== this.props.containerId) {
      shouldRedrawChart = true;
    }

    if (prevProps.data.chartData !== this.props.data.chartData && this.props.chartId !== this.props.containerId) {
      shouldRedrawChart = true;
    }

    if (shouldRedrawChart) {
      this.drawChart(this.props.keys);
    }
  }

  drawChart = (keys) => {
    const margin = { top: 10, right: 10, bottom: 20, left: 35 };
    const { width } = this.props;
    const { height } = this.props;
    const data = this.props.data.chartData;
    const averageUtilization = this.props.data.averageUtilization;

    const { t } = this.props;

    d3.select(`#${this.props.containerId} svg`).remove('svg');
    d3.select(`#${this.props.containerId} .tooltip`).remove('.tooltip');

    const svg = d3.select(`#${this.props.containerId}`).append('svg').attr('width', width).attr('height', height);

    const groupKey = 'day';
    const color = { utilization: '#72BEF4' };

    const x0 = d3
      .scaleBand()
      .domain(data.map((d) => d[groupKey]))
      .rangeRound([margin.left, width - margin.right])
      .paddingInner(0.1);

    const x1 = d3.scaleBand().domain(this.props.keys).rangeRound([0, x0.bandwidth()]).padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d3.max(this.props.keys, (key) => d[key]))])
      .nice()
      .rangeRound([height - margin.bottom, margin.top]);

    const xAxis = (g) => g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .attr('class', 'axis')
      .call(d3.axisBottom(x0).tickSizeOuter(0))
      .call((g1) => g1.select('.domain').remove());

    const yAxis = (g) => g
      .attr('transform', `translate(${margin.left}, 0)`)
      .attr('class', 'axis')
      .call(d3.axisLeft(y).tickFormat(d3.format('.0%')))
      .append('text');

    const yGrid = (g) => g
      .attr('transform', `translate(${margin.left}, 0)`)
      .attr('class', 'grid-lines')
      .selectAll('line')
      .data(y.ticks())
      .join('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', (d) => y(d) + 0.5)
      .attr('y2', (d) => y(d) + 0.5);

    const tooltip = d3.select(`#${this.props.containerId}`).append('div').attr('class', 'tooltip').style('visibility', 'hidden');

    svg.append('g').call(xAxis);

    svg.append('g').call(yAxis);

    svg.append('g').call(yGrid);

    // add average utilization
    svg
      .append('g')
      .append('line')
      .attr('transform', `translate(0, ${y(averageUtilization / 100)})`)
      .attr('x2', width)
      .attr('x1', margin.left)
      .style('stroke', color.utilization)
      .style('stroke-width', '2px')
      .on('mouseover', () => {
        tooltip.style('visibility', 'visible');
      })
      .on('mousemove', function () {
        tooltip
          .html(`<div class="utilization">${t('Average utilization')} ${averageUtilization}%</div>`)
          .style('left', `${d3.mouse(this)[0] < (margin.left + width / 2) ? d3.mouse(this)[0] + 20 : d3.mouse(this)[0] - 150}px`)
          .style('top', `${y(averageUtilization / 100) - 40}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('visibility', 'hidden');
      });

    svg
      .append('g')
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('class', 'text-bar')
      .attr('transform', (d) => `translate(${x0(d[groupKey])},0)`)
      .selectAll('rect')
      .data((d) => keys.map((key) => ({ key, value: d[key] })))
      .join('rect')
      .attr('class', 'my-bar')
      .attr('x', (d) => x1(d.key))
      .attr('y', (d) => y(d.value))
      .attr('xEnd', (d) => x0(d.day))
      .attr('width', x1.bandwidth())
      .attr('height', (d) => y(0) - y(d.value))
      .attr('fill', (d) => color[d.key])
      .on('mouseover', () => {
        tooltip.style('visibility', 'visible');
      })
      .on('mousemove', function (d) {
        const bar = this;
        const ctm = bar.getCTM();
        const coords = {};
        coords.x = bar.getAttribute('x');
        coords.y = bar.getAttribute('y');
        const barWidth = bar.getAttribute('width');

        const x = ctm.e + coords.x * ctm.a + coords.y * ctm.c;
        tooltip
          .html(`<div class="utilization">${t('Utilization')}: ${Math.ceil(d.value * 100)}%</div>`)
          .style('left', `${x < (margin.left + width / 2) ? x + parseInt(barWidth, 10) + 10 : x - 110 - parseInt(barWidth, 10)}px`)
          .style('top', `${Math.min(d3.mouse(this)[1], height - 100)}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('visibility', 'hidden');
      });
  };

  render() {
    return (
      <div className="bar-chart">
        <div id={this.props.containerId} />
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} redux store
 */
function mapStateToProps(store) {
  return { ...store.chartState };
}

/**
 * @param {Function} dispatch - redux dispatch
 * @returns {object} redux dispatch actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchResetChartData: CharActions.resetData },
    dispatch
  );
}

UtilizationWeeklyChartClass.propTypes = {
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Change selected dates
   */
  dispatchResetChartData: PropTypes.func.isRequired,
  /**
   * Reset bar chart
   */
  resetBarChart: PropTypes.instanceOf(Date),
  /**
   * Chart width
   */
  width: PropTypes.number.isRequired,
  /**
   * Chart height
   */
  height: PropTypes.number.isRequired,
  containerId: PropTypes.string.isRequired,
  chartId: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string),
  /**
   * Chart data
   */
  data: PropTypes.shape({
    chartData: PropTypes.arrayOf(PropTypes.shape({})),
    dataExists: PropTypes.bool,
    averageUtilization: PropTypes.number
  }).isRequired
};

UtilizationWeeklyChartClass.defaultProps = {
  resetBarChart: null,
  keys: null,
  chartId: null
};

const UtilizationWeeklyChart = connect(mapStateToProps, mapDispatchToProps)(UtilizationWeeklyChartClass);

export default withTranslation('translations')(UtilizationWeeklyChart);
