import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';
import MapMarkerPopup from '../../popups/components/MapMarkerPopup';

import MapMarker from '../MapMarker';
import MultipleMarkersPopup from '../components/MultipleMarkersPopup';

export const MapMarkerUtil = { addMarkerToMap: addMarkerToMap };

function addMarkerToMap(map, coordinates, markerProps, markerPopupData, onHoverPopup) {
  const markerElement = document.createElement('div');
  ReactDOM.render(
    <MapMarker
      icon={markerProps.icon}
      text={`${markerProps.text}`}
      id={`${markerProps.id}`}
      mapPointIconData={markerProps.mapPointIconData}
      cssClass={markerProps.cssClass}
      onMouseEnter={markerProps.onMouseEnter}
      onMouseLeave={markerProps.onMouseLeave}
      backgroundImageUrl={markerProps.backgroundImageUrl}
      onClick={markerProps.onClick}
      withPulsingAnimation={markerProps.withPulsingAnimation}
      additionalNumber={markerProps.additionalNumber}
      onClickLogMessage={markerProps.onClickLogMessage}
      onHoverPopupData={
        onHoverPopup
          ? {
            map: map,
            coordinates: coordinates,
            getHoverPopup: onHoverPopup
          }
          : null
      }
    />,
    markerElement
  );

  const marker = new mapboxgl.Marker(markerElement).setLngLat(coordinates || [0, 0]).addTo(map);
  if (!onHoverPopup && markerPopupData) {
    const placeholder = document.createElement('div');
    if (Array.isArray(markerPopupData)) {
      ReactDOM.render(<MultipleMarkersPopup data={markerPopupData} />, placeholder);
    } else {
      ReactDOM.render(<MapMarkerPopup data={markerPopupData} />, placeholder);
    }
    const popup = new mapboxgl.Popup({ maxWidth: '500px', offset: 8 }).setDOMContent(placeholder);
    marker.setPopup(popup);
  }

  return marker;
}
