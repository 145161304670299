import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EN from './translations/en_US.json';
import SR from './translations/sr_SR.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // we init with resources
    detection: { order: ['localStorage', 'cookie', 'navigator', 'querystring', 'htmlTag', 'path', 'subdomain'] },
    resources: {
      en: { translations: EN },
      sr: { translations: SR }
    },
    fallbackLng: 'en',
    debug: false,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },
    react: { wait: true }
  });
export default i18n;
