import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import HeatMapChart from './HeatMapChart';
import './HeatMapChartWrapper.scss';
import stopsUtil from '../../utils/stopsUtil';
import InfoDialogWrapper from '../../../../common/components/dialogs/infoDialog/InfoDialogWrapper';

/**
 * @param {object} props - component props
 * @returns {JSX.Element} HeatMapChartWrapper component
 */
export default function HeatMapChartWrapper(props) {
  const { t } = useTranslation();
  const { data, showDelivery, showPickup, containerId } = props;

  const finalData = [];

  const stopsDayHourData = () => {
    const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const deliveriesDataXY = {};
    const pickupDataXY = {};
    const activeHours = new Set();

    days.forEach((day) => {
      hours.forEach((hour) => {
        if (!deliveriesDataXY[day]) {
          deliveriesDataXY[day] = [];
        }

        if (!pickupDataXY[day]) {
          pickupDataXY[day] = [];
        }

        deliveriesDataXY[day][hour] = 0;
        pickupDataXY[day][hour] = 0;
      });
    });

    if (data && data.stops) {
      data.stops.forEach((stop) => {
        if (stopsUtil.getStopType(stop.event) === 'delivery') {
          deliveriesDataXY[stop.dayOfTheWeek][stop.hour] += 1;
        } else {
          pickupDataXY[stop.dayOfTheWeek][stop.hour] += 1;
        }
        activeHours.add(stop.hour);
      });
    }

    days.forEach((day) => {
      Array.from(activeHours.values())
        .sort((a, b) => a - b)
        .forEach((hour) => {
          let hourStops = 0;
          if (showDelivery) {
            hourStops += deliveriesDataXY[day][hour];
          }

          if (showPickup) {
            hourStops += pickupDataXY[day][hour];
          }

          finalData.push({
            day,
            hour,
            value: hourStops,
            deliveries: deliveriesDataXY[day][hour],
            pickups: pickupDataXY[day][hour]
          });
        });
    });

    return finalData;
  };

  return (
    <div className="heat-map-wrapper">
      <InfoDialogWrapper title={t('Stops by hour of day')} popupText={t('HeatMapInfoText')} eventName="Courier Analysis - Info - Stops by hour of day" />
      {data && data.stops && data.stops.length > 0 ? (
        <HeatMapChart data={stopsDayHourData()} showDelivery={showDelivery} showPickup={showPickup} width={430} height={230} containerId={containerId} />
      ) : (
        <div className="no-data">
          <div className="no-data-text">{t('No data for selected dates')}</div>
        </div>
      )}
    </div>
  );
}

HeatMapChartWrapper.propTypes = {
  /**
   * Chart data
   */
  data: PropTypes.shape({ stops: PropTypes.arrayOf(PropTypes.shape({})) }),
  /**
   * Flag for showing pickups on chart
   */
  showPickup: PropTypes.bool,
  /**
   * Flag for showing deliveries on chart
   */
  showDelivery: PropTypes.bool,
  containerId: PropTypes.string
};

HeatMapChartWrapper.defaultProps = {
  data: null,
  showPickup: true,
  showDelivery: true,
  containerId: null
};
