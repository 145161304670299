import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './RoutePreviewPage.scss';
import csv from 'jquery-csv';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import { raygunClient } from '../../../../setup/raygunClient';
import PlanningApi from '../../api/planningApi';
import StepInfoHeader from '../components/StepInfoHeader';
import * as PageActions from '../../../../state/actions/pageActions';
import * as PlanningPageActions from '../../../../state/actions/planningPageActions';
import S3Util from '../../../../common/utils/s3Util';
import MixPanel from '../../../../setup/mixPanel';
import PlanPreview from '../components/PlanPreview';
import BackendResourceConfigUtil from '../../../../common/utils/api/backendResourceConfigUtil';
import { H3_HEX_RESOLUTION } from '../../../../common/constants/mapConstants';
import CardMenuTable from '../../../../common/components/tables/cardMenuTable/CardMenuTable';
import PreviewCard from '../components/PreviewCard';

export default function RoutePreviewPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const regionsData = useSelector((state) => state.planningPageState.regionsData);
  const shipmentsData = useSelector((state) => state.planningPageState.shipmentsData);
  const shipmentsFileName = useSelector((state) => state.planningPageState.shipmentsFileName);

  const planDate = history?.location?.state?.planDate;
  const startHub = history?.location?.state?.startHub;
  const endHub = history?.location?.state?.endHub || startHub;
  const departureTime = history?.location?.state?.departureTime;
  const regionHexagonsFileKey = history?.location?.state?.regionHexagonsFileKey;
  const courierId = history?.location?.state?.courierId;
  const courierFullName = history?.location?.state?.courierFullName;
  const teamName = history?.location?.state?.teamName;

  const [route, setRoute] = useState(null);
  const [shipments, setShipments] = useState(null);
  const [tablePageSize, setPageSize] = useState(1);
  const [clickedRoute, setClickedRoute] = useState(null);
  const ws = useRef(null);

  const nextStep = () => {
    if (route) {
      const routeData = route.map((r, index) => ({
        id: index,
        ...r
      }));
      MixPanel.track('Delivery plans - Plan created', { date: planDate, courierId: courierId, teamName: teamName, courierName: courierFullName });
      dispatch(PlanningPageActions.saveRoutesData(routeData));
    }

    const oldState = history?.location?.state;
    history.push({ pathname: '/delivery-plan', state: { ...oldState, planDate: planDate } });
  };

  const getRoutesFromFiles = (routes) => {
    let promises = [];

    promises = routes.map((file) => {
      return S3Util.getFileFromS3(file, { download: true, bucket: BackendResourceConfigUtil.getPlanningBucketName(), cacheControl: 'no-cache' }).then(
        (content) => {
          return JSON.parse(content);
        }
      );
    });

    Promise.all(promises)
      .then((routesFromFile) => {
        const routeData = [];
        let regionDataElement;
        routesFromFile.forEach((routeFromFile) => {
          regionDataElement = regionsData.find((region) => region.regionId === routeFromFile.regionId);
          routeData.push({ ...routeFromFile, name: regionDataElement.name });
        });
        setRoute(routeData);
      })
      .catch((err) => {
        raygunClient.send(err, 'Error loading route files');
        toast.error(t('Oops something went wrong'));
      });
  };

  const getShipmentsFromFiles = (orderedShipments) => {
    let promises = [];

    promises = orderedShipments.map((file) => {
      return S3Util.getFileFromS3(file, { download: true, bucket: BackendResourceConfigUtil.getPlanningBucketName(), cacheControl: 'no-cache' }).then(
        (content) => {
          return csv.toObjects(content, { separator: ';' });
        }
      );
    });

    Promise.all(promises).then((res) => {
      setShipments(res);
    });
  };

  const onMessage = (e) => {
    if (e.data) {
      const response = JSON.parse(e.data);

      if (response && response.clientData) {
        getRoutesFromFiles(response.clientData.optimizedRoutes);
        getShipmentsFromFiles(response.clientData.orderedShipment);
      } else if (response && response.error) {
        raygunClient.send(response.error, 'Error reading websocket message');
        toast.error(t('Oops something went wrong'));
      }
    }

    if (ws?.current) {
      ws.current.close();
    }

    dispatch(PageActions.loadingPage(false));
  };

  useEffect(() => {
    async function getOptRoutes() {
      if (process.env.REACT_APP_DATA_SOURCE === 'api') {
        if (shipmentsData) {
          dispatch(PageActions.loadingPage(true));
          delete startHub.__typename;
          delete endHub.__typename;
          await PlanningApi.invokeRouteOptimizationSM(
            shipmentsFileName,
            H3_HEX_RESOLUTION,
            history.location.state.teamId,
            planDate,
            startHub,
            endHub,
            departureTime,
            regionHexagonsFileKey,
            courierFullName
          )
            .then(() => {
              ApiHelpers.connectToWebSocket(shipmentsFileName, onMessage)
                .then((wsRes) => {
                  ws.current = wsRes;
                })
                .catch((e) => {
                  raygunClient.send(e, 'Error connecting to websocket');
                  toast.error(t('Oops something went wrong'));
                  dispatch(PageActions.loadingPage(false));
                });
            })
            .catch((e) => {
              raygunClient.send(e, 'Failed to get optimized routes');
              toast.error(t('Oops something went wrong'));
              dispatch(PageActions.loadingPage(false));
            });
        } else {
          // Fix in case we refresh this page
          dispatch(PageActions.loadingPage(false));
        }
      } else {
        const getTestData = async () => {
          const route1Promise = fetch('/testData/planning-per-courier/planPreview/optimized_route.json').then((data) => data.json());

          const orderedShipments1Promise = fetch('/testData/planning-per-courier/planPreview/ordered_shipments.csv').then(async (data) => {
            return csv.toObjects(await data.text(), { separator: ';' });
          });

          await Promise.all([route1Promise]).then((results) => {
            setRoute(results);
          });
          await Promise.all([orderedShipments1Promise]).then((results) => {
            setShipments(results);
          });

          dispatch(PageActions.loadingPage(false));
        };

        await getTestData();
      }
    }

    getOptRoutes();

    return () => {
      ApiHelpers.closeWebSocketConnection(ws?.current);
    };
  }, [
    dispatch,
    shipmentsData,
    shipmentsFileName,
    planDate,
    startHub,
    endHub,
    history.location.state.teamId,
    courierFullName,
    regionHexagonsFileKey,
    departureTime
  ]);

  const getTableColumns = useMemo(
    () => [
      {
        Header: null,
        accessor: 'name'
      },
      { accessor: 'regionId' },
      {
        Header: () => <i className="icon icon-alarm" />,
        accessor: 'duration',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <span className="duration">
            {(row.values.duration / 60).toFixed(0)}
            {' '}
            min
          </span>
        )
      },
      {
        Header: () => <i className="icon icon-road" />,
        accessor: 'distance',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          <span className="distance">
            {(row.values.distance / 1000).toFixed(2)}
            {' '}
            km
          </span>
        )
      }
    ],
    []
  );

  const calculateTablePageSize = (height) => {
    if (tablePageSize !== height) {
      setPageSize(height);
    }
  };

  const handleRouteClick = (clickedData) => {
    setClickedRoute(clickedData);
  };

  let stepInfoHeaderMessage = t('You are ready to go');
  if (courierFullName) {
    stepInfoHeaderMessage = t('route-preview-ready-to-go', { courierFullName: courierFullName || 'You' });
  }
  return (
    <div className="route-preview">
      <StepInfoHeader
        message={stepInfoHeaderMessage}
        options={{ cancelButtonHidden: true, returnButtonHidden: true }}
        onNextClick={nextStep}
        nextButtonText={t('Done')}
      />
      <div className="route-preview-content">
        {route && route.length > 1 && (
          <div className="menu-wrapper">
            <div className="menu">
              <div className="table-wrapper">
                <CardMenuTable
                  key={tablePageSize}
                  columns={getTableColumns}
                  data={route}
                  sortBy={[{ id: 'regionId', desc: false }]}
                  pageSize={tablePageSize}
                  hiddenColumns={['regionId']}
                  handleRowClick={handleRouteClick}
                  cardItemComponent={PreviewCard}
                  defaultPageSize={tablePageSize}
                  calculateTablePageSize={calculateTablePageSize}
                />
              </div>
            </div>
          </div>
        )}
        <div className="map-wrapper">
          <PlanPreview
            routeData={route}
            regionsData={regionsData}
            shipmentsData={shipments}
            previousViewState={history.location.state.previousViewState}
            selectedRoute={clickedRoute}
          />
        </div>
      </div>
    </div>
  );
}
