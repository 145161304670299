import { gql } from '@apollo/client';
import { DB_TYPES } from '../../../../common/constants/dbTypes';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';
import { appSyncClient } from '../../../../setup/appSyncClient';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';

const PermissionsApi = {
  getAllRoles: getAllRoles,
  upsertRole: upsertRole,
  getAllFeatures: getAllFeatures,
  getPolicies: getPolicies,
  upsertPolicies: upsertPolicies,
  getZones: getZones,
  upsertZone: upsertZone
};

const GetAllRoles = gql`
  query GetAllRoles($pk: String) {
    getAllRoles(pk: $pk) {
      id
      name
      zoneId
      policyId
    }
  }
`;

const UpsertRole = gql`
  mutation UpsertRole($pk: String, $sk: String, $id: String, $name: String, $policyId: String, $zoneId: String, $type: String) {
    upsertData(pk: $pk, sk: $sk, input: { roleInputData: { id: $id, name: $name, policyId: $policyId, zoneId: $zoneId, type: $type } })
  }
`;

const GetAllFeatures = gql`
  query GetAllFeatures($pk: String) {
    getAllFeatures(pk: $pk) {
      management {
        courierManagement
        hubManagement
        deliveryAreaManagement
        userManagement
        shipmentManagement
        labeling
        zipCodeManagement
      }
      planning {
        planning
        planTracking
      }
      execution {
        realTime
      }
      analytics {
        courierAnalysis
        deliveryAreaAnalysis
        routeAnalysis
        statistics
        oohAnalysis
      }
    }
  }
`;

const GetPolicies = gql`
  query GetPolicies($pk: String) {
    getPolicies(pk: $pk) {
      id
      name
      description
      permissions {
        management {
          courierManagement
          deliveryAreaManagement
          hubManagement
          shipmentManagement
          userManagement
          labeling
          zipCodeManagement
        }
        planning {
          planning
          planTracking
        }
        execution {
          realTime
        }
        analytics {
          courierAnalysis
          routeAnalysis
          statistics
          deliveryAreaAnalysis
          oohAnalysis
        }
      }
    }
  }
`;

const UpsertPolicies = gql`
  mutation UpsertPolicy($pk: String, $sk: String, $id: String, $name: String, $description: String, $permissions: PermissionsInputData, $type: String) {
    upsertPolicy(
      pk: $pk
      sk: $sk
      input: { userPermissionsInputData: { id: $id, name: $name, description: $description, permissions: $permissions, type: $type } }
    )
  }
`;

const GetZones = gql`
  query GetZones($pk: String) {
    getZones(pk: $pk) {
      id
      name
      teamIds
      deliveryAreaIds
      hubIds
      description
    }
  }
`;

const UpsertZones = gql`
  mutation UpsertZones(
    $pk: String
    $sk: String
    $id: String
    $name: String
    $description: String
    $teams: [String]
    $deliveryAreas: [String]
    $hubs: [String]
    $type: String
  ) {
    upsertData(
      pk: $pk
      sk: $sk
      input: {
        userPermissionsInputData: {
          id: $id
          name: $name
          description: $description
          teams: $teams
          deliveryAreas: $deliveryAreas
          hubs: $hubs
          type: $type
        }
      }
    )
  }
`;

async function getAllRoles() {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetAllRoles, { pk: 'ROLE' });
  }

  return null;
}

async function upsertRole(roleId, name, policyId, zoneId) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpsertRole,
      variables: {
        pk: 'ROLE',
        sk: DynamoStringExtensionsUtil.addRolePrefix(roleId),
        id: roleId,
        name: name,
        policyId: policyId,
        zoneId: zoneId,
        type: DB_TYPES.ROLE
      }
    });
  }

  return null;
}

async function getAllFeatures() {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetAllFeatures, { pk: 'PERMISSIONS' });
  }

  return null;
}

async function getPolicies() {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetPolicies, { pk: 'POLICY' });
  }

  return null;
}

async function upsertPolicies(id, data) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpsertPolicies,
      variables: {
        pk: 'POLICY',
        sk: DynamoStringExtensionsUtil.addPolicyPermissionPrefix(id),
        id: id,
        name: data.name,
        description: data.description,
        permissions: data.permissions,
        type: DB_TYPES.POLICY
      }
    });
  }

  return null;
}

async function getZones() {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetZones, { pk: 'ZONE' });
  }

  return [];
}

async function upsertZone(id, data) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpsertZones,
      variables: {
        pk: 'ZONE',
        sk: DynamoStringExtensionsUtil.addZonePrefix(id),
        id: id,
        name: data.name,
        description: data.description,
        hubs: data.hubs,
        teams: data.teams,
        deliveryAreas: data.deliveryAreas,
        type: DB_TYPES.ZONE
      }
    });
  }

  return null;
}

export default PermissionsApi;
