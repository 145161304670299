import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import DynamoStringExtensionsUtil from '../../../common/utils/api/dynamoStringExtensionsUtil';
import { raygunClient } from '../../../setup/raygunClient';
import AppDialogActionsWrapper from '../../../common/components/dialogs/utils/appDialogActionsWrapper';
import StopsUtil from '../../analysis/utils/stopsUtil';
import ShipmentForm from '../../planning/planManagement/components/ShipmentForm';
import { AddressLabelingApi } from '../addressLabeling/api/addressLabelingApi';
import * as PageActions from '../../../state/actions/pageActions';
import ShipmentsApi from './api/shipmentsApi';

import DatePickerWrapper from '../../../common/components/datePickers/DayPickerWrapper';
import TableActions from '../../../common/components/tables/common/TableActions';
import TableComponent from '../../../common/components/tables/tableComponent/TableComponent';
import './ShipmentManagement.scss';
import DeliveryAreaSelect from '../../../common/components/selections/deliveryAreaSelect/DeliveryAreaSelect';

class ShipmentManagementClass extends React.Component {
  constructor() {
    super();

    this.state = {
      shipments: [],
      tablePageSize: 1,
      selectedDeliveryAreaId: 'BG-NB'
    };

    this.currentDate = moment();
  }

  loadShipments = async () => {
    this.props.dispatchLoadingPage(true);
    let res = null;
    let lastEvaluatedKey = null;
    const shipments = [];
    do {
      try {
        const shipmentDate = lastEvaluatedKey ? lastEvaluatedKey.shiSk : DynamoStringExtensionsUtil.addShipmentPrefix(this.currentDate.format('YYYY-MM-DD'));
        // eslint-disable-next-line no-await-in-loop
        res = await ShipmentsApi.getNonCompletedShipmentsFor(this.state.selectedDeliveryAreaId, shipmentDate);
        if (res?.data?.queryShipments?.items && res.data.queryShipments.items.length > 0) {
          const filteredShipments = res.data.queryShipments.items.map((shipment) => ({
            ...shipment,
            lat: shipment.fuzzyAddress && shipment.fuzzyAddress.geoLat,
            lng: shipment.fuzzyAddress && shipment.fuzzyAddress.geoLng
          }));
          shipments.push(...filteredShipments);
        }
        lastEvaluatedKey = JSON.parse(res?.data?.queryShipments?.lastEvaluatedKey);
      } catch (e) {
        raygunClient.send(e, 'Error loading shipments');
        this.props.dispatchLoadingPage(false);
        toast.error(this.props.t('Error loading shipments'));
        lastEvaluatedKey = null;
      }
    } while (lastEvaluatedKey && lastEvaluatedKey.shiSk);
    this.props.dispatchLoadingPage(false);
    this.setState({ shipments: shipments });
  };

  onDateChange = (date) => {
    this.currentDate = moment(date);

    this.loadShipments(this.state.selectedDeliveryAreaId);
  };

  editShipment = (row) => {
    console.log(row);
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: ShipmentForm,
      dialogComponentProps: {
        title: this.props.t('Edit shipment'),
        data: row.values,
        submitCallback: (data) => {
          AddressLabelingApi.addLabelingDataToShipment(data.shipmentCode, StopsUtil.getStopType(row.original.jobType), data.lat, data.lng)
            .then(() => {
              this.setState((oldState) => {
                const newData = oldState.shipments;
                newData[row.index] = {
                  ...newData[row.index],
                  ...data
                };

                return { shipments: newData };
              });
              AddressLabelingApi.saveLabeledShipment(
                data.shipmentCode,
                StopsUtil.getStopType(row.original.jobType),
                data.lat,
                data.lng,
                moment(),
                row.original.region
              );
            })
            .catch((e) => {
              raygunClient.send(e, 'Error geocoding shipment');
              toast.error(this.props.t('Oops, something went wrong'));
            });
        }
      }
    });
  };

  getTableColumns = () => [
    {
      Header: this.props.t('shipmentCode'),
      accessor: 'shipmentCode'
    },
    {
      Header: this.props.t('Name'),
      accessor: 'name'
    },
    {
      Header: this.props.t('Address'),
      accessor: 'address'
    },
    {
      Header: this.props.t('Lat'),
      accessor: 'lat'
    },
    {
      Header: this.props.t('Lng'),
      accessor: 'lng'
    },
    {
      Header: this.props.t('Actions'),
      id: 'actions',
      disableGlobalFilter: true,
      className: 'centered',
      Cell: ({ row }) => <TableActions type="table" row={row} onEdit={this.editShipment} />
    }
  ];

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize !== pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  onDeliveryAreaChange = (deliveryAreaId) => {
    this.loadShipments(deliveryAreaId);
    this.setState({ selectedDeliveryAreaId: deliveryAreaId });
  };

  getAdditionalRowClass = (row) => {
    if (!row.values.lat || !row.values.lng) {
      return 'warning-row';
    }

    return '';
  };

  render() {
    return (
      <div className="shipments-management">
        <div className="filter-wrapper">
          <DeliveryAreaSelect onDeliveryAreaChange={this.onDeliveryAreaChange} selectedDeliveryAreaId="BG-NB" />
          <DatePickerWrapper onDateChange={this.onDateChange} selectedDay={this.currentDate.toDate()} disableFutureDays={false} />
        </div>
        {this.state.shipments && this.state.shipments.length > 0 ? (
          <div className="table-wrapper">
            <TableComponent
              key={this.state.tablePageSize}
              columns={this.getTableColumns()}
              data={this.state.shipments}
              resetOn={this.state.selectedDeliveryAreaId}
              paginationLabel={this.props.t('shipments')}
              getAdditionalRowClass={this.getAdditionalRowClass}
              sortBy={[{ id: 'lat' }]}
              pageSize={this.state.tablePageSize}
              onActionButtonClick={this.loadShipments}
              actionButtonText={this.props.t('Refresh')}
              showSearch
              calculateTablePageSize={this.calculateTablePageSize}
            />
          </div>
        ) : (
          <div>{this.props.t('No results found')}</div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

ShipmentManagementClass.propTypes = {
  dispatchLoadingPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const ShipmentManagement = connect(null, mapDispatchToProps)(ShipmentManagementClass);

export default withTranslation('translations')(ShipmentManagement);
