/**
 * Util for stop related functions
 *
 * @namespace
 * @category Common
 */
import { COLLECTED_EVENT,
  DELIVERY_EVENT,
  RETURN_EVENT,
  UNSUCCESSFUL_EVENT } from '../../../common/constants/shipmentEventsConstants';

const StopUtil = { getStopType: getStopType };

/**
 * Returns stop type based on the provided status.
 *
 * @param {string} event - stop status
 * @returns {string} delivery or pickup
 * @memberOf StopUtil
 * @function
 */
function getStopType(event) {
  // NOTE: keep for backward compatibility with dexpress
  if (event === '1' || event === '20') {
    return 'delivery';
  }

  if (event === DELIVERY_EVENT || event === RETURN_EVENT) {
    return 'delivery';
  }

  if (event === COLLECTED_EVENT) {
    return 'delivery';
  }

  if (event === UNSUCCESSFUL_EVENT) {
    return 'unsuccessful-attempt';
  }

  return 'pickup';
}

export default StopUtil;
