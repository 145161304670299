/**
 * Enum with different values that couriers api can return.
 *
 * @type {object}
 */
export const ENTITY_TYPE = {
  COURIERS: 'COURIER',
  PACKAGE_LOCKERS: 'PACKAGE_LOCKER'
};

export const ENTITY_TYPE_QUERY_STRING = {
  COURIER: 'courierId',
  PACKAGE_LOCKER: 'oohPointId'
};
