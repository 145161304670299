import { gql } from '@apollo/client';
import moment from 'moment/moment';
import AuthUtil from '../../../../common/utils/authUtil';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';

export const STOPS_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH';

export const READ_POINT_OF_INTEREST_EVENT_TYPE = {
  READ_BY_DELIVERY_AREA: 'readByDeliveryArea',
  READ_BY_TEAM: 'readByTeam'
};

export const POINT_OF_INTEREST_TYPE = {
  PARCEL_SHOPS: 'parcelShops',
  PARCEL_LOCKERS: 'parcelLockers',
  GAS_STATIONS: 'gasStations',
};

export function getHexagonsQuery() {
  return gql`
  query GetHexagons($areaId: String, $fromDate: String, $toDate: String, $userMapLayers: [String], $shouldUseCache: Boolean) {
    getHexagonsForDeliveryArea(
      areaId: $areaId,
      fromDate: $fromDate,
      toDate: $toDate,
      userMapLayers: $userMapLayers,
      shouldUseCache: $shouldUseCache,
    ) {
      hexagons {
        ${ApiHelpers.createQueryFieldList(AuthUtil.getRegionAnalysisLayers())}
        id
      },
      cacheLocationS3 {
        bucket,
        fileName
      }
    }
  }
`;
}

export const GetPointsOfInterest = gql`
  query GetPointsOfInterest($groupId: String, $eventType: String, $pointOfInterestType: String) {
    getPointsOfInterest(groupId: $groupId, eventType: $eventType, pointOfInterestType: $pointOfInterestType) {
      count
      pointsOfInterest {
        id
        lat
        lng
        workingHours
        name
        address
        capacity
        company
      }
    }
  }
`;

export const GetOohPointCollections = gql`
  query GetOohPointCollections($pk: String, $skStart: String, $skEnd: String, $operation: String, $indexName: String) {
    getOohPointCollections(pk: $pk, skStart: $skStart, skEnd: $skEnd, operation: $operation, indexName: $indexName)
  }
`;

export async function getOohPointCollections(oohPointId, dateFrom, dateTo) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetOohPointCollections, {
      pk: DynamoStringExtensionsUtil.addOohStopPrefix(oohPointId),
      skStart: DynamoStringExtensionsUtil.addOohStopPrefix(moment(dateFrom).startOf('day').format(STOPS_DATE_TIME_FORMAT)),
      skEnd: DynamoStringExtensionsUtil.addOohStopPrefix(moment(dateTo).endOf('day').format(STOPS_DATE_TIME_FORMAT)),
      operation: 'between',
      indexName: 'gsi1'
    });
  }
  return fetch(`/testData/analysis/ooh/collections/ooh-point-${oohPointId}.json`).then((data) => data.json());
}

const GenerateNetwork = gql`
  query GenerateNetwork(
    $coverage: Float
    $maxLocations: Int
    $layers: [LayerInfoInput]
    $coverageType: String
    $areaId: String
    $distanceType: String
    $forbiddenLocations: [String]
    $allowedLocations: [String]
    $dateFrom: String
    $dateTo: String
    $originalFileKey: String
    $pkgLockerNetworkFile: String
    $layerDataFileName: String
    $pinsNetworkFileKey: String
    $debugMode: Boolean
  ) {
    generateNetwork(
      data: {
        coverage: $coverage
        maxLocations: $maxLocations
        layers: $layers
        coverageType: $coverageType
        areaId: $areaId
        distanceType: $distanceType
        dateFrom: $dateFrom
        dateTo: $dateTo
        originalFileKey: $originalFileKey
        pkgLockerNetworkFile: $pkgLockerNetworkFile
        layerDataFileName: $layerDataFileName
        pinsNetworkFileKey: $pinsNetworkFileKey
        debugMode: $debugMode
        forbiddenLocations : $forbiddenLocations
        allowedLocations : $allowedLocations
      }
    ) {
      resultFileKey
      numberOfSelectedLocations
      coveragePercent
      baseLayerCoveragePercent
    }
 }
`;

export async function generateNetwork(
  coverage,
  maxLocations,
  layers,
  coverageType,
  areaId,
  distanceType,
  dateFrom,
  dateTo,
  originalFileKey,
  currentNetworkFileKey,
  cachedFileName,
  additionalNetworkFileKey,
  unwantedLocations,
  allowedLocations
) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api' || process.env.REACT_APP_SHOWCASE_RECOMMENDER) {
    return ApiHelpers.createQuery(GenerateNetwork, {
      coverage: coverage,
      maxLocations: maxLocations,
      layers: layers,
      coverageType: coverageType,
      areaId: areaId,
      distanceType: distanceType,
      dateFrom: dateFrom,
      dateTo: dateTo,
      originalFileKey: originalFileKey,
      pkgLockerNetworkFile: currentNetworkFileKey,
      layerDataFileName: cachedFileName,
      debugMode: process.env.REACT_APP_DEBUG_RECOMMENDER === 'yes',
      pinsNetworkFileKey: additionalNetworkFileKey,
      forbiddenLocations: unwantedLocations,
      allowedLocations: allowedLocations
    }).then((response) => { return { data: response.data?.generateNetwork, errors: response.errors }; });
  }

  return { result: { data: { invokeSM: { status: 'Ok' } } } };
}

/**
 * Fetches package lockers for specific teamId
 *
 * @param {string} teamId - team ID
 * @returns {Promise<Array.<object>> | null} Array of couriers
 * @async
 * @function
 * @memberOf RegionAnalysisApi
 * @alias getCouriers
 * * @example
 * {
 "count": 34,
 "packageLockers": [
 {
 "id": "8150",
 "firstName": "-",
 "lastName": "-",
 "zone": "BG-BX",
 "name": "NIS petrol - Ada ciganlija",
 "lat": 44.786217,
 "lng": 20.415366,
 "workingHours": 0 - 24,
 }
 ]
 }
 * */

const GetPackageLockersByTeam = gql`
  query GetPackageLockersByTeam($teamId: String) {
    getPackageLockersByTeam(teamId: $teamId) {
      count
      packageLockers {
        id
        lat
        lng
        workingHours
        name
      }
    }
  }
`;

export async function getPackageLockersByTeam(teamId) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetPackageLockersByTeam, { teamId: teamId });
  }

  return fetch(`/testData/management/package_lockers_${teamId}.json`).then((data) => data.json());
}

export const GET_ISOCHRONES_AS_HEXAGONS = gql`
  query GetIsochronesAsHexagons($innerRequest: IsochronesRequestInput, $outerRequest: IsochronesRequestInput, $resolution: Int) {
    innerIsochrone: getIsochronesAsHexagons(request: $innerRequest, resolution: $resolution)
    outerIsochrone: getIsochronesAsHexagons(request: $outerRequest, resolution: $resolution)
  }
`;

export function getParamsForReadPointOfInterestByDA(groupId, pointOfInterestType) {
  return {
    variables: {
      groupId,
      eventType: READ_POINT_OF_INTEREST_EVENT_TYPE.READ_BY_DELIVERY_AREA,
      pointOfInterestType
    }
  };
}
