/**
 * Util for map related functionalities
 *
 * @namespace
 * @category CourierAnalysis
 */
import { POINT_DISTANCE_CHART_BAR_GAPS } from '../constants/chartTypes';

const FiltersUtil = {
  checkDayIntervalSelection: checkDayIntervalSelection,
  checkHourIntervalSelection: checkHourIntervalSelection,
  checkDistanceIntervalSelection: checkDistanceIntervalSelection,
  checkDwellTimeIntervalSelection: checkDwellTimeIntervalSelection,
};

function checkDayIntervalSelection(day, selectedDayInterval) {
  return selectedDayInterval.has(day);
}

function checkHourIntervalSelection(hour, selectedHourIntervals) {
  return selectedHourIntervals.has(`${hour}`);
}

function checkDwellTimeIntervalSelection(dwellTime, selectedDwellTimeInterval) {
  return (selectedDwellTimeInterval.start <= dwellTime) && (dwellTime < selectedDwellTimeInterval.end);
}

function checkDistanceIntervalSelection(geoDistance, selectedPointDistanceInterval) {
  if (!geoDistance) return false;

  const start = POINT_DISTANCE_CHART_BAR_GAPS[selectedPointDistanceInterval.start - 1]
    ? POINT_DISTANCE_CHART_BAR_GAPS[selectedPointDistanceInterval.start - 1] : 0;

  if (selectedPointDistanceInterval.end === POINT_DISTANCE_CHART_BAR_GAPS.length) {
    if (geoDistance > POINT_DISTANCE_CHART_BAR_GAPS[POINT_DISTANCE_CHART_BAR_GAPS.length - 1]) return true;
    return geoDistance > start
      && geoDistance < POINT_DISTANCE_CHART_BAR_GAPS[selectedPointDistanceInterval.end - 1];
  }

  return geoDistance > start
    && geoDistance < POINT_DISTANCE_CHART_BAR_GAPS[selectedPointDistanceInterval.end];
}

export default FiltersUtil;
