import React from 'react';
import * as d3 from 'd3';
import './BarChart.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as CharActions from '../../../../state/actions/chartActions';
import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import MixPanel from '../../../../setup/mixPanel';

const ALL_KEYS = ['collected', 'unsuccessful'];
const BAR_DAY_NUMBER_CONVERSION_MAP = {
  0: 'Monday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Wednesday',
  6: 'Thursday',
  7: 'Thursday',
  8: 'Friday',
  9: 'Friday',
  10: 'Saturday',
  11: 'Saturday',
  12: 'Sunday',
  13: 'Sunday'
};

class DailyBreakdownChartClass extends React.Component {
  componentDidMount() {
    this.allKeys = ALL_KEYS;
    const keys = this.getActiveStopTypes();
    this.drawChart(keys);
  }

  componentDidUpdate(prevProps) {
    let shouldRedrawChart = false;
    if (prevProps.t !== this.props.t) {
      this.props.dispatchResetChartData();
      shouldRedrawChart = true;
    }

    if (prevProps.showCollected !== this.props.showCollected || prevProps.showUnsuccessful !== this.props.showUnsuccessful) {
      shouldRedrawChart = true;
    }

    if (prevProps.resetBarChart !== this.props.resetBarChart && this.props.chartId !== this.props.containerId) {
      shouldRedrawChart = true;
    }

    if (prevProps.data.chartData !== this.props.data.chartData && this.props.chartId !== this.props.containerId) {
      shouldRedrawChart = true;
    }

    if (shouldRedrawChart) {
      const keys = this.getActiveStopTypes();
      this.drawChart(keys);
    }
  }

  getActiveStopTypes() {
    const keys = [];
    if (this.props.showCollected) {
      keys.push('collected');
    }

    if (this.props.showUnsuccessful) {
      keys.push('unsuccessful');
    }
    return keys;
  }

  drawChart = (keys) => {
    const margin = { top: 10, right: 10, bottom: 20, left: 30 };
    const { width } = this.props;
    const { height } = this.props;
    const data = this.props.data.chartData;
    const averageCollected = this.props.data.averageCollected;
    const averageUnsuccessful = this.props.data.averageUnsuccessful;

    const { t } = this.props;

    d3.select(`#${this.props.containerId} svg`).remove('svg');
    d3.select(`#${this.props.containerId} .tooltip`).remove('.tooltip');

    const svg = d3.select(`#${this.props.containerId}`).append('svg').attr('width', width).attr('height', height);

    const groupKey = 'day';
    const color = {
      unsuccessful: colorsAndFonts.warning_color,
      collected: colorsAndFonts.deliveries_color
    };

    const x0 = d3
      .scaleBand()
      .domain(data.map((d) => d[groupKey]))
      .rangeRound([margin.left, width - margin.right])
      .paddingInner(0.1);

    const x1 = d3.scaleBand().domain(this.allKeys).rangeRound([0, x0.bandwidth()]).padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d3.max(this.allKeys, (key) => d[key]))])
      .nice()
      .rangeRound([height - margin.bottom, margin.top]);

    const xAxis = (g) => g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .attr('class', 'axis')
      .call(d3.axisBottom(x0).tickSizeOuter(0))
      .call((g1) => g1.select('.domain').remove());

    const yAxis = (g) => g.attr('transform', `translate(${margin.left}, 0)`).attr('class', 'axis').call(d3.axisLeft(y)).append('text');

    const yGrid = (g) => g
      .attr('transform', `translate(${margin.left}, 0)`)
      .attr('class', 'grid-lines')
      .selectAll('line')
      .data(y.ticks())
      .join('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', (d) => y(d) + 0.5)
      .attr('y2', (d) => y(d) + 0.5);

    const tooltip = d3.select(`#${this.props.containerId}`).append('div').attr('class', 'tooltip').style('visibility', 'hidden');

    svg.append('g').call(xAxis);

    svg.append('g').call(yAxis);

    svg.append('g').call(yGrid);

    // Add brushing
    this.brush = d3.brush();
    svg
      .append('g')
      .attr('class', 'brush')
      .call(
        this.brush // Add the brush feature using the d3.brush function
          .extent([
            [0, 0],
            [width, height]
          ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
          .on('start brush', this.prepareForBrushing)
          .on('end', this.filterData) // Each time the brush selection changes, trigger the 'updateChart' function
      );

    // add average unsuccessful
    svg
      .append('g')
      .append('line')
      .attr('transform', `translate(0, ${y(averageUnsuccessful)})`)
      .attr('x2', width)
      .attr('x1', margin.left)
      .style('stroke', colorsAndFonts.warning_color)
      .style('stroke-width', '2px')
      .style('visibility', this.props.showUnsuccessful ? 'visible' : 'hidden')
      .on('mouseover', () => {
        tooltip.style('visibility', 'visible');
      })
      .on('mousemove', function () {
        tooltip
          .html(`<div class="unsuccessful">${t('Average unsuccessful')} ${averageUnsuccessful.toFixed(1)}</div>`)
          .style('left', `${d3.mouse(this)[0] < (margin.left + width / 2) ? d3.mouse(this)[0] + 20 : d3.mouse(this)[0] - 150}px`)
          .style('top', `${y(averageUnsuccessful) - 40}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('visibility', 'hidden');
      });

    // add average drop-offs
    svg
      .append('g')
      .append('line')
      .attr('transform', `translate(0, ${y(averageCollected)})`)
      .attr('x2', width)
      .attr('x1', margin.left)
      .style('stroke', colorsAndFonts.deliveries_color)
      .style('stroke-width', '2px')
      .style('visibility', this.props.showCollected ? 'visible' : 'hidden')
      .on('mouseover', () => {
        tooltip.style('visibility', 'visible');
      })
      .on('mousemove', function () {
        tooltip
          .html(`<div class="collected">${t('Average collected')} ${averageCollected.toFixed(1)}</div>`)
          .style('left', `${d3.mouse(this)[0] < (margin.left + width / 2) ? d3.mouse(this)[0] + 20 : d3.mouse(this)[0] - 150}px`)
          .style('top', `${y(averageCollected) - 40}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('visibility', 'hidden');
      });

    svg
      .append('g')
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('transform', (d) => `translate(${x0(d[groupKey])},0)`)
      .selectAll('rect')
      .data((d) => keys.map((key) => ({ key, value: d[key] })))
      .join('rect')
      .attr('class', 'my-bar')
      .attr('x', (d) => x1(d.key))
      .attr('y', (d) => y(d.value))
      .attr('xEnd', (d) => x0(d.day))
      .attr('width', x1.bandwidth())
      .attr('height', (d) => y(0) - y(d.value))
      .attr('fill', (d) => color[d.key])
      .attr('cursor', 'crosshair')
      .on('mouseover', () => {
        tooltip.style('visibility', 'visible');
      })
      .on('mousemove', function (d) {
        const bar = this;
        const ctm = bar.getCTM();
        const coords = {};
        coords.x = bar.getAttribute('x');
        coords.y = bar.getAttribute('y');
        const barWidth = bar.getAttribute('width');

        const x = ctm.e + coords.x * ctm.a + coords.y * ctm.c;
        tooltip
          .html(`<div class="${d.key}">${t(d.key)}: ${d.value}</div>`)
          .style('left', `${x < (margin.left + width / 2) ? x + parseInt(barWidth, 10) + 10 : x - 130 - parseInt(barWidth, 10)}px`)
          .style('top', `${Math.min(d3.mouse(this)[1], height - 100)}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('visibility', 'hidden');
      })
      .on('mousedown', () => {
        tooltip.style('visibility', 'hidden');
        const brushElm = svg.select('.brush > .overlay').node();
        const brushSelection = svg.select('.brush > .selection').node();
        const bbox = brushSelection.getBoundingClientRect();
        if (
          brushSelection.style.display !== 'none'
          && d3.event.pageX > bbox.left
          && d3.event.pageX < bbox.left + bbox.width
          && d3.event.pageY > bbox.top
          && d3.event.pageY < bbox.top + bbox.height
        ) {
          // Click happened on a dot, inside the current brush selection, so, don't do anything
          return;
        }

        // Click happened on a dot, with no rectangle selection or outside the rectangle selection
        // so let's start a new selection :
        const newClickEvent = new MouseEvent('mousedown', {
          pageX: d3.event.pageX,
          pageY: d3.event.pageY,
          clientX: d3.event.clientX,
          clientY: d3.event.clientY,
          layerX: d3.event.layerX,
          layerY: d3.event.layerY,
          bubbles: true,
          cancelable: true,
          view: window
        });
        brushElm.dispatchEvent(newClickEvent);
      });
  };

  prepareForBrushing = () => {
    d3.select(`#${this.props.containerId} .tooltip`).style('visibility', 'hidden');
    d3.selectAll(`#${this.props.containerId} .my-bar`).style('opacity', 0.5);
  };

  filterData = () => {
    const barsSelection = d3.selectAll(`#${this.props.containerId} .my-bar`);
    const bars = barsSelection._groups[0];
    if (!d3.event.selection) {
      this.props.dispatchResetChartData();
      d3.selectAll(`#${this.props.containerId} .my-bar`).style('opacity', 1);
      return;
    }

    const [selectionStart, selectionEnd] = d3.event.selection;

    let dayIndexes = new Set();
    bars.forEach((bar, i) => {
      const ctm = bar.getCTM();
      const coords = {};
      coords.x = bar.getAttribute('x');
      coords.y = bar.getAttribute('y');
      const width = bar.getAttribute('width');

      const x = ctm.e + coords.x * ctm.a + coords.y * ctm.c;
      const xEnd = x + parseInt(width, 10);

      let index = i;
      if (this.props.showCollected && !this.props.showUnsuccessful) {
        index = 2 * i;
      } else if (!this.props.showCollected && this.props.showUnsuccessful) {
        index = 2 * i + 1;
      }

      if (x <= selectionEnd[0] && xEnd >= selectionStart[0]) {
        dayIndexes.add(BAR_DAY_NUMBER_CONVERSION_MAP[index]);
        // eslint-disable-next-line no-param-reassign
        bar.style.opacity = 1;
      } else {
        // eslint-disable-next-line no-param-reassign
        bar.style.opacity = 0.5;
      }
    });

    if (dayIndexes.size === 0) {
      dayIndexes = null;
    }

    this.fixHoleDayBarSelection(bars, dayIndexes);

    MixPanel.track('OOH Point Analysis - Daily breakdown chart filter applied');
    this.props.dispatchChangeSelectedDates(dayIndexes, null, this.props.containerId);
    this.brush(d3.select(`#${this.props.containerId}`));
  };

  fixHoleDayBarSelection = (bars, selectedDays) => {
    if (!this.props.showCollected || !this.props.showUnsuccessful) {
      return;
    }

    bars.forEach((bar, i) => {
      if (selectedDays.has(BAR_DAY_NUMBER_CONVERSION_MAP[i])) {
        // eslint-disable-next-line no-param-reassign
        bar.style.opacity = 1;
      }
    });
  };

  render() {
    return (
      <div className="bar-chart">
        <div id={this.props.containerId} />
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} redux store
 */
function mapStateToProps(store) {
  return { ...store.chartState };
}

/**
 * @param {Function} dispatch - redux dispatch
 * @returns {object} redux dispatch actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchChangeSelectedDates: CharActions.changeSelectedData,
      dispatchResetChartData: CharActions.resetData
    },
    dispatch
  );
}

DailyBreakdownChartClass.propTypes = {
  /**
   * Show collected bars
   */
  showCollected: PropTypes.bool,
  /**
   * Show unsuccessful bars
   */
  showUnsuccessful: PropTypes.bool,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Change selected dates
   */
  dispatchChangeSelectedDates: PropTypes.func.isRequired,
  dispatchResetChartData: PropTypes.func.isRequired,
  /**
   * Reset bar chart
   */
  resetBarChart: PropTypes.instanceOf(Date),
  /**
   * Chart width
   */
  width: PropTypes.number.isRequired,
  /**
   * Chart height
   */
  height: PropTypes.number.isRequired,
  chartId: PropTypes.string,
  containerId: PropTypes.string.isRequired,
  /**
   * Chart data
   */
  data: PropTypes.shape({
    chartData: PropTypes.arrayOf(PropTypes.shape({
      collected: PropTypes.number,
      unsuccessful: PropTypes.number
    })),
    averageCollected: PropTypes.number,
    averageUnsuccessful: PropTypes.number
  }).isRequired
};

DailyBreakdownChartClass.defaultProps = {
  showCollected: true,
  showUnsuccessful: true,
  resetBarChart: null,
  chartId: null
};

const DailyBreakdownChart = connect(mapStateToProps, mapDispatchToProps)(DailyBreakdownChartClass);

export default withTranslation('translations')(DailyBreakdownChart);
