import colorsAndFonts from '../../../../resources/colors-and-fonts.scss';
import { HOUR_BAR_GAP } from './chartTypes';

export const INTERVAL_BAR_CHART_PROPS = {
  dwellTimeChart: {
    groupKey: 'dwellTimeGroup',
    barColor: '#0de0e0',
    className: 'dwell-time',
    mixpanelFilterEvent: 'OOH Point Analysis - Dwell time chart filter applied',
    averageValueUnit: 'h',
    getSelectedBarsInterval: (selectedBars) => {
      return {
        dwellTimeInterval: {
          start: selectedBars[0] * HOUR_BAR_GAP,
          end: (selectedBars[selectedBars.length - 1] + 1) * HOUR_BAR_GAP
        }
      };
    }
  },
  pointDistanceChart: {
    groupKey: 'distanceGroup',
    barColor: colorsAndFonts.kashmir_blue,
    className: 'point-distance',
    mixpanelFilterEvent: 'OOH Point Analysis - Point distance chart filter applied',
    averageValueUnit: 'm',
    getSelectedBarsInterval: (selectedBars) => {
      return {
        pointDistanceInterval: {
          start: selectedBars[0],
          end: selectedBars[selectedBars.length - 1]
        }
      };
    }
  }
};
