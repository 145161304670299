import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import i18next from 'i18next';
import * as LanguageActions from '../../../../state/actions/languageActions';
import './LanguageSelection.scss';
import MixPanel from '../../../../setup/mixPanel';
import i18n from '../../../../resources/i18n';
import MilyTooltipWrapper from '../../tooltips/MilyTooltipWrapper';

/**
 * Component for selecting language of the app
 *
 * @param {object} props - set of properties for language picker
 * @returns {JSX.Element} Language selector component
 * @component
 * @category Common
 */
export default function LanguageSelection(props) {
  const { menuVertical, showTextLabel, showingTextLabel, showTooltip } = props;

  const dispatch = useDispatch();

  const [openMenu, setMenuOpen] = useState(false);

  /**
   * Gets current language from i18n localStorage
   *
   * @returns {string} code of current language - sr, en...
   */
  const getCurrentLanguage = () => i18next.language.slice(0, 2);

  /**
   * Toggles visibility of the language menu
   *
   * @function
   */
  const handleMenuToggle = () => {
    setMenuOpen(!openMenu);
  };

  /**
   * Closes language menu
   *
   * @function
   */
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  /**
   * Handles change of language.<br/>
   * Dispatches @see {LanguageActions.changeLanguage} event.
   *
   * @param {object} event - event from language menu whose value is selected language
   * @function
   */
  const handleLanguageChange = (event) => {
    if (menuVertical) {
      window.location.reload(false);
    }
    const language = event.currentTarget.dataset.value;
    dispatch(LanguageActions.changeLanguage(language));
    MixPanel.track(`Language changed to ${language}`);
    handleMenuClose();
  };

  function getMenuClass() {
    let className = menuVertical ? 'menu menu-vertical' : 'menu menu-horizontal';
    if (showingTextLabel) {
      className = `${className} extended`;
    }

    return className;
  }

  return (
    <div className="language-selection" onClick={handleMenuToggle}>
      <div
        className={menuVertical ? 'language-section vertical' : 'language-section'}
        data-tip={`${i18n.t('Change language')}`}
        data-for="language-section"
      >
        <i className="icon icon-language" />
        {getCurrentLanguage()}
      </div>
      {showTextLabel && <div className={showingTextLabel ? 'language-label-extended' : 'language-label'}>{showTextLabel}</div>}
      {!openMenu && showTooltip && <MilyTooltipWrapper id="language-section" className="tooltip language-tooltip" effect="solid" />}
      <div className={getMenuClass()} style={{ display: openMenu ? 'block' : 'none' }}>
        <MenuItem onClick={handleLanguageChange} data-value="en">
          EN
        </MenuItem>
        <MenuItem onClick={handleLanguageChange} data-value="sr">
          SR
        </MenuItem>
      </div>
    </div>
  );
}

LanguageSelection.propTypes = {
  /**
   * If menu language menu should be rendered vertically or horizontally
   */
  menuVertical: PropTypes.bool,
  showTextLabel: PropTypes.string,
  showingTextLabel: PropTypes.bool,
  showTooltip: PropTypes.bool
};

LanguageSelection.defaultProps = {
  menuVertical: false,
  showTextLabel: null,
  showingTextLabel: false,
  showTooltip: false
};
