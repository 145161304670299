import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { Authenticator } from '@aws-amplify/ui-react';
import { ApolloProvider } from '@apollo/client';
import store from './state/store';
import i18n from './resources/i18n';
import AppWrapper from './appLayout/AppWrapper';

import './index.scss';
import { appSyncClient } from './setup/appSyncClient';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Authenticator.Provider>
              <ApolloProvider client={appSyncClient}>
                <AppWrapper />
              </ApolloProvider>
            </Authenticator.Provider>
          </MuiPickersUtilsProvider>
        </Router>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
