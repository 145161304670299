import PropTypes from 'prop-types';
import React from 'react';
import './ViewPointDetailsMenu.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as RegionAnalysisActions from '../../../../../state/actions/regionAnalysisActions';
import MixPanel from '../../../../../setup/mixPanel';
import { POINT_DETAILS_MENU_ITEMS } from '../../constants/menuConstants';
import DistanceTypeUtil, { DISTANCE_VALUE_TYPE_KEYS_ARRAY } from '../../../../../common/utils/distanceTypeUtil';

export default function ViewPointDetailsMenu(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBackButtonClick = () => {
    MixPanel.track('Delivery Area Analysis - View Point Details Menu Back Button Clicked');
    dispatch(RegionAnalysisActions.closePointDetailsMenu());
  };

  const getDataRowValue = (value, hexType = null) => {
    if (!hexType || !value) return value || '/';
    return value[hexType] || '/';
  };

  const getMapPointIcon = () => {
    if (!props.mapPointStaticData) return null;
    const mapPointKey = props.mapPointStaticData.iconType || props.mapPointStaticData.type;

    return (<img alt={`/mapData/${mapPointKey}_logo.svg`} className="background-image" src={`/mapData/${mapPointKey}_logo.svg`} key={mapPointKey} />);
  };

  const getLocationDetailsItems = () => {
    if (!props.mapPointStaticData) return null;

    return Object.keys(POINT_DETAILS_MENU_ITEMS).filter((key) => !!props.mapPointStaticData[key]).map((key) => {
      return (
        <div className="table-item-row" key={key}>
          <div className="item-name">{`${t(POINT_DETAILS_MENU_ITEMS[key])}:`}</div>
          <div className="item-value">{getDataRowValue(props.mapPointStaticData[key])}</div>
        </div>
      );
    });
  };

  const getAreaCoverageItems = () => {
    if (!props.mapPointCoverageData) return null;
    const mode = t(DistanceTypeUtil.getName(props.distanceType));
    const unit = DistanceTypeUtil.getUnit(props.distanceType);
    const area = t('area');
    return DISTANCE_VALUE_TYPE_KEYS_ARRAY.map((key) => {
      const value = DistanceTypeUtil.getDistanceValue(props.distanceType, key);
      return (
        <div className="table-item-row" key={key}>
          <div className="item-name">
            {`${mode} ${value}${unit} ${area}`}
            :
          </div>
          <div className="item-value">
            {
              // TODO(https://app.clickup.com/t/86bwq9upd): Remove the hexType parameter after decoupling of lat/lng
              // from polygon data.
              getDataRowValue(props.mapPointCoverageData[key], props.hexType)
            }
          </div>
        </div>
      );
    });
  };

  return (
    <div className="view-point-details-menu-container">
      <div className="view-point-details-menu">
        <div className="back-button" onClick={handleBackButtonClick}>
          <i className="icon icon-keyboard-arrow-left" />
          {' '}
          {t('Back') }
        </div>
        <div className="table-title">
          {t('Location Details')}
          {getMapPointIcon()}
        </div>
        <div className="table-items">
          {getLocationDetailsItems()}
        </div>
        <div className="table-title">
          {t(`${props.hexType} coverage`)}
        </div>
        <div className="table-items">
          {getAreaCoverageItems()}
        </div>
      </div>
    </div>
  );
}

ViewPointDetailsMenu.propTypes = {
  hexType: PropTypes.string,
  mapPointStaticData: PropTypes.object,
  mapPointCoverageData: PropTypes.object,
  distanceType: PropTypes.string.isRequired
};

ViewPointDetailsMenu.defaultProps = {
  hexType: null,
  mapPointStaticData: null,
  mapPointCoverageData: null
};
