import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace CoordinatesActions
 * @category Actions
 */

/**
 * Creates an action with information on whether or not a page is loading.
 *
 * @param {object} coordinates - new coordinates of the hub pin
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf CoordinatesActions
 * @alias coordinatesChange
 * @function
 */
export const coordinatesChange = (coordinates) => ({
  type: ActionTypes.COORDINATES_CHANGED,
  payload: coordinates
});
