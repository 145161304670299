import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from '../../../../common/components/form/Form';
import { FormInput } from '../../../../common/components/form/components/FormInput';
import Button from '../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../common/components/form/utils/defaultFormDialogCss.scss';
import RadioButton from '../../../../common/components/buttons/radioButton/RadioButton';
import { REPORT_CONSTANTS } from '../constants/reportsForm';
import { BUTTON_TYPES } from '../../../../common/components/buttons/button/constants/buttonTypes';

class ReportForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        fileName: this.generateFileName(),
        fileFormat: null,
        weekend: null
      },
      serverErrors: null
    };
    this.formRef = null;
  }

  generateFileName = () => {
    if (this.props?.data) return `${this.props.data.teamName}_${this.props.data.dateFrom}_${this.props.data.dateTo}`;
    return '';
  };

  processSubmittedData = (data) => {
    const processedData = data;
    processedData.fileFormat = REPORT_CONSTANTS.formatTypesNameMappings[processedData.fileFormat];
    return processedData;
  };

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    if (this.props.submitCallback) {
      const submittedFormData = this.processSubmittedData(this.state.formData);
      this.props.submitCallback(submittedFormData);
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  fileFormatChange = (value) => {
    this.setState((prevState) => {
      const newFormData = prevState.formData;
      newFormData.fileFormat = value;
      return { formData: newFormData };
    });
  };

  weekendIncludedChange = (value) => {
    this.setState((prevState) => {
      const newFormData = prevState.formData;
      newFormData.weekend = value;
      return { formData: newFormData };
    });
  };

  render() {
    return (
      <div className="form-dialog-component">
        <div className="title">{this.props.title}</div>
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            this.setState({ formData: newFormData });
          }}
          serverErrors={this.state.serverErrors}
        >
          <FormInput
            inputType="input"
            label={this.props.t('File name')}
            name="fileName"
            validation={{ required: true, maxLength: REPORT_CONSTANTS.fileNameMaxLength }}
            placeholder={this.props.t('Enter file name')}
          />
          <RadioButton
            selectedValueChanged={this.fileFormatChange}
            values={Object.keys(REPORT_CONSTANTS.formatTypesNameMappings)}
            type="format"
            defaultValue={Object.keys(REPORT_CONSTANTS.formatTypesNameMappings)[0]}
            title="File format"
          />
          <RadioButton selectedValueChanged={this.weekendIncludedChange} values={['Yes', 'No']} type="days" defaultValue="Yes" title="Include weekends" />
        </Form>

        <div className="buttons">
          <Button onClick={this.cancelCallback} type={BUTTON_TYPES.SECONDARY} text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} type={BUTTON_TYPES.PRIMARY} text={this.props.t('Create')} />
        </div>
      </div>
    );
  }
}

ReportForm.propTypes = {
  data: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    team: PropTypes.string,
    teamName: PropTypes.string
  }),
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

ReportForm.defaultProps = {
  data: null,
  submitCallback: null,
  cancelCallback: null
};

export default withTranslation('translations')(ReportForm);
