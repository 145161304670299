import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './MapPointMarker.scss';
import { MARKER_ICON_NAMES_IMPACT_POINTS, MARKER_ICON_NAMES_MAPPING, MARKER_STATES } from './constants/markerConstants';

export default function MapPointMarker(props) {
  const [stateContent, setStateContent] = useState(null);

  useEffect(() => {
    let newState;
    switch (props.state) {
      case MARKER_STATES.IMPACTFUL:
        newState = { className: `${props.options.impactType}-${MARKER_STATES.IMPACTFUL}`, iconComponent: MARKER_ICON_NAMES_IMPACT_POINTS[props.options.index] };
        break;
      case MARKER_STATES.DISABLED:
      case MARKER_STATES.STANDARD:
      default:
        newState = { className: props.state, iconComponent: MARKER_ICON_NAMES_MAPPING[props.mapPointType] };
    }
    setStateContent(newState);
  }, [props.options, props.mapPointType, props.state]);

  const getIconComponent = (icon) => {
    switch (icon) {
      case 'icon-demo-shops-new': {
        return (
          <span className={`icon ${icon}`}>
            <span className="path1" />
            <span className="path2" />
          </span>
        );
      }
      default: return (<i className={`icon ${icon}`} />);
    }
  };

  return (
    <div
      className={`map-point-marker ${stateContent?.className} ${props.isSelected ? 'selected' : ''}`}
      onPointerOverCapture={props.popupActions.addOnHoverPopup}
      onPointerOutCapture={props.popupActions.removeOnHoverPopup}
      onContextMenu={props.popupActions.addOnRightClickPopup}
    >
      { props.isSelected && <div className="selected-ring" />}
      {stateContent?.iconComponent && getIconComponent(stateContent?.iconComponent)}
    </div>
  );
}

MapPointMarker.propTypes = {
  state: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  options: PropTypes.shape({
    impactType: PropTypes.string,
    index: PropTypes.number
  }),
  mapPointType: PropTypes.string.isRequired,
  popupActions: PropTypes.shape({
    addOnHoverPopup: PropTypes.func,
    removeOnHoverPopup: PropTypes.func,
    addOnRightClickPopup: PropTypes.func
  })
};

MapPointMarker.defaultProps = {
  isSelected: false,
  options: {},
  popupActions: {}
};
