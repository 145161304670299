import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HubSelect from '../../../../common/components/selections/hubSelect/HubSelect';
import Button from '../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import './DepartureForm.scss';
import TimePickerWrapper from '../../../../common/components/datePickers/TimePickerWrapper';
import { BUTTON_TYPES } from '../../../../common/components/buttons/button/constants/buttonTypes';

/**
 * Hub form component
 *
 * @returns {JSX.Element} - Hub form
 * @component
 * @alias DepartureForm
 * @category planning
 */

// let endHub = null;

export default function DepartureForm(props) {
  const { t } = useTranslation();
  // const [selectEndHub, setSelectEndHub] = useState(true);
  const [departureTime, setDepartureTime] = useState(process.env.REACT_APP_DATA_SOURCE === 'api' ? moment().add(30, 'minutes').format('HH:mm') : '06:00');
  const startHub = useRef();

  const onStartHubChange = useCallback((hub) => {
    startHub.current = hub;
  }, []);

  // function onEndHubChange(hub) {
  //   endHub = hub;
  // }
  //
  // function toggleEndHub(e) {
  //   setSelectEndHub(e.target.checked);
  // }

  const handleSubmit = useCallback(() => {
    if (departureTime) {
      if (props.submitCallback) {
        props.submitCallback({ startHub: startHub.current, endHub: startHub.current, departureTime: departureTime });
        // props.submitCallback({ startHub: startHub, endHub: !selectEndHub ? endHub : startHub });
      }

      AppDialogActionsWrapper.closeAppDialog();
    } else {
      toast.error(t('Time input error'));
    }
  }, [props, departureTime, t]);

  const cancelCallback = useCallback(() => {
    AppDialogActionsWrapper.closeAppDialog();
  }, []);

  return (
    <div className="hub-form">
      <div className="title">{t('Set start and end locations')}</div>
      <div className="selections-wrapper">
        <div className="hub-selection">
          <HubSelect onHubChange={onStartHubChange} returnFullObject />
          {/* {!selectEndHub && <HubSelect onHubChange={onEndHubChange} label={t("Choose ending point")} returnFullObject />} */}
        </div>
        <TimePickerWrapper
          onChange={setDepartureTime}
          defaultTime={departureTime}
          label={t('Time of departure')}
          allowBeforePresent={process.env.REACT_APP_DATA_SOURCE !== 'api'}
        />
        {/* <div className="checkbox-wrapper"> */}
        {/*  <input type="checkbox" checked={selectEndHub} onChange={toggleEndHub} /> */}
        {/*  <div className="label">{t("Return to same location")}</div> */}
        {/* </div> */}
      </div>
      <div className="buttons">
        <Button onClick={cancelCallback} type={BUTTON_TYPES.SECONDARY} text={t('Cancel')} />
        <Button onClick={handleSubmit} type={BUTTON_TYPES.PRIMARY} text={t('Set')} />
      </div>
    </div>
  );
}

DepartureForm.propTypes = { submitCallback: PropTypes.func.isRequired };
