import * as ActionTypes from '../constants/actionTypes';

const initialState = {
  selectedDay: null,
  selectedHours: null,
  resetBarChart: null,
  resetHeatMap: null,
  chartId: null
};

/**
 * Reducer - Charts
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHART_DATA_SELECTED:
      return {
        ...state,
        chartId: action.payload.id,
        selectedDay: action.payload.days,
        selectedHours: action.payload.hours,
        selectedDwellTimeInterval: action.payload.dwellTimeInterval,
        selectedPointDistanceInterval: action.payload.pointDistanceInterval,
      };

    case ActionTypes.CHART_RESET_DATA:
      return {
        ...state,
        chartId: null,
        selectedDay: null,
        selectedHours: null,
        selectedDwellTimeInterval: null,
        selectedPointDistanceInterval: null,
        resetBarChart: new Date(),
        resetHeatMap: new Date()
      };
    default:
      return state;
  }
};

export default reducer;
