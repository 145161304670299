import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { amplifyI18n } from '../setup/amplifyTranslate';
import App from './components/App';
import SR from '../resources/translations/amplify_sr_SR.json';
import EN from '../resources/translations/amplify_en_US.json';
import AuthUtil from '../common/utils/authUtil';
import MixPanel from '../setup/mixPanel';

const authScreenLabels = {
  sr: SR,
  en: EN
};

/**
 * Wrapper for the whole application. It injects translation and i18n functionalities in the app.
 *
 * @returns {JSX.Element} - element used to set language in app
 * @alias AppWrapper
 * @component
 * @category App
 */
export default function AppWrapper() {
  const language = useSelector((state) => state.languageState.language);
  const userPermissions = useSelector((state) => state.authState.userPermissions);

  const previousPermissions = useRef(null);
  const { i18n } = useTranslation();

  const intercomBoot = useCallback(
    (newLanguage) => {
      const username = AuthUtil.getUsername();
      if (!userPermissions) {
        window.Intercom('boot', {
          api_base: process.env.REACT_APP_INTERCOM_API_BASE,
          app_id: process.env.REACT_APP_INTERCOM_API_ID,
          language_override: newLanguage
        });
      } else {
        window.Intercom('boot', {
          api_base: process.env.REACT_APP_INTERCOM_API_BASE,
          app_id: process.env.REACT_APP_INTERCOM_API_ID,
          name: username,
          email: username,
          language_override: newLanguage,
          created_at: Math.floor(Date.now() / 1000) // Signup date as a Unix timestamp
        });
        MixPanel.track(`Language set ${newLanguage}`);
      }
    },
    [userPermissions]
  );

  useEffect(() => {
    const newLanguage = language || localStorage.getItem('i18nextLng') || authScreenLabels.en;
    if (userPermissions && previousPermissions.current !== userPermissions) {
      MixPanel.track(`Language set ${newLanguage.slice(0, 2)}`);
    }
    previousPermissions.current = userPermissions;
    intercomBoot(newLanguage);
  }, [intercomBoot, language, userPermissions]);

  useEffect(() => {
    const newLanguage = language || localStorage.getItem('i18nextLng') || authScreenLabels.en;

    amplifyI18n.setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  }, [language, i18n]);

  return <App />;
}
