export const DB_TYPES = {
  TEAM: 'TEAM',
  DELIVERY_AREA: 'DELIVERY_AREA',
  HUB: 'HUB',
  ZONE: 'ZONE',
  POLICY: 'POLICY',
  ROLE: 'ROLE',
  ZIP_CODE: 'ZIP_CODE',
  INTEGRATION_MAPPING: 'INTEGRATION_MAPPING'
};
