import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FEATURE_KEY_ICON_MAP } from '../../../constants/mapAdditionalFeaturesData';
import './MarkerPopupRowWithIcon.scss';

export default function MarkerPopupRowWithIcon(props) {
  const { t } = useTranslation();
  const { dataKey, value } = props;

  function handleSpacialCaseRendering() {
    switch (dataKey) {
      case 'numberOfShipments':
        return value > 1 ? (
          <div className="marker-popup-row-with-icon" key={dataKey}>
            {renderIcon()}
            <div className="data-wrapper">
              <div className="data">
                {t('shipments')}
                :
                <span className="data-value">
                  {value}
                </span>
              </div>
            </div>
          </div>
        ) : null;
      case 'numberOfPackages':
        return value > 1 ? (
          <div className="marker-popup-row-with-icon" key={dataKey}>
            {renderIcon()}
            <div className="data-wrapper">
              <div className="data">
                {t('packages')}
                :
                <span className="data-value">
                  {value}
                </span>
              </div>
            </div>
          </div>
        ) : null;
      default:
        return (
          <div className="marker-popup-row-with-icon" key={dataKey}>
            {renderIcon()}
            {renderValue()}
          </div>
        );
    }
  }

  function renderIcon() {
    return (
      <div className="icon-wrapper">
        <i className={`icon ${FEATURE_KEY_ICON_MAP[dataKey]}`} />
      </div>
    );
  }

  function renderValue() {
    return (
      <div className="data-wrapper">
        <div className="data">{value}</div>
      </div>
    );
  }

  return handleSpacialCaseRendering();
}

MarkerPopupRowWithIcon.propTypes = {
  dataKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MarkerPopupRowWithIcon.defaultProps = {
  dataKey: null,
  value: null
};
