import { MAP_VIEW_TYPE, POLYGON_COLORS } from '../constants/mapConstants';

export const PlotUtil = {
  getMinZoomLevel: getMinZoomLevel,
  getRegionColor: getRegionColor,
  getHeatmapVisibilityString: getHeatmapVisibilityString,
  getClusterVisibilityString: getClusterVisibilityString,
  getCommonVisibilityString: getCommonVisibilityString
};

function getMinZoomLevel(centerPositionData) {
  return Math.max(12, centerPositionData.zoom + 0.5);
}

function getRegionColor(regionId) {
  const colorIndex = parseInt(regionId.replace(/[^0-9]/g, ''), 10) % POLYGON_COLORS.length || 0;

  return POLYGON_COLORS[colorIndex];
}

function getHeatmapVisibilityString(mapViewType, currentSource, visibleMapSource) {
  return getVisibilityString(isSourceVisible(currentSource, visibleMapSource) && isHeatMapVisible(mapViewType));
}

function getClusterVisibilityString(mapViewType, currentSource, visibleMapSource) {
  return getVisibilityString(isSourceVisible(currentSource, visibleMapSource) && isClusterVisible(mapViewType));
}

function getCommonVisibilityString(currentSource, visibleMapSource) {
  return getVisibilityString(isSourceVisible(currentSource, visibleMapSource));
}

function isHeatMapVisible(mapViewType) {
  return mapViewType === MAP_VIEW_TYPE.HEATMAP;
}

function isSourceVisible(currentSource, visibleMapSource) {
  return currentSource.layerType === visibleMapSource.layerType;
}

function isClusterVisible(mapViewType) {
  return mapViewType === MAP_VIEW_TYPE.CLUSTER;
}

function getVisibilityString(isVisible) {
  return isVisible ? 'visible' : 'none';
}
