import React from 'react';
import './ResultMessage.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COVERAGE, LOCATIONS } from '../../constants/formTypes';

export default function ResultMessage(props) {
  const { t } = useTranslation();
  function getMessageUiData() {
    // In case of state machine error show error message
    if (props.recommendationResults?.error) {
      return {
        icon: (<i className="icon icon-warning-circle" />),
        className: 'error'
      };
    }

    if (props.messageType === LOCATIONS) {
      // in case we manage to put enough locations we show success message
      if (props.targetLocations <= props.recommendationResults.numberOfSelectedLocations) {
        return {
          icon: (<i className="icon icon-check-circle" />),
          className: 'successful',
          messageDiv: getResultMessageElement()
        };
      }

      // in case we don't manage to put enough locations we show warning message
      return {
        icon: (<i className="icon icon-warning-triangle" />),
        className: 'warning',
        messageDiv: getResultMessageElement()
      };
    }

    if (props.messageType === COVERAGE) {
      // in case we manage to reach target coverage we show success message
      if (props.targetCoverage <= props.recommendationResults.baseLayerCoveragePercent) {
        return {
          icon: (<i className="icon icon-check-circle" />),
          className: 'successful',
          messageDiv: getResultMessageElement()
        };
      }

      // in case we don't manage to reach target coverage we show warning message
      return {
        icon: (<i className="icon icon-warning-triangle" />),
        className: 'warning',
        messageDiv: getUnsuccessfulResultMessageElement()
      };
    }

    return null;
  }

  function getCoverageMessagePart(message) {
    const resultCoverage = Math.trunc(props.recommendationResults.baseLayerCoveragePercent);
    return (
      <div>
        <span>{t(message, { coverage: Math.trunc(props.recommendationResults.baseLayerCoveragePercent) })}</span>
        <span>
          :&nbsp;
          {resultCoverage}
          %
        </span>
      </div>
    );
  }

  function getResultMessageElement() {
    return (
      <div>
        {getCoverageMessagePart('successful-coverage-message-beginning')}
        <div>
          {t('location-message', { locations: props.recommendationResults.numberOfSelectedLocations })}
        </div>
      </div>
    );
  }

  function getUnsuccessfulResultMessageElement() {
    return (
      <div>
        {getCoverageMessagePart('unsuccessful-coverage-message-beginning')}
        <div>
          {t('location-message', { locations: props.recommendationResults.numberOfSelectedLocations })}
        </div>
      </div>
    );
  }

  const messageUiData = getMessageUiData();

  return (
    <div className={`result-message-wrapper ${messageUiData.className}`}>
      {messageUiData.icon}
      <div className="message">
        {messageUiData.messageDiv}
      </div>

    </div>
  );
}

ResultMessage.propTypes = {
  targetCoverage: PropTypes.number.isRequired,
  targetLocations: PropTypes.number.isRequired,
  messageType: PropTypes.string.isRequired,
  recommendationResults: PropTypes.shape({
    numberOfSelectedLocations: PropTypes.number,
    coveragePercent: PropTypes.number,
    baseLayerCoveragePercent: PropTypes.number,
    error: PropTypes.string
  }).isRequired,
};
