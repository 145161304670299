import * as h3 from 'h3-js';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import EntitySelect from '../../../../common/components/selections/entitySelect/EntitySelect';
import TableActions from '../../../../common/components/tables/common/TableActions';
import { ENTITY_TYPE } from '../../../../common/constants/entityTypes';
import MixPanelUtil from '../../../../common/utils/mixPanelUtil';
import QueryStringUtil from '../../../../common/utils/queryStringUtil';
import TenantUtil from '../../../../common/utils/tenantUtil';
import MixPanel from '../../../../setup/mixPanel';
import { raygunClient } from '../../../../setup/raygunClient';
import * as PageActions from '../../../../state/actions/pageActions';
import * as PlanningPageActions from '../../../../state/actions/planningPageActions';
import S3Util from '../../../../common/utils/s3Util';
import TableComponent from '../../../../common/components/tables/tableComponent/TableComponent';
import './ShipmentsPerCourierUploadPage.scss';
import EntityUtil from '../../../analysis/utils/entityUtil';
import StopsUtil from '../../../analysis/utils/stopsUtil';
import { AddressLabelingApi } from '../../../management/addressLabeling/api/addressLabelingApi';
import StepInfoHeader from '../components/StepInfoHeader';
import PlanningPageUtil from '../../utils/planningPageUtil';
import BackendResourceConfigUtil from '../../../../common/utils/api/backendResourceConfigUtil';
import PlanningApi from '../../api/planningApi';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import ShipmentForm from '../components/ShipmentForm';
import DepartureForm from '../components/DepartureForm';

/**
 * Shipments upload component
 *
 * @returns {JSX.Element} - page for uploading shipments
 * @component
 * @alias ShipmentsPerCourierUploadPage
 * @category planning
 */
class ShipmentsPerCourierUploadPageClass extends React.Component {
  constructor() {
    super();

    this.state = {
      tablePageSize: 1,
      couriersData: null
    };
  }

  async componentDidMount() {
    const routerState = this.props?.history?.location?.state;
    this.teamId = routerState?.teamId || QueryStringUtil.getQueryStringValue('teamId');
    this.teamName = routerState?.teamName;
    this.planDate = routerState?.planDate || moment().format('YYYY-MM-DD');
    this.entityType = routerState?.entityType || ENTITY_TYPE.COURIERS;

    if (this.teamId) {
      QueryStringUtil.setQueryStringValue('teamId', this.teamId);
    }

    this.getCouriers();

    MixPanel.track('Delivery plans - Shipments selection loaded');
    MixPanelUtil.setUnloadListener('Delivery plans - Shipments selection unloaded');
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
  }

  getCouriers = () => {
    this.props.dispatchLoadingPage(true);
    EntityUtil.getEntityData(this.teamId, this.entityType, true)
      .then((couriersData) => {
        this.setState({ couriersData: couriersData });
        this.props.dispatchLoadingPage(false);
      })
      .catch((error) => {
        raygunClient.send(error, 'Failed to load couriers for Planning');
        toast.error(this.props.t('Failed to load couriers'));
        this.props.dispatchLoadingPage(false);
      });
  };

  getCourierShipments = () => {
    this.props.dispatchLoadingPage(true);
    // Get assigned shipments setup
    PlanningApi.getAssignedShipments(this.state.courierId, this.planDate)
      .then((res) => {
        if (res?.data?.queryShipments?.items && res?.data?.queryShipments?.items.length > 0) {
          const newShipments = res.data.queryShipments.items.map((shipment) => ({
            ...shipment,
            lat: shipment?.fuzzyAddress?.geoLat,
            lng: shipment?.fuzzyAddress?.geoLng
          }));
          let shipmentsFileName = `shipments/${this.planDate}/${this.state.courierId}/${this.planDate}_${this.state.courierId}__shipments.csv`;
          shipmentsFileName = TenantUtil.addTenantToFileName(shipmentsFileName);
          this.setState({ tablePageSize: 1 }, () => {
            this.props.dispatchSaveShipmentsData(newShipments, shipmentsFileName);
          });
        } else {
          this.props.dispatchSaveShipmentsData([]);
        }
        this.props.dispatchLoadingPage(false);
      })
      .catch((e) => {
        raygunClient.send(e, 'Error loading assigned shipments for planning');
        this.props.dispatchLoadingPage(false);
      });
  };

  editShipment = (row) => {
    MixPanel.track('Delivery plans - Edit shipment started', { courierId: this.state.courierId, planDate: this.planDate });
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: ShipmentForm,
      dialogComponentProps: {
        title: this.props.t('Edit shipment'),
        data: {
          ...row.values,
          fuzzyAddress: row.original.fuzzyAddress,
          featureRequest: 'Delivery plans'
        },
        submitCallback: (data) => {
          if (data.saveGeolocation) {
            PlanningApi.labelShipmentAddress(
              row.original.fuzzyAddress.streetName,
              row.original.fuzzyAddress.houseNumber,
              row.original.fuzzyAddress.receiver,
              row.original.region,
              data.lat,
              data.lng
            )
              .then(() => {
                toast.success(this.props.t('Address has been successfully labeled'));
                MixPanel.track('Delivery plans - Address labeled', {
                  teamName: this.teamName,
                  courierId: this.state.courierId,
                  courierName: this.state.courierFullName,
                  addressLabeled: row.original.fuzzyAddress
                });
              })
              .catch((er) => {
                console.error(er);
                raygunClient.send(er, 'Error labeling address through planning page', row.original);
                toast.error(this.props.t('Error labeling'));
              });
          }
          AddressLabelingApi.addLabelingDataToShipment(data.shipmentCode, StopsUtil.getStopType(row.original.jobType), data.lat, data.lng)
            .then(() => {
              const newData = this.props.shipmentsData;
              Object.keys(data).forEach((key) => {
                newData[row.index][key] = data[key];
              });

              AddressLabelingApi.saveLabeledShipment(
                data.shipmentCode,
                StopsUtil.getStopType(row.original.jobType),
                data.lat,
                data.lng,
                moment(),
                row.original.region
              );
              this.props.dispatchSaveShipmentsData([...newData]);

              MixPanel.track('Delivery plans - Edit shipment finished', {
                courierId: this.state.courierId,
                planDate: this.planDate,
                teamName: this.teamName,
                courierName: this.state.courierFullName
              });
            })
            .catch((e) => {
              raygunClient.send(e, 'Error geocoding shipment');
              toast.error(this.props.t('Oops, something went wrong'));
            });
        }
      }
    });
  };

  deleteShipment = (data, e) => {
    e.stopPropagation();
    AppDialogActionsWrapper.openConfirmationDialog({
      title: `${this.props.t('Remove shipment')}?`,
      body: `${this.props.t('The shipment will be excluded from the plan')}.`,
      confirmButtonText: this.props.t('Remove'),
      continueCallback: () => {
        const newData = this.props.shipmentsData;
        newData.splice(data.index, 1);
        this.props.dispatchSaveShipmentsData([...newData]);
        MixPanel.track('Delivery plans - Remove shipment', { courierId: this.state.courierId, planDate: this.planDate });
      }
    });
  };

  /**
   * Column structure for courier stops table
   *
   * @returns {Array} - table columns
   * @function
   */
  getTableColumns = () => [
    {
      Header: this.props.t('ShipmentId'),
      accessor: 'shipmentCode' // accessor is the "key" in the data
    },
    {
      Header: this.props.t('Name'),
      accessor: 'name',
      className: 'flex-2'
    },
    {
      Header: this.props.t('PhoneNumber'),
      accessor: 'phone'
    },
    {
      Header: this.props.t('Address'),
      accessor: 'address',
      className: 'flex-3'
    },
    {
      Header: this.props.t('Town'),
      accessor: 'townName'
    },
    {
      Header: this.props.t('Latitude'),
      accessor: 'lat'
    },
    {
      Header: this.props.t('Longitude'),
      accessor: 'lng'
    },
    {
      Header: this.props.t('Actions'),
      id: 'actions',
      disableGlobalFilter: true,
      className: 'centered',
      Cell: ({ row }) => <TableActions type="table" row={row} onEdit={this.editShipment} onDelete={this.deleteShipment} />
    }
  ];

  findOptimalRoute = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: DepartureForm,
      dialogComponentProps: {
        title: this.props.t('Select hub'),
        submitCallback: (data) => {
          const departureDate = moment().set({
            hour: data.departureTime.split(':')[0],
            minute: data.departureTime.split(':')[1]
          });
          MixPanel.track('Delivery plans - Departure time set', {
            timeUntilStart: departureDate.diff(moment(), 'minutes'),
            courierId: this.state.courierId,
            courierName: this.state.courierName,
            teamName: this.teamName
          });
          this.startHub = data.startHub;
          this.endHub = data.endHub;
          this.departureTime = data.departureTime;
          this.mockRegionSelection(true);
        }
      }
    });
  };

  nextStep = () => {
    MixPanel.track('Delivery plans - Start route optimization flow', { courierId: this.state.courierId, date: this.planDate });
    if (process.env.REACT_APP_DATA_SOURCE === 'api') {
      const file = PlanningPageUtil.jsonToCsv(this.props.shipmentsData);
      S3Util.uploadFileToS3(this.props.shipmentsFileName, file, {
        contentType: 'text/csv',
        bucket: BackendResourceConfigUtil.getPlanningBucketName()
      })
        .then(() => {
          console.log('edited shipments uploaded');
          this.findOptimalRoute();
        })
        .catch((err) => {
          raygunClient.send(err, 'Failed to same shipments data');
          toast.error(this.props.t('Failed save shipments'));
        });
    } else {
      this.findOptimalRoute();
    }
  };

  createRegionsJsonFile = () => {
    const fileJson = [];
    const hexIds = new Set();

    this.props.shipmentsData.forEach((shipment) => {
      hexIds.add(h3.geoToH3(shipment.lat, shipment.lng, 10));
    });

    const regionData = {
      regionId: this.state.courierId,
      name: this.state.courierFullName,
      hexIds: Array.from(hexIds)
    };

    fileJson.push(regionData);
    return JSON.stringify(fileJson);
  };

  mockRegionSelection = () => {
    const courierId = this.state.courierId;

    let latestFileName = `${process.env.REACT_APP_LATEST_DELIVERY_AREA_FILE_PATH}/${this.teamId}/${courierId}_region_hexagons.json`;
    latestFileName = TenantUtil.addTenantToFileName(latestFileName);

    if (process.env.REACT_APP_DATA_SOURCE === 'api') {
      this.props.dispatchLoadingPage(true);

      const file = this.createRegionsJsonFile();

      const latestFilePromise = S3Util.uploadFileToS3(latestFileName, file, {
        contentType: 'application/json',
        bucket: BackendResourceConfigUtil.getRegionDataBucketName()
      });

      this.props.dispatchSaveRegionsData([
        {
          regionId: courierId,
          name: this.state.courierFullName
        }
      ]);

      Promise.all([latestFilePromise])
        .then((res) => {
          const [latestFileRes] = res;
          if (latestFileRes?.key) {
            this.nextPage(latestFileName);
          } else {
            toast.error(this.props.t('Failed to save regions data. Please try again.'));
            this.props.dispatchLoadingPage(false);
            raygunClient.send(`Failed to save regions data. Reason: We are missing a key in response. Response: ${res}`);
            console.log(res);
          }
        })
        .catch((err) => {
          this.props.dispatchLoadingPage(false);
          toast.error(this.props.t('Failed to save regions data. Please try again.'));
          raygunClient.send(err, 'Failed to save regions data');
        });
    } else {
      this.nextPage(latestFileName);
    }
  };

  nextPage = (regionHexagonsFileKey) => {
    this.props.dispatchLoadingPage(false);
    this.props.history.push({
      pathname: '/delivery-plan/route-view',
      state: {
        teamId: this.teamId,
        teamName: this.teamName,
        planDate: this.planDate,
        startHub: this.startHub,
        endHub: this.endHub,
        departureTime: this.departureTime,
        regionHexagonsFileKey: regionHexagonsFileKey,
        courierId: this.state.courierId,
        courierFullName: this.state.courierFullName
      }
    });
  };

  /**
   * Create table warning html element
   *
   * @returns {JSXElement} - warning element
   * @function
   */
  getTableWarning = () => {
    const hasWarnings = this.props.shipmentsData.some((element) => !element.lat || !element.lng);

    if (hasWarnings) {
      return (
        <div className="warning">
          <i className="icon icon-warning" />
          {this.props.t('shipmentsGeocodingTableWarning')}
        </div>
      );
    }

    return null;
  };

  /**
   * Add additional class to table row
   *
   * @param {object} row - table row
   * @returns {string} - new class
   * @function
   */
  getAdditionalRowClass = (row) => {
    if (!row.values.lat || !row.values.lng) {
      return 'warning-row';
    }

    return '';
  };

  calculateTablePageSize = (pageSize) => {
    if (this.state.tablePageSize === 1 || this.state.tablePageSize.tablePageSize < pageSize) {
      this.setState({ tablePageSize: pageSize });
    }
  };

  onCourierChange = (courierOpt) => {
    if (courierOpt) {
      QueryStringUtil.setQueryStringValue('teamId', this.teamId);
      this.setState({ courierId: courierOpt?.value, courierFullName: courierOpt.label }, () => {
        this.getCourierShipments();
      });
    }
  };

  handleTableRefresh = () => {
    MixPanel.track('Delivery plans - Refresh assigned shipments in planning', { courierId: this.state.courierId });
    this.getCourierShipments();
  };

  render() {
    const infoHeaderOptions = {
      nextButtonDisabled: !this.props.shipmentsData || this.props.shipmentsData.length === 0,
      returnButtonHidden: true
    };

    return (
      <div className="shipments-per-courier-upload-component">
        {this.props.shipmentsData && this.props.shipmentsData.length > 0 ? (
          <StepInfoHeader
            message={this.props.t('shipmentsUploadStepMessage')}
            onNextClick={this.nextStep}
            nextButtonText={this.props.t('Next')}
            options={infoHeaderOptions}
            onReturnClick={() => {
              this.props.history.push({ pathname: '/delivery-plan', state: this.props?.history?.location?.state });
            }}
          />
        ) : (
          <StepInfoHeader
            message={this.props.t('Select courier and shipments to be delivered')}
            nextButtonText={this.props.t('Next')}
            options={infoHeaderOptions}
          />
        )}
        {this.props.shipmentsData && this.props.shipmentsData.length > 0 && this.getTableWarning()}
        <div className="selection-wrapper">
          <div className="label">{this.props.t('Courier')}</div>
          <EntitySelect
            entitiesData={this.state.couriersData}
            onChange={this.onCourierChange}
            eventTrackerNamePrefix="Delivery plans"
            entityType={ENTITY_TYPE.COURIERS}
          />
        </div>
        {this.props.shipmentsData && this.props.shipmentsData.length > 0 ? (
          <div className="table-view">
            <div className="table-wrapper">
              <TableComponent
                key={this.state.tablePageSize}
                columns={this.getTableColumns()}
                data={this.props.shipmentsData}
                resetOn={this.state.courierId}
                paginationLabel={this.props.t('Number of shipments')}
                sortBy={[{ id: 'lat' }]}
                getAdditionalRowClass={this.getAdditionalRowClass}
                pageSize={this.state.tablePageSize}
                onActionButtonClick={this.handleTableRefresh}
                actionButtonText={this.props.t('Refresh')}
                showSearch
                calculateTablePageSize={this.calculateTablePageSize}
              />
            </div>
          </div>
        ) : (
          <div className="empty-page">{this.props.t('No data for selected filters')}</div>
        )}
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.planningPageState };
}

/**
 * @param {object} dispatch - redux store
 * @returns {object} - actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchSaveShipmentsData: PlanningPageActions.saveShipmentsData,
      dispatchSaveRegionsData: PlanningPageActions.saveRegionsData
    },
    dispatch
  );
}

ShipmentsPerCourierUploadPageClass.propTypes = {
  /**
   * Dispatch if page is loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Store shipments data in redux store
   */
  dispatchSaveShipmentsData: PropTypes.func.isRequired,
  dispatchSaveRegionsData: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Shipments data stored in redux store
   */
  shipmentsData: PropTypes.arrayOf(PropTypes.object),
  /**
   * React router history object
   */
  history: PropTypes.object.isRequired,
  /**
   * Name of geocoded shipments file
   */
  shipmentsFileName: PropTypes.string
};

ShipmentsPerCourierUploadPageClass.defaultProps = {
  shipmentsData: null,
  shipmentsFileName: null
};

const ShipmentsPerCourierUploadPage = withRouter(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ShipmentsPerCourierUploadPageClass))
);

export default ShipmentsPerCourierUploadPage;
