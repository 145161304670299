import { gql } from '@apollo/client';
import { appSyncClient } from '../../../../setup/appSyncClient';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';

const CouriersApi = {
  getCouriers: getCouriers,
  createCourier: createCourier,
  updateCourier: updateCourier
};

const GetAllCouriers = gql`
  query getAllCouriers($pk: String, $isActive: Boolean) {
    getAllCouriers(pk: $pk, isActive: $isActive) {
      id
      email
      firstName
      lastName
      phoneNumber
      isActive
      team {
        id
        name
      }
    }
  }
`;

const GetCouriersForTeam = gql`
  query getCouriersForTeam($gsi1Pk: String, $isActive: Boolean) {
    getCouriersForTeam(gsi1Pk: $gsi1Pk, isActive: $isActive) {
      id
      email
      firstName
      lastName
      phoneNumber
      isActive
    }
  }
`;

const GetCouriersTeamDataForTeam = gql`
  query getCouriersForTeam($gsi1Pk: String, $isActive: Boolean) {
    getCouriersForTeam(gsi1Pk: $gsi1Pk, isActive: $isActive) {
      id
      firstName
      lastName
      isActive
      team {
        id
        name
      }
    }
  }
`;

const CreateCourier = gql`
  mutation CreateCourier($sk: String, $teamId: String, $id: String, $firstName: String, $lastName: String, $isActive: Boolean) {
    createCourier(
      sk: $sk
      input: {
        id: $id
        teamId: $teamId
        firstName: $firstName
        lastName: $lastName
        isActive: $isActive
        gsi1: { id: $id, firstName: $firstName, lastName: $lastName, isActive: $isActive }
      }
    )
  }
`;

// TODO update gsi1Pk for team
const UpdateCourier = gql`
  mutation UpdateCourier($pk: String, $sk: String, $firstName: String, $lastName: String, $isActive: Boolean) {
    upsertData(
      pk: $pk
      sk: $sk
      input: {
        courierInputData: {
          firstName: $firstName
          lastName: $lastName
          isActive: $isActive
          gsi1: { firstName: $firstName, lastName: $lastName, isActive: $isActive }
        }
      }
    )
  }
`;

async function getCouriers(teamId = null, isActive = false, loadTeamData = false) {
  if (teamId) {
    if (process.env.REACT_APP_DATA_SOURCE === 'api') {
      const queryArguments = { gsi1Pk: DynamoStringExtensionsUtil.addCourierPrefix(teamId) };
      if (isActive) queryArguments.isActive = true;
      return ApiHelpers.createQuery(loadTeamData ? GetCouriersTeamDataForTeam : GetCouriersForTeam, queryArguments);
    }

    return fetch(`/testData/management/couriers${teamId}.json`).then((data) => data.json());
  }

  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetAllCouriers, { pk: 'COURIER', isActive: isActive });
  }

  return fetch('/testData/management/couriersALL.json').then((data) => data.json());
}

async function createCourier(id, data, isActive = true) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: CreateCourier,
      variables: {
        sk: id,
        id: id,
        teamId: data?.team?.value,
        firstName: data.firstName,
        lastName: data.lastName,
        // TODO uncomment, fix, or remove once a mobile application communication is mapped out
        // phoneNumber: data.phoneNumber,
        // email: data.email,
        isActive: isActive
      }
    });
  }

  return null;
}

async function updateCourier(id, data) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return appSyncClient.mutate({
      mutation: UpdateCourier,
      variables: {
        pk: 'COURIER',
        sk: DynamoStringExtensionsUtil.addCourierPrefix(id),
        // teamId: data.team,
        firstName: data.firstName,
        lastName: data.lastName,
        // TODO uncomment, fix, or remove once a mobile application communication is mapped out
        // phoneNumber: data.phoneNumber,
        // email: data.email,
        isActive: data.isActive
      }
    });
  }

  return null;
}

export default CouriersApi;
