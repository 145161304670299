import React from 'react';
import * as d3 from 'd3';
import './BarChart.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as CharActions from '../../../../state/actions/chartActions';
import MixPanel from '../../../../setup/mixPanel';
import { INTERVAL_BAR_CHART_PROPS } from '../constants/intervalBarChartConstants';

const ALL_KEYS = ['collections', 'unsuccessful'];

class IntervalBarChartClass extends React.Component {
  componentDidMount() {
    this.allKeys = this.props.dataKeys || ALL_KEYS;
    const keys = this.getActiveStopTypes();
    this.chartProps = INTERVAL_BAR_CHART_PROPS[this.props.chartName];
    this.drawChart(keys);
  }

  componentDidUpdate(prevProps) {
    let shouldRedrawChart = false;
    if (prevProps.t !== this.props.t) {
      this.props.dispatchResetChartData();
      shouldRedrawChart = true;
    }

    if (prevProps.showCollected !== this.props.showCollected || prevProps.showUnsuccessful !== this.props.showUnsuccessful) {
      shouldRedrawChart = true;
    }

    if (prevProps.resetBarChart !== this.props.resetBarChart && this.props.chartId !== this.props.containerId) {
      shouldRedrawChart = true;
    }

    if (prevProps.data.chartData !== this.props.data.chartData && this.props.chartId !== this.props.containerId) {
      shouldRedrawChart = true;
    }

    if (shouldRedrawChart) {
      const keys = this.getActiveStopTypes();
      this.drawChart(keys);
    }
  }

  getActiveStopTypes() {
    const keys = [];
    if (this.props.showCollected) {
      keys.push('collections');
    }

    if (this.props.showUnsuccessful) {
      keys.push('unsuccessful');
    }
    return keys;
  }

  getMaxValue(data) {
    const newArray = [];
    this.allKeys.forEach((key) => {
      data.forEach((d, index) => {
        if (!newArray[index]) {
          newArray[index] = 0;
        }

        newArray[index] += d[key];
      });
    });

    return d3.max(newArray);
  }

  drawChart = (keys) => {
    const margin = {
      top: 35,
      right: 10,
      bottom: 20,
      left: 30
    };
    const { width } = this.props;
    const { height } = this.props;
    const data = this.props.data.chartData;

    d3.select(`#${this.props.containerId} svg`)
      .remove('svg');
    d3.select(`#${this.props.containerId} .tooltip`)
      .remove('.tooltip');

    const svg = d3.select(`#${this.props.containerId}`)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    const groupKey = this.chartProps.groupKey;
    const color = this.chartProps.barColor;

    const x0 = d3
      .scaleBand()
      .domain(data.map((d) => d[groupKey]))
      .rangeRound([margin.left, width - margin.right])
      .paddingInner(0.0);

    const x1 = d3.scaleBand()
      .domain(['key'])
      .rangeRound([0, x0.bandwidth()])
      .padding(0.001);

    const y = d3
      .scaleLinear()
      .domain([0, this.getMaxValue(data)])
      .nice()
      .rangeRound([height - margin.bottom, margin.top]);

    const xAxis = (g) => g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .attr('class', 'axis')
      .call(d3.axisBottom(x0)
        .tickSizeOuter(0))
      .call((g1) => g1.select('.domain')
        .remove());

    const yAxis = (g) => g.attr('transform', `translate(${margin.left}, 0)`)
      .attr('class', 'axis')
      .call(d3.axisLeft(y))
      .append('text');

    const tooltip = d3.select(`#${this.props.containerId}`)
      .append('div')
      .attr('class', 'tooltip')
      .style('visibility', 'hidden');

    svg.append('g')
      .call(xAxis);

    svg.append('g')
      .call(yAxis);

    // svg.append('g').call(yGrid);

    const instance = this;

    // Add brushing
    this.brush = d3.brush();
    svg
      .append('g')
      .attr('class', 'brush')
      .call(
        this.brush // Add the brush feature using the d3.brush function
          .extent([
            [0, 0],
            [width, height]
          ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
          .on('start brush', this.prepareForBrushing)
          .on('end', this.filterData) // Each time the brush selection changes, trigger the 'updateChart' function
      );

    // Bar
    svg
      .append('g')
      .selectAll('g')
      .data(data)
      .join('g')
      .attr('class', 'text-bar')
      .attr('transform', (d) => `translate(${x0(d[groupKey])},0)`)
      .selectAll('rect')
      .data((d) => {
        let sum = 0;
        keys.forEach((key) => {
          sum += d[key];
        });
        return [{ key: 'key', value: sum }];
      })
      .join('rect')
      .attr('class', `my-${this.chartProps.className}-bar`)
      .attr('x', (d) => x1(d.key))
      .attr('y', (d) => y(d.value))
      .attr('xEnd', (d) => x0(d.day))
      .attr('width', x1.bandwidth())
      .attr('height', (d) => y(0) - y(d.value))
      .attr('fill', () => color)
      .attr('cursor', 'crosshair')
      .on('mouseover', () => {
        tooltip.style('visibility', 'visible');
      })
      .on('mousemove', function (d) {
        const bar = this;
        const ctm = bar.getCTM();
        const coords = {};
        coords.x = bar.getAttribute('x');
        coords.y = bar.getAttribute('y');
        const barWidth = bar.getAttribute('width');

        const x = ctm.e + coords.x * ctm.a + coords.y * ctm.c;
        tooltip
          .html(`<div class=${instance.chartProps.className}>${d.value}</div>`)
          .style('left', `${x < (margin.left + width / 2) ? x + parseInt(barWidth, 10) + 10 : x - 115 - parseInt(barWidth, 10)}px`)
          .style('top', `${Math.min(d3.mouse(this)[1], height - 100)}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('visibility', 'hidden');
      })
      .on('mousedown', () => {
        tooltip.style('visibility', 'hidden');
        const brushElm = svg.select('.brush > .overlay')
          .node();
        const brushSelection = svg.select('.brush > .selection')
          .node();
        const bbox = brushSelection.getBoundingClientRect();
        if (
          brushSelection.style.display !== 'none'
          && d3.event.pageX > bbox.left
          && d3.event.pageX < bbox.left + bbox.width
          && d3.event.pageY > bbox.top
          && d3.event.pageY < bbox.top + bbox.height
        ) {
          // Click happened on a dot, inside the current brush selection, so, don't do anything
          return;
        }

        // Click happened on a dot, with no rectangle selection or outside the rectangle selection
        // so let's start a new selection :
        const newClickEvent = new MouseEvent('mousedown', {
          pageX: d3.event.pageX,
          pageY: d3.event.pageY,
          clientX: d3.event.clientX,
          clientY: d3.event.clientY,
          layerX: d3.event.layerX,
          layerY: d3.event.layerY,
          bubbles: true,
          cancelable: true,
          view: window
        });
        brushElm.dispatchEvent(newClickEvent);
      });

    svg.append('text')
      .attr('class', 'average-text')
      .attr('x', width - margin.right)
      .attr('y', 30)
      .attr('text-anchor', 'end')
      .attr('font-size', '24px')
      .attr('fill', color)
      .text(`Avg: ${d3.format('.2f')(this.props.data.averageValue)}${this.chartProps.averageValueUnit}`);
  };

  prepareForBrushing = () => {
    d3.select(`#${this.props.containerId} .tooltip`).style('visibility', 'hidden');
    d3.selectAll(`.my-${this.chartProps.className}-bar`).style('opacity', 0.5);
  };

  filterData = () => {
    const barsSelection = d3.selectAll(`.my-${this.chartProps.className}-bar`);
    const bars = barsSelection._groups[0];
    if (!d3.event.selection) {
      this.props.dispatchResetChartData();
      d3.selectAll(`.my-${this.chartProps.className}-bar`).style('opacity', 1);
      return;
    }

    const [selectionStart, selectionEnd] = d3.event.selection;

    const selectedBars = [];

    bars.forEach((bar, i) => {
      const ctm = bar.getCTM();
      const coords = {};
      coords.x = bar.getAttribute('x');
      coords.y = bar.getAttribute('y');
      const width = bar.getAttribute('width');

      const x = ctm.e + coords.x * ctm.a + coords.y * ctm.c;
      const xEnd = x + parseInt(width, 10);

      if (x <= selectionEnd[0] && xEnd >= selectionStart[0]) {
        selectedBars.push(i);
        // eslint-disable-next-line no-param-reassign
        bar.style.opacity = 1;
      } else {
        // eslint-disable-next-line no-param-reassign
        bar.style.opacity = 0.5;
      }
    });
    let selectedInterval = null;

    if (selectedBars.length >= 0) {
      selectedInterval = this.chartProps.getSelectedBarsInterval(selectedBars);
    }

    MixPanel.track(this.chartProps.mixPanelEventName);
    this.props.dispatchChangeSelectedDates(
      null,
      null,
      this.props.containerId,
      selectedInterval?.dwellTimeInterval,
      selectedInterval?.pointDistanceInterval
    );
    this.brush(d3.select(`#${this.props.containerId}`));
  };

  render() {
    return (
      <div className="bar-chart">
        <div id={this.props.containerId} />
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} redux store
 */
function mapStateToProps(store) {
  return { ...store.chartState };
}

/**
 * @param {Function} dispatch - redux dispatch
 * @returns {object} redux dispatch actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchChangeSelectedDates: CharActions.changeSelectedData,
      dispatchResetChartData: CharActions.resetData,
    },
    dispatch
  );
}

IntervalBarChartClass.propTypes = {
  /**
   * Show collected bars
   */
  showCollected: PropTypes.bool,
  /**
   * Show unsuccessful bars
   */
  showUnsuccessful: PropTypes.bool,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Change selected dates
   */
  dispatchChangeSelectedDates: PropTypes.func.isRequired,
  dispatchResetChartData: PropTypes.func.isRequired,
  /**
   * Reset bar chart
   */
  resetBarChart: PropTypes.instanceOf(Date),
  /**
   * Chart width
   */
  width: PropTypes.number.isRequired,
  /**
   * Chart height
   */
  height: PropTypes.number.isRequired,
  containerId: PropTypes.string.isRequired,
  chartName: PropTypes.string.isRequired,
  chartId: PropTypes.string,
  dataKeys: PropTypes.arrayOf(PropTypes.string),
  /**
   * Chart data
   */
  data: PropTypes.shape({
    chartData: PropTypes.arrayOf(PropTypes.shape({
      collections: PropTypes.number,
      unsuccessful: PropTypes.number,
      day: PropTypes.object
    })),
    dataExists: PropTypes.bool,
    averageValue: PropTypes.number
  }).isRequired
};

IntervalBarChartClass.defaultProps = {
  showCollected: true,
  showUnsuccessful: true,
  resetBarChart: null,
  dataKeys: null,
  chartId: null
};

const IntervalBarChart = connect(mapStateToProps, mapDispatchToProps)(IntervalBarChartClass);

export default withTranslation('translations')(IntervalBarChart);
