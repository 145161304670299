import { Marker, Popup } from 'react-map-gl';
import React from 'react';
import PropTypes from 'prop-types';
import haversine from 'haversine-distance';
import IsochronePolygonWrapper from '../mapSourceAndLayers/IsochronePolygonWrapper';
import MapMarkerPopup from '../../../../../common/components/popups/components/MapMarkerPopup';
import MixPanel from '../../../../../setup/mixPanel';

export default function DistancePinMarkerWrapper(props) {
  const [draggingPin, setDraggingPin] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);

  function getPinRemoveButtonData(pinId) {
    return {
      action: () => {
        MixPanel.track('Delivery Area Analysis - Distance pin removed', { pinOrigin: props.pin.pinOrigin, lat: props.pin.lat, lng: props.pin.lng });
        props.removeDistancePin(pinId);
      },
      text: 'Remove pin'
    };
  }

  return (
    <>
      <Marker
        draggable
        longitude={props.pin.lng}
        latitude={props.pin.lat}
        onDragStart={() => {
          setDraggingPin(true);
          props.removeDistancePinPolygon(props.pin.id);
        }}
        onDragEnd={(e) => {
          props.updateDistancePinData(e, props.pin);
          const distanceMovedInMeters = haversine(e.lngLat, { lat: props.pin.lat, lng: props.pin.lng }).toFixed(0);
          MixPanel.track('Delivery Area Analysis - Distance pin moved', {
            pinOrigin: props.pin.pinOrigin,
            prevlat: props.pin.lat,
            prevlng: props.pin.lng,
            newlat: e.lngLat.lat,
            newlng: e.lngLat.lng,
            distanceMovedInMeters: distanceMovedInMeters
          });
          setDraggingPin(false);
        }}
        onClick={() => {
          MixPanel.track('Delivery Area Analysis - Distance pin clicked', { pinOrigin: props.pin.pinOrigin, lat: props.pin.lat, lng: props.pin.lng });
          setShowPopup(true);
        }}
        color="#0B71AF"
      />
      {!draggingPin && props.isochroneData?.polygonFeatures && (<IsochronePolygonWrapper polygonId={props.pin.id} sourceData={props.isochroneData.polygonFeatures} />)}
      {showPopup && (
      <Popup
        latitude={props.pin.lat}
        longitude={props.pin.lng}
        onClose={() => setShowPopup(false)}
        offset={35}
        closeButton={false}
      >
        <MapMarkerPopup data={{
          pointCoverageData: props.distancePinCoverageData,
          buttonsData: [getPinRemoveButtonData(props.pin.id)],
          hexType: props.hexType,
          distanceType: props.distanceType
        }}
        />
      </Popup>
      )}

    </>
  );
}

DistancePinMarkerWrapper.propTypes = {
  pin: PropTypes.object,
  updateDistancePinData: PropTypes.func.isRequired,
  distancePinCoverageData: PropTypes.object,
  hexType: PropTypes.string,
  distanceType: PropTypes.string.isRequired,
  removeDistancePin: PropTypes.func.isRequired,
  removeDistancePinPolygon: PropTypes.func.isRequired,
  isochroneData: PropTypes.object
};

DistancePinMarkerWrapper.defaultProps = {
  pin: null,
  distancePinCoverageData: null,
  hexType: null,
  isochroneData: null
};
