import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import * as _ from 'lodash';
import DistanceTypeUtil from '../../../../common/utils/distanceTypeUtil';
import { GET_ISOCHRONES_AS_HEXAGONS } from '../api/deliveryAreaAnalysisApi';
import PolygonCalculationsUtil from '../../../../common/utils/polygonCalculationsUtil';
import { IsochroneUtil } from '../util/isochroneUtil';

export default function useIsochroneApi(mapPointsStaticData, distanceType) {
  const [getIsochronesAsHexagons, { loading, data: getIsochronesQueryResults }] = useLazyQuery(GET_ISOCHRONES_AS_HEXAGONS);

  useEffect(() => {
    if (!_.isEmpty(mapPointsStaticData) && DistanceTypeUtil.shouldFetchIsochronesFromBack(distanceType)) {
      const requestVariables = IsochroneUtil.getIsochronesAsHexagonsRequestVariables(mapPointsStaticData, distanceType);
      getIsochronesAsHexagons({ variables: requestVariables });
    }
  }, [mapPointsStaticData, distanceType, getIsochronesAsHexagons]);

  const mapPointsIsochroneData = useMemo(() => {
    let newMapPointsIsochroneData = {};
    if (!_.isEmpty(getIsochronesQueryResults) && !_.isEmpty(mapPointsStaticData) && DistanceTypeUtil.shouldFetchIsochronesFromBack(distanceType)) {
      if (Object.keys(mapPointsStaticData).length === getIsochronesQueryResults.innerIsochrone.length) {
        newMapPointsIsochroneData = IsochroneUtil.createPointsIsochroneData(mapPointsStaticData, getIsochronesQueryResults);
      }
    } else if (!_.isEmpty(mapPointsStaticData) && !DistanceTypeUtil.shouldFetchIsochronesFromBack(distanceType)) {
      Object.values(mapPointsStaticData).forEach((mapPoint) => {
        const hexagonsCovered = PolygonCalculationsUtil.getIsochroneFromRadialDistance(mapPoint.lat, mapPoint.lng, true);
        newMapPointsIsochroneData[mapPoint.id] = {
          ...hexagonsCovered,
          id: mapPoint.id
        };
      });
    }

    return newMapPointsIsochroneData;
  }, [getIsochronesQueryResults, distanceType, mapPointsStaticData]);

  return { mapPointsIsochroneData, loading };
}
