import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './RadioButton.scss';
import { useTranslation } from 'react-i18next';
import SingleRadioButton from './SingleRadioButton';

export default function RadioButton(props) {
  const { t } = useTranslation();
  const { selectedValueChanged, defaultValue } = props;
  const [selectedValue, setSelectedValue] = useState(props.defaultValue);

  useEffect(() => {
    selectedValueChanged(defaultValue);
  }, [selectedValueChanged, defaultValue]);

  const onValueChange = useCallback((val) => {
    selectedValueChanged(val);
    setSelectedValue(val);
  }, [selectedValueChanged]);

  function getSingleInputComponent(value) {
    return (
      <SingleRadioButton value={value} checked={selectedValue === value} type={props.type} onValueChange={onValueChange} />
    );
  }

  return (
    <div className="radio-button-group">
      <div className="radio-title">{`${t(props.title)}:`}</div>
      {props.values?.length > 0 && props.values.map((value) => getSingleInputComponent(value))}
    </div>
  );
}

RadioButton.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string,
  selectedValueChanged: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string
};

RadioButton.defaultProps = {
  values: [],
  defaultValue: PropTypes.string,
  type: 'radio-group'
};
