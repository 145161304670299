import { Layer, Source } from 'react-map-gl';
import PropTypes from 'prop-types';
import React from 'react';
import { COVERAGE_RECOMMENDATION_LAYER, COVERAGE_RECOMMENDATION_SOURCE, } from '../../constants/userMapLayers';

export default function RecommenderNetworkMapSourceWrapper(props) {
  const { data } = props;

  return (
    <Source type="geojson" key={COVERAGE_RECOMMENDATION_SOURCE} id={COVERAGE_RECOMMENDATION_SOURCE} data={data}>
      <Layer
        type="fill"
        id={`${COVERAGE_RECOMMENDATION_LAYER}-hexagons-layer`}
        key={`${COVERAGE_RECOMMENDATION_LAYER}-hexagons-layer`}
        source={COVERAGE_RECOMMENDATION_SOURCE}
        paint={{
          'fill-color': '#8851FE',
          'fill-opacity': 0.5
        }}
      />
    </Source>

  );
}

RecommenderNetworkMapSourceWrapper.propTypes = { data: PropTypes.object };

RecommenderNetworkMapSourceWrapper.defaultProps = { data: null };
