import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './FormInput.scss';
import Toggle from '../../buttons/toggle/Toggle';
import CustomSelect from '../../selections/CustomSelect';
import InputErrorText from './InputErrorText';
import { INPUT_SIZE, LABEL_POSITION } from '../../../constants/uiConstants';
import { UiUtil } from '../../../utils/uiUtil';
import MilyCheckbox from '../../buttons/checkbox/MilyCheckbox';
import MilyTooltipWrapper from '../../tooltips/MilyTooltipWrapper';

export class FormInput extends React.Component {
  addLabelPositionToClassName = (className) => {
    if (this.props.labelPosition === LABEL_POSITION.LEFT) {
      return `${className} label-left`;
    }
    return className;
  };

  addSizeToClassName = (className) => {
    return `${className} ${UiUtil.getInputSizeClassName(this.props.size)}`;
  };

  getWrapperClassName = () => {
    return `form-input-wrapper input-type-${this.props.inputType}`;
  };

  isInputWithoutError = () => {
    const INPUT_TYPES_WITH_NO_ERROR_FIELD = ['checkbox', 'toggle', 'radio', 'time'];

    return INPUT_TYPES_WITH_NO_ERROR_FIELD.includes(this.props.inputType);
  };

  renderInputComponent = () => {
    const props = _.omit(this.props, ['inputType', 'helperText', 'error', 'validation', 'onFormInputBeforeChange', 'onFormInputChange', 'labelPosition']);
    // this.props.value comes from Form component!!!
    switch (this.props.inputType) {
      case 'input': {
        return (
          <div className="text-input" data-tip={`${this.props.helperText}`} data-for={props.name}>
            {/* We need html required props in order to make css :valid to work, so component can have different appearance for filled and empty component */}
            <input className={this.addSizeToClassName('default-input')} {...props} required />
            {props.label && <div className="form-label input-label">{props.label}</div>}
            {this.props.helperText && (this.props.error || this.props.disabled) && (
              <MilyTooltipWrapper id={props.name} className="tooltip" place="right" effect="solid" />
            )}
          </div>
        );
      }
      case 'input-number': {
        return (
          <div className={this.addLabelPositionToClassName('text-input')} data-tip={`${this.props.helperText}`} data-for={props.name}>
            <input className={this.addSizeToClassName('default-input')} {...props} required />
            {props.label && <div className="form-label input-label">{props.label}</div>}
            {this.props.helperText && (this.props.error || this.props.disabled) && (
              <MilyTooltipWrapper id={props.name} className="tooltip" place="right" effect="solid" />
            )}
          </div>
        );
      }
      case 'checkbox': {
        return (
          <div className="checkbox-input" data-tip={`${this.props.helperText}`} data-for={props.name}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading  */}
            <MilyCheckbox {...props} />
            {props.customLabel && props.customLabel}
            {this.props.helperText && (this.props.error || this.props.disabled) && (
              <MilyTooltipWrapper id={props.name} className="tooltip" place="right" effect="solid" />
            )}
          </div>
        );
      }
      case 'select': {
        return (
          <div>
            {props.label && <div className="form-label input-label">{props.label}</div>}
            <CustomSelect {...props} />
          </div>
        );
      }
      case 'toggle': {
        return (
          <div className="toggle-input">
            {props.label && <div className="form-label input-label">{props.label}</div>}
            <Toggle {...props} />
          </div>
        );
      }
      case 'time': {
        return (
          <div className="text-input">
            <input type="time" className={this.addSizeToClassName('default-input')} {...props} />
          </div>
        );
      }
      default: {
        return '';
      }
    }
  };

  render() {
    return (
      <div className={this.getWrapperClassName()}>
        {this.renderInputComponent()}
        {!this.isInputWithoutError() && <InputErrorText text={this.props.error} labelPosition={this.props.labelPosition} />}
      </div>
    );
  }
}

FormInput.propTypes = {
  inputType: PropTypes.oneOf(['input', 'textarea', 'select', 'checkbox', 'input-number', 'toggle']).isRequired,
  name: PropTypes.string,
  onFormInputBeforeChange: PropTypes.func, // function(newValue) must return value that will be saved as new value if validation pass
  onFormInputChange: PropTypes.func, // function(newValue, currentFormData, nextFormData) newValue is value returned from onFormInputBeforeChange (if this props is added to FormInput element)
  helperText: PropTypes.string,
  error: PropTypes.string,
  validation: PropTypes.shape({
    required: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([true])]),
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    isNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([true])]),
    isValidEmailAddress: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([true])]),
    isValidGeocoordinates: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([true])]),
    regex: PropTypes.oneOfType([
      PropTypes.instanceOf(RegExp),
      PropTypes.shape({
        value: PropTypes.instanceOf(RegExp),
        message: PropTypes.string
      })
    ]),
    custom: PropTypes.func
  }),
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(INPUT_SIZE)),
  labelPosition: PropTypes.oneOf(Object.values(LABEL_POSITION))
};

FormInput.defaultProps = {
  helperText: undefined,
  name: '',
  error: undefined,
  validation: null,
  onFormInputBeforeChange: null,
  onFormInputChange: null,
  disabled: false,
  size: INPUT_SIZE.MEDIUM,
  labelPosition: LABEL_POSITION.TOP
};
