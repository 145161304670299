const DynamoStringExtensionsUtil = {
  getMappingPrefix: getMappingPrefix,
  getDeliveryAreaPrefix: getDeliveryAreaPrefix,
  getTeamPrefix: getTeamPrefix,
  getHubPrefix: getHubPrefix,
  addCourierPrefix: addCourierPrefix,
  addShipmentPrefix: addShipmentPrefix,
  addCourierLocationPrefix: addCourierLocationPrefix,
  addPolicyPermissionPrefix: addPolicyPermissionPrefix,
  addZonePrefix: addZonePrefix,
  addRolePrefix: addRolePrefix,
  addLabeledShipmentPrefix: addLabeledShipmentPrefix,
  addCourierPlanPrefix: addCourierPlanPrefix,
  addOohStopPrefix: addOohStopPrefix
};

function getMappingPrefix() {
  return 'm';
}

function getDeliveryAreaPrefix() {
  return 'da#';
}

function getTeamPrefix() {
  return 't#';
}

function getHubPrefix() {
  return 'hub#';
}

function addCourierPrefix(data) {
  return `c#${data}`;
}

function addShipmentPrefix(data) {
  return `sh#${data}`;
}

function addCourierLocationPrefix(data) {
  return `ccl#${data}`;
}

function addPolicyPermissionPrefix(data) {
  return `p#${data}`;
}

function addZonePrefix(data) {
  return `z#${data}`;
}

function addRolePrefix(data) {
  return `ro#${data}`;
}

function addLabeledShipmentPrefix(data) {
  return `ls#${data}`;
}

function addCourierPlanPrefix(data) {
  return `o#${data}`;
}

function addOohStopPrefix(data) {
  return `os#${data}`;
}

export default DynamoStringExtensionsUtil;
