import { Layer, Source } from 'react-map-gl';
import React from 'react';
import PropTypes from 'prop-types';
import colorsAndFonts from '../../../../../resources/colors-and-fonts.scss';

export default function IsochronePolygonWrapper(props) {
  if (!props.sourceData) return null;

  return (
    <div>
      <Source id={`${props.polygonId}-pin-polygon-source`} type="geojson" data={props.sourceData} />
      <Layer
        id={`${props.polygonId}-pin-polygon-layer`}
        type="fill"
        source={`${props.polygonId}-pin-polygon-source`}
        paint={{
          'fill-color': colorsAndFonts.selection_color,
          'fill-opacity': 0.3
        }}
      />
      <Layer
        id={`${props.polygonId}-pin-outline-layer`}
        type="line"
        source={`${props.polygonId}-pin-polygon-source`}
        paint={{
          'line-color': colorsAndFonts.polygon_border_color,
          'line-width': 1.5
        }}
      />
    </div>
  );
}

IsochronePolygonWrapper.propTypes = {
  polygonId: PropTypes.string.isRequired,
  sourceData: PropTypes.object
};

IsochronePolygonWrapper.defaultProps = { sourceData: null };
