import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '../../buttons/button/Button';
import './ConfirmationDialog.scss';
import * as DialogActions from '../../../../state/actions/dialogActions';
import { BUTTON_TYPES } from '../../buttons/button/constants/buttonTypes';

/**
 * Component for displaying confirm dialog
 *
 * @returns {JSX.Element} Confirm dialog component
 * @component
 * @category Common
 */
export default function ConfirmationDialog(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const continueAction = () => {
    if (props.continueCallback) {
      props.continueCallback();
    }

    dispatch(DialogActions.closeDialog());
  };

  const cancelAction = () => {
    if (props.cancelCallback) {
      props.cancelCallback();
    }

    dispatch(DialogActions.closeDialog());
  };

  return (
    <div className="alert-dialog-component">
      <div className="title">{props.title}</div>
      <div className="body">{props.body}</div>
      <div className="buttons">
        <Button type={BUTTON_TYPES.SECONDARY} text={t('Cancel')} onClick={cancelAction} />
        <Button type={BUTTON_TYPES.PRIMARY} text={props.confirmButtonText} onClick={continueAction} />
      </div>
    </div>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  continueCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  confirmButtonText: PropTypes.string
};

ConfirmationDialog.defaultProps = {
  title: '',
  body: '',
  continueCallback: null,
  cancelCallback: null,
  confirmButtonText: ''
};
