import React from 'react';
import './MilyCheckbox.scss';
import PropTypes from 'prop-types';

export default function MilyCheckbox(props) {
  const [checkboxHovered, setCheckboxHovered] = React.useState(false);
  const [checked, setChecked] = React.useState(!!props.checked);

  function handleMouseEnter() {
    setCheckboxHovered(true);
  }

  function handelMouseLeave() {
    setCheckboxHovered(false);
  }

  function toggleCheckbox() {
    if (!props.disabled) {
      setChecked(!checked);

      if (props.onChange) {
        props.onChange(!checked);
      }
    }
  }

  function getCheckboxClass() {
    let className = 'checkbox-input';

    if (props.disabled) {
      className = `${className} disabled`;
    }

    if (checked) {
      className = `${className} checked`;
    }

    if (checkboxHovered) {
      className = `${className} hovered`;
    }

    return className;
  }

  return (
    <div className="mily-checkbox-container">
      <div className="label">{props.label}</div>
      <div className="checkbox-wrapper">
        <div
          className={getCheckboxClass()}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handelMouseLeave}
          onClick={toggleCheckbox}
        />
      </div>
      <span className="checkmark" />
    </div>

  );
}

MilyCheckbox.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

MilyCheckbox.defaultProps = {
  label: '',
  disabled: false,
  checked: false,
  onChange: null
};
