import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import './RegionStopsDistributionChart.scss';
import { bindActionCreators } from 'redux';
import AuthUtil from '../../../../../common/utils/authUtil';
import * as RegionAnalysisActions from '../../../../../state/actions/regionAnalysisActions';
import MixPanel from '../../../../../setup/mixPanel';
import colorsAndFonts from '../../../../../resources/colors-and-fonts.scss';
import { PlotUtil } from '../../util/plotUtil';

/**
 * Region distribution Bar chart which shows distributions of stops per region
 * in a bar chart form implemented via d3.js.
 *
 * @component
 * @alias RegionStopsDistributionChart
 * @category RegionAnalysis
 */
class RegionStopsDistributionChartClass extends React.Component {
  /**
   * Calculates average number of stops for provided regions
   *
   * @returns {number} Average number of stops for provided regions
   * @function
   */
  getAvg = (regionStopsDistribution) => {
    const sum = regionStopsDistribution.reduce((acc, value) => acc + value.count, 0);
    return sum / regionStopsDistribution.length || 0;
  };

  getRegionsStopsDistribution = () => {
    if (!this.props.regionsTotal) {
      return null;
    }

    const regionsStopsDistribution = [];
    Object.entries(this.props.regionsTotal).forEach(([regionId, hexData]) => {
      regionsStopsDistribution.push({
        regionId: regionId,
        center: [44, 22],
        name: regionId.substring(regionId.indexOf('-') + 1),
        count: (hexData && hexData[this.props.hexType]) || 0,
        color: PlotUtil.getRegionColor(regionId)
      });
    });

    return regionsStopsDistribution;
  };

  /**
   * Creates Tooltip to be shown on a Bar hover event
   *
   * @param {object} options - tooltip options
   * options.active - visibility of the element
   * options.payload - region name and stops count
   * @returns {JSX.Element|null} <div> element which shows region name and stops count on bar hover
   * @function
   */
  BarChartTooltip = (options) => {
    const { active } = options;
    const { payload } = options;
    if (!active) return null;

    return (
      <div className="bar-chart-tooltip">
        <p className="label">{payload[0].payload.name}</p>
        <p className="count">
          {this.props.t('Count')}
          :
          {payload[0].payload.count}
        </p>
      </div>
    );
  };

  createChartStatistic = (regionStopsDistribution) => {
    const userMapLayers = AuthUtil.getRegionAnalysisLayers();

    const { centerTotalsInfo } = this.props;
    const length = regionStopsDistribution.length;
    const data = [];

    userMapLayers.forEach((layer) => {
      data.push({
        key: layer,
        titleTotal: this.props.t(`Total ${layer}`),
        valueTotal: centerTotalsInfo[layer],
        titleAvg: this.props.t(`Average ${layer}`),
        valueAvg: Math.round(centerTotalsInfo[layer] / length)
      });
    });

    return (
      <div className="flex-container-totals-info">
        {data.map((item) => (
          <div className="flex-one-total-info" key={item.key}>
            <div className="stat-group">
              <div className="flex-item-title">
                {item.titleTotal}
                {' '}
              </div>
              <div className="flex-item-value">{item.valueTotal}</div>
            </div>
            <div className="stat-group">
              <div className="flex-item-title">
                {item.titleAvg}
                {' '}
              </div>
              <div className="flex-item-value">{item.valueAvg}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const regionStopsDistribution = this.getRegionsStopsDistribution();

    if (!regionStopsDistribution || regionStopsDistribution.length < 1 || !this.props.isChartVisible) return <span />;

    return (
      <div className="bottom-content">
        {this.createChartStatistic(regionStopsDistribution)}
        <div className="div-responsive-container">
          <ResponsiveContainer width="99%" height="100%">
            <BarChart className="custom-bar-chart" data={regionStopsDistribution}>
              <XAxis dataKey="name" tick={{ fill: colorsAndFonts.light_text, fontSize: 10 }} interval={0} angle={-45} textAnchor="end" />
              <YAxis dataKey="count" tick={{ fill: '#fff' }} />
              <CartesianGrid strokeDasharray="3 3" stroke="#fff" line={{ fill: '#fff' }} style={{ opacity: 1 }} vertical={false} />
              <Tooltip cursor={{ fill: 'transparent' }} content={this.BarChartTooltip} />
              <Bar dataKey="count" fill="#8884d8">
                {regionStopsDistribution.map((entry) => (
                  <Cell
                    className="bar-cell"
                    key={entry.regionId}
                    fill={entry.color}
                    onMouseEnter={() => {
                      this.props.dispatchHighlightRegion(entry.regionId, 0.8, false);
                      MixPanel.track('Delivery Area Analysis - BarChart - Bar hovered', { regionId: entry.regionId });
                    }}
                    onMouseLeave={() => {
                      this.props.dispatchHighlightRegion(null);
                    }}
                    onClick={() => {
                      this.props.dispatchHighlightRegion(entry.regionId, 0.8, true);
                      MixPanel.track('Delivery Area Analysis - BarChart - Bar clicked', { regionId: entry.regionId });
                    }}
                  />
                ))}
              </Bar>
              <ReferenceLine y={this.getAvg(regionStopsDistribution)} stroke="red" strokeDasharray="3 3" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.regionAnalysisState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchHighlightRegion: RegionAnalysisActions.highlightRegion },
    dispatch
  );
}

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RegionStopsDistributionChartClass));

RegionStopsDistributionChartClass.propTypes = {
  /**
   * Info about total counts of package, stops, shipments for a one Center.
   */
  centerTotalsInfo: PropTypes.object.isRequired,
  /**
   * Translation function
   */
  t: PropTypes.func.isRequired,
  /**
   * Toggles visibility of the Bar chart
   */
  isChartVisible: PropTypes.bool,
  /**
   * Function that dispatches event with payload about region which should be highlighted.
   * It can change style of the region, or show it at the center of the screen.
   */
  dispatchHighlightRegion: PropTypes.func.isRequired,
  regionsTotal: PropTypes.object,
  hexType: PropTypes.string
};

RegionStopsDistributionChartClass.defaultProps = {
  isChartVisible: false,
  regionsTotal: null,
  hexType: null
};
