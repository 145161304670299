import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './SingleRadioButton.scss';

export default function SingleRadioButton(props) {
  const { t } = useTranslation();
  const [buttonHovered, setButtonHovered] = useState(false);

  function handleMouseEnter() {
    setButtonHovered(true);
  }

  function handleMouseLeave() {
    setButtonHovered(false);
  }
  return (
    <div className="single-radio-button" key={props.value}>
      <input
        type="radio"
        value={props.value}
        name={props.type}
        className={`${buttonHovered ? 'radio-button-hovered' : 'radio-button'}`}
        onChange={() => {
          props.onValueChange(props.value);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        checked={props.checked}
        disabled={props.disabled}
        onClick={props.onClickAdditionalAction}
      />
      {props.displayValue && <div className="value-name">{t(props.value)}</div>}
    </div>
  );
}

SingleRadioButton.propTypes = {
  value: PropTypes.string,
  displayValue: PropTypes.bool,
  checked: PropTypes.bool,
  type: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClickAdditionalAction: PropTypes.func
};

SingleRadioButton.defaultProps = {
  value: '',
  displayValue: true,
  checked: false,
  type: '',
  disabled: false,
  onClickAdditionalAction: () => {}
};
