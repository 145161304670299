import React from 'react';
import PropTypes from 'prop-types';
import './MapMarkerPopup.scss';
import { useTranslation } from 'react-i18next';
import CopyToClipboardWrapper from '../../copy/CopyToClipboardWrapper';
import MarkerPopupCircleIcon from '../../marker/components/MarkerPopupCircleIcon';
import MarkerPopupRowWithIcon from '../../marker/components/MarkerPopupRowWithIcon';
import { FEATURE_KEY_ICON_MAP } from '../../../constants/mapAdditionalFeaturesData';
import Button from '../../buttons/button/Button';
import IsochronePolygonSymbol from './IsochronePolygonSymbol';
import { BUTTON_TYPES } from '../../buttons/button/constants/buttonTypes';
import { raygunClient } from '../../../../setup/raygunClient';
import DistanceTypeUtil from '../../../utils/distanceTypeUtil';

export default function MapMarkerPopup(props) {
  const { t } = useTranslation();

  const dataKeys = props.data.keyOrder || Object.keys(props.data);
  let missedPointsPickup = null;
  let missedPointsDropoff = null;
  if (props.data.missedDistance && props.data.deliveryType === 'delivery') missedPointsPickup = true;
  if (props.data.missedDistance && props.data.deliveryType === 'pickup') missedPointsDropoff = true;

  function getIsochroneDescriptionRows() {
    const descriptionElement = [];

    if (props.data.isochroneCoveredEntities) {
      // Ooh analysis page case, we don't have pointCoverageData
      Object.keys(props.data.isochroneCoveredEntities.count).forEach((key) => {
        const coverage = props.data.isochroneCoveredEntities.count[key];
        descriptionElement.push(getIsochroneDescriptionRow(key, coverage));
      });

      return descriptionElement;
    }

    // Delivery area analysis page case
    Object.keys(props.data.pointCoverageData).forEach((key) => {
      if (props.data.pointCoverageData[key]) {
        const coverage = props.data.pointCoverageData[key][props.data.hexType];
        descriptionElement.push(getIsochroneDescriptionRow(key, coverage));
      }
    });

    return descriptionElement;
  }

  function getIsochroneDescriptionRow(key, coverage) {
    if (!props.data?.distanceType) {
      raygunClient.send('Missing distanceType in MapMarkerPopup', null, { props, key, coverage });
      return (<div className="isochrone-description-row" key={key} />);
    }

    const value = DistanceTypeUtil.getDistanceValue(props.data.distanceType, key);
    const unit = DistanceTypeUtil.getUnit(props.data.distanceType);
    const mode = t(DistanceTypeUtil.getName(props.data.distanceType));

    return (
      <div className="isochrone-description-row" key={key}>
        <div className="isochrone-description-content" key={key}>
          <IsochronePolygonSymbol polygonDistance={key} />
          <div className="isochrone-description-text">{`${value} ${unit} ${mode}`}</div>
          <div className="entities-count">{coverage}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="map-marker-popup">
      <div>
        {dataKeys.map((key) => {
          if (props.data.featureType === 'mapFeature') {
            if (key === 'name' && props.data[key]) {
              return (
                <div className="map-marker-popup-title strong-border" key={key}>
                  {props.data[key]}
                </div>
              );
            }

            return null;
          }
          if (key === 'shipmentCode' && props.data[key]) {
            return (
              <div className="row-wrapper" key={key}>
                <MarkerPopupCircleIcon className={props.data.className} />
                <div className="data-wrapper sh-codes">
                  <div className={`delivery-type ${props.data.className}`}>{t(props.titleOverride || props.data.deliveryType)}</div>
                  <div className="sh-code">
                    {props.data[key]}
                    <CopyToClipboardWrapper text={props.data[key]} />
                  </div>
                </div>
              </div>
            );
          }

          if (FEATURE_KEY_ICON_MAP[key] && props.data[key]) {
            return <MarkerPopupRowWithIcon key={key} dataKey={key} value={props.data[key]} />;
          }

          return null;
        })}
      </div>
      <div>
        {missedPointsDropoff && (
          <div className="description">
            {t('popUpMissedDistanceDeliveryText')}
            {' '}
            <b>
              {' '}
              {`${props.data.missedDistance} m`}
              {' '}
            </b>
          </div>
        )}
        {missedPointsPickup && (
          <div className="description">
            {t('popUpMissedDistancePickupText')}
            {' '}
            <b>
              {' '}
              {`${props.data.missedDistance} m`}
              {' '}
            </b>
          </div>
        )}
        {(props.data.pointCoverageData || props.data.isochroneCoveredEntities) && (
          <div className="description">
            {getIsochroneDescriptionRows()}
          </div>
        )}
        {
          props.data.buttonsData?.map((buttonData) => {
            return (
              <div className="buttons" key={buttonData.text}>
                <Button onClick={buttonData.action} text={t(buttonData.text)} type={BUTTON_TYPES.SECONDARY} />
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

MapMarkerPopup.propTypes = {
  data: PropTypes.shape({
    keyOrder: PropTypes.arrayOf(PropTypes.string),
    featureType: PropTypes.string,
    name: PropTypes.string,
    shipmentCode: PropTypes.string,
    deliveryType: PropTypes.string,
    className: PropTypes.string,
    missedDistance: PropTypes.number,
    pointCoverageData: PropTypes.object,
    hexType: PropTypes.string,
    distanceType: PropTypes.string,
    buttonsData: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      action: PropTypes.func
    })),
    isochroneCoveredEntities: PropTypes.shape({ count: PropTypes.object })
  }),
  titleOverride: PropTypes.string
};

MapMarkerPopup.defaultProps = { data: null, titleOverride: null };
