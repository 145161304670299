import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './TimePickerWrapper.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormInput } from '../form/components/FormInput';
import InputErrorText from '../form/components/InputErrorText';

export default function TimePickerWrapper(props) {
  const { t } = useTranslation();
  const [time, setTime] = useState(props.defaultTime || moment());
  const [error, setError] = useState(false);

  const inputStringToTimeFormat = (inputTime) => {
    const [hour, minute] = inputTime.split(':');
    const date = moment();
    date.set('hours', hour);
    date.set('minutes', minute);
    return date;
  };

  const isInputValid = (inputTime) => {
    return props.allowBeforePresent ? true : inputStringToTimeFormat(inputTime).isSameOrAfter(moment());
  };

  const handleChange = (event) => {
    const inputTime = event.target.value;
    const errorOccurred = !isInputValid(inputTime);
    setTime(inputTime);
    setError(errorOccurred);
    props.onChange(!errorOccurred ? inputTime : null);
  };

  const getErrorClass = () => (error ? 'wrapper-error' : '');

  return (
    <div className={`time-picker-wrapper ${getErrorClass()}`}>
      <div className="label">{props.label}</div>
      <div className="input-wrapper">
        <FormInput inputType="time" value={time} onChange={handleChange} />
        <InputErrorText text={error ? t('Time before present') : ''} />
      </div>
    </div>
  );
}

TimePickerWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  defaultTime: PropTypes.string,
  allowBeforePresent: PropTypes.bool
};

TimePickerWrapper.defaultProps = { defaultTime: null, allowBeforePresent: false };
