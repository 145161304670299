import { Layer, Source } from 'react-map-gl';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import PolygonUtil from '../../../utils/polygonUtil';
import { PlotUtil } from '../../util/plotUtil';

export default function RegionsWrapper(props) {
  const { source, visibleMapSource } = props;
  const mapViewType = useSelector((state) => state.regionAnalysisState.mapViewType);
  const highlightedRegion = useSelector((state) => state.regionAnalysisState.highlightedRegion);

  function getHexagonsClusterSourceData(regionId, regionGeojson) {
    const regionCenter = PolygonUtil.getPolygonCentroidHex(regionGeojson.geometry.coordinates[0], props.regionTotals?.maxStopsHex?.hex);

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: regionCenter
          }
        }
      ]
    };
  }

  const clusterVisibility = PlotUtil.getClusterVisibilityString(mapViewType, source, visibleMapSource);
  const heatmapVisibility = PlotUtil.getHeatmapVisibilityString(mapViewType, source, visibleMapSource);

  function getPolygonPaintProperty(sourceId) {
    const paint = {
      'fill-color': PlotUtil.getRegionColor(sourceId),
      'fill-opacity': 0.4
    };

    if (highlightedRegion && highlightedRegion.regionId === sourceId) {
      paint['fill-outline-color'] = highlightedRegion.opacity === 1 ? '#000000' : PlotUtil.getRegionColor(sourceId);
      paint['fill-opacity'] = highlightedRegion.opacity;
    }

    return paint;
  }

  function getBorderPaintProperty(sourceId) {
    const paint = { 'line-color': '#555555' };

    if (highlightedRegion && highlightedRegion.regionId === sourceId) {
      paint['line-width'] = highlightedRegion.opacity > 0.5 ? 4 : 1;
    }

    return paint;
  }

  return (
    <>
      <Source type="geojson" key={`${source.id}-border`} id={source.id} data={source.data}>
        {/* regions layer */}
        <Layer
          id={`heatmap-view-${source.id}-layer`}
          type="line"
          source={source.id}
          minzoom={3}
          layout={{ visibility: heatmapVisibility }}
          paint={getBorderPaintProperty(source.id)}
          properties={{ regionId: source.id }}
        />
        <Layer
          id={`heatmap-view-${source.id}-fill-layer`}
          type="fill"
          source={source.id}
          minzoom={3}
          layout={{ visibility: heatmapVisibility }}
          paint={{ 'fill-opacity': 0 }}
        />

        <Layer
          id={`${source.id}-layer`}
          type="fill"
          source={source.id}
          minzoom={3}
          layout={{ visibility: clusterVisibility }}
          paint={getPolygonPaintProperty(source.id)}
        />
      </Source>

      {props.regionTotals && (
        <Source type="geojson" id={`${source.id}-hexagons-cluster`} data={getHexagonsClusterSourceData(source.id, source.data)}>
          <Layer
            id={`${source.id}-hexagons-cluster-layer`}
            type="circle"
            source={`${source.id}-hexagons-cluster`}
            minzoom={3}
            maxzoom={PlotUtil.getMinZoomLevel(props.centerPositionData)}
            layout={{ visibility: clusterVisibility }}
            paint={{
              'circle-color': PlotUtil.getRegionColor(source.id),
              'circle-radius': 18,
              'circle-opacity': 0.6
            }}
          />
          <Layer
            id={`${source.id}-hexagons-cluster-layer-count`}
            type="symbol"
            source={`${source.id}-hexagons-cluster`}
            minzoom={3}
            maxzoom={PlotUtil.getMinZoomLevel(props.centerPositionData)}
            layout={{
              'text-field': `${props.regionTotals[source.layerType]}\n${source.id.substring(source.id.indexOf('-') + 1)}`,
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-size': 10,
              'text-allow-overlap': true,
              visibility: clusterVisibility
            }}
            paint={{ 'text-color': '#000000' }}
          />
        </Source>
      )}
    </>
  );
}

RegionsWrapper.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.object,
    layerType: PropTypes.string
  }),
  visibleMapSource: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.object,
    layerType: PropTypes.string
  }),
  centerPositionData: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number
  }),
  regionTotals: PropTypes.object
};

RegionsWrapper.defaultProps = {
  source: null,
  visibleMapSource: null,
  centerPositionData: null,
  regionTotals: null
};
