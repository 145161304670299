import * as ActionTypes from '../constants/actionTypes';

/**
 * @namespace RegionAnalysisActions
 * @category Actions
 */

/**
 * Creates an action containing a newly selected date range to change to.
 *
 * @param {object} dateFrom - newly selected start date
 * @param {object} dateTo - newly selected end date
 * @returns {{payload: {dateTo: *, dateFrom: *}, type: string}} Action with a payload
 * @memberOf RegionAnalysisActions
 * @alias changeDateRange
 * @function
 */
export const changeDateRange = (dateFrom, dateTo) => ({
  type: ActionTypes.REGION_ANALYSIS_DATE_CHANGED,
  payload: { dateFrom, dateTo }
});

/**
 * Creates an action containing which map type to change to.
 *
 * @param {string} mapType - newly selected map type
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf RegionAnalysisActions
 * @alias changeMapType
 * @function
 */
export const changeMapType = (mapType) => ({
  type: ActionTypes.REGION_ANALYSIS_MAP_TYPE_CHANGED,
  payload: mapType
});

/**
 * Creates an action containing which hex type to change to.
 *
 * @param {string} hexType - newly selected hex type
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf RegionAnalysisActions
 * @alias changeHexType
 * @function
 */
export const changeHexType = (hexType) => ({
  type: ActionTypes.REGION_ANALYSIS_HEX_TYPE_CHANGED,
  payload: hexType
});

/**
 * Creates an action containing which regions stops distribution to change to.
 *
 * @param {object} regionStopsDistribution - new regions stops distribution
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf RegionAnalysisActions
 * @alias updateRegionStopsDistribution
 * @function
 */
export const updateRegionStopsDistribution = (regionStopsDistribution) => ({
  type: ActionTypes.REGION_ANALYSIS_REGIONS_STOPS_DISTRIBUTION_CHANGED,
  payload: regionStopsDistribution
});

/**
 * Creates an action containing which optimized regions stops distribution to change to.
 *
 * @param {object} regionStopsDistribution - new regions stops distribution
 * @returns {{payload: *, type: string}} Action with a payload
 * @memberOf RegionAnalysisActions
 * @alias updateOptimizedRegionStopsDistribution
 * @function
 */
export const updateOptimizedRegionStopsDistribution = (regionStopsDistribution) => ({
  type: ActionTypes.REGION_ANALYSIS_OPTIMIZED_REGIONS_STOPS_DISTRIBUTION_CHANGED,
  payload: regionStopsDistribution
});

/**
 * Creates an action containing which region to highlight.
 *
 * @param {string} regionId - region identifier
 * @param {float} opacity - level of opacity to fill region polygon with
 * @param {boolean} flyTo - whether or not to zoom into the region
 * @returns {{payload: {regionId: *, opacity: *, flyTo: *}, type: string}} Action with a payload
 * @memberOf RegionAnalysisActions
 * @alias highlightRegion
 * @function
 */
export const highlightRegion = (regionId, opacity, flyTo) => ({
  type: ActionTypes.REGION_ANALYSIS_HIGHLIGHT_REGION,
  payload: { regionId, opacity, flyTo }
});

export const updateCoverageMenuTable = (coverageMenuTableData) => ({
  type: ActionTypes.REGION_ANALYSIS_COVERAGE_MENU_TABLE_UPDATE,
  payload: coverageMenuTableData
});

export const updatePointImpactList = (pointImpactList) => ({
  type: ActionTypes.REGION_ANALYSIS_IMPACT_LIST_UPDATE,
  payload: pointImpactList
});

export const disableMapPoint = (disabledMapPointId) => ({
  type: ActionTypes.REGION_ANALYSIS_DISABLE_MAP_POINT,
  payload: disabledMapPointId
});

export const enableMapPoint = (enabledMapPointId) => ({
  type: ActionTypes.REGION_ANALYSIS_ENABLE_MAP_POINT,
  payload: enabledMapPointId
});

export const resetDisabledMapPoints = () => ({ type: ActionTypes.REGION_ANALYSIS_RESET_DISABLED_MAP_POINTS });

export const enableMapPointsFromList = (enabledMapPointIds) => ({
  type: ActionTypes.REGION_ANALYSIS_ENABLE_MAP_POINTS_FROM_LIST,
  payload: enabledMapPointIds
});

export const toggleSelectMapPoint = (selectedMapPointId) => ({
  type: ActionTypes.REGION_ANALYSIS_TOGGLE_SELECT_MAP_POINT,
  payload: selectedMapPointId
});

export const unselectMapPoint = (selectedMapPointId) => ({
  type: ActionTypes.REGION_ANALYSIS_UNSELECT_MAP_POINT,
  payload: selectedMapPointId
});

export const resetSelectedMapPoints = () => ({ type: ActionTypes.REGION_ANALYSIS_RESET_SELECTED_MAP_POINTS });

export const unselectMapPointFromList = (selectedMapPointIds) => ({
  type: ActionTypes.REGION_ANALYSIS_UNSELECT_MAP_POINT_FROM_LIST,
  payload: selectedMapPointIds
});

export const updateActivePointsList = (activePointsList) => ({
  type: ActionTypes.REGION_ANALYSIS_UPDATE_ACTIVE_POINTS_LIST,
  payload: activePointsList
});

export const openPointDetailsMenu = (mapPoint) => ({
  type: ActionTypes.REGION_ANALYSIS_OPEN_POINT_DETAILS_MENU,
  payload: mapPoint
});

export const closePointDetailsMenu = () => ({
  type: ActionTypes.REGION_ANALYSIS_CLOSE_POINT_DETAILS_MENU,
  payload: null
});
