import PropTypes from 'prop-types';
import React from 'react';
import './MapPointCountIcon.scss';

export default function MapPointCountIcon(props) {
  const { count, imageName } = props;

  return (
    <div className="visible-coverage-point" key={imageName}>
      <div className="map-points-count">
        {count}
      </div>
      <img alt={`/mapData/${imageName}_logo.svg`} className="background-image" src={`/mapData/${imageName}_logo.svg`} key={imageName} />
    </div>
  );
}

MapPointCountIcon.propTypes = {
  count: PropTypes.number.isRequired,
  imageName: PropTypes.string.isRequired
};
