import { gql } from '@apollo/client';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';
import { appSyncClient } from '../../../../setup/appSyncClient';
import DynamoStringExtensionsUtil from '../../../../common/utils/api/dynamoStringExtensionsUtil';
import { DB_TYPES } from '../../../../common/constants/dbTypes';
import AuthUtil from '../../../../common/utils/authUtil';

const DeliveryAreasApi = {
  getDeliveryArea: getDeliveryArea,
  upsertDeliveryArea: upsertDeliveryArea,
  updateDeliveryAreaMapView: updateDeliveryAreaMapView,
  deleteDeliveryArea: deleteDeliveryArea,
  getAllDeliveryAreas: getAllDeliveryAreas
};

const UpsertDeliveryAreaWithMapping = gql`
  mutation UpsertMappingDeliveryArea(
    $pk: String
    $sk: String
    $id: String
    $name: String
    $hexagons: String
    $polygons: String
    $type: String
    $integrationId: String
    $mappingPk: String
    $mappingSk: String
    $mappingType: String
  ) {
    upsertDataWithMapping(
      pk: $pk
      sk: $sk
      input: {
        deliveryAreaInputData: { id: $id, name: $name, hexagonsFile: $hexagons, polygonFile: $polygons, type: $type, integrationId: $integrationId }
        mappingInputData: { pk: $mappingPk, sk: $mappingSk, id: $integrationId, milyId: $id, type: $mappingType }
      }
    )
  }
`;

const UpdateDeliveryAreaMapView = gql`
  mutation UpdateDeliveryAreaMapView($pk: String, $sk: String, $zoom: String, $lat: String, $lng: String) {
    upsertData(pk: $pk, sk: $sk, input: { deliveryAreaInputData: { lat: $lat, lng: $lng, zoom: $zoom } })
  }
`;

const DeleteEntityWithMapping = gql`
  mutation DeleteEntityWithMapping($pk: String, $sk: String, $mappingPk: String, $mappingSk: String) {
    deleteDataWithMapping(pk: $pk, sk: $sk, input: { mappingInputData: { pk: $mappingPk, sk: $mappingSk } })
  }
`;

const GetAllDeliveryAreas = gql`
  query GetAllDeliveryAreas($pk: String) {
    getDeliveryAreas(pk: $pk) {
      name
      id
      hexagonsFile
      polygonFile
      lat
      lng
      zoom
      integrationId
    }
  }
`;

async function getDeliveryArea(dataType) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    if (dataType === 'zip-code') {
      return getAllDeliveryAreas(dataType);
    }

    return Object.values(AuthUtil.getDeliveryArea());
  }

  return fetch('/testData/management/deliveryAreas.json').then((data) => data.json());
}

async function upsertDeliveryArea(id, data, dataType) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const pk = dataType === 'zip-code' ? DB_TYPES.ZIP_CODE : DB_TYPES.DELIVERY_AREA;

    return appSyncClient.mutate({
      mutation: UpsertDeliveryAreaWithMapping,
      variables: {
        pk: pk,
        sk: `${DynamoStringExtensionsUtil.getDeliveryAreaPrefix()}${id}`,
        name: data.name,
        id: id,
        hexagons: data.hexagonsFile,
        polygons: data.polygonFile,
        type: pk,
        integrationId: data.integrationId,
        mappingPk: DB_TYPES.INTEGRATION_MAPPING,
        mappingSk: `${DynamoStringExtensionsUtil.getMappingPrefix()}${DynamoStringExtensionsUtil.getDeliveryAreaPrefix()}${id}`,
        mappingType: DB_TYPES.INTEGRATION_MAPPING
      }
    });
  }

  return null;
}

async function updateDeliveryAreaMapView(id, data, dataType) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const pk = dataType === 'zip-code' ? DB_TYPES.ZIP_CODE : DB_TYPES.DELIVERY_AREA;
    return appSyncClient.mutate({
      mutation: UpdateDeliveryAreaMapView,
      variables: { pk: pk, sk: `${DynamoStringExtensionsUtil.getDeliveryAreaPrefix()}${id}`, zoom: data.zoom, lat: data.lat, lng: data.lng }
    });
  }

  return null;
}

function deleteDeliveryArea(id, dataType) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const pk = dataType === 'zip-code' ? DB_TYPES.ZIP_CODE : DB_TYPES.DELIVERY_AREA;
    return appSyncClient.mutate({
      mutation: DeleteEntityWithMapping,
      variables: {
        pk: pk,
        sk: `${DynamoStringExtensionsUtil.getDeliveryAreaPrefix()}${id}`,
        mappingPk: DB_TYPES.INTEGRATION_MAPPING,
        mappingSk: `${DynamoStringExtensionsUtil.getMappingPrefix()}${DynamoStringExtensionsUtil.getDeliveryAreaPrefix()}${id}`
      }
    });
  }

  return null;
}

async function getAllDeliveryAreas(dataType) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    const pk = dataType === 'zip-code' ? DB_TYPES.ZIP_CODE : DB_TYPES.DELIVERY_AREA;
    return ApiHelpers.createQuery(GetAllDeliveryAreas, { pk: pk });
  }

  return fetch('/testData/management/deliveryAreas.json').then((data) => data.json());
}

export default DeliveryAreasApi;
