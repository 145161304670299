import React from 'react';
import './CourierAnalysis.scss';
import moment from 'moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import memoize from 'memoize-one';
import SimpleBar from 'simplebar-react';
import { raygunClient } from '../../../setup/raygunClient';
import HeatMapChartWrapper from './components/HeatMapChartWrapper';
import DatePickerWrapper from './components/DatePickerWrapper';
import MapWrapper from './components/MapWrapper';
import DataCard from '../../../common/components/cards/DataCard';
import BarChartWrapper from './components/BarChartWrapper';
import * as InfoDialogActions from '../../../state/actions/infoDialogActions';
import * as PageActions from '../../../state/actions/pageActions';
import MixPanel from '../../../setup/mixPanel';
import colorsAndFonts from '../../../resources/colors-and-fonts.scss';
import QueryStringUtil from '../../../common/utils/queryStringUtil';
import StopUtil from '../utils/stopsUtil';
import * as CharActions from '../../../state/actions/chartActions';
import TeamSelect from '../../../common/components/selections/teamSelect/TeamSelect';
import EntitySelect from '../../../common/components/selections/entitySelect/EntitySelect';
import EntityUtil from '../utils/entityUtil';
import CourierAnalysisApi from './api/courierAnalysisApi';
import { ENTITY_TYPE } from '../../../common/constants/entityTypes';
import MixPanelUtil from '../../../common/utils/mixPanelUtil';

class CourierAnalysisClass extends React.Component {
  constructor() {
    super();
    this.state = {
      showPickup: true,
      showDelivery: true,
      courierId: null,
      isLoadingStops: false,
      isLoadingCouriers: false,
      teamId: '',
      entityType: ENTITY_TYPE.COURIERS
    };

    this.stopsData = null;
    this.couriersData = null;

    this.startDate = QueryStringUtil.getQueryStringValue('fromDate')
      ? moment(QueryStringUtil.getQueryStringValue('fromDate'), 'YYYY-MM-DDTHH').toDate()
      : moment().startOf('isoWeek').toDate();
    this.endDate = QueryStringUtil.getQueryStringValue('toDate')
      ? moment(QueryStringUtil.getQueryStringValue('toDate'), 'YYYY-MM-DDTHH').toDate()
      : moment().endOf('isoWeek').toDate();
  }

  componentDidMount() {
    MixPanel.track('Page Load - Courier Analysis');
    MixPanelUtil.setUnloadListener('Page Unload - Courier Analysis');
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isMapLoading !== this.props.isMapLoading
      || prevState.isLoadingStops !== this.state.isLoadingStops
      || prevState.isLoadingCouriers !== this.state.isLoadingCouriers
    ) {
      this.props.dispatchLoadingPage(this.props.isMapLoading || this.state.isLoadingStops || this.state.isLoadingCouriers);
    }
  }

  componentWillUnmount() {
    MixPanelUtil.removeUnloadListener();
  }

  stopCounters = memoize((stopsData, selectedHours, selectedDay, showPickup, showDelivery) => {
    return this.getStopsCounters(stopsData, selectedHours, selectedDay, showPickup, showDelivery);
  });

  getStopsCounters = (stopsData, selectedHours, selectedDay, showPickup, showDelivery) => {
    let pickupCounter = 0;
    let deliveriesCounter = 0;

    if (stopsData && stopsData.stops) {
      stopsData.stops.forEach((stop) => {
        let shouldPushStop = true;
        if (selectedDay?.size > 0) {
          shouldPushStop = selectedDay.has(stop.dayOfTheWeek);
        }

        if (selectedHours?.size > 0) {
          shouldPushStop = shouldPushStop && selectedHours.has(`${stop.hour}`);
        }

        const stopType = StopUtil.getStopType(stop.event);

        if (stopType === 'delivery') {
          shouldPushStop = shouldPushStop && showDelivery;
        } else {
          shouldPushStop = shouldPushStop && showPickup;
        }
        if (!shouldPushStop) {
          return;
        }

        if (stopType === 'delivery') {
          deliveriesCounter++;
        } else {
          pickupCounter += 1;
        }
      });
    }

    return {
      pickupCounter,
      deliveriesCounter
    };
  };

  fetchCouriers = async (teamId, entityType) => {
    this.setState({ teamId: teamId, isLoadingCouriers: true, entityType: entityType });

    // fetch couriers
    EntityUtil.getEntityData(teamId, entityType, true)
      .then((couriersData) => {
        this.couriersData = couriersData;
        this.stopsData = null;
        this.setState({ isLoadingCouriers: false });
      })
      .catch((error) => {
        raygunClient.send(error, 'Failed to load couriers for Courier Analysis');
        toast.error(this.props.t('Failed to load couriers'));
        this.setState({ isLoadingCouriers: false });
      });

    if (QueryStringUtil.getQueryStringValue('showPickup')) {
      this.onShowPickupChanged(QueryStringUtil.getQueryStringValue('showPickup') === 'true');
    }
    if (QueryStringUtil.getQueryStringValue('showDelivery')) {
      this.onShowDeliveryChanged(QueryStringUtil.getQueryStringValue('showDelivery') === 'true');
    }
  };

  onShowPickupChanged = (value) => {
    this.setState({ showPickup: value });
    MixPanel.track(`Courier Analysis - Pickups turned ${value ? 'ON' : 'OFF'}`);
    QueryStringUtil.setQueryStringValue('showPickup', value);
  };

  onShowDeliveryChanged = (value) => {
    this.setState({ showDelivery: value });
    MixPanel.track(`Courier Analysis - Deliveries turned ${value ? 'ON' : 'OFF'}`);
    QueryStringUtil.setQueryStringValue('showDelivery', value);
  };

  onCourierChange = (opt) => {
    const courierId = opt && opt.value;
    this.setState({ courierId });
    if (courierId) {
      MixPanel.track('Courier Analysis - Courier changed');
      this.fetchCourierStopsDataAsync(courierId);
    }

    this.props.dispatchResetChartData();
  };

  onDateChanged = (startDate, endDate) => {
    this.startDate = startDate;
    this.endDate = endDate;
    MixPanel.track('Courier Analysis - Date range changed');
    QueryStringUtil.setQueryStringValue('fromDate', moment(startDate).startOf('day').format('YYYY-MM-DDTHH'));
    QueryStringUtil.setQueryStringValue('toDate', moment(endDate).endOf('day').format('YYYY-MM-DDTHH'));
    QueryStringUtil.setQueryStringValue('date', moment(startDate).startOf('day').format('YYYY-MM-DDTHH'));
    this.props.dispatchResetChartData();
    this.fetchCourierStopsDataAsync(this.state.courierId);
  };

  fetchCourierStopsDataAsync = async (courierId) => {
    if (!courierId) {
      return;
    }

    this.setState({ isLoadingStops: true });

    try {
      this.stopsData = await CourierAnalysisApi.getStops(courierId, this.startDate, this.endDate);
    } catch (error) {
      raygunClient.send(error, 'Error loading courier for Courier Analysis');
      toast.error(this.props.t('Error loading courier'));
    } finally {
      this.setState({ isLoadingStops: false });
    }
  };

  render() {
    const stopCounters = this.stopCounters(
      this.stopsData,
      this.props.selectedHours,
      this.props.selectedDay,
      this.state.showPickup,
      this.state.showDelivery
    );

    return (
      <div className="home">
        <SimpleBar style={{ maxHeight: '100%' }} className="home-data-section">
          <div className="filters-section">
            <div className="team-selection-wrapper">
              <div className="label">{this.props.t('Team')}</div>
              <TeamSelect onTeamChange={this.fetchCouriers} eventTrackerNamePrefix="Courier Analysis" teamTypeFilter={[ENTITY_TYPE.COURIERS]} />
            </div>
            <div className="courier-selection-wrapper">
              <div className="label">{this.props.t('Courier')}</div>
              <EntitySelect entitiesData={this.couriersData} onChange={this.onCourierChange} entityType={ENTITY_TYPE.COURIERS} />
            </div>
            <div className="date-filters">
              <div className="label">{this.props.t('Date range')}</div>
              <DatePickerWrapper onDateChange={this.onDateChanged} selectedDays={[this.startDate, this.endDate]} />
            </div>
          </div>

          {this.state.courierId ? (
            <div className="data-section">
              {!this.state.isLoadingStops && this.stopsData ? (
                <div className="data-cards">
                  <DataCard
                    title={this.props.t('Drop-offs')}
                    value={this.stopsData.statistics ? this.stopsData.statistics.totalNumberOfDeliveries : 0}
                    filteredValue={stopCounters.deliveriesCounter}
                    toggleData={this.onShowDeliveryChanged}
                    initialChecked={this.state.showDelivery}
                    borderColor={colorsAndFonts.deliveries_color}
                    toggleClass="delivery"
                  />
                  <DataCard
                    title={this.props.t('Pickups')}
                    value={this.stopsData.statistics ? this.stopsData.statistics.totalNumberOfPickups : 0}
                    filteredValue={stopCounters.pickupCounter}
                    toggleData={this.onShowPickupChanged}
                    initialChecked={this.state.showPickup}
                    borderColor={colorsAndFonts.pickups_color}
                    toggleClass="pickups"
                  />
                  <DataCard
                    title={this.props.t('Total stops')}
                    value={this.stopsData.statistics ? this.stopsData.statistics.totalNumberOfStops : 0}
                    filteredValue={stopCounters.deliveriesCounter + stopCounters.pickupCounter}
                    borderColor={colorsAndFonts.tile_background_color}
                  />
                </div>
              ) : (
                <div className="data-cards-loader">
                  <CircularProgress />
                </div>
              )}
              {!this.state.isLoadingStops ? (
                <HeatMapChartWrapper data={this.stopsData} showDelivery={this.state.showDelivery} showPickup={this.state.showPickup} containerId="courier-analysis-heat-map" />
              ) : (
                <div className="chart-loader">
                  <CircularProgress />
                </div>
              )}
              {!this.state.isLoadingStops && this.stopsData ? (
                <BarChartWrapper data={this.stopsData} showDelivery={this.state.showDelivery} showPickup={this.state.showPickup} containerId="courier-analysis-bar-chart" />
              ) : (
                <div className="chart-loader bar-chart-loader">
                  <CircularProgress />
                </div>
              )}
            </div>
          ) : (
            <div className="no-data">
              <div className="no-data-text">{this.props.t('No results found')}</div>
              <div className="no-data-call-to-action">{this.props.t('Please select a courier')}</div>
            </div>
          )}
        </SimpleBar>
        <div className="map-section">
          {this.state.courierId ? (
            <MapWrapper
              hasStops={!this.state.isLoadingStops}
              stopsData={this.stopsData}
              showDelivery={this.state.showDelivery}
              showPickup={this.state.showPickup}
              teamId={this.state.teamId}
              entityType={this.state.entityType}
            />
          ) : (
            <i className="logo icon icon-mily-logo" />
          )}
        </div>

        <Popover
          id="simple-popover"
          open={this.props.isInfoDialogOpen}
          anchorEl={this.props.infoDialogAnchorEl}
          onClose={this.props.dispatchCloseInfoDialog}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          PaperProps={{ square: true }}
        >
          {React.createElement(this.props.component)}
        </Popover>
      </div>
    );
  }
}

/**
 * @param {object} store - redux store
 * @returns {object} redux store
 */
function mapStateToProps(store) {
  return {
    ...store.chartState,
    ...store.infoDialogState,
    ...store.mapState,
    ...store.authState
  };
}

/**
 * @param {Function} dispatch - redux dispatch
 * @returns {object} redux actions
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchCloseInfoDialog: InfoDialogActions.closeInfoDialog,
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchResetChartData: CharActions.resetData
    },
    dispatch
  );
}

const CourierAnalysis = connect(mapStateToProps, mapDispatchToProps)(CourierAnalysisClass);

export default withTranslation('translations')(CourierAnalysis);

CourierAnalysisClass.propTypes = {
  /**
   * Is map loading
   */
  isMapLoading: PropTypes.bool,
  /**
   * Set is map loading
   */
  dispatchLoadingPage: PropTypes.func.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * Change selected dates
   */
  dispatchResetChartData: PropTypes.func.isRequired,
  /**
   * Selected hours
   */
  selectedHours: PropTypes.object,
  /**
   * Selected days
   */
  selectedDay: PropTypes.object,
  /**
   * Show info dialog
   */
  isInfoDialogOpen: PropTypes.bool,
  /**
   * Parent element for info dialog
   */
  infoDialogAnchorEl: PropTypes.element,
  /**
   * Close info dialog
   */
  dispatchCloseInfoDialog: PropTypes.func.isRequired,
  /**
   * Info dialog component
   */
  component: PropTypes.func.isRequired
};

CourierAnalysisClass.defaultProps = {
  isMapLoading: true,
  selectedHours: null,
  selectedDay: null,
  isInfoDialogOpen: false,
  infoDialogAnchorEl: null
};
