import moment from 'moment';
import * as ActionTypes from '../constants/actionTypes';
import QueryStringUtil from '../../common/utils/queryStringUtil';
import { IMPACT_TABLE_TYPES } from '../../features/analysis/deliveryAreaAnalysis/constants/menuConstants';

const initialState = {
  regionsDateFrom: QueryStringUtil.getQueryStringValue('fromDate')
    ? moment(QueryStringUtil.getQueryStringValue('fromDate'), 'YYYY-MM-DDTHH')
      .toDate()
    : moment()
      .subtract(1, 'day')
      .toDate(),
  regionsDateTo: QueryStringUtil.getQueryStringValue('toDate')
    ? moment(QueryStringUtil.getQueryStringValue('toDate'), 'YYYY-MM-DDTHH')
      .toDate()
    : moment()
      .subtract(1, 'day')
      .toDate(),
  mapViewType: 'heatmap',
  hexType: null,
  coverageMenuTableData: null,
  pointImpactList: {
    [IMPACT_TABLE_TYPES.MOST]: [],
    [IMPACT_TABLE_TYPES.LEAST]: []
  },
  regions: [],
  regionStopsDistribution: [],
  optimizedRegionStopsDistribution: [],
  highlightedRegion: null,
  disabledPoints: new Set(),
  selectedMapPoints: new Set(),
  activePointsList: null,
  mapPointDetailsMenuData: null
};

/**
 * Reducer - RegionAnalysis
 *
 * @param {object} state - initial or current state
 * @param {object} action - action object from which we extract action.type
 * @returns {object} changed state
 * @memberOf Reducers
 * @function
 */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  const disabledPoints = new Set(state.disabledPoints);
  const selectedMapPoints = new Set(state.selectedMapPoints);
  switch (action.type) {
    case ActionTypes.REGION_ANALYSIS_DATE_CHANGED:
      QueryStringUtil.setQueryStringValue('fromDate', moment(action.payload.dateFrom)
        .startOf('day')
        .format('YYYY-MM-DDTHH'));
      QueryStringUtil.setQueryStringValue('toDate', moment(action.payload.dateTo)
        .endOf('day')
        .format('YYYY-MM-DDTHH'));

      return {
        ...state,
        regionsDateFrom: action.payload.dateFrom,
        regionsDateTo: action.payload.dateTo
      };
    case ActionTypes.REGION_ANALYSIS_MAP_TYPE_CHANGED:
      return {
        ...state,
        mapViewType: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_HEX_TYPE_CHANGED:
      return {
        ...state,
        hexType: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_REGIONS_STOPS_DISTRIBUTION_CHANGED:
      return {
        ...state,
        regionStopsDistribution: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_OPTIMIZED_REGIONS_STOPS_DISTRIBUTION_CHANGED:
      return {
        ...state,
        optimizedRegionStopsDistribution: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_HIGHLIGHT_REGION:
      return {
        ...state,
        highlightedRegion: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_COVERAGE_MENU_TABLE_UPDATE:
      return {
        ...state,
        coverageMenuTableData: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_IMPACT_LIST_UPDATE:
      return {
        ...state,
        pointImpactList: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_DISABLE_MAP_POINT:
      disabledPoints.add(action.payload);
      return {
        ...state,
        disabledPoints: disabledPoints
      };
    case ActionTypes.REGION_ANALYSIS_ENABLE_MAP_POINT:
      disabledPoints.delete(action.payload);
      return {
        ...state,
        disabledPoints: disabledPoints
      };
    case ActionTypes.REGION_ANALYSIS_RESET_DISABLED_MAP_POINTS:
      return {
        ...state,
        disabledPoints: new Set()
      };
    case ActionTypes.REGION_ANALYSIS_ENABLE_MAP_POINTS_FROM_LIST:
      action.payload.forEach((point) => {
        disabledPoints.delete(point);
      });
      return {
        ...state,
        disabledPoints: disabledPoints
      };
    case ActionTypes.REGION_ANALYSIS_UPDATE_ACTIVE_POINTS_LIST:
      return {
        ...state,
        activePointsList: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_TOGGLE_SELECT_MAP_POINT:
      if (selectedMapPoints.has(action.payload)) {
        selectedMapPoints.delete(action.payload);
      } else {
        selectedMapPoints.add(action.payload);
      }
      return {
        ...state,
        selectedMapPoints: selectedMapPoints
      };
    case ActionTypes.REGION_ANALYSIS_UNSELECT_MAP_POINT:
      selectedMapPoints.delete(action.payload);
      return {
        ...state,
        selectedMapPoints: selectedMapPoints
      };
    case ActionTypes.REGION_ANALYSIS_RESET_SELECTED_MAP_POINTS:
      return {
        ...state,
        selectedMapPoints: new Set()
      };
    case ActionTypes.REGION_ANALYSIS_UNSELECT_MAP_POINT_FROM_LIST:
      action.payload.forEach((point) => {
        selectedMapPoints.delete(point);
      });
      return {
        ...state,
        selectedMapPoints: selectedMapPoints
      };
    case ActionTypes.REGION_ANALYSIS_OPEN_POINT_DETAILS_MENU:
      return {
        ...state,
        mapPointDetailsMenuData: action.payload
      };
    case ActionTypes.REGION_ANALYSIS_CLOSE_POINT_DETAILS_MENU:
      return {
        ...state,
        mapPointDetailsMenuData: null
      };
    default:
      return state;
  }
};

export default reducer;
