import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import { raygunClient } from '../../../../setup/raygunClient';
import * as PageActions from '../../../../state/actions/pageActions';
import * as CoordinatesActions from '../../../../state/actions/coordinatesActions';
import HubMap from '../components/HubMap';
import './AddHubPage.scss';
import StepInfoHeader from '../../../planning/planManagement/components/StepInfoHeader';
import HubApi from '../api/hubApi';
import { INITIAL_VIEW_STATE } from '../../../../common/constants/mapConstants';
import AppDialogActionsWrapper from '../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import HubForm from '../components/HubForm';

class AddHubPageClass extends React.Component {
  constructor(props) {
    super(props);

    this.markerCoordinates = { lat: this.props.location.state.lat, lng: this.props.location.state.lng };

    if (!this.props.location.state.create) {
      this.zoom = 15.0;
    } else {
      this.zoom = INITIAL_VIEW_STATE.zoom;
      this.props.location.state.name = `${props.t('HUB')} - ${moment().format('YYYY-MM-DD')}`;
    }

    this.state = { formData: { name: props?.history?.location?.state?.name } };
  }

  componentDidMount() {
    this.props.dispatchLoadingPage(false);
    this.props.dispatchCoordinates(this.markerCoordinates);
  }

  saveHub = (integrationId, hubName) => {
    const id = this.props.location.state.id;
    if (id) {
      const data = {
        lat: `${this.markerCoordinates.lat}`,
        lng: `${this.markerCoordinates.lng}`,
        name: hubName,
        integrationId: integrationId
      };
      HubApi.upsertHub(id, data)
        .then(() => {
          this.props.history.push('/hub-management');
        })
        .catch((error) => {
          raygunClient.send(error, 'Error saving hub', { id: id });
          toast.error(this.props.t('Oops something went wrong'));
        });
    }
  };

  getDefaultName = () => {
    return `${this.props.t('Hub')} ${moment().format('YYYY-MM-DD')}`;
  };

  openHubForm = () => {
    AppDialogActionsWrapper.openAppDialog({
      dialogComponent: HubForm,
      dialogComponentProps: {
        data: { name: this.state.formData.name || this.getDefaultName(), integrationId: this.props?.history?.location?.state?.integrationId },
        title: this.props.t('Add new hub'),
        submitCallback: (data) => {
          this.props.dispatchLoadingPage(true);
          this.saveHub(data.integrationId, data.name);
        }
      }
    });
  };

  savePinLocation = (marker) => {
    this.markerCoordinates = marker.getLngLat();
    this.props.dispatchCoordinates(this.markerCoordinates);
  };

  render() {
    return (
      <div className="hub-add">
        <StepInfoHeader
          message={this.props.t('hubPlacementMessage')}
          nextButtonText={this.props.t('Save')}
          options={{
            nextButtonDisabled: false,
            returnButtonHidden: true,
            cancelButtonText: this.props.t('Cancel'),
            cancelButtonHidden: false,
            cancelToPage: '/hub-management'
          }}
          onNextClick={this.openHubForm}
          hubUsing
        />
        <HubMap
          savePin={this.savePinLocation}
          hubData={{ lng: this.markerCoordinates.lng, lat: this.markerCoordinates.lat }}
          zoom={this.zoom}
          hubAdd
          showMap
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchLoadingPage: PageActions.loadingPage,
      dispatchCoordinates: CoordinatesActions.coordinatesChange
    },
    dispatch
  );
}

AddHubPageClass.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  dispatchLoadingPage: PropTypes.func.isRequired,
  dispatchCoordinates: PropTypes.func.isRequired
};

AddHubPageClass.defaultProps = { location: null };

export default withTranslation('translations')(connect(null, mapDispatchToProps)(AddHubPageClass));
