import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MixPanel from '../../../../setup/mixPanel';
import CustomSelect from '../CustomSelect';
import './EntitySelect.scss';
import QueryStringUtil from '../../../utils/queryStringUtil';
import { ENTITY_TYPE_QUERY_STRING } from '../../../constants/entityTypes';
import { INPUT_SIZE } from '../../../constants/uiConstants';

/**
 * Select for entities
 *
 * @param {object} props - component props
 * @returns {JSX.Element} Select element
 * @component
 * @alias EntitySelect
 * @category Common
 */
export default function EntitySelect(props) {
  const { t } = useTranslation();
  const { entitiesData, onChange, eventTrackerNamePrefix } = props;

  const [entityId, setEntityId] = useState(QueryStringUtil.getQueryStringValue(ENTITY_TYPE_QUERY_STRING[props.entityType]));
  const [entityOptions, setEntityOptions] = useState([]);

  /**
   * Create select option
   *
   * @param {string} id - entityId to create option for
   * @returns {object} - select option
   * @function
   */
  const createEntityOption = useCallback(
    (id) => {
      if (id) {
        const entity = entitiesData.find((c) => c.id === id);
        if (entity) {
          if (!entity.firstName && !entity.lastName) {
            return {
              value: entity.id,
              label: entity.name
            };
          }

          return {
            value: entity.id,
            label: `${entity.firstName} ${entity.lastName}`
          };
        }
      }

      return null;
    },
    [entitiesData]
  );

  /**
   * Handle entity change in select
   *
   * @param {object} opt - selected option
   * @function
   */
  const onEntityChange = useCallback(
    (opt) => {
      const updateQueryString = entitiesData && entitiesData.length > 0;
      if (eventTrackerNamePrefix && opt && opt.value) {
        MixPanel.track(`${eventTrackerNamePrefix} - Entity changed`, { entityId: opt.value });
      }
      setEntityId(opt && opt.value);
      if (updateQueryString) {
        QueryStringUtil.setQueryStringValue(ENTITY_TYPE_QUERY_STRING[props.entityType], opt && opt.value);
      }

      if (onChange) {
        onChange(opt);
      }
    },
    [entitiesData, eventTrackerNamePrefix, onChange, props.entityType]
  );

  useEffect(() => {
    if (entitiesData) {
      const options = entitiesData.map((entity) => {
        if (entity?.id) {
          return createEntityOption(entity.id);
        }

        return {};
      });

      setEntityOptions(options);
    }
  }, [entitiesData, createEntityOption]);

  useEffect(() => {
    /**
     * Check if selected entity belongs to selected center
     *
     * @returns {boolean} - true if entity belongs to current center
     * @function
     */
    const isEntityInSelectedCenter = () => {
      return entitiesData && entitiesData.some((entity) => entity.id === QueryStringUtil.getQueryStringValue(ENTITY_TYPE_QUERY_STRING[props.entityType]));
    };

    const id = QueryStringUtil.getQueryStringValue(ENTITY_TYPE_QUERY_STRING[props.entityType]);

    let option;
    if (QueryStringUtil.getQueryStringValue(ENTITY_TYPE_QUERY_STRING[props.entityType]) && isEntityInSelectedCenter()) {
      option = createEntityOption(id);
    }

    onEntityChange(option);
  }, [entitiesData, onEntityChange, createEntityOption, props.entityType]);

  return (
    <div className="entity-select">
      {entitiesData && (
        <CustomSelect
          className="select"
          options={entityOptions}
          value={entityOptions.filter((o) => o.value === entityId)}
          onChange={onEntityChange}
          placeholder={t(`Enter ${props.entityType} name`)}
          size={props.size}
        />
      )}
    </div>
  );
}

EntitySelect.propTypes = {
  entitiesData: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  eventTrackerNamePrefix: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(INPUT_SIZE))
};

EntitySelect.defaultProps = {
  entitiesData: null,
  onChange: null,
  eventTrackerNamePrefix: null,
  size: INPUT_SIZE.MEDIUM
};
