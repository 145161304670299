import { Auth } from 'aws-amplify';
import Sockette from 'sockette';
import { appSyncClient } from '../../../setup/appSyncClient';
import * as PageActions from '../../../state/actions/pageActions';
import store from '../../../state/store';
import BackendResourceConfigUtil from './backendResourceConfigUtil';

/**
 * @namespace ApiHelpers
 * @category Common
 */

const ApiHelpers = {
  getHeader: getHeader,
  createQuery: createQuery,
  createQueryFieldList: createQueryFieldList,
  removeTypenameFromObject: removeTypenameFromObject,
  connectToWebSocket: connectToWebSocket,
  closeWebSocketConnection: closeWebSocketConnection,
};

/**
 * Generates header for rest api call with a proper auth token
 *
 * @async
 * @returns {Promise<{Authorization: string, "Content-Type": string}>} ready to use header for API requests
 * @function
 * @memberOf ApiHelpers
 */
async function getHeader() {
  return {
    'Content-Type': 'application/json',
    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
  };
}

function createQuery(query, variables) {
  return appSyncClient.query({
    query: query,
    variables: variables,
    fetchPolicy: 'no-cache'
  });
}

function createQueryFieldList(fields) {
  let qryFieldList = '';

  if (fields && fields.length) {
    fields.forEach((fld) => {
      qryFieldList += `${fld}\n`;
    });
  }

  return qryFieldList;
}

function omitTypename(key, value) {
  return key === '__typename' ? undefined : value;
}

function removeTypenameFromObject(data) {
  return JSON.parse(JSON.stringify(data), omitTypename);
}

/**
 * Close websocket connection to server
 *
 * @param {object} ws - websocket instance
 * @param {Function} additionalCallback - additional function called before closing connection
 * @memberOf PlanningPageUtil
 * @function
 */
function closeWebSocketConnection(ws, additionalCallback) {
  if (additionalCallback) {
    additionalCallback();
  }

  if (ws) {
    ws.close();
  }
}

/**
 * Connect to websocket
 *
 * @param {string} fileName - uploaded file name
 * @param {Function} onMessage - function called when message is received from websocket
 * @param {object} options - object containing additional options
 * @returns {object} - websocket instance
 * @memberOf PlanningPageUtil
 * @function
 */
function connectToWebSocket(fileName, onMessage, options = {}) {
  if (process.env.REACT_APP_DATA_SOURCE === 'api' || process.env.REACT_APP_SHOWCASE_RECOMMENDER) {
    return Auth.currentSession()
      .then((res) => {
        const token = res.getIdToken()
          .getJwtToken();
        return new Sockette(`${BackendResourceConfigUtil.getWebSocketUrl()}?token=${token}&fileId=${fileName}`, {
          timeout: 5e3,
          maxAttempts: 1,
          onopen: (e) => {
            console.log('Connected!', e);
            if (options.onopen) options.onopen();
          },
          onmessage: onMessage,
          onreconnect: (e) => console.log('Reconnecting...', e),
          onmaximum: (e) => console.log('Stop Attempting!', e),
          onclose: (e) => console.log('Closed!', e),
          onerror: (e) => {
            store.dispatch(PageActions.loadingPage(false));
            console.log('Error:', e);
          }
        });
      });
  }

  return onMessage();
}

export default ApiHelpers;
