import React, { useRef } from 'react';
import './Toggle.scss';
import idUtil from '../../../utils/idUtil';

export default function Toggle(props) {
  const [toggleHovered, setToggleHovered] = React.useState(false);

  const toggleId = useRef(idUtil.generateId());

  function handleMouseEnter() {
    setToggleHovered(true);
  }

  function handelMouseLeave() {
    setToggleHovered(false);
  }

  function getHoveredCheckboxClass() {
    return toggleHovered ? 'toggle hovered' : 'toggle';
  }

  return (
    <label
      className="toggle-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handelMouseLeave}
      htmlFor={toggleId.current}
    >
      <input
        type="checkbox"
        className={getHoveredCheckboxClass()}
        id={toggleId.current}
        {...props}
      />
      <span className="slider" />
    </label>
  );
}
