import { Layer, Source } from 'react-map-gl';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import * as _ from 'lodash';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { COUNT_DAYS_FOR_LAYERS, DEFAULT_ADJUSTMENT_FACTORS, HEATMAP_COLOR, PER_DAY_ADJUSTMENT_FACTORS } from '../../constants/userMapLayers';
import { PlotUtil } from '../../util/plotUtil';

export default function HexagonsSourceWrapper(props) {
  const { source, visibleMapSource } = props;
  const regionsDateFrom = useSelector((state) => state.regionAnalysisState.regionsDateFrom);
  const regionsDateTo = useSelector((state) => state.regionAnalysisState.regionsDateTo);
  const hexagonsOpacityLimit = useMemo(getHexagonOpacityLimits, [regionsDateFrom, regionsDateTo, props.totalLayerCount, source.layerType]);
  const mapViewType = useSelector((state) => state.regionAnalysisState.mapViewType);

  function getMapLayerConstantFromConfig(config, layer) {
    return _.has(config, layer) ? config[layer] : config.default;
  }

  function getHexagonOpacityLimits() {
    const daysInBetweenDates = Math.max(moment(regionsDateTo).diff(moment(regionsDateFrom), 'days'), 1);

    let result = 0;

    const totalsPerDay = getMapLayerConstantFromConfig(COUNT_DAYS_FOR_LAYERS, source.layerType)
      ? (props.totalLayerCount / daysInBetweenDates)
      : props.totalLayerCount;

    result = Math.floor(
      totalsPerDay
      * (getMapLayerConstantFromConfig(DEFAULT_ADJUSTMENT_FACTORS, source.layerType)
        + daysInBetweenDates * getMapLayerConstantFromConfig(PER_DAY_ADJUSTMENT_FACTORS, source.layerType))
    );

    return result;
  }

  const heatmapVisibility = PlotUtil.getHeatmapVisibilityString(mapViewType, source, visibleMapSource);
  const sourceVisibility = PlotUtil.getCommonVisibilityString(source, visibleMapSource);

  return (
    <Source type="geojson" key={source.id} id={source.id} data={source.data}>
      {/* common layers */}
      <Layer
        type="symbol"
        id={`${props.centerPositionData.id}-${source.layerType}-hexagons-count`}
        key={`${props.centerPositionData.id}-${source.layerType}-hexagons-count`}
        source={source.id}
        maxzoom={17}
        minzoom={14}
        layout={{
          'text-field': ['case', ['>', ['get', 'data'], 0], ['get', 'data'], ''],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': ['interpolate', ['linear'], ['zoom'], 14, 10, 17, 20],
          visibility: sourceVisibility
        }}
        paint={{ 'text-color': '#794118' }}
      />
      <Layer
        type="line"
        id={`${props.centerPositionData.id}-${source.layerType}-hexagons-layer-border`}
        key={`${props.centerPositionData.id}-${source.layerType}-hexagons-layer-border`}
        source={source.id}
        minzoom={PlotUtil.getMinZoomLevel(props.centerPositionData)}
        paint={{
          'line-color': getMapLayerConstantFromConfig(HEATMAP_COLOR, [source.layerType]),
          'line-opacity': 0.1,
          'line-width': 2
        }}
        layout={{ visibility: sourceVisibility }}
        beforeId={`${props.centerPositionData.id}-${source.layerType}-hexagons-count`}
      />
      {/* heatmap layer */}
      <Layer
        type="fill"
        id={`${props.centerPositionData.id}-${source.layerType}-hexagons-layer`}
        key={`${props.centerPositionData.id}-${source.layerType}-hexagons-layer`}
        source={source.id}
        paint={{
          'fill-color': getMapLayerConstantFromConfig(HEATMAP_COLOR, [source.layerType]),
          'fill-opacity': ['interpolate', ['linear'], ['get', 'data'], 0, 0.1, hexagonsOpacityLimit + 1, 0.8]
        }}
        layout={{ visibility: heatmapVisibility }}
        beforeId={`${props.centerPositionData.id}-${source.layerType}-hexagons-layer-border`}
      />
    </Source>

  );
}

HexagonsSourceWrapper.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.object,
    layerType: PropTypes.string
  }),
  visibleMapSource: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.object,
    layerType: PropTypes.string
  }),
  totalLayerCount: PropTypes.number,
  centerPositionData: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
    id: PropTypes.string
  })
};

HexagonsSourceWrapper.defaultProps = {
  source: null,
  visibleMapSource: null,
  totalLayerCount: null,
  centerPositionData: null
};
