import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { raygunClient } from '../../setup/raygunClient';
import Button from '../../common/components/buttons/button/Button';
import { BUTTON_TYPES } from '../../common/components/buttons/button/constants/buttonTypes';
import './ErrorBoundary.scss';
import store from '../../state/store';
import * as PageActions from '../../state/actions/pageActions';

/**
 * Error boundary component that catches errors in child components and displays a fallback UI.
 * In order to use this component, wrap the component that you want to catch errors from with this component.
 * @see https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * To catch async errors, use the useThrowAsyncError hook.
 * @see src/appLayout/hooks/useThrowAsyncError.js
 * @see https://www.developerway.com/posts/how-to-handle-errors-in-react
 */
class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error tracking service (e.g., Sentry, LogRocket)
    raygunClient.send(error, 'Error caught by error boundary', errorInfo);
    store.dispatch(PageActions.loadingPage(false));
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <span className="text">{this.props.t('Something went wrong. Please try again later')}</span>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            text={this.props.t('Ok, I understand')}
            onClick={() => {
              this.setState({ hasError: false });
            }}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default withTranslation('translations')(ErrorBoundary);
