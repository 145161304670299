import React from 'react';
import PropTypes from 'prop-types';
import './MapMarkerPopupMenu.scss';
import { useTranslation } from 'react-i18next';

export default function MapMarkerPopupMenu(props) {
  const { t } = useTranslation();

  function getMenuRowButtons() {
    return props.rowButtonsData.map((buttonData) => {
      return (
        <div className="menu-row-button" onClick={buttonData.onClickAction} key={buttonData.title}>
          {t(buttonData.title)}
        </div>
      );
    });
  }

  return (
    <div className="map-marker-popup-menu">
      {getMenuRowButtons()}
    </div>
  );
}

MapMarkerPopupMenu.propTypes = {
  rowButtonsData: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    onClickAction: PropTypes.func
  })).isRequired
};
