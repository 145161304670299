import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from '../../../../../common/components/form/Form';
import { FormInput } from '../../../../../common/components/form/components/FormInput';
import Button from '../../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../../common/components/form/utils/defaultFormDialogCss.scss';
import { BUTTON_TYPES } from '../../../../../common/components/buttons/button/constants/buttonTypes';

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        firstName: props?.data?.firstName || '',
        lastName: props?.data?.lastName || '',
        email: props?.data?.email || '',
        role: props?.data?.role || this.getDefaultRoleValue()
      },
      serverErrors: null
    };
    this.formRef = null;
  }

  getRoleOptions = () => this.props.roles.map((role) => ({ value: role.id, label: role.name }));

  getDefaultRoleValue = () => {
    if (this.props.roles && this.props.roles.length > 0) return { value: this.props.roles[0].id, label: this.props.roles[0].name };
    return null;
  };

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    if (this.props.submitCallback) {
      this.props.submitCallback({
        ...this.state.formData,
        role: { id: this.state.formData.role.value, name: this.state.formData.role.label }
      });
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  render() {
    return (
      <div className="form-dialog-component">
        <div className="title">{this.props.title}</div>
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            this.setState({ formData: newFormData });
          }}
          serverErrors={this.state.serverErrors}
        >
          <FormInput inputType="input" label={this.props.t('First name')} name="firstName" validation={{ required: true }} placeholder={this.props.t('Enter first name')} />
          <FormInput inputType="input" label={this.props.t('Last name')} name="lastName" validation={{ required: true }} placeholder={this.props.t('Enter last name')} />
          <FormInput
            inputType="input"
            label={this.props.t('Email')}
            name="email"
            validation={{ required: true, isValidEmailAddress: true }}
            disabled={this.props.data && this.props.data.email}
            placeholder={this.props.t('Enter email address')}
          />
          {this.props.roles && this.props.roles.length > 0 && (
            <FormInput
              inputType="select"
              label={this.props.t('Role')}
              options={this.getRoleOptions()}
              menuPortalTarget={document.querySelector('.MuiDialog-root')}
              menuPosition="fixed"
              placeholder={this.props.t('Select role')}
              name="role"
              defaultValue={this.props?.data?.role || this.getDefaultRoleValue()}
            />
          )}
        </Form>

        <div className="buttons">
          <Button onClick={this.cancelCallback} type={BUTTON_TYPES.SECONDARY} text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} type={BUTTON_TYPES.PRIMARY} text={this.props.t('Save')} />
        </div>
      </div>
    );
  }
}

UserForm.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.object,
    email: PropTypes.string
  }),
  roles: PropTypes.arrayOf(PropTypes.object),
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

UserForm.defaultProps = {
  data: null,
  submitCallback: null,
  cancelCallback: null,
  roles: null
};

export default withTranslation('translations')(UserForm);
