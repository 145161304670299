import { gql } from '@apollo/client';
import ApiHelpers from '../../../../common/utils/api/apiHelpers';

const StreetMappingApi = { getStreetsFromHexagon: getStreetsFromHexagon };

const GetStreetsFromHexagon = gql`
  query GetStreetsFromHexagon($hexId: String) {
    getStreetsFromHexagon(pk: $hexId) {
      hexId 
      streetName
      source
      createdAt
      createdAtUtc
    }
  }
`;

async function getStreetsFromHexagon(hexId) {
  if (!hexId) return {};

  if (process.env.REACT_APP_DATA_SOURCE === 'api') {
    return ApiHelpers.createQuery(GetStreetsFromHexagon, { hexId: hexId }).then((res) => {
      return res.data.getStreetsFromHexagon.map((street) => {
        const streetObject = {};
        Object.keys(street).forEach((field) => {
          if (street[field]) {
            if (field === 'createdAt') {
              streetObject.createdAtUtc = street[field];
            } else {
              streetObject[field] = street[field];
            }
          }
        });
        return streetObject;
      });
    });
  }

  return null;
}

export default StreetMappingApi;
