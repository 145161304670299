import * as React from 'react';
import mapboxgl from 'mapbox-gl';

import './MapWrapper.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

import 'react-toggle/style.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import haversine from 'haversine-distance';
import { MAP_STYLE } from '../../../../common/constants/mapConstants';
import * as MapActions from '../../../../state/actions/mapActions';
import PackageLockerUtilClass from '../../../../common/utils/packageLockersUtil';
import stopsUtil from '../../utils/stopsUtil';
import StopsUtilClass from '../utils/stopsClass';
import FiltersUtil from '../utils/filtersUtil';
import MapHelperUtil from '../utils/mapHelpersUtil';
import MapBoxWrapper from '../../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import { ENTITY_TYPE } from '../../../../common/constants/entityTypes';
import MapUtil from '../../../../common/utils/mapUtil';
import { MAP_ADDITIONAL_FEATURES_TOGGLES } from '../../../../common/constants/mapAdditionalFeaturesData';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const delivery = ['==', ['get', 'deliveryType'], 'delivery'];
const pickup = ['==', ['get', 'deliveryType'], 'unsuccessful-attempt'];

/**
 * A wrapper for CourierAnalysis map functionalities
 *
 * @component
 * @alias MapWrapperClass
 * @category CourierAnalysis
 */
class MapWrapperClass extends React.Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.collected = null;
    this.unsuccessful = null;
    this.allStops = null;
    this.defaultView = MapUtil.getInitialViewStateForCompany();

    props.dispatchIsMapLoading(true);
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAP_STYLE,
      center: [this.defaultView.lng, this.defaultView.lat],
      zoom: this.defaultView.zoom
    });

    this.map = map;
    this.props.dispatchSetMap(map);
    this.StopsUtil = new StopsUtilClass(map, this.props.t, true);
    this.packageLockerUtil = new PackageLockerUtilClass(map);

    this.addMapControls();
    this.setMapEvents();

    this.loadMapData(true, true);
    this.props.dispatchIsMapLoading(false);
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.hasStops && !this.props.hasStops) || prevProps.teamId !== this.props.teamId || (prevProps.oohPoint !== this.props.oohPoint)) {
      this.StopsUtil.removeAllMarkers();
      this.StopsUtil.hideStopsLayer();

      this.packageLockerUtil.removeMapMarkers(MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS);
    }

    if (
      prevProps.showCollected !== this.props.showCollected
      || prevProps.showUnsuccessful !== this.props.showUnsuccessful
      || prevProps.selectedDay !== this.props.selectedDay
      || prevProps.selectedHours !== this.props.selectedHours
      || prevProps.selectedDwellTimeInterval !== this.props.selectedDwellTimeInterval
      || prevProps.selectedPointDistanceInterval !== this.props.selectedPointDistanceInterval
      || prevProps.stopsData !== this.props.stopsData
      || prevProps.oohPoint !== this.props.oohPoint
    ) {
      this.StopsUtil.removeAllMarkers();

      const didHeatmapOrBarChartChange = prevProps.selectedDay !== this.props.selectedDay
      || prevProps.selectedHours !== this.props.selectedHours
      || prevProps.selectedDwellTimeInterval !== this.props.selectedDwellTimeInterval
      || prevProps.selectedPointDistanceInterval !== this.props.selectedPointDistanceInterval;

      const didCourierOrDateChange = (prevProps.oohPoint !== this.props.oohPoint) || (prevProps.stopsData !== this.props.stopsData);

      if (didCourierOrDateChange) {
        this.StopsUtil.hideStopsLayer();

        this.packageLockerUtil.removeMapMarkers(MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS);
      }

      this.loadMapData(didHeatmapOrBarChartChange, didCourierOrDateChange);
    }

    if (
      this.props.entityType === ENTITY_TYPE.PACKAGE_LOCKERS
      && this.props.stopsData
      && ((prevProps.stopsData && prevProps.stopsData.courierId !== this.props.stopsData.courierId) || (!prevProps.stopsData && this.props.stopsData.courierId))
    ) {
      if (this.props.stopsData.stops && this.props.stopsData.stops.length > 0) {
        this.map.flyTo({
          center: [this.props.stopsData.stops[0].lng, this.props.stopsData.stops[0].lat],
          zoom: 17
        });
      }
    }
  }

  /**
   * Place where we set all map events
   *
   * @function
   */
  setMapEvents = () => {
    this.map.on('data', (e) => {
      if (e.isSourceLoaded) {
        this.StopsUtil.updateMarkers(this.props.entityType);
      }
    });
    this.map.on('move', () => {
      this.StopsUtil.updateMarkers(this.props.entityType);
    });
    this.map.on('moveend', () => {
      this.StopsUtil.updateMarkers(this.props.entityType);
    });
  };

  /**
   * Place where we add all map controls
   *
   * @function
   */
  addMapControls = () => {
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl());
  };

  /**
   * Add all map sources
   *
   * @param {object.<Array>} collected - all collected
   * @param {object.<Array>} pickups - all pickups
   * @param {object.<Array>} allStops - all stops
   * @function
   */
  addAllSources = (collected, pickups, allStops) => {
    if (this.map.getSource('stops')) {
      this.map.getSource('stops')
        .setData({
          features: [...collected, ...pickups],
          type: 'FeatureCollection'
        });
      this.map.getSource('unclustered_stops')
        .setData({
          features: [...collected, ...pickups],
          type: 'FeatureCollection'
        });
    } else {
      this.map.addSource('stops', {
        type: 'geojson',
        data: {
          features: [...collected, ...pickups],
          type: 'FeatureCollection'
        },
        cluster: true,
        clusterMaxZoom: 12,
        clusterRadius: 50,
        clusterProperties: {
          delivery: ['+', ['case', delivery, 1, 0]],
          pickup: ['+', ['case', pickup, 1, 0]]
        }
      });

      this.map.addSource('unclustered_stops', {
        type: 'geojson',
        data: {
          features: allStops || [],
          type: 'FeatureCollection'
        }
      });
    }
  };

  /**
   * Add all sources and layers needed for courier analysis
   *
   * @param {boolean} didHeatmapOrBarChartChange - did chart change
   * @param {boolean} didCourierOrDateChange - did courier or date change
   * @function
   */
  loadMapData = async (didHeatmapOrBarChartChange = false, didCourierOrDateChange = true) => {
    const oohPoint = this.props.oohPoint;

    if (didHeatmapOrBarChartChange || didCourierOrDateChange) {
      const geoFeatures = this.getStopGeoFeatures();
      this.collected = geoFeatures.deliveryGeoFeatures;
      this.unsuccessful = geoFeatures.unsuccessfulGeoFeatures;
      this.allStops = [...this.collected, ...this.unsuccessful];
      if (didCourierOrDateChange) {
        this.map.flyTo({
          center: [oohPoint.lng, oohPoint.lat],
          zoom: 14
        });
      }
    }

    // wait for map to finish loading
    let intervalHandler = null;
    intervalHandler = setInterval(() => {
      const isDone = Array.from({ length: 10 }, () => this.map.isStyleLoaded())
        .every((l) => l === true);

      if (isDone) {
        clearInterval(intervalHandler);
        const collected = this.props.showCollected && this.collected ? [...this.collected] : [];
        const unsuccessful = this.props.showUnsuccessful && this.unsuccessful ? [...this.unsuccessful] : [];

        this.addAllSources(collected, unsuccessful, this.allStops);

        if (didCourierOrDateChange) {
          this.props.dispatchIsMapLoading(true);
        }

        this.StopsUtil.plotStops(false);

        if (oohPoint) {
          this.packageLockerUtil.updateStops([...collected, ...unsuccessful]);
          this.packageLockerUtil.removeMapMarkers(MAP_ADDITIONAL_FEATURES_TOGGLES.PACKAGE_LOCKERS);
          this.packageLockerUtil.plotPackageLockerIcon(oohPoint, 'no-hex', this.props.distanceType);
        }

        this.props.dispatchIsMapLoading(false);
      }
    }, 250);
  };

  /**
   * Get stops feature collection
   *
   * @returns {object} delivery and pickups geo-features
   * @function
   */
  getStopGeoFeatures = () => {
    const deliveries = [];
    const unsuccessful = [];

    if (this.props.stopsData) {
      this.props.stopsData.forEach((stop) => {
        let shouldPushStop = true;
        if (this.props.selectedDay?.size > 0) {
          shouldPushStop = FiltersUtil.checkDayIntervalSelection(stop.dayOfTheWeek, this.props.selectedDay);
        }

        if (this.props.selectedHours?.size > 0) {
          shouldPushStop = shouldPushStop && FiltersUtil.checkHourIntervalSelection(stop.hour, this.props.selectedHours);
        }

        if (this.props.selectedDwellTimeInterval) {
          shouldPushStop = shouldPushStop && FiltersUtil.checkDwellTimeIntervalSelection(stop.dwellTime, this.props.selectedDwellTimeInterval);
        }

        if (this.props.selectedPointDistanceInterval) {
          shouldPushStop = shouldPushStop && (FiltersUtil.checkDistanceIntervalSelection(stop.geoDistance, this.props.selectedPointDistanceInterval));
        }

        if (!shouldPushStop) {
          return;
        }

        const feature = {
          type: 'Feature',
          properties: {
            id: Math.random()
              .toString(36)
              .substring(7),
            entityType: this.props.entityType,
            actualLong: MapHelperUtil.getStringFromFloat(stop.geoLng),
            actualLat: MapHelperUtil.getStringFromFloat(stop.geoLat),
            time: stop.timestamp,
            dayOfTheWeek: stop.day, // dayOfTheWeek do we need this?
            shipmentCode: stop.shipmentCode,
            dwellTime: `${stop.dwellTime.toFixed(2)} h`,
            // numberOfShipments: stop.shipmentCodes.length,
            numberOfPackages: stop.numPackages
          },
          geometry: {
            coordinates: [stop.geoLng, stop.geoLat],
            type: 'Point'
          }
        };
        feature.properties.address = stop.address;
        feature.properties.deliveryType = stopsUtil.getStopType(stop.event);
        if (stop.geoLng && stop.geoLng) {
          feature.properties.oohPointDistance = `${haversine({
            lng: this.props.oohPoint.lng,
            lat: this.props.oohPoint.lat
          }, {
            lng: stop.geoLng,
            lat: stop.geoLat
          })
            .toFixed(2)} m`;
        }

        if (feature.properties.deliveryType === 'delivery') {
          feature.properties.titleOverride = 'collected';
          deliveries.push(feature);
        } else {
          feature.properties.titleOverride = 'unsuccessful-attempt';
          unsuccessful.push(feature);
        }
      });
    }

    return {
      deliveryGeoFeatures: deliveries,
      unsuccessfulGeoFeatures: unsuccessful
    };
  };

  setMapRef = (ref) => {
    this.mapContainer = ref;
  };

  render() {
    return (
      <div className="ooh-point-analysis-map-wrapper">
        <MapBoxWrapper setMapRef={this.setMapRef} />
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.chartState, ...store.authState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    dispatchIsMapLoading: MapActions.mapIsLoading,
    dispatchSetMap: MapActions.setMap
  }, dispatch);
}

MapWrapperClass.propTypes = {
  /**
   * Chart data
   */
  stopsData: PropTypes.arrayOf(PropTypes.object),
  /**
   * Ooh point data
   */
  oohPoint: PropTypes.object,
  /**
   * Flag for showing pickups on chart
   */
  showUnsuccessful: PropTypes.bool,
  /**
   * Flag for showing deliveries on chart
   */
  showCollected: PropTypes.bool,
  /**
   * Set if map is loading
   */
  dispatchIsMapLoading: PropTypes.func.isRequired,
  /**
   * Selected team ID
   */
  teamId: PropTypes.string.isRequired,
  /**
   * Translate function
   */
  t: PropTypes.func.isRequired,
  /**
   * True if we are done loading stops
   */
  hasStops: PropTypes.bool,
  /**
   * Days selected on charts
   */
  selectedDay: PropTypes.object,
  /**
   * Hours selected on charts
   */
  selectedHours: PropTypes.object,
  /**
   * Dwell times selected
   */
  selectedDwellTimeInterval: PropTypes.object,
  /**
   * Distances selected on charts
   */
  selectedPointDistanceInterval: PropTypes.object,
  /**
   * Entity type
   */
  entityType: PropTypes.string,
  dispatchSetMap: PropTypes.func.isRequired,
  /*
   * Distance type
   */
  distanceType: PropTypes.string.isRequired,
};

MapWrapperClass.defaultProps = {
  stopsData: null,
  oohPoint: null,
  showCollected: true,
  showUnsuccessful: true,
  hasStops: false,
  selectedDay: null,
  selectedHours: null,
  selectedDwellTimeInterval: null,
  selectedPointDistanceInterval: null,
  entityType: ENTITY_TYPE.COURIERS
};

const MapWrapper = connect(mapStateToProps, mapDispatchToProps)(MapWrapperClass);

export default withTranslation('translations')(MapWrapper);
