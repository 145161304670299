import * as React from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import 'react-toggle/style.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import MapBoxWrapper from '../../../common/components/wrappers/mapBoxWrapper/MapBoxWrapper';
import { COURIER_LOCATION_ZOOM, MAP_STYLE } from '../../../common/constants/mapConstants';
import * as MapActions from '../../../state/actions/mapActions';
import './RealTimeMapWrapper.scss';
import RouteUtilClass from '../../analysis/routeAnalysis/utils/routeUtilClass';
import PinPlottingUtilClass from '../../../common/utils/pinPlotingUtil';
import MapModeButton from '../../../common/components/buttons/mapModeButton/MapModeButton';
import MixPanel from '../../../setup/mixPanel';
import MapUtil from '../../../common/utils/mapUtil';
import PinLegend from './PinLegend';
import AuthUtil from '../../../common/utils/authUtil';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class RealTimeMapWrapperClass extends React.Component {
  constructor(props) {
    super(props);

    this.map = null;

    this.state = { routeShown: this.isRouteHighQuality() };

    props.dispatchIsMapLoading(true);

    this.defaultView = MapUtil.getInitialViewStateForCompany();
    this.flyToCourierLocationPending = false;
    this.additionalLocationMarker = null;
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: MAP_STYLE,
      center: [this.defaultView.lng, this.defaultView.lat],
      zoom: this.defaultView.zoom
    });

    this.courierMapMarker = null;

    this.addMapControls();

    this.RouteUtil = new RouteUtilClass(this.map, this.props.t);
    this.PinPlottingUtil = new PinPlottingUtilClass(this.map, this.props.t);

    this.props.dispatchIsMapLoading(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.courierId !== prevProps.courierId) {
      this.clearMap();
    }
    if (!this.props.locationData && !this.props.shipmentsData) {
      this.clearMap();
    } else if (this.props.locationData && prevProps.locationData !== this.props.locationData) {
      if (this.flyToCourierLocationPending && this.props.locationData?.lng && this.props.locationData?.lat) {
        this.map.flyTo({ center: [this.props.locationData.lng, this.props.locationData.lat], zoom: COURIER_LOCATION_ZOOM });
        this.flyToCourierLocationPending = false;
      }
      this.courierMapMarker = this.PinPlottingUtil.plotCourierLocation(
        this.courierMapMarker,
        this.props.locationData.lat,
        this.props.locationData.lng,
        this.props.locationLive
      );
      if (this.state.routeShown) this.plotRoute();
    }

    if (this.props.shipmentsData && prevProps.shipmentsData !== this.props.shipmentsData) {
      this.PinPlottingUtil.plotPins(this.props.shipmentsData, 'realTime');
    }

    if (this.props.locationLive !== prevProps.locationLive && this.props.locationData) {
      this.courierMapMarker = this.PinPlottingUtil.plotCourierLocation(
        this.courierMapMarker,
        this.props.locationData.lat,
        this.props.locationData.lng,
        this.props.locationLive
      );
    }

    if (this.props.lng && this.props.lat) {
      this.map.flyTo({
        center: [this.props.lng, this.props.lat],
        zoom: this.props.zoom
      });

      this.props.dispatchCenterToStop(null, null, 16);
    }

    if (prevProps.additionalLocationMarker !== this.props.additionalLocationMarker) {
      if (this.additionalLocationMarker) {
        this.additionalLocationMarker.remove();
        this.additionalLocationMarker = null;
      }

      if (this.props.additionalLocationMarker) {
        this.additionalLocationMarker = this.PinPlottingUtil.plotCourierLocation(
          this.additionalLocationMarker,
          this.props.additionalLocationMarker.lat,
          this.props.additionalLocationMarker.lng,
          false,
          'additional-courier-location'
        );
      }
    }
  }

  clearMap = () => {
    this.PinPlottingUtil.removeAllMarkers();
    if (this.courierMapMarker) {
      this.courierMapMarker.remove();
      this.courierMapMarker = null;
    }

    if (this.additionalLocationMarker) {
      this.additionalLocationMarker.remove();
      this.additionalLocationMarker = null;
    }
    this.flyToCourierLocationPending = true;

    this.RouteUtil.removeRouteMapData();
  };

  isRouteHighQuality = () => {
    if (process.env.REACT_APP_DATA_SOURCE === 'api') {
      return AuthUtil.isLocationDataHighQuality();
    }
    return true;
  };

  plotRoute = () => {
    const properties = [];
    const coordinates = this.props.locationData.route.map((location) => {
      properties.push({ gpsTimestamp: location.gpsTimestamp });
      return [location.lng, location.lat];
    });
    this.RouteUtil.removeRouteMapData();
    this.RouteUtil.plotRoute(coordinates, properties, this.isRouteHighQuality(), this.props.locationData.mileage);
  };

  toggleRouteVisibility = () => {
    MixPanel.track(`Real-time - Show courier route turned ${this.state.routeShown ? 'off' : 'on'}`);
    if (this.state.routeShown) {
      this.RouteUtil.removeRouteMapData();
      this.setState({ routeShown: false });
    } else {
      if (this.props.locationData) this.plotRoute();
      this.setState({ routeShown: true });
    }
  };

  /**
   * Place where we add all map controls
   *
   * @function
   */
  addMapControls = () => {
    this.map.addControl(new mapboxgl.FullscreenControl());
    this.map.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl());
  };

  setMapRef = (ref) => {
    this.mapContainer = ref;
  };

  render() {
    return (
      <div className="real-time-map-wrapper">
        <div className="live-location-wrapper">
          <MapModeButton
            customKey="live-location"
            dataTip={this.props.t('Show courier current location')}
            onClick={() => {
              if (this.courierMapMarker) {
                const location = this.courierMapMarker.getLngLat();
                this.props.dispatchCenterToStop(location.lat.toString(), location.lng.toString(), 16);
              }
              MixPanel.track('Real-time - Show current courier location clicked');
            }}
            icon="icon-live-location"
          />
        </div>
        <div className="route-toggle-wrapper">
          <MapModeButton
            customKey="route-toggle"
            dataTip={this.props.t('Route show toggle')}
            onClick={this.toggleRouteVisibility}
            isActive={this.state.routeShown}
            icon="icon-road"
          />
        </div>
        <div className="pin-legend-wrapper">
          <PinLegend />
        </div>
        <MapBoxWrapper setMapRef={this.setMapRef} />
      </div>
    );
  }
}

/**
 * @param {object} store store object
 * @returns {object} extended state
 */
function mapStateToProps(store) {
  return { ...store.chartState, ...store.mapState };
}

/**
 * @param {Function} dispatch - dispatch function
 * @returns {object} The object mimicking the original object, but with every action creator wrapped into the dispatch call.
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchIsMapLoading: MapActions.mapIsLoading,
      dispatchCenterToStop: MapActions.centerToStop
    },
    dispatch
  );
}

RealTimeMapWrapperClass.propTypes = {
  locationData: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string,
    timestamp: PropTypes.string,
    route: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.string,
        lng: PropTypes.string,
        dayOfTheWeek: PropTypes.string,
        hour: PropTypes.string
      })
    ),
    mileage: PropTypes.number
  }),
  shipmentsData: PropTypes.arrayOf(PropTypes.object),
  courierId: PropTypes.string,
  dispatchIsMapLoading: PropTypes.func.isRequired,
  lat: PropTypes.string,
  lng: PropTypes.string,
  zoom: PropTypes.number,
  additionalLocationMarker: PropTypes.object,
  t: PropTypes.func.isRequired,
  dispatchCenterToStop: PropTypes.func.isRequired,
  locationLive: PropTypes.bool
};

RealTimeMapWrapperClass.defaultProps = {
  locationData: null,
  shipmentsData: null,
  courierId: null,
  lat: null,
  lng: null,
  zoom: null,
  additionalLocationMarker: null,
  locationLive: false
};

const RealTimeMapWrapper = connect(mapStateToProps, mapDispatchToProps)(RealTimeMapWrapperClass);

export default withTranslation('translations')(RealTimeMapWrapper);
