import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form } from '../../../../../common/components/form/Form';
import { FormInput } from '../../../../../common/components/form/components/FormInput';
import Button from '../../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../../common/components/form/utils/defaultFormDialogCss.scss';
import './RecommenderDialogInputForm.scss';
import { COVERAGE, LOCATIONS } from '../../constants/formTypes';
import MixPanel from '../../../../../setup/mixPanel';
import { BUTTON_TYPES } from '../../../../../common/components/buttons/button/constants/buttonTypes';
import { LABEL_POSITION } from '../../../../../common/constants/uiConstants';
import DividerLine from '../../../../../common/components/dividerLine/DividerLine';

const CoverageType = {
  BASE_LAYER: 'baseLayer',
  BASE_WITH_ENRICHMENT_LAYERS: 'baseWithEnrichmentLayers',
};

class CoverageInputForm extends React.Component {
  constructor(props) {
    super(props);

    this.formDefaults = this.getDefaultValuesByType();

    this.state = {
      formData: {
        coverage: this.formDefaults.coverage,
        locations: '',
        placePins: true,
        coverageType: this.formDefaults.coverageType
      }
    };
    this.formRef = null;
  }

  getDefaultValuesByType = () => {
    switch (this.props.formType) {
      case LOCATIONS:
        return {
          coverage: '100',
          title: this.props.t('Generate new locations'),
          locationsDescription: this.props.t('Enter wanted number of locations to add'),
          placePins: false,
          coverageType: CoverageType.BASE_WITH_ENRICHMENT_LAYERS
        };
      case COVERAGE:
        return {
          coverage: '',
          title: this.props.t('Generate higher coverage'),
          locationsDescription: this.props.t('Enter maximum number of locations to add'),
          placePins: false,
          coverageType: CoverageType.BASE_LAYER
        };
      default:
        return null;
    }
  };

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    if (this.props.submitCallback) {
      this.props.submitCallback({ ...this.state.formData, layers: [{ layerType: 'population', weight: 1 }] });
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  cancelCallback = () => {
    MixPanel.track('Delivery Area Analysis - recommendation form canceled', { formType: this.props.formType });

    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  render() {
    return (
      <div className="coverage-input-form">
        <div className="title">{this.formDefaults.title}</div>
        <div className="close-button" onClick={this.cancelCallback}>
          <i className="icon icon-close" />
        </div>
        <DividerLine />
        <Form
          ref={(formRef) => {
            this.formRef = formRef;
          }}
          formData={this.state.formData}
          onFormChange={(newFormData) => {
            this.setState({ formData: newFormData });
          }}
        >
          {this.props.formType === COVERAGE && (
            <div>
              <div className="description">{this.props.t('Enter the target coverage you wish to achieve')}</div>
              <FormInput
                inputType="input-number"
                label={this.props.t('Coverage')}
                name="coverage"
                validation={{ required: true, isNumber: true }}
                labelPosition={LABEL_POSITION.LEFT}
                placeholder={this.props.t('Enter percentage')}
              />
            </div>
          )}
          <div className="description">{this.formDefaults.locationsDescription}</div>
          <FormInput
            inputType="input-number"
            label={this.props.t('Locations')}
            name="locations"
            validation={{ required: true, isNumber: true }}
            labelPosition={LABEL_POSITION.LEFT}
            placeholder={this.props.t('Enter a number')}
          />
          <FormInput inputType="toggle" label={this.props.t('Place pins')} name="placePins" checked={this.state.formData.placePins} />
        </Form>

        <DividerLine />
        <div className="buttons">
          <Button onClick={this.cancelCallback} type={BUTTON_TYPES.SECONDARY} text={this.props.t('Cancel')} />
          <Button onClick={this.handleSubmit} type={BUTTON_TYPES.PRIMARY} text={this.props.t('Generate')} />
        </div>
      </div>
    );
  }
}

CoverageInputForm.propTypes = {
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  formType: PropTypes.string,
};

CoverageInputForm.defaultProps = {
  submitCallback: null,
  cancelCallback: null,
  formType: COVERAGE,
};

export default withTranslation('translations')(CoverageInputForm);
