import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import SimpleBar from 'simplebar-react';
import { Form } from '../../../../../common/components/form/Form';
import { FormInput } from '../../../../../common/components/form/components/FormInput';
import Button from '../../../../../common/components/buttons/button/Button';
import AppDialogActionsWrapper from '../../../../../common/components/dialogs/utils/appDialogActionsWrapper';
import '../../../../../common/components/form/utils/defaultFormDialogCss.scss';
import ApiHelpers from '../../../../../common/utils/api/apiHelpers';
import SortUtil from '../../../../../common/utils/sortUtil';
import { raygunClient } from '../../../../../setup/raygunClient';
import * as PageActions from '../../../../../state/actions/pageActions';
import TeamsApi from '../../../couriers/api/teamsApi';
import DeliveryAreasApi from '../../../deliveryAreas/api/deliveryAreasApi';
import HubApi from '../../../hubs/api/hubApi';
import './ZoneForm.scss';
import 'simplebar/dist/simplebar.min.css';
import { BUTTON_TYPES } from '../../../../../common/components/buttons/button/constants/buttonTypes';

class ZoneForm extends React.Component {
  constructor() {
    super();
    this.state = { formData: null };

    this.formRef = null;
    this.deliveryAreas = null;
    this.teams = null;
    this.hubs = null;
  }

  componentDidMount() {
    const deliveryAreasPromise = DeliveryAreasApi.getAllDeliveryAreas().then((res) => SortUtil.sortArrayByUpperCaseField(res?.data?.getDeliveryAreas || [], 'name'));

    const teamsPromise = TeamsApi.getTeams().then((res) => SortUtil.sortArrayByUpperCaseField(res?.data?.getTeams || [], 'name'));

    const hubsPromise = HubApi.getHubs().then((res) => SortUtil.sortArrayByUpperCaseField(res?.data?.getHubs || [], 'name'));

    Promise.all([deliveryAreasPromise, teamsPromise, hubsPromise])
      .then((res) => {
        const [deliveryAreas, teams, hubs] = res;
        this.deliveryAreas = deliveryAreas;
        this.teams = teams;
        this.hubs = hubs;
        this.setState({
          formData: this.createFormDataFromDefaultFeatures(
            {
              deliveryAreas,
              teams,
              hubs
            },
            !!this.props.zone
          )
        });
        this.props.dispatchLoadingPage(false);
      })
      .catch((e) => {
        raygunClient.send(e, 'Error zone form data');
        toast.error(this.props.t('Oops something went wrong'));
        this.props.dispatchLoadingPage(false);
      });
  }

  createFormDataFromDefaultFeatures = (rowFeatures, usePolicyValue) => {
    const features = ApiHelpers.removeTypenameFromObject(rowFeatures);
    const formData = {
      name: this.props.zone?.name,
      description: this.props.zone?.description
    };

    Object.keys(features).forEach((pageGroup) => {
      if (pageGroup === 'name' || pageGroup === 'description') {
        return;
      }

      if (features[pageGroup] && features[pageGroup].length > 0) {
        features[pageGroup].forEach((page) => {
          const id = page.id || page;
          formData[`${pageGroup}.${id}`] = usePolicyValue ? this.props.zone && this.props.zone[pageGroup] && this.props.zone[pageGroup].includes(id) : false;
        });
      }
    });

    return formData;
  };

  convertFeaturesToFormData = (rowFeatures) => {
    const features = ApiHelpers.removeTypenameFromObject(rowFeatures);
    const formData = {
      name: this.props.zone?.name,
      description: this.props.zone?.description
    };

    Object.keys(features).forEach((pageGroup) => {
      if (pageGroup === 'name' || pageGroup === 'description') {
        return;
      }

      if (features[pageGroup] && features[pageGroup].length > 0) {
        features[pageGroup].forEach((page) => {
          const id = page.id || page;
          formData[`${pageGroup}.${id}`] = true;
        });
      }
    });

    return formData;
  };

  convertPolicyToFormData = (features) => this.convertFeaturesToFormData(features, true);

  convertFormDataToPolicy = (formData) => {
    const zoneData = {
      name: formData.name,
      description: formData.description
    };

    Object.keys(formData)
      .filter((k) => formData[k])
      .forEach((key) => {
        if (key === 'name' || key === 'description') {
          return;
        }
        const parts = key.split('.');
        if (!zoneData[parts[0]]) {
          zoneData[parts[0]] = [];
        }

        zoneData[parts[0]].push(parts[1]);
      });

    return zoneData;
  };

  handleSubmit = () => {
    if (!this.formRef || !this.formRef.isFormValid()) {
      return;
    }

    const zoneData = this.convertFormDataToPolicy(this.state.formData);

    const newPayload = ApiHelpers.removeTypenameFromObject(zoneData);

    if (this.props.submitCallback) {
      this.props.submitCallback(newPayload);
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  validateFormData = (formData) => {
    const a = this.convertFormDataToPolicy(formData);
    const b = this.convertPolicyToFormData(a);
    return {
      ...b,
      name: formData.name,
      description: formData.description || ''
    };
  };

  cancelCallback = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }

    AppDialogActionsWrapper.closeAppDialog();
  };

  render() {
    return (
      <SimpleBar style={{ maxHeight: 'calc(100vh - 64px)' }}>
        <div className="zone-form">
          {this.state.formData && (
            <Form
              ref={(formRef) => {
                this.formRef = formRef;
              }}
              formData={this.state.formData}
              onFormChange={(newFormData) => {
                const validatedFormData = this.validateFormData(newFormData);
                this.setState({ formData: validatedFormData });
              }}
              serverErrors={this.state.serverErrors}
            >
              <FormInput name="name" inputType="input" label={this.props.t('Zone name')} placeholder={this.props.t('enter-name')} />
              <FormInput name="description" inputType="input" label={this.props.t('Description')} placeholder={this.props.t('Enter text')} />
              <div className="page-group-title">{this.props.t('zone-form-da-title')}</div>
              <div key="da" className="page-group">
                {this.deliveryAreas.map((da) => (
                  <FormInput
                    key={da.id}
                    name={`deliveryAreas.${da.id}`}
                    inputType="toggle"
                    label={da.name}
                    defaultChecked={this.props?.zone?.deliveryAreas?.includes(da.id)}
                    checked={!!this.state.formData[`deliveryAreas.${da.id}`]}
                  />
                ))}
              </div>
              <div className="page-group-title">{this.props.t('zone-form-teams-title')}</div>
              <div key="team" className="page-group">
                {this.teams.map((team) => (
                  <FormInput
                    key={team.id}
                    name={`teams.${team.id}`}
                    inputType="toggle"
                    label={team.name}
                    defaultChecked={this.props?.zone?.teams?.includes(team.id)}
                    checked={!!this.state.formData[`teams.${team.id}`]}
                  />
                ))}
              </div>
              <div className="page-group-title">{this.props.t('zone-form-hubs-title')}</div>
              <div key="hubs" className="page-group">
                {this.hubs.map((team) => (
                  <FormInput
                    key={team.id}
                    name={`hubs.${team.id}`}
                    inputType="toggle"
                    label={team.name}
                    defaultChecked={this.props?.zone?.hubs?.includes(team.id)}
                    checked={!!this.state.formData[`hubs.${team.id}`]}
                  />
                ))}
              </div>
              <div className="buttons">
                <Button onClick={this.cancelCallback} type={BUTTON_TYPES.SECONDARY} text={this.props.t('Cancel')} />
                <Button onClick={this.handleSubmit} type={BUTTON_TYPES.PRIMARY} text={this.props.t('Save')} />
              </div>
            </Form>
          )}
        </div>
      </SimpleBar>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { dispatchLoadingPage: PageActions.loadingPage },
    dispatch
  );
}

ZoneForm.propTypes = {
  zone: PropTypes.shape({
    deliveryAreas: PropTypes.arrayOf(PropTypes.string),
    teams: PropTypes.arrayOf(PropTypes.string),
    hubs: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string
  }),
  submitCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
  t: PropTypes.func.isRequired,
  dispatchLoadingPage: PropTypes.func.isRequired
};

ZoneForm.defaultProps = {
  zone: null,
  submitCallback: null,
  cancelCallback: null
};

export default withTranslation('translations')(connect(null, mapDispatchToProps)(ZoneForm));
