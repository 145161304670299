import * as turf from '@turf/turf';
import * as h3 from 'h3-js';
import geojson2h3 from 'geojson2h3';
import { H3_HEX_RESOLUTION } from '../constants/mapConstants';
import DistanceTypeUtil, { DISTANCE_TYPE,
  DISTANCE_VALUE_TYPE_KEYS_ARRAY, } from './distanceTypeUtil';
import oohAnalysisApi from '../../features/analysis/oohAnalysis/api/oohAnalysisApi';

export class PolygonCalculationsUtilClass {
  constructor() {
    this.fetchedIsochronePolygonsData = {};
  }

  /*
   * Fetch isochrone polygon data for each map point using the routing API.
   *
   * @param {Object} mapPoint - map point (package locker or shop) to get isochrones for.
   * @param {String} distanceType - costing type. See ISOCHRONE.DISTANCE_TYPE in mapAdditionalFeaturesData.js
   * @param {Boolean} asHexagons - if true, returns hexagonsCovered in addition to polygonFeatures
   * @returns Promise<{Array}> - Promise of an array of polygonData for each map point. Each item of the array is an
   *                             object with the following structure:
   *                             { polygonFeatures: Object, hexagonsCovered: Object | null }
   *                             The polygonFeatures object is a geojson with the same number of features as the number
   *                             of times requested. The hexagonsCovered is filled up if asHexagons is true and contains
   *                             the set of hexagons contained in the return polygon.
   */
  getIsochrones(mapPoint, distanceType, asHexagons = false) {
    return oohAnalysisApi.getIsochronesAsPolygons(mapPoint, distanceType).then((responses) => {
      // The number of properties of the response object is the same as the length of times since that is the number of
      // futures we created when doing `times.map`.
      // So Initial response is:
      // innerIsochrone: [polygon0, polygon1, polygon2, ...], outerIsochrone: [...], ...
      // We want to convert this to the following:
      // polygon0: [innerIsochrone, outerIsochrone, ...]
      const allIsochrones = [responses.innerIsochrone, responses.outerIsochrone];
      if (asHexagons) {
        return this.convertHexagonsToPolyFeatures(allIsochrones);
      }
      return {
        polygonFeatures: {
          type: 'FeatureCollection',
          features: allIsochrones.map((polygon) => {
            return {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: polygon
              }
            };
          })
        },
        hexagonsCovered: null
      };
    });
  }

  getIsochroneFromRadialDistance(lat, lng, asHexagons = false) {
    const isochronePolygon = this.generateRadialPolygons(lat, lng);
    if (asHexagons) {
      return this.convertPolygonsToHexPolygon(isochronePolygon.features);
    }

    return {
      polygonFeatures: isochronePolygon,
      hexagonsCovered: null
    };
  }

  /*
   * @param {Array} mapPoints - array of map mapPoints
   * @param {String} distanceType - costing type. See ISOCHRONE.DISTANCE_TYPE in mapAdditionalFeaturesData.js
   * @param {Boolean} asHexagons - if true, returns hexagonsCovered in addition to polygonFeatures
   * @returns {Array} - array of isochrone polygon data for each map point. Each item of the array is an object with
   *                    polygonFeatures and hexagonsCovered properties.
   */
  async getIsochronesPolygonData(mapPoint, distanceType, asHexagons = false) {
    const id = this.createIdFromLatLng(mapPoint.lat, mapPoint.lng, distanceType);

    if (this.fetchedIsochronePolygonsData && this.fetchedIsochronePolygonsData[id]) {
      return this.fetchedIsochronePolygonsData[id];
    }

    const fetchedIsochrones = DistanceTypeUtil.shouldFetchIsochronesFromBack(distanceType)
      ? await this.getIsochrones(mapPoint, distanceType, asHexagons)
      : this.getIsochroneFromRadialDistance(mapPoint.lat, mapPoint.lng, asHexagons);
    // Populate the cache. For each mapPointToFetch we have one result.
    this.fetchedIsochronePolygonsData[id] = fetchedIsochrones;

    return fetchedIsochrones;
  }

  /*
 * Generate polygon for a map point using the haversine distance.
 * @param {Number} lat - latitude of the map point.
 * @param {Number} lng - longitude of the map point.
 * @returns {Object} - isochrone polygon for the map point with structure
 *                     { features: Array, type: String }. Refer to turf.circle.
 */
  generateRadialPolygons(lat, lng) {
    const distances = DistanceTypeUtil.getDistanceValues(DISTANCE_TYPE.RADIAL);
    const centerPoint = turf.point([lng, lat]);
    const circleAreaFeatures = distances.map((distance) => {
      return turf.circle(centerPoint, distance, { steps: 64, units: 'meters' });
    });
    return {
      features: circleAreaFeatures,
      type: 'FeatureCollection'
    };
  }

  /*
   * Converts a set of hexagons to a polygon bounded by those hexagons.
   * @param {Array} hexagonsForDistances - Array of hexagons covered for each distance. Could be calculated using
   *                                       routing api or haversine distance/turfjs.
   * @param {Object} properties - properties to add to the polygon features.
   * @returns {Object} - isochrone polygon for the map point with structure
   *                    { polygonFeatures: Object, hexagonsCovered: Object }
   *                    polygonFeatures is the transformed geojson and hexagonsCovered is the set of hexagons covered
   *                    for each distance.
   */
  convertHexagonsToPolyFeatures(hexagonsForDistances, properties = {}) {
    const polygonHexagonsCovered = {};
    const collection = hexagonsForDistances.map((hexagonsCovered, i) => {
      polygonHexagonsCovered[DISTANCE_VALUE_TYPE_KEYS_ARRAY[i]] = hexagonsCovered;
      return geojson2h3.h3SetToFeature(hexagonsCovered, properties);
    });
    return {
      polygonFeatures: {
        type: 'FeatureCollection',
        features: collection
      },
      hexagonsCovered: polygonHexagonsCovered
    };
  }

  /*
   * Converts a polygon to a set of hexagons and then converts the set of hexagons to a polygon again.
   * The end result is a polygon bounded by the boundaries of the hexagons from the converted set.
   * @param {Array} polygonFeatures - array of polygon features. Refer to generateIsochronePolygon.
   * @returns {Object} - isochrone polygon for the map point with structure
   *                    { polygonFeatures: Object, hexagonsCovered: Object }
   *                    polygonFeatures is the transformed geojson and hexagonsCovered is the set of hexagons covered
   *                    for each distance. See convertHexagonsToPolyFeatures for more details.
   */
  convertPolygonsToHexPolygon(polygonFeatures) {
    const polygonsAsHexagonSet = polygonFeatures.map((feature) => {
      return h3.polyfill(feature.geometry.coordinates, H3_HEX_RESOLUTION, true);
    });
    return this.convertHexagonsToPolyFeatures(polygonsAsHexagonSet);
  }

  createIdFromLatLng(lat, lng, distanceType) {
    return `${Number.parseFloat(lat).toFixed(6)}#${Number.parseFloat(lng).toFixed(6)}#${distanceType}`;
  }
}

export default new PolygonCalculationsUtilClass();
