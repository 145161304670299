import React from 'react';
import { Marker, Popup } from 'react-map-gl';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import haversine from 'haversine-distance';
import MixPanel from '../../../../../setup/mixPanel';
import MapPointMarker from './MapPointMarker';
import MapMarkerPopupMenu from '../../../../../common/components/popups/components/MapMarkerPopupMenu';

export default function UnwantedLocationMarkerWrapper(props) {
  const { t } = useTranslation();

  const [rightClickMenuOpened, setRightClickMenuOpened] = React.useState(false);

  function getPopupMenuButtonsData() {
    const popupButtons = [];
    const pointId = props.pin.id;

    popupButtons.push({
      title: t('Remove location'),
      onClickAction: () => {
        props.removeMarker(pointId);
        MixPanel.track('Delivery Area Analysis - Unwanted location pin removed');
        setRightClickMenuOpened(false);
      }
    });

    return popupButtons;
  }

  function isMarkerSelected() {
    return rightClickMenuOpened;
  }

  return (
    <>
      <Marker
        longitude={props.pin.lng}
        latitude={props.pin.lat}
        draggable
        onDragEnd={(e) => {
          props.updateMarkerData(e, props.pin);
          const distanceMovedInMeters = haversine(e.lngLat, { lat: props.pin.lat, lng: props.pin.lng }).toFixed(0);
          MixPanel.track('Delivery Area Analysis - Unwanted location pin moved', {
            prevlat: props.pin.lat,
            prevlng: props.pin.lng,
            newlat: e.lngLat.lat,
            newlng: e.lngLat.lng,
            distanceMovedInMeters: distanceMovedInMeters
          });
        }}
      >
        <MapPointMarker
          pinType={props.pin.type}
          isStaticFeaturePoint={props.isStaticFeaturePoint}
          isSelected={isMarkerSelected()}
          openRightClickMenu={setRightClickMenuOpened}
        />
      </Marker>

      {rightClickMenuOpened && (
        <Popup
          className="context-menu-popup"
          latitude={props.pin.lat}
          longitude={props.pin.lng}
          onClose={() => setRightClickMenuOpened(false)}
          offset={[105, -20]}
          closeButton={false}
        >
          <MapMarkerPopupMenu rowButtonsData={getPopupMenuButtonsData()} />
        </Popup>
      )}

    </>
  );
}

UnwantedLocationMarkerWrapper.propTypes = {
  pin: PropTypes.object,
  isStaticFeaturePoint: PropTypes.bool,
  updateMarkerData: PropTypes.func.isRequired,
  removeMarker: PropTypes.func
};

UnwantedLocationMarkerWrapper.defaultProps = {
  pin: null,
  isStaticFeaturePoint: false,
  removeMarker: null
};
