import { INPUT_SIZE } from '../constants/uiConstants';

export const UiUtil = { getInputSizeClassName: getInputSizeClassName };

function getInputSizeClassName(size) {
  switch (size) {
    case INPUT_SIZE.MEDIUM: {
      return 'size-m';
    }
    case INPUT_SIZE.LARGE: {
      return 'size-l';
    }
    default: {
      return '';
    }
  }
}
