import { POLYGON_COLORS } from '../../../common/constants/mapConstants';

/**
 * Util for planning related functionalities
 *
 * @namespace
 * @category planning
 */
const PlanningPageUtil = {
  hexToRGB: hexToRGB,
  getRegionIdFromPlanKey: getRegionIdFromPlanKey,
  jsonToCsv: jsonToCsv,
  getColorFromRegionId: getColorFromRegionId
};

/**
 * Create shipments tooltip as html string
 *
 * @param {string} planKey - plan key in format {CenterId}-{RegionId}
 * @returns {string} - regionId
 * @memberOf PlanningPageUtil
 * @function
 */
function getRegionIdFromPlanKey(planKey) {
  const separatorIndex = planKey.indexOf('-');

  return planKey.slice(separatorIndex + 1);
}

/**
 * Convert json object to csv
 *
 * @param {object} data - json data to convert to csv
 * @param {string} delimiter - delimiter for csv
 * @returns {string} - csv file
 * @memberOf PlanningPageUtil
 * @function
 */
function jsonToCsv(data, delimiter = ';') {
  const items = data;
  const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  return [
    header.join(delimiter), // header row first
    ...items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(delimiter))
  ].join('\r\n');
}

/**
 * Helper function to convert hex colors to rgb colors
 *
 * @param {string} h - hex color
 * @returns {Array.<number>} rgba color
 * @memberOf PlanningPageUtil
 * @function
 */
function hexToRGB(h, notTransparent) {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;

    // 6 digits
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }

  return [Number(r), Number(g), Number(b), notTransparent ? 200 : 256];
}

function getColorFromRegionId(regionId) {
  const numberReg = /\d+/;

  const colorIndex = regionId ? regionId.match(numberReg) || 0 : 0;

  if (POLYGON_COLORS[colorIndex]) {
    return POLYGON_COLORS[colorIndex];
  }

  return '#fff';
}

export default PlanningPageUtil;
