import React from 'react';
import './TableSearch.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { INPUT_SIZE } from '../../../constants/uiConstants';
import { UiUtil } from '../../../utils/uiUtil';

export default function TableSearch({ globalFilter, setGlobalFilter, searchPlaceholder, size }) {
  const { t } = useTranslation();

  const onChange = (val) => {
    setGlobalFilter(val || undefined);
  };

  return (
    <div className="table-search">
      <i className={`icon icon-icon-search ${globalFilter && globalFilter.length > 0 ? 'active' : ''}`} />
      <input
        className={`search ${UiUtil.getInputSizeClassName(size)}`}
        type="text"
        value={globalFilter || ''}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={searchPlaceholder || t('Search')}
        required
      />
    </div>
  );
}

TableSearch.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  size: PropTypes.oneOf(Object.values(INPUT_SIZE))
};

TableSearch.defaultProps = {
  globalFilter: null,
  searchPlaceholder: null,
  size: INPUT_SIZE.MEDIUM
};
